/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import cn from 'classnames';
import moment from 'moment';
import { NavLink, useNavigate } from 'react-router-dom';
import { IRootState, useSelector } from '../../../store';
import { WarningUnSavedChangesModal } from '../../Modals/WarningUnSavedChangesModal';
import { WeeklyTaskEntries, TimeSheetDataEntryType, TimeSheetDisplayWeek } from '../../../types/timesheetType';
import { UserProfile } from '../../../types/user';
import { checkIfTimesheetExists, submitWeeklyTimesheet } from '../../../services/employeeTimesheet';
import { ActionStatus } from '../../../types/employee';
import { TimeSheetData } from '../TimeSheetData';
import { calculateTotalHours, getHoursFromString } from '../../../helper/dateAndTime';
import { calculateColumnTotal, getWeekDaysByDate } from '../TimeSheetFn';
import '../TimeSheetData.scss';
import './EmployeeTimesheetMultiWeek.scss';
import { useAppContext } from '../../../hooks/useAppContext';
import { AlphaNumericAndCharactersRegex } from '../../../constants/validations';

const MAX_WEEKS_LIMIT = 1;
const MAX_TASK_LENGTH = 75;
type TimesheetExist = { requestNumber: string | null; timesheetId: string | null };
const EmployeeTimesheetMultiWeek = () => {
    const loggedInUser = useSelector((state: IRootState) => state.user) as UserProfile;
    const defaultZero = '00:00';
    const isLocked = false;
    const defaultsDataEntry = {
        task: { value: '', isLocked },
        entrycol1: { value: defaultZero, isLocked },
        entrycol2: { value: defaultZero, isLocked },
        entrycol3: { value: defaultZero, isLocked },
        entrycol4: { value: defaultZero, isLocked },
        entrycol5: { value: defaultZero, isLocked },
        entrycol6: { value: defaultZero, isLocked },
        entrycol7: { value: defaultZero, isLocked },
    } as TimeSheetDataEntryType;

    const currentDate = moment();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultError = {} as any;
    const { triggerSuccess } = useAppContext();
    const navigate = useNavigate();
    const [errors, setErrors] = useState(defaultError);
    const [editingRowIndex, setEditingRowIndex] = useState(-1);
    const [unSavedChangesShowModal, setUnSavedChangesShowModal] = useState(false);
    const [clickedWeekButton, setClickedWeekButton] = useState('');
    const [submitLoading, setSubmitLoading] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const globalError = useSelector((state: IRootState) => state.error);
    const [startDate, setStartDate] = useState(currentDate);
    const [displayWeeks, setDisplayWeeks] = useState<TimeSheetDisplayWeek[]>([]);
    const [startWeekDate, setStartWeekDate] = useState('');
    const [endWeekDate, setEndWeekDate] = useState('');
    const [alreadySubmittedtimesheet, setAlreadySubmittedtimesheet] = useState<TimesheetExist>({
        requestNumber: null,
        timesheetId: null,
    });
    const [entryRowTotal, setEntryRowTotal] = useState({ value: defaultZero, isLocked });

    const [dataEntryRow, setDataEntryRow] = useState(defaultsDataEntry);
    const [savedDataEntries, setSavedDataEntries] = useState([] as TimeSheetDataEntryType[]);
    const [savedDataColTotal, setSavedDataColTotal] = useState(defaultsDataEntry);

    const handleClear = () => {
        setSubmitLoading(false);
        setDataEntryRow(defaultsDataEntry);
        setSavedDataColTotal(defaultsDataEntry);
        setSavedDataEntries([]);
    };
    const updateWeekLimits = () => {
        const startWkDate = startDate.startOf('isoWeek').format('YYYY-MM-DD');
        const endWKate = startDate.endOf('isoWeek').format('YYYY-MM-DD');
        setStartWeekDate(startWkDate);
        setEndWeekDate(endWKate);
    };
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
        const value = e.target.value;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { inputType } = e.nativeEvent as any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { selectionStart, selectionEnd } = e.target as any;
        const isBackspaceOrDelete = inputType === 'deleteContentBackward' || inputType === 'deleteContentForward';
        const isBackspace = inputType === 'deleteContentBackward';
        const isDelete = inputType === 'deleteContentForward';
        if (e.target.name.includes('entrycol')) {
            if (
                isBackspaceOrDelete ||
                (isBackspace && selectionStart === 0) ||
                (isDelete && selectionEnd === value.length)
            ) {
                // Handle backspace or delete actions
                const newValue = value.slice(0, -1); // Remove the last character
                setDataEntryRow({ ...dataEntryRow, [e.target.name]: { isLocked: false, value: newValue } });
            } else if (/^\d*\.?\d*$/.test(value)) {
                // Check if the input is a valid number
                setDataEntryRow({ ...dataEntryRow, [e.target.name]: { isLocked: false, value } });
            }
        } else {
            if (e.target.name === 'task' && value.length > MAX_TASK_LENGTH && !isBackspaceOrDelete) {
                return;
            } else if (value && !AlphaNumericAndCharactersRegex.test(value)) {
                setErrors({
                    ...errors,
                    task: 'Details should only contain alphanumeric characters, hyphens, underscores, dot and spaces',
                });
            } else {
                delete errors[e.target.name];
                setErrors({ ...errors });
            }
            setDataEntryRow({ ...dataEntryRow, [e.target.name]: { isLocked: false, value } });
        }
    };

    const handleBlur = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
        if (e.target.name.includes('entrycol')) {
            let rawValue = e.target.value;
            const isAlreadyFormatted = /^[0-9]{2}:[0-9]{2}$/.test(rawValue);
            if (isAlreadyFormatted) return;
            // Check if the input is a valid number
            const isValidNumber = /^\d+(\.\d+)?$/.test(rawValue);

            if (!isValidNumber) {
                rawValue = '0';
            }
            // Convert the input to a float, round to 2 decimal places
            const hours = parseFloat(rawValue).toFixed(2);

            const [wholeHours, decimal] = hours.split('.');
            let adjustedHours = parseInt(wholeHours);
            let adjustedMinutes = parseInt(decimal, 10);
            if (decimal && adjustedMinutes > 60) {
                adjustedHours++;
                adjustedMinutes = adjustedMinutes - 60;
            }

            const formattedValue = `${String(adjustedHours).padStart(2, '0')}:${String(adjustedMinutes).padStart(2, '0')}`;

            if (parseFloat(wholeHours) > 15) {
                // formattedValue = '00:00';
                setErrors({ ...errors, [e.target.name]: 'above 15 hours' });
            } else {
                delete errors[e.target.name];
                setErrors({ ...errors });
            }
            const formattedEntryRows = { ...dataEntryRow, [e.target.name]: { isLocked: false, value: formattedValue } };
            const entryRows = Object.keys(dataEntryRow)
                .filter((key) => key.startsWith('entrycol'))
                .map((col) => formattedEntryRows[col as keyof TimeSheetDataEntryType].value);

            const rowTotal = calculateTotalHours(entryRows);
            setEntryRowTotal({ value: rowTotal, isLocked });
            setDataEntryRow(formattedEntryRows);

            return;
        } else {
            setDataEntryRow({ ...dataEntryRow, [e.target.name]: { isLocked: false, value: e.target.value } });
        }
    };

    const weeksBetweenDates = () => {
        // Calculate the difference in weeks
        const weeks = moment(startWeekDate).diff(currentDate, 'weeks');
        // Check if pastDate is in the past or future
        const isPast = moment(startWeekDate).isBefore(currentDate);
        if (isPast) {
            return weeks - 1;
        }
        return weeks + 1; // Use Math.abs() to ensure a positive value
    };

    const changeWeekHandle = (btnType: string) => {
        if (savedDataEntries.length > 0 && !unSavedChangesShowModal) {
            setClickedWeekButton(btnType);
            setUnSavedChangesShowModal(true);
        } else {
            setClickedWeekButton('');
            setUnSavedChangesShowModal(false);

            if (btnType === 'prev') {
                const date = startDate.startOf('isoWeek').subtract(1, 'days');
                setStartDate(date);
                setDisplayWeeks(getWeekDaysByDate(date));
            }
            if (btnType === 'next') {
                const date = startDate.endOf('isoWeek').add(1, 'days');
                setStartDate(date);
                setDisplayWeeks(getWeekDaysByDate(date));
            }

            if (btnType === 'current-week') {
                const date = currentDate;
                setStartDate(date);
                setDisplayWeeks(getWeekDaysByDate(date));
            }
            handleClear();
        }
    };

    const handleEntryRowSave = () => {
        let updatedData = [] as TimeSheetDataEntryType[];
        updatedData = savedDataEntries;
        if (editingRowIndex >= 0) {
            updatedData[editingRowIndex] = {
                ...dataEntryRow,
                rowTotal: entryRowTotal,
            };
        } else {
            updatedData.push({
                ...dataEntryRow,
                rowTotal: entryRowTotal,
            });
        }
        setEditingRowIndex(-1);
        setSavedDataEntries(updatedData);
        const columnTotals = calculateColumnTotal(updatedData);
        setSavedDataColTotal(columnTotals);
        setDataEntryRow(defaultsDataEntry);
        setEntryRowTotal({ value: defaultZero, isLocked });
    };

    const handleRemoveEntry = (index: number) => {
        savedDataEntries.splice(index, 1);
        setSavedDataEntries(savedDataEntries);
        if (savedDataEntries.length > 0) {
            const columnTotals = calculateColumnTotal(savedDataEntries);
            setSavedDataColTotal(columnTotals);
        } else {
            setSavedDataColTotal(defaultsDataEntry);
        }
    };

    const submitTimesheetHandler = async (actionStatus: ActionStatus) => {
        setErrors(defaultError);
        setSubmitLoading(true);
        try {
            const taskDetails = [] as WeeklyTaskEntries[];
            for (const savedDataEntry of savedDataEntries) {
                const objectKeys = Object.keys(savedDataEntry);
                const updatedKeyEntry = {} as WeeklyTaskEntries;
                objectKeys.map((key) => {
                    const digitIndex = parseInt(key[key.length - 1], 10);
                    let newKey = key;
                    let cellLocked = false;
                    if (displayWeeks[digitIndex - 1]) {
                        newKey = `${displayWeeks[digitIndex - 1].day}-${displayWeeks[digitIndex - 1].month}-${
                            displayWeeks[digitIndex - 1].date
                        }`;
                        cellLocked = displayWeeks[digitIndex - 1].isPastDate;
                    }

                    updatedKeyEntry[newKey] = {
                        isLocked: cellLocked,
                        value: savedDataEntry[key as keyof TimeSheetDataEntryType].value,
                    };
                });
                taskDetails.push(updatedKeyEntry);
            }
            const data = {
                employeeId: loggedInUser.uuid,
                weekStartDate: startWeekDate,
                weekEndDate: endWeekDate,
                taskDetails,
                timeSheetStatus: actionStatus,
                totalHours: getHoursFromString(savedDataColTotal.rowTotal.value),
            };
            await submitWeeklyTimesheet(data);
            handleClear();
            triggerSuccess(`Weekly Timesheet submitted.`);
            navigate('/employee/weekly-timesheet/list');
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (_e: any) {
            setSubmitLoading(false);
        }
    };

    const handleRowEdits = (rowData: TimeSheetDataEntryType, editingIndex: number) => {
        setDataEntryRow(rowData);
        setEntryRowTotal(rowData.rowTotal);
        setEditingRowIndex(editingIndex);
    };

    useEffect(() => {
        if (displayWeeks.length > 0) {
            updateWeekLimits();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayWeeks]);

    useEffect(() => {
        setDisplayWeeks(getWeekDaysByDate());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setDisplayWeeks(getWeekDaysByDate());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const validateIfExists = async () => {
            if (startWeekDate && loggedInUser.uuid) {
                const { data } = await checkIfTimesheetExists(loggedInUser.uuid, startWeekDate);
                if (data.requestNumber) {
                    setAlreadySubmittedtimesheet({
                        requestNumber: data.requestNumber,
                        timesheetId: data.timesheetId,
                    });
                } else {
                    setAlreadySubmittedtimesheet({
                        requestNumber: null,
                        timesheetId: null,
                    });
                }
            }
            return false;
        };
        validateIfExists();
    }, [startWeekDate, loggedInUser.uuid]);

    return (
        <main className="content timesheet-entry-form timesheet-weekly-data">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon icon={icon({ name: 'calendar-days' })} /> Weekly Timesheet Submission
                        </h3>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-info">
                                <p> Fill your work hours for each day of the week.</p>
                            </div>
                            <div className="card-body card-body__min-height  col-12">
                                <div className="row">
                                    <div className="col-12 my-1 p-3 text-dark">
                                        <span
                                            role="button"
                                            className="btn btn-primary"
                                            onClick={() => setShowHelp(!showHelp)}
                                        >
                                            {' '}
                                            <FontAwesomeIcon icon={icon({ name: 'circle-info' })} /> Help Information
                                        </span>
                                    </div>
                                    {showHelp && (
                                        <div className="col-10 ms-3 mb-3 p-3 bg-info text-white">
                                            <ul>
                                                <li>
                                                    <strong>Workhours Format:</strong>{' '}
                                                    <span>Enter work hours using the hh:mm format.</span>
                                                </li>
                                                <li>
                                                    <strong>Daily Limit:</strong> Workhours for each task should be less than
                                                    15 hours per day.
                                                </li>
                                                <li>
                                                    <strong>Task Distribution:</strong> Distribute workhours across multiple
                                                    tasks if needed.
                                                </li>
                                                <li>
                                                    <strong>Time Frame:</strong> Fill timesheet data for the current day,
                                                    future days of the week, or up to two weeks in the future.
                                                </li>
                                                <li>
                                                    <strong>Submission and Editing:</strong> After submission, view and edit
                                                    your timesheet in the timesheet list.
                                                </li>
                                                <li>
                                                    <strong>Locking Past Entries:</strong> Time entries for past dates are
                                                    locked and cannot be modified.
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                    <div className="row mb-2 col-12">
                                        {alreadySubmittedtimesheet.timesheetId && (
                                            <div className="p-3">
                                                <div
                                                    className="alert alert-warning alert-outline-coloured alert-dismissible"
                                                    role="alert"
                                                >
                                                    <div className="alert-icon">
                                                        <FontAwesomeIcon
                                                            icon={icon({ name: 'bell' })}
                                                            className="far fa-fw fa-bell"
                                                        />
                                                    </div>
                                                    <div className="alert-message">
                                                        <p className="note">
                                                            <strong>Note:</strong> <br /> You have already submitted
                                                            timesheet for this week.
                                                            <br />
                                                            If you want to add entries for this week,{' '}
                                                            <NavLink
                                                                to={`/employee/weekly-timesheet/update/${alreadySubmittedtimesheet.timesheetId}`}
                                                            >
                                                                <span className="bg-danger badge badge-sm">Click here</span>
                                                            </NavLink>{' '}
                                                            to check and update the timesheet.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="col-6 week-label text-start">
                                            <strong>Selected Week:</strong> {startWeekDate.split('-')[2]} -{' '}
                                            {moment(endWeekDate).format('DD MMM YYYY')}
                                        </div>
                                        <div className="col-6 text-end">
                                            <button
                                                className="btn btn-outline-primary"
                                                onClick={() => changeWeekHandle('prev')}
                                                disabled={weeksBetweenDates() < -MAX_WEEKS_LIMIT}
                                            >
                                                <FontAwesomeIcon icon={icon({ name: 'chevron-left' })} />
                                            </button>

                                            <button
                                                className="btn btn-outline-primary me-2 ms-2"
                                                onClick={() => changeWeekHandle('current-week')}
                                            >
                                                This Week
                                            </button>

                                            <button
                                                className="btn btn-outline-primary"
                                                onClick={() => changeWeekHandle('next')}
                                                disabled={weeksBetweenDates() > MAX_WEEKS_LIMIT}
                                            >
                                                <FontAwesomeIcon icon={icon({ name: 'chevron-right' })} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        {' '}
                                        <div className="d-flex header-row">
                                            <div className="col-3 me-4 header-col text-start">
                                                <div className="p-2 pt-3 heading">Task Details</div>
                                            </div>
                                            {displayWeeks.map((week) => (
                                                <div key={`${week.date}`} className="col-1 header-col">
                                                    <div
                                                        className={cn('p-2 px-3 heading', {
                                                            'heading__is-today': week.isToday === true,
                                                        })}
                                                    >
                                                        {week.day},
                                                        <br />
                                                        {week.month} {week.date}
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="col-1 header-col">
                                                <div className="p-2 heading">{''}</div>
                                            </div>
                                        </div>
                                        <div className="d-flex data-row">
                                            <div className="col-3 me-4 data-col">
                                                <div className="m-2">
                                                    <input
                                                        name="task"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={dataEntryRow.task.value}
                                                        type="text"
                                                        className={`form-control ${errors.task ? 'is-invalid' : ''}`}
                                                        max={MAX_TASK_LENGTH}
                                                    />
                                                    <span className="text-secondary text-sm ">
                                                        Allowed limit: {MAX_TASK_LENGTH - dataEntryRow.task.value.length}
                                                    </span>
                                                </div>
                                            </div>
                                            {displayWeeks.map((week, index) => (
                                                <div
                                                    key={`${week.date}`}
                                                    className={cn('col-1 data-col input-container', {
                                                        'data-col__is-today': week.isToday === true,
                                                    })}
                                                >
                                                    <div className="m-2">
                                                        <input
                                                            name={`entrycol${index + 1}`}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            disabled={dataEntryRow.task.value.length === 0}
                                                            value={
                                                                dataEntryRow[
                                                                    `entrycol${index + 1}` as keyof TimeSheetDataEntryType
                                                                ].value
                                                            }
                                                            type="text"
                                                            className={`form-control text-center ${
                                                                errors[`entrycol${index + 1}`] ? 'is-invalid' : ''
                                                            }`}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="col-2 data-col ">
                                                <div className="m-2 row-total col-8">
                                                    <div className="p-1 ps-0">
                                                        {entryRowTotal.value}
                                                        <FontAwesomeIcon
                                                            className={cn('ms-2 ', {
                                                                'text-secondary':
                                                                    Object.keys(errors).length > 0 ||
                                                                    dataEntryRow.task.value.length === 0,
                                                                'cursor-pointer text-success':
                                                                    Object.keys(errors).length === 0 &&
                                                                    dataEntryRow.task.value.length !== 0,
                                                            })}
                                                            size="lg"
                                                            icon={icon({ name: 'circle-plus' })}
                                                            onClick={() => {
                                                                if (
                                                                    Object.keys(errors).length === 0 &&
                                                                    dataEntryRow.task.value.length !== 0
                                                                ) {
                                                                    handleEntryRowSave();
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {Object.keys(errors).length > 0 && (
                                            <div className="d-flex ps-3 text-danger">{errors.task}</div>
                                        )}
                                        {savedDataEntries.length > 0 && (
                                            <>
                                                <TimeSheetData
                                                    timeSheetData={savedDataEntries}
                                                    timeSheetDataTotalRow={savedDataColTotal}
                                                    displayWeeks={displayWeeks}
                                                    handleDelete={handleRemoveEntry}
                                                    handleRowEdits={handleRowEdits}
                                                    editingRow={editingRowIndex}
                                                />

                                                <div className="mt-3 col-md-12">
                                                    <div className="row col-md-6 mt-sm-2">
                                                        <div className="col-md-4">
                                                            <strong>Total Hours Spent:</strong>
                                                        </div>
                                                        <div className="col-md-3">{savedDataColTotal.rowTotal.value}</div>
                                                    </div>
                                                    <div className="text-end mt-4">
                                                        {' '}
                                                        <button
                                                            onClick={() => handleClear()}
                                                            disabled={submitLoading}
                                                            className="btn btn-secondary me-2"
                                                        >
                                                            Clear
                                                        </button>
                                                        <button
                                                            onClick={() => submitTimesheetHandler(ActionStatus.REQUESTED)}
                                                            disabled={!savedDataEntries.length}
                                                            className="btn btn-primary me-2"
                                                        >
                                                            Submit Timesheet{' '}
                                                            {submitLoading && !globalError.isError && (
                                                                <div
                                                                    className="spinner-border spinner-border-sm   me-2"
                                                                    role="status"
                                                                >
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <WarningUnSavedChangesModal
                showModal={unSavedChangesShowModal}
                handleClose={() => setUnSavedChangesShowModal(false)}
                handleProceed={() => changeWeekHandle(clickedWeekButton)}
            />
        </main>
    );
};

export default EmployeeTimesheetMultiWeek;
