/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import DatePicker from 'react-date-picker';
import './Datepicker.scss';
import { UserRole } from '../../../types/user';

type GeneralInfoProp = {
    formData: any; //UserProfile;
    formErrors: any;
    inputChangeHandler: (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void;
    validateIfAvailable: () => void;
    emailAvailableLoading: boolean;
    setFormData: any;
    updateFlow?: boolean;
    userRole: UserRole;
};

const GeneralInfo = (props: GeneralInfoProp) => {
    const { formData, formErrors, inputChangeHandler, validateIfAvailable, emailAvailableLoading, setFormData, updateFlow } =
        props;
    return (
        <>
            <label>
                Full Name <span className="required-field">*</span>
            </label>
            <input
                className={`form-control form-control-lg mb-3 ${formErrors.name ? 'is-invalid' : ''}`}
                type="text"
                name="name"
                value={formData.name}
                onChange={inputChangeHandler}
                disabled={updateFlow}
            />
            {formErrors.name && <div className="invalid-feedback mb-3">{formErrors.name}</div>}
            <label>
                Gender <span className="required-field">*</span>
            </label>
            <select
                name="gender"
                className={`form-control form-control-lg mb-3  form-select mb-3 ${formErrors.gender ? 'is-invalid' : ''}`}
                value={formData.gender}
                onChange={inputChangeHandler}
            >
                <option value="0">Select gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
            </select>
            {formErrors.gender && <div className="invalid-feedback mb-3">{formErrors.gender}</div>}

            <label>
                Date Of Birth <span className="required-field">*</span>
            </label>
            <DatePicker
                className={`form-control form-control-lg mb-3 ${formErrors.dateOfBirth ? 'is-invalid' : ''}`}
                name="dateOfBirth"
                format="dd/MM/yyyy"
                maxDate={new Date()}
                onChange={(date) => date && setFormData({ ...formData, dateOfBirth: date })}
                value={formData.dateOfBirth}
                isOpen={false}
            />
            {formErrors.dateOfBirth && <div className="invalid-feedback mb-3">{formErrors.dateOfBirth}</div>}
            <label>Contact Number (e.g. +91 XXXXXXXXXXX)</label>
            <input
                className={`form-control form-control-lg mb-3 ${formErrors.contactNumber ? 'is-invalid' : ''}`}
                type="text"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={inputChangeHandler}
            />
            {formErrors.contactNumber && <div className="invalid-feedback mb-3">{formErrors.contactNumber}</div>}
            <label>
                Personal Email <span className="required-field">*</span>
            </label>
            <input
                className={`form-control form-control-lg mb-3 ${formErrors.personalEmail ? 'is-invalid' : ''}`}
                type="text"
                name="personalEmail"
                value={formData.personalEmail}
                onChange={inputChangeHandler}
                onBlur={validateIfAvailable}
                disabled={updateFlow}
            />
            {emailAvailableLoading && (
                <div className="email-availability-search mb-1">
                    Searching for availability{' '}
                    <div className="spinner-border spinner-border-sm me-2" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
            {formErrors.personalEmail && <div className="invalid-feedback mb-3">{formErrors.personalEmail}</div>}
            <label>Blood Group</label>
            <select
                name="bloodGroup"
                className="form-control form-control-lg form-select mb-3 "
                onChange={inputChangeHandler}
                value={formData.bloodGroup}
            >
                <option value="0">Select bloodGroup</option>
                <option value="A+">A+</option>
                <option value="A-">A-</option>
                <option value="B+">B+</option>
                <option value="B-">B-</option>
                <option value="AB+">AB+</option>
                <option value="AB-">AB-</option>
                <option value="O+">O+</option>
                <option value="O-">O-</option>
            </select>
            <label>Marital Status</label>
            <select
                name="maritalStatus"
                className="form-control form-control-lg form-select mb-3 "
                onChange={inputChangeHandler}
                value={formData.maritalStatus}
            >
                <option value="0">Select Status</option>
                <option value="Married">Married</option>
                <option value="UnMarried">UnMarried</option>
                <option value="Divorced">Divorced</option>
                <option value="Widowed">Widowed</option>
            </select>
            {formErrors.maritalStatus && <div className="invalid-feedback mb-3">{formErrors.maritalStatus}</div>}
        </>
    );
};

export default GeneralInfo;
