/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import React from 'react';
import cn from 'classnames';
import DatePicker from 'react-date-picker';
import { UserRole } from '../../../types/user';
import EmployeeSearchInput from '../../EmployeeSearchInput/EmployeeSearchInput';
import { searchReportingManagerAssigmentList } from '../../../services/employee';
import { IRootState, useSelector } from '../../../store';

type OfficialInfoProp = {
    formData: any; //UserProfile;
    formErrors: any;
    inputChangeHandler: (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void;
    setFormData: any;
    userRole: UserRole;
    validateIfAvailable: () => void;
    ofcEmailAvailableLoading: boolean;
    setErrors: (e: any) => void;
    updateFlow?: boolean;
    selfLogin?: boolean;
};

const OfficialInfo = (props: OfficialInfoProp) => {
    const { designation: stateDesignation, department: stateDepartment } = useSelector((state: IRootState) => state.data);
    const {
        formData,
        formErrors,
        inputChangeHandler,
        setFormData,
        ofcEmailAvailableLoading,
        validateIfAvailable,
        updateFlow,
        userRole,
        selfLogin,
        setErrors,
    } = props;

    if (userRole === UserRole.employee || selfLogin) {
        return (
            <>
                <p>
                    <strong className="pe-2">Employee Id:</strong>
                    {formData.employeeId}
                </p>

                <p>
                    <strong className="pe-2">Official Email Id:</strong>
                    {formData.officialEmailId}
                </p>

                <p>
                    <strong className="pe-2">Date Of Joining:</strong>
                    {moment(formData.dateOfJoining).format('DD/MM/yyyy')}
                </p>

                <p>
                    <strong className="pe-2">Reporting Manager:</strong>
                    {formData.managerDetail}
                </p>
                <p>
                    <strong className="pe-2">Reporting Director:</strong>
                    {formData.directorDetail}
                </p>
                <p>
                    <strong className="pe-2">Designation:</strong>
                    {formData.designation}
                </p>

                <p>
                    <strong className="pe-2">Department:</strong>
                    {formData.department}
                </p>

                <p>
                    <strong className="pe-2">Current CTC:</strong>
                    {formData.currentCtc ?? '-'}
                </p>
            </>
        );
    }
    return (
        <>
            <label>
                Employee Id <span className="required-field">*</span>
            </label>
            <input
                className={`form-control form-control-lg mb-3 ${formErrors.employeeId ? 'is-invalid' : ''}`}
                type="text"
                name="employeeId"
                value={formData.employeeId}
                onChange={inputChangeHandler}
                disabled={updateFlow}
            />
            {formErrors.employeeId && <div className="invalid-feedback mb-3">{formErrors.employeeId}</div>}

            <label>
                Official Email Id <span className="required-field">*</span>
            </label>
            <input
                className={`form-control form-control-lg mb-3 ${formErrors.officialEmailId ? 'is-invalid' : ''}`}
                type="text"
                name="officialEmailId"
                value={formData.officialEmailId}
                onChange={inputChangeHandler}
                onBlur={validateIfAvailable}
                disabled={updateFlow}
            />
            {ofcEmailAvailableLoading && (
                <div className="email-availability-search mb-1">
                    Searching for availability{' '}
                    <div className="spinner-border spinner-border-sm me-2" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
            {formErrors.officialEmailId && <div className="invalid-feedback mb-3">{formErrors.officialEmailId}</div>}
            <label>
                Date Of Joining <span className="required-field">*</span>
            </label>
            <DatePicker
                className={`form-control form-control-lg mb-3 ${formErrors.dateOfJoining ? 'is-invalid' : ''}`}
                name="dateOfJoining"
                format="dd/MM/yyyy"
                maxDate={new Date()}
                value={formData.dateOfJoining}
                isOpen={false}
                onChange={(date) => date && setFormData({ ...formData, dateOfJoining: date })}
            />
            {formErrors.dateOfJoining && <div className="invalid-feedback mb-3">{formErrors.dateOfJoining}</div>}
            <label>
                Reporting Manager <span className="required-field">*</span>
            </label>
            <EmployeeSearchInput
                errors={formErrors}
                setErrors={setErrors}
                formData={formData}
                setFormData={setFormData}
                searchFnCallback={searchReportingManagerAssigmentList}
                inputIndex={'reportingManagerId'}
                inputData={formData.managerDetail}
            />

            <div
                className={cn('invalid-feedback mb-3', {
                    show: formErrors.reportingManagerId,
                })}
            >
                {formErrors.reportingManagerId}
            </div>
            <label className="mt-3">Reporting Director</label>
            <EmployeeSearchInput
                errors={formErrors}
                setErrors={setErrors}
                formData={formData}
                setFormData={setFormData}
                searchFnCallback={searchReportingManagerAssigmentList}
                inputIndex={'reportingDirectorId'}
                inputData={formData.directorDetail}
            />

            <div
                className={cn('invalid-feedback mb-3', {
                    show: formErrors.reportingDirectorId,
                })}
            >
                {formErrors.reportingDirectorId}
            </div>

            <label className="mt-3">
                Designation <span className="required-field">*</span>
            </label>
            <select
                name="designation"
                className={`form-control form-control-lg mb-3  form-select mb-3 ${
                    formErrors.designation ? 'is-invalid' : ''
                }`}
                onChange={inputChangeHandler}
                value={formData.designation}
            >
                <option value="0">Select Designation</option>
                {stateDesignation &&
                    stateDesignation.map((designation, i) => (
                        <option key={i} value={`${designation}`}>
                            {designation}
                        </option>
                    ))}
            </select>
            {formErrors.designation && <div className="invalid-feedback mb-3">{formErrors.designation}</div>}
            <label>
                Department <span className="required-field">*</span>
            </label>
            <select
                name="department"
                className={`form-control form-control-lg mb-3  form-select mb-3 ${
                    formErrors.department ? 'is-invalid' : ''
                }`}
                onChange={inputChangeHandler}
                value={formData.department}
            >
                <option value="0">Select Department</option>
                {stateDepartment &&
                    stateDepartment.map((designation, i) => (
                        <option key={i} value={`${designation}`}>
                            {designation}
                        </option>
                    ))}
            </select>
            {formErrors.department && <div className="invalid-feedback mb-3">{formErrors.department}</div>}
            <label>Current CTC</label>
            <input
                className={`form-control form-control-lg mb-3 ${formErrors.currentCtc ? 'is-invalid' : ''}`}
                type="text"
                name="currentCtc"
                value={formData.currentCtc}
                onChange={inputChangeHandler}
            />
            {formErrors.currentCtc && <div className="invalid-feedback mb-3">{formErrors.currentCtc}</div>}
        </>
    );
};

export default OfficialInfo;
