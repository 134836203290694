// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.employee-registration .tab-content button {
  margin: 5px;
}
.employee-registration .email-availability-search {
  color: #032271;
  font-size: 14px;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/Employees/EmployeeRegistration.scss"],"names":[],"mappings":"AAGQ;EACI,WAAA;AAFZ;AAMI;EACI,cAAA;EACA,eAAA;EACA,cAAA;AAJR","sourcesContent":["\n.employee-registration {\n    .tab-content {\n        button {\n            margin: 5px;\n        }\n    }\n\n    .email-availability-search {\n        color: #032271;\n        font-size: 14px;\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
