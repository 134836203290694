import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
// import thunk from 'redux-thunk';
import user from './user';
import error from './errorHandler';
import token from './token';
import data from './data';

const persistConfig = {
    key: 'token',
    storage,
};
const persistedTokenReducer = persistReducer(persistConfig, token);

const reducer = combineReducers({
    user,
    data,
    error,
    token: persistedTokenReducer,
});

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;

export const persistor = persistStore(store);

export type IRootState = ReturnType<typeof reducer>;

export type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<IRootState> = useReduxSelector;
