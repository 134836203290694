import React from 'react';
import { SurveyFormConfig } from '../../../types/survey';

type FormDetailsProp = {
    surveyQuestion: SurveyFormConfig[];
};

export const SurveyFormDetail = ({ surveyQuestion }: FormDetailsProp) => {
    return (
        <>
            {surveyQuestion.map((survey, index) => (
                <div className="row gx-0 m-2 bg-light py-2" key={index}>
                    <div className="col-8 p-2 m-0 ps-3 fw-bold">{survey.question}</div>
                    <div className="col-4 p-2 m-0 fw-bold">{survey.inputType.toLocaleUpperCase()}</div>
                </div>
            ))}
        </>
    );
};
