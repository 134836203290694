import { request } from '../api';
import { ResourceAllocation } from '../types/customer';

export const customerAllocateResource = async (allocationDetails: ResourceAllocation) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/customer/create/resource-allocation`,
        data: { ...allocationDetails },
    };
    return request(requestOptions);
};

export const checkIfResourceAllocated = async (employeeId: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/is-resource-allocated/${employeeId}`,
    };
    return request<boolean>(requestOptions);
};

export const updateAllocateResource = async (allocationDetails: ResourceAllocation & { uuid: string }) => {
    const requestOptions = {
        method: 'PATCH',
        url: `${process.env.REACT_APP_API_ENDPOINT}/customer/update/resource-allocation`,
        data: { ...allocationDetails },
    };
    return request(requestOptions);
};

export const deleteAllocation = async (allocationDetails: { uuid: string }) => {
    const requestOptions = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_ENDPOINT}/customer/delete/resource-allocation`,
        data: { ...allocationDetails },
    };
    return request(requestOptions);
};
