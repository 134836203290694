/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import * as Icon from 'react-feather';
import { capitalize } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { getEmployeeLeaves, getEmployeeLeavesAllocation } from '../../../services/employeeLeaves';
import { EmployeeLeaveAllocation, EmployeeLeave, EmployeeLeaveFilters, ActionStatus } from '../../../types/employee';
import { EmployeeLeaveTypes } from '../../../constants/data';
import { StatusIndicator } from '../../StatusIndicator/StatusIndicator';
import { LeaveDetailModal } from './LeaveDetailModal';

import './EmployeeLeavesList.scss';

// TODO: LEAVE META DATA, like total applied leaves, rejected total, approved total, leave balance.
type Prop = {
    employeeId: string;
    approverView?: boolean;
    employeeName?: string;
};
const EmployeeLeavesList = (prop: Prop) => {
    const currentYear = new Date().getFullYear().toString();
    const navigate = useNavigate();
    const [employeeLeaves, setEmployeeLeaves] = useState<EmployeeLeave[]>([]);
    const [showModalId, setShowModalId] = useState<string | null>(null);
    const [filters, setFilters] = useState<EmployeeLeaveFilters>({ year: currentYear });
    const [error, setError] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const [leaveBalance, setLeaveBalance] = useState<EmployeeLeaveAllocation[]>([]);
    const [balanceDateLoading, setBalanceDateLoading] = useState(true);
    const fetchEmployeeLeaves = async (page: number, searchFilters: EmployeeLeaveFilters) => {
        try {
            setLoading(true);
            const { data } = await getEmployeeLeaves(page, prop.employeeId, searchFilters);
            if (data.length > 0) {
                setHasMore(true);
                setEmployeeLeaves((prevData) => [...prevData, ...data]);
            } else {
                setHasMore(false);
            }
            setLoading(false);
        } catch (err: unknown) {
            setError('Something went wrong');
            setLoading(false);
        }
    };

    const fetchEmployeeBalance = async (balanceDataFilter: Pick<EmployeeLeaveFilters, 'year'>) => {
        try {
            setBalanceDateLoading(true);
            setBalanceDateLoading(true);
            const { data } = await getEmployeeLeavesAllocation(prop.employeeId, balanceDataFilter);
            setLeaveBalance(data);
            setBalanceDateLoading(false);
        } catch (err: unknown) {
            setError('Something went wrong');
            setBalanceDateLoading(false);
        }
    };

    const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const filterType = event.target.name; // Get the id of the select element as filterType
        const newFilters: EmployeeLeaveFilters = { ...filters };
        if (event.target.value !== '0') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            newFilters[filterType as keyof EmployeeLeaveFilters] = event.target.value as any;
            setFilters(newFilters);
        } else {
            delete newFilters[filterType as keyof EmployeeLeaveFilters];
            setFilters(newFilters);
        }
    };

    const openModal = (id: string) => {
        setShowModalId(id);
    };

    const handleModalClose = () => {
        setShowModalId(null);
    };

    const handleSearchClick = () => {
        setEmployeeLeaves([]);
        if (filters.leaveType === '0') {
            delete filters.leaveType;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (filters.leaveApprovalStatus === ('0' as any)) {
            delete filters.leaveApprovalStatus;
        }
        fetchEmployeeLeaves(1, filters);
        fetchEmployeeBalance({ year: filters.year });
    };

    const handleClearFilter = () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setFilters({ year: currentYear, leaveType: '0', leaveApprovalStatus: '0' as any });
        setEmployeeLeaves([]);
        fetchEmployeeLeaves(1, {});
        fetchEmployeeBalance({ year: filters.year });
    };

    useEffect(() => {
        fetchEmployeeLeaves(1, filters);
        fetchEmployeeBalance({ year: filters.year });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const approverTags = (leaves: EmployeeLeave) => {
        const approverRoleList = leaves.leaveApprovers.map((approver) => {
            if (approver.approvalStatus === ActionStatus.APPROVED) {
                return (
                    <span key={approver.approverId} className="badge bg-success">
                        {approver.approverRole.toUpperCase()}
                    </span>
                );
            } else if (approver.approvalStatus === ActionStatus.REJECTED) {
                return (
                    <span key={approver.approverId} className="badge bg-danger">
                        {approver.approverRole.toUpperCase()}
                    </span>
                );
            } else {
                return <></>;
            }
        });
        return approverRoleList;
    };
    const allLeaveType = [...EmployeeLeaveTypes.basic, ...EmployeeLeaveTypes.special];
    return (
        <main className="content view-employees-leaves-list">
            <div className="container-fluid p-0">
                <div className="row mb-2 mb-xl-3">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            {!prop.approverView ? (
                                <>
                                    <FontAwesomeIcon icon={icon({ name: 'pen' })} /> Applied leaves
                                </>
                            ) : (
                                <>
                                    <FontAwesomeIcon icon={icon({ name: 'list' })} /> Reporting Employee Leaves -{' '}
                                    {prop.employeeName}
                                </>
                            )}
                        </h3>
                    </div>
                    <div className="col-auto ms-auto text-end mt-n1">
                        <button className="btn btn-primary" onClick={() => navigate(-1)}>
                            <FontAwesomeIcon icon={icon({ name: 'angle-left' })} className="far fa-fw fa-bell" /> Back
                        </button>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-info">
                                <p>This page provides overview of your applied leaves. </p>
                                <p className="mb-0">
                                    <strong>Note:</strong>
                                </p>
                                <ul>
                                    <li>
                                        In case you need to cancel the applied leave, drop a email to your manager or HR.
                                    </li>
                                    <li>By default leaves from current year are shown.</li>
                                </ul>
                            </div>
                            <div className="card-body">
                                <div
                                    id="datatables-reponsive_wrapper"
                                    className="dataTables_wrapper dt-bootstrap5 no-footer"
                                >
                                    {error && (
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div
                                                    className="alert alert-danger alert-outline alert-dismissible"
                                                    role="alert"
                                                >
                                                    <div className="alert-icon">
                                                        <Icon.Bell className="far fa-fw fa-bell" />
                                                    </div>
                                                    <div className="alert-message">
                                                        <strong>Error</strong> Something went wrong when trying to get your
                                                        leaves data.
                                                        <br /> If the problem persists, please contact support.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {
                                        <>
                                            <div className="search-container">
                                                <div className="mb-3 row">
                                                    <strong>Filter By:</strong>
                                                </div>
                                                <div className="mb-3 row">
                                                    <div className="col-md-2 mt-sm-2">
                                                        <select
                                                            name="year"
                                                            className="form-control form-control-lg form-select"
                                                            onChange={handleFilterChange}
                                                            value={filters.year}
                                                        >
                                                            <option value="2024">2024</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-3 mt-sm-2">
                                                        <select
                                                            name="leaveType"
                                                            className="form-control form-control-lg form-select"
                                                            onChange={handleFilterChange}
                                                            value={filters.leaveType}
                                                        >
                                                            <option value="0">Select leave type</option>
                                                            {allLeaveType.map((type, i) => (
                                                                <option key={i} value={`${type}`}>
                                                                    {capitalize(type.replaceAll('_', ' '))}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-3 mt-sm-2">
                                                        <select
                                                            name="leaveApprovalStatus"
                                                            className="form-control form-control-lg form-select"
                                                            onChange={handleFilterChange}
                                                            value={filters.leaveApprovalStatus}
                                                        >
                                                            <option value="0">Select leave status</option>
                                                            <option value="APPROVED">APPROVED</option>
                                                            <option value="REJECTED">REJECTED</option>
                                                            <option value="REQUESTED">REQUESTED</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-1 mt-sm-2">
                                                        {' '}
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={handleSearchClick}
                                                        >
                                                            Search
                                                        </button>
                                                    </div>
                                                    <div className="col-md-1 text-start pt-3 pe-1">
                                                        <span
                                                            onClick={handleClearFilter}
                                                            className="text-primary cursor-pointer"
                                                        >
                                                            Clear All
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    <div className="col-md-12 p-3 mb-2 leave-balance-table">
                                        {balanceDateLoading && (
                                            <div key={0} className="row">
                                                <div className="col-sm-12 mt-2 mb-2 text-center">
                                                    <div className="spinner-border text-primary me-2" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {leaveBalance.length > 0 && !balanceDateLoading && (
                                            <>
                                                <em className="text-secondary fw-bolder">
                                                    ** Showing for year <span className="text-info">{filters.year}</span>
                                                </em>
                                                <div className="row col-md-12 p-1 pb-2 pt-2 header-row border">
                                                    <div className="col-4  header-col">
                                                        <div className=" heading">Leave Type</div>
                                                    </div>
                                                    <div className="col-4 header-col">
                                                        <div className=" heading">Leave Applied</div>
                                                    </div>
                                                    <div className="col-4 header-col">
                                                        <div className=" heading">Leave Balance</div>
                                                    </div>
                                                </div>
                                                {leaveBalance.map((leave, index) => (
                                                    <div
                                                        key={`${leave.leaveType}${index}`}
                                                        className="row p-1 pb-2 col-md-12 data-row"
                                                    >
                                                        <div className="col-4 data-col">
                                                            {leave.leaveType.replace('_', '+')}
                                                        </div>
                                                        <div className="col-4 data-col ps-3"> {leave.leavesApplied}</div>
                                                        <div className="col-4 data-col ps-3">
                                                            {' '}
                                                            {leave.leavesBalance === 999 ? '-' : leave.leavesBalance}
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </div>

                                    {!loading && !error && employeeLeaves?.length === 0 && (
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div
                                                    className="alert alert-danger alert-outline alert-dismissible mt-5"
                                                    role="alert"
                                                >
                                                    <div className="alert-icon">
                                                        <Icon.Bell className="far fa-fw fa-bell" />
                                                    </div>
                                                    <div className="alert-message">
                                                        <p>
                                                            <strong>
                                                                No leaves data available. <br /> It is possible that there is
                                                                no data added yet.
                                                                <br />
                                                                OR try refining your search filters.
                                                            </strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {employeeLeaves?.length !== 0 && (
                                        <>
                                            <div style={{ height: '500px', overflow: 'auto' }}>
                                                <InfiniteScroll
                                                    pageStart={1}
                                                    loadMore={(page: number) => fetchEmployeeLeaves(page, filters)}
                                                    hasMore={hasMore && !loading}
                                                    loader={
                                                        <div key={0} className="row">
                                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                                <div
                                                                    className="spinner-border text-primary me-2"
                                                                    role="status"
                                                                >
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    useWindow={false}
                                                >
                                                    <table
                                                        id="datatables-reponsive"
                                                        className="table table-striped dataTable no-footer dtr-inline view-employees-leaves-list-table"
                                                        style={{ width: '100%', tableLayout: 'fixed' }}
                                                        aria-describedby="datatables-reponsive_info"
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th>Request #</th>
                                                                <th>Leave Type</th>
                                                                <th className="d-hide-sm">Start Date</th>
                                                                <th className="d-hide-sm">End Date</th>
                                                                <th>Total Days</th>
                                                                <th>Status</th>
                                                                <th>Applied on</th>
                                                                <th className="d-hide-sm">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {employeeLeaves &&
                                                                employeeLeaves.map((leave, index) => (
                                                                    <tr
                                                                        className={cn({
                                                                            odd: index % 2 === 0,
                                                                            even: index % 2 !== 0,
                                                                        })}
                                                                        key={`${leave.uuid}-${index}`}
                                                                    >
                                                                        <td>{leave.requestNumber}</td>
                                                                        <td>{leave.leaveType}</td>
                                                                        <td className="d-hide-sm">
                                                                            {moment(leave.leaveStartDate).format(
                                                                                'DD/MM/YYYY'
                                                                            ) ?? '-'}
                                                                        </td>
                                                                        <td className="d-hide-sm">
                                                                            {moment(leave.leaveEndDate).format(
                                                                                'DD/MM/YYYY'
                                                                            ) ?? '-'}
                                                                        </td>
                                                                        <td>
                                                                            {parseFloat(`${leave.leaveDays}`)
                                                                                .toFixed(1)
                                                                                .replace(/\.0+$/, '')}
                                                                            {leave.leaveDays > 1 ? ` days` : ` day`}
                                                                        </td>
                                                                        <td>
                                                                            <StatusIndicator
                                                                                status={leave.leaveApprovalStatus}
                                                                            />
                                                                            {approverTags(leave).map((tags) => tags)}
                                                                            <LeaveDetailModal
                                                                                showModal={showModalId === leave.uuid}
                                                                                leaveDetails={leave}
                                                                                handleClose={handleModalClose}
                                                                            />
                                                                        </td>
                                                                        <td className="d-hide-sm">
                                                                            {moment(leave.requestedDate).format(
                                                                                'DD/MM/YYYY'
                                                                            ) ?? '-'}
                                                                        </td>
                                                                        <td>
                                                                            <FontAwesomeIcon
                                                                                title="View Details"
                                                                                icon={icon({ name: 'info-circle' })}
                                                                                size="lg"
                                                                                className="cursor-pointer text-primary"
                                                                                onClick={() => openModal(leave.uuid)}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </InfiniteScroll>
                                            </div>
                                        </>
                                    )}
                                    {loading && (
                                        <div key={0} className="row">
                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                <div className="spinner-border text-primary me-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default EmployeeLeavesList;
