/* eslint-disable @typescript-eslint/no-explicit-any */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import React from 'react';
import { UserRole } from '../../../types/user';
type ApplicationAccessInfoProp = {
    formData: any; //UserProfile;
    formErrors: any;
    inputChangeHandler: (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void;
    userRole: UserRole;
    updateFlow?: boolean;
    selfLogin?: boolean;
};

const ApplicationAccessInfo = (props: ApplicationAccessInfoProp) => {
    const { formData, formErrors, inputChangeHandler, userRole, selfLogin } = props;
    return (
        <form>
            <div className="alert alert-warning alert-outline-coloured alert-dismissible" role="alert">
                <div className="alert-icon">
                    <FontAwesomeIcon icon={icon({ name: 'bell' })} />
                </div>
                <div className="alert-message">
                    <strong>Note:</strong> If you do not wish to update password keep it blank.
                </div>
            </div>
            {userRole !== UserRole.employee && !selfLogin ? (
                <>
                    {' '}
                    <label>
                        Role <span className="required-field">*</span>
                    </label>
                    <select
                        name="role"
                        className="form-control form-control-lg form-select mb-3 "
                        onChange={inputChangeHandler}
                        value={formData.role}
                    >
                        <option value="0">Select user role</option>
                        <option value="admin">App Admin</option>
                        <option value="hr">HR Executive</option>
                        <option value="director">Director</option>
                        <option value="manager">Manager</option>
                        <option value="employee">Employee</option>
                    </select>
                    {formErrors.role && <div className="invalid-feedback mb-3">{formErrors.role}</div>}
                </>
            ) : (
                <div className="mt-4 mb-4">
                    <strong>Assigned Role:</strong> {formData.role.replace('_', ' ').toUpperCase() ?? ''}
                    <hr />
                </div>
            )}
            <label>
                Password <span className="required-field">*</span>
            </label>
            <input
                className={`form-control form-control-lg mb-3 ${formErrors.password ? 'is-invalid' : ''}`}
                type="password"
                name="password"
                value={formData.password}
                autoComplete="false"
                onChange={inputChangeHandler}
            />
            {formErrors.password && <div className="invalid-feedback mb-3">{formErrors.password}</div>}
            <label>
                Confirm Password <span className="required-field">*</span>
            </label>
            <input
                className={`form-control form-control-lg mb-3 ${formErrors.confirmPassword ? 'is-invalid' : ''}`}
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={inputChangeHandler}
                autoComplete="false"
            />
            {formErrors.confirmPassword && <div className="invalid-feedback mb-3">{formErrors.confirmPassword}</div>}
        </form>
    );
};

export default ApplicationAccessInfo;
