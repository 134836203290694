import React from 'react';
import cn from 'classnames';
import Dropzone from 'react-dropzone';
import './FileDropzone.scss';

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    acceptedMimeTypes: any;
    title: React.ReactNode | string;
    onDrop?(acceptedFiles: File[]): void;
    multiple?: boolean;
    isDisabled?: boolean;
    note?: string;
}

const FileDropzone = ({ acceptedMimeTypes, onDrop, title, note, multiple = false, isDisabled = false }: Props) => (
    <Dropzone accept={acceptedMimeTypes} multiple={multiple} onDrop={onDrop} disabled={isDisabled}>
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
            const rootProps = getRootProps({
                className: cn('FileDropzone', {
                    'FileDropzone--active': isDragActive,
                    'FileDropzone--reject': isDragReject,
                    'FileDropzone--disabled': isDisabled,
                }),
            });
            return (
                <>
                    <div {...rootProps}>
                        <input {...getInputProps()} />
                        {title && title}
                    </div>
                    {note && <em style={{ fontWeight: 'bold' }}>{note}</em>}
                </>
            );
        }}
    </Dropzone>
);

export default FileDropzone;
