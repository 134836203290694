import React, { useCallback, useEffect, useState } from 'react';
import * as Icon from 'react-feather';
import { faUser, faUnlockKeyhole, faBell, faCake, faCalendarDays, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NavLink } from 'react-router-dom';
import { SuperUserDashBoardMeta } from '../../types/dashboard';
import { IRootState, useSelector } from '../../store';
import { UserProfile } from '../../types/user';
import { getSuperUserDashboardData } from '../../services/dashboard';
import { SimpleMetricCard } from './MetricCard';
import './Dashboard.scss';
import GenericCard from './GenericCard';

// Get total number of employees
// Get employees who have app access
// Get employees who have not logged in for 24 hours -> use last login status./ ONLINE STATUS
// Get employees which are online currently.
// Employees joined in last month

const SuperUserDashboard = () => {
    const loggedInUser = useSelector((state: IRootState) => state.user) as UserProfile;
    const { uuid } = loggedInUser;
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [dashboardData, setDashboardData] = useState<SuperUserDashBoardMeta>();
    const fetchDashboardData = useCallback(async () => {
        try {
            setLoading(true);
            const { data } = await getSuperUserDashboardData(uuid);
            setDashboardData(data.dashboardData);
            setLoading(false);
        } catch (err: unknown) {
            setError('Something went wrong');
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDashboardRefresh = () => {
        fetchDashboardData();
    };

    useEffect(() => {
        if (uuid) {
            fetchDashboardData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);
    return (
        <>
            {loading && (
                <div className="row mt-4">
                    <div className="col-sm-12 mt-2 mb-2 text-center">
                        <div className="spinner-border text-primary me-2" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            )}

            {error && (
                <div className="row mt-4">
                    <div className="col-sm-12">
                        <div className="alert alert-danger alert-outline alert-dismissible mt-5" role="alert">
                            <div className="alert-icon">
                                <Icon.Bell className="far fa-fw fa-bell" />
                            </div>
                            <div className="alert-message">
                                <p>
                                    <strong>Dashboard not available yet</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {!loading && !error && dashboardData && (
                <>
                    <div className="row">
                        <div className="col-xl-6 col-xxl-4 order-md-1 ">
                            <div className="w-100">
                                <div className="row">
                                    <div className="col-12 d-flex">
                                        <div className="card flex-fill quick-link">
                                            <div className="card-body text-center">
                                                <button className="btn btn-primary" onClick={handleDashboardRefresh}>
                                                    <FontAwesomeIcon
                                                        size="sm"
                                                        icon={icon({ name: 'refresh' })}
                                                        className="far fa-fw fa-bell"
                                                    />{' '}
                                                    Refresh Dashboard Data
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12   d-flex">
                                        <div className="card flex-fill quick-link">
                                            <div className="card-header">
                                                <h5 className="card-title mb-0">
                                                    <FontAwesomeIcon
                                                        size="sm"
                                                        icon={icon({ name: 'link' })}
                                                        className="far fa-fw fa-bell p-2  bg-light"
                                                        style={{ borderRadius: '50%', verticalAlign: 'middle' }}
                                                    />{' '}
                                                    Quick Actions
                                                </h5>
                                            </div>

                                            <div className="card-body pt-0">
                                                <div className="col-12 bg-light p-2 link-item">
                                                    <NavLink to="/employee/register">
                                                        <FontAwesomeIcon
                                                            size="sm"
                                                            icon={icon({ name: 'angle-right' })}
                                                            className="far fa-fw fa-bell"
                                                        />{' '}
                                                        Register New Employee
                                                    </NavLink>
                                                </div>
                                                <div className="col-12 bg-light p-2 link-item">
                                                    <NavLink to="/user/register/access">
                                                        <FontAwesomeIcon
                                                            size="sm"
                                                            icon={icon({ name: 'angle-right' })}
                                                            className="far fa-fw fa-bell"
                                                        />{' '}
                                                        Grant Application Access
                                                    </NavLink>
                                                </div>
                                                <div className="col-12 bg-light p-2 link-item">
                                                    <NavLink to="/employee/reportee/leaves/list">
                                                        <FontAwesomeIcon
                                                            size="sm"
                                                            icon={icon({ name: 'angle-right' })}
                                                            className="far fa-fw fa-bell"
                                                        />{' '}
                                                        Leave Approvals
                                                    </NavLink>
                                                </div>
                                                <div className="col-12 bg-light p-2 link-item">
                                                    <NavLink to="/employee/reportee/weekly-timesheet/list">
                                                        <FontAwesomeIcon
                                                            size="sm"
                                                            icon={icon({ name: 'angle-right' })}
                                                            className="far fa-fw fa-bell"
                                                        />{' '}
                                                        Timesheet Approvals
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-xxl-8  order-md-0">
                            <div className="w-100">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <SimpleMetricCard
                                            heading="Total Employees"
                                            value={dashboardData.totalEmployees.toString()}
                                            info="Registered in application"
                                            icon={faUser}
                                        />

                                        <SimpleMetricCard
                                            heading="Application Access"
                                            value={dashboardData.employeeWithAppAccess.toString()}
                                            info="Having application access"
                                            icon={faUnlockKeyhole}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <SimpleMetricCard
                                            heading="Currently Online"
                                            value={dashboardData.employeesCurrentlyOnline.toString()}
                                            info="Logged in now"
                                            icon={faCircle}
                                            iconColor="text-success"
                                        />

                                        <SimpleMetricCard
                                            heading="Offline Employees"
                                            value={dashboardData.employessOffline24hr.toString()}
                                            info="More than 24 hours"
                                            icon={faCircleExclamation}
                                            iconColor="text-danger"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-4 col-xxl">
                            <GenericCard
                                label="Offline Users"
                                note="Not logged in for more than 24 hrs"
                                data={dashboardData.employeeNotLoggedIn24hr}
                                icon={faCircleExclamation}
                            />
                        </div>
                        <div className="col-12 col-lg-4 col-xxl">
                            {/* TODO: Make generic component */}
                            <GenericCard
                                label="Upcoming Leaves"
                                note="In next 7 days"
                                data={dashboardData.employeeUpcomingLeaves}
                                icon={faCalendarCheck}
                            />
                        </div>
                        <div className="col-12 col-lg-4 col-xxl">
                            {/* TODO: Make generic component */}
                            <GenericCard
                                label="Holidays"
                                note="Holidays for this year in India"
                                data={dashboardData.currentYearHolidays}
                                icon={faCalendarDays}
                                actionLink="/employees/calendar"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-4 col-xxl">
                            <GenericCard
                                label="Leave Approvals"
                                note="Pending approval requests"
                                data={dashboardData.leaveRequestApprovals}
                                actionLink="/employee/reportee/leaves/list"
                                icon={faBell}
                            />
                        </div>
                        <div className="col-12 col-lg-4 col-xxl">
                            <GenericCard
                                label="Timesheet Approvals"
                                note="Pending approval requests"
                                data={dashboardData.timesheetRequestApprovals}
                                actionLink="/employee/reportee/weekly-timesheet/list"
                                icon={faBell}
                            />
                        </div>
                        <div className="col-12 col-lg-4 col-xxl">
                            <GenericCard
                                label="Pending Timesheet"
                                note="Not submitted for last week"
                                data={dashboardData.employeeNotSubmittedTimesheet}
                                actionLink="/employee/reportee/weekly-timesheet/list"
                                icon={faBell}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-6 col-xxl">
                            <GenericCard
                                label="Birthday Reminders"
                                note="Upcoming birthdays in next 7 days"
                                data={dashboardData.employeeBirthdays}
                                icon={faCake}
                            />
                        </div>
                        <div className="col-12 col-lg-6 col-xxl">
                            <GenericCard
                                label="Work Anniversary"
                                note="Upcoming work anniversaries in next 7 days"
                                data={dashboardData.employeesWorkAnniversary}
                                icon={faCake}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default SuperUserDashboard;
