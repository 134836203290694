import { request } from '../api/';
import { PageLimit } from '../constants/data';
import { ApplicationUser, UserActivity, UserCreds, UserFilters, UserProfile } from '../types/user';

export const loginUser = async (creds: UserCreds) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/auth/login`,
        skipAuth: true,
        data: { ...creds },
    };

    return request(requestOptions);
};

export const logoutUser = async () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/user/logout`,
    };
    return request(requestOptions);
};

export const getUserProfile = async () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/user/profile`,
    };
    return request<{ data: UserProfile }>(requestOptions);
};

export const getUserProfileById = async (uuid: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/user/${uuid}`,
    };
    return request<{ data: UserProfile }>(requestOptions);
};

export const getUserList = async (page: number, filters: UserFilters = {}) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/user/list`,
        data: {
            pagination: {
                page,
                pageLimit: PageLimit,
            },
            filters,
        },
    };
    return request<{ data: ApplicationUser[] }>(requestOptions);
};

export const deleteUser = async (uuid: string) => {
    const requestOptions = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_ENDPOINT}/user/${uuid}`,
    };
    return request(requestOptions);
};

export const searchUserList = async (keyword: string, excludedUsers?: string[]) => {
    let url = `${process.env.REACT_APP_API_ENDPOINT}/user/search/list/${keyword}`;

    if (excludedUsers && excludedUsers.length > 0) {
        const excludedQueryString = excludedUsers.join(',');
        url += `?exclude=${excludedQueryString}`;
    }

    const requestOptions = {
        method: 'GET',
        url: url,
    };

    return request<{ data: UserProfile[] }>(requestOptions);
};

export const registerUser = async (userData: Omit<UserProfile, 'uuid' | 'lastLogin' | 'status'>) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/user/register`,
        data: { ...userData },
    };
    return request(requestOptions);
};

export const updatePassword = async (id: string, oldPassword: string, newPassword: string) => {
    const requestOptions = {
        method: 'PATCH',
        url: `${process.env.REACT_APP_API_ENDPOINT}/user/update/password`,
        data: { id, oldPassword, newPassword },
    };
    return request(requestOptions);
};

export const updateProfile = async (fields: Partial<UserProfile>) => {
    const requestOptions = {
        method: 'PATCH',
        url: `${process.env.REACT_APP_API_ENDPOINT}/user/update/profile`,
        data: { ...fields },
    };
    return request(requestOptions);
};

export const extendSession = async () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/user/extend-session`,
    };
    return request(requestOptions);
};

export const getApplicationUserdetails = async (uuid: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/user/${uuid}`,
    };
    return request<{ data: ApplicationUser }>(requestOptions);
};

export const updateUserData = async (fields: Partial<ApplicationUser>) => {
    const requestOptions = {
        method: 'PATCH',
        url: `${process.env.REACT_APP_API_ENDPOINT}/user/update`,
        data: { ...fields },
    };
    return request(requestOptions);
};

export const updateUserActivity = async (fields: Partial<UserActivity>) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/user/activity/update`,
        data: { ...fields },
    };
    return request(requestOptions);
};

export const resetPasswordRequest = async (username: string) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/non-auth/generate-reset-link`,
        skipAuth: true,
        data: { username },
    };

    return request(requestOptions);
};

export const validateResetLink = async (key: string, sec: string) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/non-auth/validate-reset-link`,
        skipAuth: true,
        data: { key, sec },
    };

    return request<{
        email: string;
    }>(requestOptions);
};

export const passwordResetRequest = async (email: string, password: string, sec: string) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/non-auth/reset-password`,
        skipAuth: true,
        data: { email, sec, password },
    };

    return request<boolean>(requestOptions);
};

export const sendCustomerAccessRequest = async (id: string) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/user/create/customer-access`,
        data: { id },
    };
    return request<boolean>(requestOptions);
};
