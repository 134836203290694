/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Modal } from 'react-bootstrap';
import EmployeeSearchInput from '../../EmployeeSearchInput/EmployeeSearchInput';
import { searchEmployeeByKeyword } from '../../../services/employee';
import { getEmployeePreferences, updateEmployeePreferences } from '../../../services/controlCenter';
import { EmployeePreference } from '../../../types/employee';
import { getMasterPreferences } from '../../../services/data';
import { MasterEmployeePreference } from '../../../types/orgData';
import { useAppContext } from '../../../hooks/useAppContext';
import { HelpTip } from '../../HelpTip/HelpTip';
import '../ControlCenter.scss';

const EmployeePreferences = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultError = {} as any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const childRef = useRef() as any;
    const { triggerSuccess } = useAppContext();
    const [loadingData, setLoadingData] = useState(false);
    const [showMasterPreferenceModal, setShowMasterPreferenceModal] = useState(false);
    const [preferences, setPreferences] = useState<MasterEmployeePreference[]>([]);
    const [errors, setErrors] = useState(defaultError);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [error, setError] = useState('');
    const [dataFetched, setDataFetched] = useState(false);
    const pristineForm = {
        employeeId: '',
        employeePreferences: [] as EmployeePreference[],
        employeeSelectedPreferences: [] as string[],
    };
    const [formData, setFormData] = useState(pristineForm);
    const fetchEmployeePreferences = async () => {
        try {
            setLoadingData(true);
            const { data } = await getEmployeePreferences(formData.employeeId);
            setDataFetched(true);
            setFormData({
                ...formData,
                employeePreferences: data.employeePrereferences,
                employeeSelectedPreferences: data.employeePrereferences.map((pref) => pref.preference),
            });
            setSubmitLoading(false);
            setLoadingData(false);
        } catch (err: unknown) {
            setDataFetched(false);
            setLoadingData(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };
    const getPreferences = () => {
        if (!formData.employeeId) {
            setErrors({ employeeId: 'Search and select employee.' });
        } else {
            setErrors(defaultError);
            fetchEmployeePreferences();
        }
    };
    const clearData = () => {
        childRef.current?.clearInputs();
        setDataFetched(false);
        setError('');
        setFormData({ ...pristineForm });
    };

    const handleSubmit = async () => {
        try {
            setSubmitLoading(true);
            const invalid = formData.employeePreferences.some((employeePreferences) => employeePreferences.value === '0');
            if (invalid) {
                setError('Option not selected');
            } else {
                const updatedPreferences = formData.employeePreferences.filter((employeePreferences) =>
                    formData.employeeSelectedPreferences.includes(employeePreferences.preference)
                );
                await updateEmployeePreferences(updatedPreferences, formData.employeeId);
                triggerSuccess(`Employee preferences updated successfully.`);
                setError('');
            }

            setSubmitLoading(false);
            setLoadingData(false);
        } catch (err: unknown) {
            setDataFetched(false);
            setLoadingData(false);
        }
    };

    const handlePreferenceSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        let updatedPreferences = formData.employeeSelectedPreferences;
        if (formData.employeeSelectedPreferences.includes(event.target.name)) {
            updatedPreferences = updatedPreferences.filter((item) => item !== event.target.name);
        } else {
            updatedPreferences.push(event.target.name);
        }
        setFormData({
            ...formData,
            employeeSelectedPreferences: updatedPreferences,
        });
    };

    const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const preferenceName = e.target.name;
        const preferenceValue = e.target.value;

        // Find the index of the preference in the array
        const preferenceIndex = formData.employeePreferences.findIndex(
            (employeePref) => employeePref.preference === preferenceName
        );

        const updatedPreferences = [...formData.employeePreferences];
        // If the preference is found, update its value
        if (preferenceIndex !== -1) {
            updatedPreferences[preferenceIndex] = {
                ...updatedPreferences[preferenceIndex],
                value: preferenceValue,
            };
        } else {
            updatedPreferences.push({ preference: preferenceName, value: preferenceValue } as EmployeePreference);
        }
        // Update the state with the new preferences
        setFormData({
            ...formData,
            employeePreferences: updatedPreferences,
        });
    };

    useEffect(() => {
        const getPreferencesData = async () => {
            const prefences = await getMasterPreferences();
            if (prefences) {
                setPreferences(prefences);
            }
        };
        getPreferencesData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <main className="content leave-allocations">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-control-center-info">
                                {' '}
                                <p>
                                    <FontAwesomeIcon className="me-3" size="lg" icon={icon({ name: 'shield-alt' })} />{' '}
                                    Application Control Center{' '}
                                    <FontAwesomeIcon className="me-3 ms-3" icon={icon({ name: 'chevron-right' })} /> Employee
                                    Preferences
                                </p>
                            </div>
                            <div className="card-body col-12">
                                <>
                                    <div className="container bg-light p-3 pb-1">
                                        <p>
                                            This feature allows you to efficiently manage preferences for each registered
                                            employee.
                                        </p>

                                        <ul>
                                            <li>
                                                <strong>Preference List:</strong>
                                                <ul>
                                                    <li>
                                                        You can view the list of preferences available and apply it to the
                                                        employee.
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Caution:</strong>
                                                <ul>
                                                    <li>
                                                        Certain preferences can affect the way employees manage their work
                                                        and get notifications.
                                                    </li>
                                                    <li>
                                                        Read the description of each preferences before applying a certain
                                                        preference option.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="form-container bg-light p-4 pt-1">
                                        <div className="row col-12">
                                            <label className="fw-bold pb-2">
                                                Search Employee <span className="required-field">*</span>{' '}
                                            </label>
                                            <div className="col-md-6">
                                                <EmployeeSearchInput
                                                    errors={errors}
                                                    setErrors={setErrors}
                                                    formData={formData}
                                                    setFormData={setFormData}
                                                    searchFnCallback={searchEmployeeByKeyword}
                                                    inputIndex={'employeeId'}
                                                    ref={childRef}
                                                />
                                                <div
                                                    className={cn('invalid-feedback mb-3', {
                                                        show: errors.employeeId,
                                                    })}
                                                >
                                                    {errors.employeeId}
                                                </div>
                                            </div>
                                            <div className="col-md-4 mt-md-0 mt-3 align-bottom text-start">
                                                <button type="button" className="btn btn-primary" onClick={getPreferences}>
                                                    Get Preferences
                                                </button>
                                                <span onClick={clearData} className="text-primary cursor-pointer ms-3">
                                                    Clear All
                                                </span>
                                            </div>
                                        </div>
                                        {(loadingData || submitLoading) && (
                                            <div className="row mt-4">
                                                <div className="col-sm-12 mt-2 mb-2 text-center">
                                                    <div className="spinner-border text-primary me-2" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {dataFetched && (
                                            <div className="row mt-3 col-12">
                                                <>
                                                    {' '}
                                                    {!loadingData &&
                                                    !submitLoading &&
                                                    formData.employeePreferences &&
                                                    formData.employeePreferences?.length > 0 ? (
                                                        <>
                                                            <div className=" col-md-8">
                                                                <p className="p-2 bg-info text-start text-white">
                                                                    Below are existing preferences for the employee. If you
                                                                    want to add or remove preferences, Click on{' '}
                                                                    <strong>Update Preferences</strong>.
                                                                </p>
                                                            </div>{' '}
                                                            <div className="col-md-4 align-bottom text-start">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                    onClick={() => setShowMasterPreferenceModal(true)}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        size="sm"
                                                                        icon={icon({ name: 'plus' })}
                                                                    />{' '}
                                                                    Update Preferences
                                                                </button>{' '}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {' '}
                                                            <div className=" col-md-8">
                                                                <p className="p-2 bg-info text-start text-white">
                                                                    Preferences for this employee are not added yet. Click on{' '}
                                                                    <strong>Add Preferences</strong> to add them now.
                                                                </p>
                                                            </div>
                                                            <div className="col-md-4 align-bottom text-start">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                    onClick={() => setShowMasterPreferenceModal(true)}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        size="sm"
                                                                        icon={icon({ name: 'plus' })}
                                                                    />{' '}
                                                                    Add Preferences
                                                                </button>
                                                            </div>
                                                        </>
                                                    )}
                                                    {error && (
                                                        <div className="col-md-8">
                                                            <p className=" error-message">
                                                                <FontAwesomeIcon
                                                                    className="me-2"
                                                                    icon={icon({ name: 'warning' })}
                                                                />{' '}
                                                                Looks like you have have not selected some option value.
                                                            </p>
                                                        </div>
                                                    )}
                                                    {formData.employeeSelectedPreferences.length !== 0 && (
                                                        <div className=" ms-1  p-2 py-1 col-12 add-holiday-container">
                                                            <div className="row gx-0">
                                                                <div className="col-4 fw-bold p-2 bg-app-header text-white">
                                                                    Preference
                                                                </div>
                                                                <div className="col-4 fw-bold p-2 bg-app-header text-white">
                                                                    Set Value
                                                                </div>
                                                            </div>
                                                            {formData.employeeSelectedPreferences.map((item, index) => (
                                                                <div className="row gx-0" key={index}>
                                                                    <div className="col-4 p-2 m-0 ps-3 bg-app-row bg-app-row__border-bottom">
                                                                        <strong>{item.replaceAll('_', ' ')}</strong>{' '}
                                                                        <HelpTip
                                                                            message={
                                                                                preferences.find(
                                                                                    (master) => master.preference === item
                                                                                )?.description ?? '-'
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-4 p-2 m-0  bg-app-row bg-app-row__border-bottom">
                                                                        {
                                                                            <select
                                                                                name={`${item}`}
                                                                                className={`form-control form-control-lg form-select mb-3 ${
                                                                                    errors.year ? 'is-invalid' : ''
                                                                                }`}
                                                                                value={
                                                                                    formData.employeePreferences.find(
                                                                                        (employeePref) =>
                                                                                            employeePref.preference === item
                                                                                    )?.value
                                                                                }
                                                                                onChange={handleOptionChange}
                                                                            >
                                                                                <option value="0">Select Option</option>
                                                                                {preferences
                                                                                    .find(
                                                                                        (master) =>
                                                                                            master.preference === item
                                                                                    )
                                                                                    ?.options.map((op, k) => (
                                                                                        <option key={k} value={op}>
                                                                                            {op}
                                                                                        </option>
                                                                                    ))}
                                                                            </select>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <div className="row">
                                                                <div className="col-8 p-2 pb-0 text-end ">
                                                                    <button
                                                                        type="button"
                                                                        className="mt-2 btn  btn-primary"
                                                                        onClick={handleSubmit}
                                                                    >
                                                                        Save Changes{' '}
                                                                        {submitLoading && (
                                                                            <div
                                                                                className="spinner-border spinner-border-sm ms-2"
                                                                                role="status"
                                                                            >
                                                                                <span className="visually-hidden">
                                                                                    Loading...
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            </div>
                                        )}
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                size={'lg'}
                show={showMasterPreferenceModal}
                centered={true}
                onHide={() => setShowMasterPreferenceModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon icon={icon({ name: 'list' })} size="lg" />
                        <span className="ms-2">Available Preferences</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>List of available system preferences and employee&apos;s saved preferences.</h4>
                    <div className="row m-auto mt-1 col-12 p-3 py-1">
                        <ul>
                            <li>If employee already have a saved preference, they are pre-selected in below list.</li>
                            <li>
                                <strong>Caution:</strong> Un-checking a prefernce will remove the preference completely.{' '}
                            </li>
                        </ul>
                    </div>
                    <div className="row m-auto mt-1 col-12">
                        {preferences.length > 0 ? (
                            preferences.map((item) => (
                                <div key={item.preference} className="row p-2 m-1 col-5 bg-light text-dark">
                                    <div className="col-9 fw-bold">
                                        {item.preference.replaceAll('_', ' ')} <HelpTip message={item.description} />
                                    </div>
                                    <div className="col-3 text-end">
                                        <input
                                            name={item.preference}
                                            onChange={handlePreferenceSelection}
                                            type="checkbox"
                                            checked={formData.employeeSelectedPreferences.includes(item.preference)}
                                        />
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="p-2 m-1 col-12 bg-warning text-center text-dark">
                                <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} size="lg" /> No preferences
                                available to be added
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="text-end mt-2">
                        <button
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={() => setShowMasterPreferenceModal(false)}
                        >
                            Close
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </main>
    );
};

export default EmployeePreferences;
