export const EmployeeLeaveTypes = {
    basic: ['Sick Leave', 'Casual Leave', 'Privileged Leave', 'Personal Emergency'],
    special: ['Leave Without Pay', 'Maternity', 'Special Leave Request'],
};

export const TimeSheetTasks = {
    default: ['Development', 'Meetings', 'Testing', 'Break', 'Documentation'],
};

export const PageLimit = 10;
