/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import * as Icon from 'react-feather';
import InfiniteScroll from 'react-infinite-scroller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import moment from 'moment';
import { CUSTOMER_USERS, EMP_MANAGERS, UserProfile } from '../../../types/user';
import { IRootState, useSelector } from '../../../store';
import { getCustomerWeeklyTimesheet, getReporteeWeeklyTimesheet } from '../../../services/employeeTimesheet';
import { EmployeeTimesheetFilters, WeeklyTimesheet } from '../../../types/timesheetType';
import { StatusIndicator } from '../../StatusIndicator/StatusIndicator';
import './EmployeeTimesheetList.scss';
import { ActionStatus } from '../../../types/employee';
import { ADMIN_USERS, UserRole } from '../../../types/user';
import { TimesheetApprovalModal } from '../TimesheetApprovalModal/TimesheetApprovalModal';
import { WeeklyTimesheetDetailModal } from './WeeklyTimesheetDetailModal';
// TODO: LEAVE META DATA, like total applied leaves, rejected total, approved total, leave balance.

const ReporteesWeeklyTimesheetList = () => {
    // const CURRENT_MONTH = (moment().month() + 1).toString();
    const CURRENT_YEAR = moment().year().toString();
    const loggedInUser = useSelector((state: IRootState) => state.user) as UserProfile;
    const [employeeTimesheet, setEmployeeTimesheet] = useState<WeeklyTimesheet[]>([]);
    const [showModalId, setShowModalId] = useState<string | null>(null);
    const [showApprovalModalId, setApprovalShowModalId] = useState<string | null>(null);
    const [filters, setFilters] = useState<EmployeeTimesheetFilters>({
        year: CURRENT_YEAR,
        month: '',
    });
    const isCustomerView = loggedInUser.role === UserRole.customer;
    const [error, setError] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const fetchEmployeeTimesheet = async (page: number, searchFilters: EmployeeTimesheetFilters) => {
        try {
            setLoading(true);

            let response = {
                data: [] as WeeklyTimesheet[],
            };
            if (isCustomerView) {
                response = await getCustomerWeeklyTimesheet(page, searchFilters);
            } else {
                response = await getReporteeWeeklyTimesheet(page, searchFilters);
            }
            if (response.data.length > 0) {
                setHasMore(true);
                setEmployeeTimesheet((prevData) => [...prevData, ...response.data]);
            } else {
                setHasMore(false);
            }

            setLoading(false);
        } catch (err: unknown) {
            setError('Something went wrong');
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const filterType = event.target.name; // Get the id of the select element as filterType
        const newFilters: EmployeeTimesheetFilters = { ...filters };
        if (event.target.value !== '0') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            newFilters[filterType as keyof EmployeeTimesheetFilters] = event.target.value as any;
            setFilters(newFilters);
        } else {
            delete newFilters[filterType as keyof EmployeeTimesheetFilters];
            setFilters(newFilters);
        }
    };

    const openModal = (id: string) => {
        setShowModalId(id);
    };

    const handleModalClose = () => {
        setShowModalId(null);
    };

    const openApprovalModal = (id: string) => {
        setApprovalShowModalId(id);
    };

    const handleApprovalModalClose = () => {
        setApprovalShowModalId(null);
    };

    const handleDataRefresh = () => {
        setEmployeeTimesheet([]);
        fetchEmployeeTimesheet(1, filters);
    };

    const handleSearchClick = () => {
        setEmployeeTimesheet([]);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (filters.approvalStatus === ('0' as any)) {
            delete filters.approvalStatus;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (filters.month === ('0' as any)) {
            delete filters.month;
        }
        fetchEmployeeTimesheet(1, filters);
    };

    const handleClearFilter = () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (filters.approvalStatus === ('0' as any)) {
            delete filters.approvalStatus;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setFilters({ ...filters, year: CURRENT_YEAR, month: '' });
        setEmployeeTimesheet([]);
        fetchEmployeeTimesheet(1, {});
    };

    useEffect(() => {
        if (loggedInUser.uuid) {
            fetchEmployeeTimesheet(1, filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInUser.uuid]);

    return (
        <main className="content view-employees-timesheet-list">
            <div className="container-fluid p-0">
                <div className="row mb-2 mb-xl-3">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon size="lg" icon={icon({ name: 'calendar-times' })} />
                            {isCustomerView ? ' Resource ' : ' Reportee '} Timesheet Submissions
                        </h3>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-info">
                                <p>This page provides overview of timesheet submissions from your resources. </p>
                                <p className="mb-0">
                                    <strong>Note:</strong>
                                </p>
                                <ul>
                                    <li>You can view details of each timesheet by clicking on info icon.</li>
                                    <li>You can unlock the task time in case you want the resource to fill it again.</li>
                                </ul>
                            </div>
                            <div className="card-body">
                                <div
                                    id="datatables-reponsive_wrapper"
                                    className="dataTables_wrapper dt-bootstrap5 no-footer"
                                >
                                    {error && (
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div
                                                    className="alert alert-danger alert-outline alert-dismissible"
                                                    role="alert"
                                                >
                                                    <div className="alert-icon">
                                                        <Icon.Bell className="far fa-fw fa-bell" />
                                                    </div>
                                                    <div className="alert-message">
                                                        <strong>Error</strong> Something went wrong when trying to get your
                                                        timesheet data.
                                                        <br /> If the problem persists, please contact support.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {
                                        <>
                                            <div className="search-container">
                                                <div className="mb-3 row">
                                                    <strong>Filter By:</strong>
                                                </div>
                                                <div className="mb-3 row">
                                                    <div className="col-3">
                                                        <select
                                                            name="year"
                                                            className="form-control form-control-lg form-select"
                                                            onChange={handleFilterChange}
                                                            value={filters.year}
                                                        >
                                                            <option value="2024">2024</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-3">
                                                        <select
                                                            name="month"
                                                            className="form-control form-control-lg form-select"
                                                            onChange={handleFilterChange}
                                                            value={filters.month}
                                                        >
                                                            <option value="0">Select Month</option>
                                                            {moment.months().map((month, i) => (
                                                                <option key={i} value={`${i + 1}`}>
                                                                    {month}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-3">
                                                        <select
                                                            name="approvalStatus"
                                                            className="form-control form-control-lg form-select"
                                                            onChange={handleFilterChange}
                                                            value={filters.approvalStatus}
                                                        >
                                                            <option value="0">Select status</option>
                                                            <option value="APPROVED">APPROVED</option>
                                                            <option value="REJECTED">REJECTED</option>
                                                            <option value="REQUESTED">REQUESTED</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-1">
                                                        {' '}
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={handleSearchClick}
                                                        >
                                                            Search
                                                        </button>
                                                    </div>
                                                    <div className="col-md-1 text-start pt-1 pl-1">
                                                        <span
                                                            onClick={handleClearFilter}
                                                            className="text-primary cursor-pointer"
                                                        >
                                                            Clear All
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {!loading && !error && employeeTimesheet?.length === 0 && (
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div
                                                    className="alert alert-danger alert-outline alert-dismissible mt-5"
                                                    role="alert"
                                                >
                                                    <div className="alert-icon">
                                                        <Icon.Bell className="far fa-fw fa-bell" />
                                                    </div>
                                                    <div className="alert-message">
                                                        <p>
                                                            <strong>
                                                                No timesheet data available. <br /> It is possible that there
                                                                is no data added yet.
                                                                <br />
                                                                OR try refining your search filters.
                                                            </strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {employeeTimesheet?.length !== 0 && (
                                        <>
                                            <div style={{ height: '500px', overflow: 'auto' }}>
                                                <InfiniteScroll
                                                    pageStart={1}
                                                    loadMore={(page: number) => fetchEmployeeTimesheet(page, filters)}
                                                    hasMore={hasMore && !loading}
                                                    loader={
                                                        <div key={0} className="row">
                                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                                <div
                                                                    className="spinner-border text-primary me-2"
                                                                    role="status"
                                                                >
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    useWindow={false}
                                                >
                                                    <table
                                                        id="datatables-reponsive"
                                                        className="table table-striped dataTable no-footer dtr-inline view-employees-leaves-list-table"
                                                        style={{ width: '100%', tableLayout: 'fixed' }}
                                                        aria-describedby="datatables-reponsive_info"
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th>Request #</th>
                                                                <th>Employee</th>
                                                                <th>Week</th>
                                                                <th>Total Hours</th>
                                                                <th>Approval Status</th>
                                                                <th>Submitted On</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {employeeTimesheet &&
                                                                employeeTimesheet.map((timesheetData, index) => (
                                                                    <tr
                                                                        className={cn({
                                                                            odd: index % 2 === 0,
                                                                            even: index % 2 !== 0,
                                                                        })}
                                                                        key={`${timesheetData.uuid}-${index}`}
                                                                    >
                                                                        <td>{timesheetData.requestNumber}</td>
                                                                        <td>{timesheetData.employeeName}</td>
                                                                        <td>
                                                                            {moment(timesheetData.weekStartDate).format(
                                                                                'DD/MM/YYYY'
                                                                            ) ?? '-'}{' '}
                                                                            -{' '}
                                                                            {moment(timesheetData.weekEndDate).format(
                                                                                'DD/MM/YYYY'
                                                                            ) ?? '-'}
                                                                        </td>
                                                                        <td>{timesheetData.totalHours}</td>
                                                                        <td>
                                                                            <StatusIndicator
                                                                                status={timesheetData.timeSheetStatus}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            {moment(timesheetData.createdAt).format(
                                                                                'DD/MM/YYYY'
                                                                            ) ?? '-'}
                                                                        </td>
                                                                        <td>
                                                                            <FontAwesomeIcon
                                                                                title="View Details"
                                                                                icon={icon({ name: 'info-circle' })}
                                                                                size="lg"
                                                                                className="cursor-pointer me-2 text-primary"
                                                                                onClick={() => openModal(timesheetData.uuid)}
                                                                            />
                                                                            {timesheetData.approvalStatus !==
                                                                                ActionStatus.REJECTED &&
                                                                                [
                                                                                    ...ADMIN_USERS,
                                                                                    ...EMP_MANAGERS,
                                                                                    ...CUSTOMER_USERS,
                                                                                ].includes(loggedInUser.role) && (
                                                                                    <>
                                                                                        {' '}
                                                                                        {((timesheetData.approvalStatus ===
                                                                                            ActionStatus.APPROVED &&
                                                                                            [...ADMIN_USERS].includes(
                                                                                                loggedInUser.role
                                                                                            )) ||
                                                                                            (timesheetData.approvalStatus ===
                                                                                                ActionStatus.REQUESTED &&
                                                                                                [
                                                                                                    ...ADMIN_USERS,
                                                                                                    ...EMP_MANAGERS,
                                                                                                    ...CUSTOMER_USERS,
                                                                                                ].includes(
                                                                                                    loggedInUser.role
                                                                                                ))) && (
                                                                                            <button
                                                                                                className="btn btn-primary btn-sm"
                                                                                                title="Timesheet Action"
                                                                                                onClick={() =>
                                                                                                    openApprovalModal(
                                                                                                        timesheetData.uuid
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                Approve/Reject
                                                                                            </button>
                                                                                        )}
                                                                                        <TimesheetApprovalModal
                                                                                            timesheet={timesheetData}
                                                                                            showModal={
                                                                                                showApprovalModalId ===
                                                                                                timesheetData.uuid
                                                                                            }
                                                                                            loggedInUserId={
                                                                                                loggedInUser.uuid
                                                                                            }
                                                                                            handleClose={
                                                                                                handleApprovalModalClose
                                                                                            }
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            <WeeklyTimesheetDetailModal
                                                                                timesheetDetails={timesheetData}
                                                                                approverView={true}
                                                                                showModal={
                                                                                    showModalId === timesheetData.uuid
                                                                                }
                                                                                handleClose={handleModalClose}
                                                                                refreshData={handleDataRefresh}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </InfiniteScroll>
                                            </div>
                                        </>
                                    )}
                                    {loading && (
                                        <div key={0} className="row">
                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                <div className="spinner-border text-primary me-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ReporteesWeeklyTimesheetList;
