/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { UserRole } from '../../../types/user';
type BankingInfoProp = {
    formData: any; //UserProfile;
    formErrors: any;
    inputChangeHandler: (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void;
    userRole: UserRole;
    updateFlow?: boolean;
};

const BankingInfo = (props: BankingInfoProp) => {
    const { formData, formErrors, inputChangeHandler } = props;
    return (
        <>
            <label>PAN Number {props.userRole === UserRole.employee && <span className="required-field">*</span>}</label>
            <input
                className={`form-control form-control-lg mb-3 ${formErrors.panCardNumber ? 'is-invalid' : ''}`}
                type="text"
                name="panCardNumber"
                value={formData.panCardNumber}
                autoComplete="false"
                onChange={inputChangeHandler}
            />
            {formErrors.panCardNumber && <div className="invalid-feedback mb-3">{formErrors.panCardNumber}</div>}
            <label>
                Bank Account Number {props.userRole === UserRole.employee && <span className="required-field">*</span>}
            </label>
            <input
                className={`form-control form-control-lg mb-3 ${formErrors.bankAccountNumber ? 'is-invalid' : ''}`}
                type="text"
                name="bankAccountNumber"
                value={formData.bankAccountNumber}
                autoComplete="false"
                onChange={inputChangeHandler}
            />
            {formErrors.bankAccountNumber && <div className="invalid-feedback mb-3">{formErrors.bankAccountNumber}</div>}
            <label>IFSC code {props.userRole === UserRole.employee && <span className="required-field">*</span>}</label>
            <input
                className={`form-control form-control-lg mb-3 ${formErrors.ifscCode ? 'is-invalid' : ''}`}
                type="text"
                name="ifscCode"
                value={formData.ifscCode}
                autoComplete="false"
                onChange={inputChangeHandler}
            />
            {formErrors.ifscCode && <div className="invalid-feedback mb-3">{formErrors.ifscCode}</div>}
            <label>PF Account</label>
            <input
                className={`form-control form-control-lg mb-3 ${formErrors.pfAccountNumber ? 'is-invalid' : ''}`}
                type="text"
                name="pfAccountNumber"
                value={formData.pfAccountNumber}
                autoComplete="false"
                onChange={inputChangeHandler}
            />
            {formErrors.pfAccountNumber && <div className="invalid-feedback mb-3">{formErrors.pfAccountNumber}</div>}
            <label>Aadhaar Number {props.userRole === UserRole.employee && <span className="required-field">*</span>}</label>
            <input
                className={`form-control form-control-lg mb-3 ${formErrors.aadhaarCardNumber ? 'is-invalid' : ''}`}
                type="text"
                name="aadhaarCardNumber"
                value={formData.aadhaarCardNumber}
                autoComplete="false"
                onChange={inputChangeHandler}
            />
            {formErrors.aadhaarCardNumber && <div className="invalid-feedback mb-3">{formErrors.aadhaarCardNumber}</div>}
        </>
    );
};

export default BankingInfo;
