/* eslint-disable no-console */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

const TermsConditions = () => {
    return (
        <main className="content user-registration">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-auto d-none d-sm-block">
                        <h4>
                            <FontAwesomeIcon icon={icon({ name: 'circle-info' })} /> Terms and conditions
                        </h4>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body col-12">
                                <h4 className="bg-info text-white p-2">Terms and conditions of usage</h4>
                                <div className="p-2">
                                    <h4>1. Acceptance of Terms</h4>
                                    <p>
                                        By accessing or using this application, you agree to be bound by these Terms &
                                        Conditions. If you do not agree with any part of these terms, you must not use the
                                        application.
                                    </p>

                                    <h4>2. Employment Contract Agreement</h4>
                                    <p>
                                        The usage of this application is subject to the terms outlined in your employment
                                        contract with <strong>Embed Square Solutions Pvt. Ltd.</strong>. The provisions in
                                        your employment contract take precedence over any conflicting terms mentioned herein.
                                    </p>

                                    <h4>3. User Eligibility</h4>
                                    <p>
                                        You must be a current employee of <strong>Embed Square Solutions Pvt. Ltd.</strong>{' '}
                                        with active employment status to use this application. Unauthorized access to or use
                                        of the application is strictly prohibited.
                                    </p>

                                    <h4>4. Termination of Employment</h4>
                                    <p>
                                        Upon termination of your employment with{' '}
                                        <strong>Embed Square Solutions Pvt. Ltd.</strong>, your access to and use of the
                                        application will be automatically terminated. You must cease using the application
                                        immediately upon the conclusion of your employment.
                                    </p>

                                    <h4>5. Confidentiality and Data Security</h4>
                                    <p>
                                        You agree to maintain the confidentiality of any information accessed or stored
                                        within the application. All data must be handled in accordance with Embed Square
                                        Solutions Pvt. Ltd.&apos;s data security policies.
                                    </p>

                                    <h4>6. Prohibited Activities</h4>
                                    <p>
                                        The following activities are strictly prohibited:
                                        <ul>
                                            <li>Unauthorized access to any part of the application.</li>
                                            <li>Sharing login credentials with unauthorized individuals.</li>
                                            <li>
                                                Attempting to interfere with the functionality or security of the
                                                application.
                                            </li>
                                        </ul>
                                    </p>

                                    <h4>7. Modification of Terms</h4>
                                    <p>
                                        <strong>Embed Square Solutions Pvt. Ltd.</strong> reserves the right to modify these
                                        Terms & Conditions at any time. Users will be notified of any changes, and continued
                                        use of the application implies acceptance of the modified terms.
                                    </p>

                                    <h4>8. Disclaimer of Warranty</h4>
                                    <p>
                                        The application is provided &quot;as is&quot; without any warranty of any kind,
                                        either expressed or implied. <strong>Embed Square Solutions Pvt. Ltd.</strong> does
                                        not warrant the accuracy, completeness, or reliability of the application.
                                    </p>

                                    <h4>9. Governing Law</h4>
                                    <p>
                                        These Terms & Conditions are governed by and construed in accordance with the laws of
                                        [Your Country/Region].
                                    </p>

                                    <h4>10. Contact Information</h4>
                                    <p>
                                        For questions or concerns regarding these Terms & Conditions, please contact the HR
                                        at <a href="mailto:hr@embedsquare.com">hr@embedsquare.com</a>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default TermsConditions;
