/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { UserRole } from '../../../types/user';

type ContactInfoProp = {
    formData: any; //UserProfile;
    formErrors: any;
    inputChangeHandler: (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => void;
    userRole: UserRole;
    updateFlow?: boolean;
};

const ContactInfo = (props: ContactInfoProp) => {
    const { formData, formErrors, inputChangeHandler } = props;
    return (
        <>
            <label>
                Permanent Address {props.userRole === UserRole.employee && <span className="required-field">*</span>}
            </label>
            <textarea
                rows={5}
                className={`form-control form-control-lg mb-3 ${formErrors.permanentAddress ? 'is-invalid' : ''}`}
                name="permanentAddress"
                value={formData.permanentAddress}
                onChange={inputChangeHandler}
            />
            {formErrors.permanentAddress && <div className="invalid-feedback mb-3">{formErrors.permanentAddress}</div>}

            <label>
                Temporary Address {props.userRole === UserRole.employee && <span className="required-field">*</span>}
            </label>
            <textarea
                rows={5}
                className={`form-control form-control-lg mb-3 ${formErrors.temporaryAddress ? 'is-invalid' : ''}`}
                name="temporaryAddress"
                value={formData.temporaryAddress}
                onChange={inputChangeHandler}
            />
            {formErrors.temporaryAddress && <div className="invalid-feedback mb-3">{formErrors.temporaryAddress}</div>}
            <label>
                Emergency Contact 1 (e.g. +91 XXXXXXXXXXX){' '}
                {props.userRole === UserRole.employee && <span className="required-field">*</span>}
            </label>
            <br />
            <select
                name="emergencyContactNumber1Of"
                className="form-control form-control-lg form-select mb-3 "
                style={{ width: '20%', display: 'inline-block', margin: '10px 10px 10px 0px' }}
                onChange={inputChangeHandler}
                value={formData.emergencyContactNumber1Of}
            >
                <option value="0">Contact Of</option>
                <option value="Father">Father</option>
                <option value="Mother">Mother</option>
                <option value="Brother">Brother</option>
                <option value="Sister">Sister</option>
                <option value="Spouse">Spouse</option>
                <option value="Other">Other</option>
            </select>
            <input
                style={{ width: '70%', display: 'inline-block' }}
                className={`form-control form-control-lg mb-3 ${formErrors.emergencyContactNumber1 ? 'is-invalid' : ''}`}
                type="text"
                name="emergencyContactNumber1"
                value={formData.emergencyContactNumber1}
                onChange={inputChangeHandler}
            />
            {formErrors.emergencyContactNumber1 && (
                <div className="invalid-feedback mb-3">{formErrors.emergencyContactNumber1}</div>
            )}
            <label>Emergency Contact 2 (e.g. +91 XXXXXXXXXXX)</label>
            <br />
            <select
                name="emergencyContactNumber2Of"
                className="form-control form-control-lg form-select mb-3 "
                style={{ width: '20%', display: 'inline-block', margin: '10px 10px 10px 0px' }}
                onChange={inputChangeHandler}
                value={formData.emergencyContactNumber2Of}
            >
                <option value="0">Contact Of</option>
                <option value="Father">Father</option>
                <option value="Mother">Mother</option>
                <option value="Brother">Brother</option>
                <option value="Sister">Sister</option>
                <option value="Spouse">Spouse</option>
                <option value="Other">Other</option>
            </select>
            <input
                style={{ width: '70%', display: 'inline-block' }}
                className={`form-control form-control-lg mb-3 ${formErrors.emergencyContactNumber2 ? 'is-invalid' : ''}`}
                type="text"
                name="emergencyContactNumber2"
                value={formData.emergencyContactNumber2}
                onChange={inputChangeHandler}
            />
            {formErrors.emergencyContactNumber2 && (
                <div className="invalid-feedback mb-3">{formErrors.emergencyContactNumber2}</div>
            )}
        </>
    );
};

export default ContactInfo;
