import React from 'react';

export const PrivacyStatmenContent = () => {
    return (
        <>
            <h3 className="bg-info text-white p-2">Privacy Statement for Employee Data Collection</h3>

            <div className="p-2">
                <p>
                    <strong>Effective Date:</strong> {`${'2023-01-01'}`}
                </p>

                <p>
                    <strong>Embed Square Solutions Pvt. Ltd.</strong> (&quot; we,&quot; &quot;us,&quot; or &quot;our&quot;)
                    is committed to protecting the privacy of your personal information. This privacy statement outlines how
                    we collect, use, and safeguard your personal data as part of our employee management processes.
                </p>

                <h4>1. Information Collected:</h4>
                <p>We collect the following categories of personal information from our employees for internal purposes:</p>
                <ul>
                    <li>Personal details: Name, address, date of birth, blood group, etc.</li>
                    <li>Contact information: Phone numbers, email addresses, emergency contact details, etc.</li>
                    <li>Employment details: Job title, department, work location, etc.</li>
                    <li>Other relevant information required for employment purposes.</li>
                </ul>

                <h4>2. Purpose of Data Collection:</h4>
                <p>
                    The collected information is used for internal HR and administrative purposes, including but not limited
                    to:
                </p>
                <ul>
                    <li>Payroll processing</li>
                    <li>Benefits administration</li>
                    <li>Leave management</li>
                    <li>Performance evaluation</li>
                    <li>Internal communication</li>
                </ul>

                <h4>3. Data Security:</h4>
                <p>
                    We take appropriate measures to ensure the security and confidentiality of your personal information. The
                    data is securely stored in our internal systems, and access is restricted to authorized personnel only.
                </p>

                <h4>4. Data Sharing:</h4>
                <p>
                    Your personal information will not be disclosed or shared with any external parties without your explicit
                    consent, except as required by law.
                </p>

                <h4>5. Data Retention:</h4>
                <p>
                    We retain your personal data for a period of one year after the termination of your employment. This
                    retention is for purposes such as claims processing, reimbursement, or other legal and administrative
                    requirements.
                </p>
                <h4>6. Access and Correction:</h4>
                <p>
                    You have the right to access and correct your personal information. If you have any concerns or requests
                    regarding your data, please contact our HR department.
                </p>

                <h4>7. Changes to the Privacy Statement:</h4>
                <p>
                    We may update this privacy statement to reflect changes in our practices. Any significant updates will be
                    communicated to you.
                </p>

                <h4>8. Consent:</h4>
                <p>
                    By providing your personal information, you consent to the collection, use, and retention of your data as
                    outlined in this privacy statement. By accepting this privacy statement, you give us permission to
                    process your data for our internal purposes.
                </p>

                <h4>Contact Information:</h4>
                <p>
                    If you have any questions or concerns regarding this privacy statement or the handling of your personal
                    information, please reach out to the HR.
                </p>

                <p>Thank you for entrusting us with your personal information.</p>
            </div>
        </>
    );
};
