import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Prop = {
    heading: string;
    value: string;
    info: string;
    icon: IconProp;
    iconColor?: string;
};

export const SimpleMetricCard = (props: Prop) => {
    const { heading, value, info, icon, iconColor } = props;
    let iconFontColor = 'text-primary';
    if (iconColor) {
        iconFontColor = iconColor;
    }
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col mt-0">
                        <h5 className="card-title text-primary">{heading}</h5>
                    </div>

                    <div className="col-auto">
                        <div className={`stat ${iconFontColor}`}>
                            <FontAwesomeIcon icon={icon as IconProp} />
                        </div>
                    </div>
                </div>
                <h1 className="mt-1 mb-3">{value}</h1>
                <div className="mb-0">
                    <span className="text-muted">{info}</span>
                </div>
            </div>
        </div>
    );
};
