export const ImageMimeType = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg', '.jpg'],
};

export const PdfMimeType = {
    'application/pdf': ['.pdf'],
};

export const EmployeeDocumentTypes = [
    'bank_passbook_image_front',
    'bank_passbook_image_back',
    'resume',
    'salary_slip_1',
    'salary_slip_2',
    'salary_slip_3',
];

export const MAX_FILE_UPLOAD_SIZE = 1024; // 1MB
