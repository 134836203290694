import { request } from '../api';
import { PageLimit } from '../constants/data';
import {
    EmployeeInsertLeave,
    EmployeeLeave,
    EmployeeLeaveAllocation,
    EmployeeLeaveBalance,
    EmployeeLeaveFilters,
    ReporteeLeave,
} from '../types/employee';

export const applyEmployeeleave = async (leave: EmployeeInsertLeave) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/leave/apply`,
        data: { ...leave },
    };
    return request(requestOptions);
};

export const getEmployeeLeaves = async (page: number, uuid: string, filters: EmployeeLeaveFilters = {}) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/leaves/${uuid}`,
        data: {
            pagination: {
                page,
                pageLimit: PageLimit,
            },
            filters,
        },
    };
    return request<{ data: EmployeeLeave[] }>(requestOptions);
};

export const getEmployeeLeavesBalance = async (uuid: string, filters: EmployeeLeaveFilters = {}) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/leave/balance/${uuid}`,
        data: {
            filters,
        },
    };
    return request<{ data: EmployeeLeaveBalance }>(requestOptions);
};

export const getEmployeeLeavesAllocation = async (uuid: string, filters: EmployeeLeaveFilters = {}) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/leave/allocation/${uuid}`,
        data: {
            filters,
        },
    };
    return request<{ data: EmployeeLeaveAllocation[] }>(requestOptions);
};

export const getReporteeLeaves = async (page: number, filters: EmployeeLeaveFilters = {}) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/reportee/leaves`,
        data: {
            pagination: {
                page,
                pageLimit: PageLimit,
            },
            filters,
        },
    };
    return request<{ data: ReporteeLeave[] }>(requestOptions);
};

export const getCustomerResourceLeaves = async (page: number, filters: EmployeeLeaveFilters = {}) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/customer/resource/leaves`,
        data: {
            pagination: {
                page,
                pageLimit: PageLimit,
            },
            filters,
        },
    };
    return request<{ data: ReporteeLeave[] }>(requestOptions);
};

export const updateLeaveStatus = async (
    leaveId: string,
    employeeId: string,
    comment: string,
    approvalStatus: 'REQUESTED' | 'APPROVED' | 'REJECTED'
) => {
    const requestOptions = {
        method: 'PATCH',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/leave/action/${leaveId}`,
        data: {
            employeeId,
            comment,
            approvalStatus,
        },
    };
    return request(requestOptions);
};

export const updateEmployeeLeaveAllocations = async (uuid: string, updatedAllocation: EmployeeLeaveAllocation[]) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/update/leave-allocation/${uuid}`,
        data: {
            leaveAllocation: updatedAllocation,
        },
    };
    return request<{ data: EmployeeLeaveAllocation[] }>(requestOptions);
};
