import { request } from '../api/';
import { MasterEmployeePreference, MasterField, OrgPolicy } from '../types/orgData';

export const getCountryAndCodes = async () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/common-data/country-and-codes`,
    };
    return request<{ [x: string]: string }>(requestOptions);
};

export const getTimezones = async () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/common-data/timezones`,
    };
    return request<{ [x: string]: string }>(requestOptions);
};

export const getOrgPolicies = async () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/org-policies`,
    };
    return request<{ data: OrgPolicy[] }>(requestOptions);
};
export const downloadPolicyDocuments = async (docId: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/org-policies/document/download/${docId}`,
    };
    return request(requestOptions);
};

export const getMasterPreferences = async () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/common-data/employee-preferences`,
    };
    return request<MasterEmployeePreference[]>(requestOptions);
};

export const getMasterData = async () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/common-data/master`,
    };
    return request<{ data: MasterField[] }>(requestOptions);
};

export const saveMasterFields = async (updatedMasterFields: MasterField[]) => {
    const requestOptions = {
        method: 'PATCH',
        url: `${process.env.REACT_APP_API_ENDPOINT}/common-data/master/save`,
        data: updatedMasterFields,
    };
    return request<{ data: MasterField[] }>(requestOptions);
};
