/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import * as Icon from 'react-feather';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { Badge, Button, Modal } from 'react-bootstrap';
import { EmployeeSurveyResultDetails, SurveyMetric } from '../../../types/survey';
import { getEmployeeSurveyMetric, getEmployeeSurveySubmission } from '../../../services/controlCenter';

const EmployeeResults = () => {
    const { id: surveyId } = useParams();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [selectedResult, setSelectedResult] = useState<EmployeeSurveyResultDetails | null>(null);
    const [employeeSurveyMetric, setEmployeeSurveyMetric] = useState<SurveyMetric | null>(null);
    const [employeeSurveySubmission, setEmployeeSurveySubmission] = useState<EmployeeSurveyResultDetails[]>([]);

    const showSurveyResult = (result: EmployeeSurveyResultDetails) => {
        setSelectedResult(result);
        setShowModal(true);
    };

    const closeModal = () => {
        setSelectedResult(null);
        setShowModal(false);
    };

    useEffect(() => {
        if (surveyId) {
            const getEmployeeSurveySubmissions = async () => {
                const [surveySubmissions, surveyMetric] = await Promise.all([
                    getEmployeeSurveySubmission(surveyId),
                    getEmployeeSurveyMetric(surveyId),
                ]);
                if (surveySubmissions.data && surveyMetric.data) {
                    setEmployeeSurveyMetric(surveyMetric.data.survey);
                    setEmployeeSurveySubmission(surveySubmissions.data.survey);
                } else {
                    setError(`Something went wrong while fetching the data.`);
                }
            };
            getEmployeeSurveySubmissions();
        }
    }, [surveyId]);

    return (
        <main className="content user-registration">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-control-center-info">
                                <p>
                                    <FontAwesomeIcon className="me-3" size="lg" icon={icon({ name: 'shield-alt' })} />
                                    Application Control Center
                                    <FontAwesomeIcon className="me-3 ms-3" icon={icon({ name: 'chevron-right' })} />
                                    Employee Survey Results
                                </p>
                            </div>

                            <div className="card-body col-12">
                                <div className="col-12 text-end mb-3">
                                    <button className="btn btn-primary" onClick={() => navigate(-1)}>
                                        <FontAwesomeIcon icon={icon({ name: 'angle-left' })} className="far fa-fw fa-bell" />{' '}
                                        Back
                                    </button>
                                </div>
                                {error && (
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="alert alert-danger alert-outline alert-dismissible" role="alert">
                                                <div className="alert-icon">
                                                    <Icon.Bell className="far fa-fw fa-bell" />
                                                </div>
                                                <div className="alert-message">
                                                    <strong>Error</strong> Something went wrong when trying to get survey
                                                    result data
                                                    <br /> If the problem persists, please contact support.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="row container ">
                                    <div className="col-12 col-md-6 bg-light p-3">
                                        <div className="row m-1">
                                            <div className="col-4">
                                                <strong>Survey Type:</strong>
                                            </div>
                                            <div className="col-7">Employee Survey</div>
                                        </div>
                                        <div className="row m-1">
                                            <div className="col-4">
                                                <strong>Survey Title:</strong>
                                            </div>
                                            <div className="col-7">{employeeSurveyMetric?.surveyTitle}</div>
                                        </div>
                                        <div className="row m-1">
                                            <div className="col-4">
                                                <strong>Start Date:</strong>
                                            </div>
                                            <div className="col-7">
                                                {moment(employeeSurveyMetric?.startDate).format('DD/MM/YYYY')}
                                            </div>
                                        </div>
                                        <div className="row m-1">
                                            <div className="col-4">
                                                <strong>End Date:</strong>
                                            </div>
                                            <div className="col-7">
                                                {moment(employeeSurveyMetric?.endDate).format('DD/MM/YYYY')}
                                            </div>
                                        </div>
                                        <div className="row m-1">
                                            <div className="col-4">
                                                <strong>Status:</strong>
                                            </div>
                                            <div className="col-7">
                                                {employeeSurveyMetric?.surveyStatus === 'future' && (
                                                    <Badge className="bg-warning rounded-pill notification-badge">
                                                        Survey not started yet
                                                    </Badge>
                                                )}

                                                {employeeSurveyMetric?.surveyStatus === 'historic' && (
                                                    <Badge className="bg-danger rounded-pill notification-badge">
                                                        Survey has ended
                                                    </Badge>
                                                )}

                                                {employeeSurveyMetric?.surveyStatus === 'live' && (
                                                    <Badge className="bg-info rounded-pill notification-badge">
                                                        Survey is live
                                                    </Badge>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 bg-light p-3">
                                        <div className="row m-1">
                                            <div className="col-6">
                                                <strong>Total Questions:</strong>
                                            </div>
                                            <div className="col-6">{employeeSurveyMetric?.totalQuestions}</div>
                                        </div>
                                        <div className="row m-1">
                                            <div className="col-6">
                                                <strong>Total Submissions:</strong>
                                            </div>
                                            <div className="col-6">{employeeSurveyMetric?.filledSurveyCount}</div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 p-3">
                                        {employeeSurveySubmission && employeeSurveySubmission.length > 0 ? (
                                            <>
                                                <div className="row gx-0 col-12 bg-app-header text-white fw-bold p-2">
                                                    <div className="col-4 p-2">Employee Name</div>
                                                    <div className="col-4 p-2">Submission Date</div>
                                                    <div className="col-4 p-2">Result</div>
                                                </div>
                                                <div className="row gx-0 bg-app-row p-2">
                                                    {employeeSurveySubmission?.map((result) => (
                                                        <div key={result.uuid} className="row gx-0 col-12 py-1">
                                                            <div className="col-4 p-2">{result.employeeName}</div>
                                                            <div className="col-4 p-2">
                                                                {moment(result.createdAt).format('DD/MM/YYYY')}
                                                            </div>
                                                            <div className="col-4 p-2">
                                                                <span
                                                                    title="View Details"
                                                                    className="ms-2 "
                                                                    onClick={() => showSurveyResult(result)}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={icon({ name: 'square-poll-vertical' })}
                                                                        size="lg"
                                                                        className="cursor-pointer"
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        ) : (
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div
                                                        className="alert alert-danger alert-outline alert-dismissible mt-5"
                                                        role="alert"
                                                    >
                                                        <div className="alert-icon">
                                                            <Icon.Bell className="far fa-fw fa-bell" />
                                                        </div>
                                                        <div className="alert-message">
                                                            <p>
                                                                <strong>No employee has submitted the survey yet</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal size="xl" show={showModal} centered={true} onHide={() => closeModal()}>
                <>
                    {' '}
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FontAwesomeIcon icon={icon({ name: 'info-circle' })} size="lg" />
                            <span className="ms-2">Employee Survey Result</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-12">
                            {selectedResult ? (
                                <>
                                    <div className="row col-12 p-3 py-1 m-2">
                                        <div className="col-6 fw-bold">Question</div>
                                        <div className="col-6 fw-bold">Responses</div>
                                    </div>
                                    {selectedResult.surveyResult.map((res) => (
                                        <div className="row col-12 bg-light p-3 m-2" key={res.uuid}>
                                            <div className="col-6 fw-bold">{res.question}</div>
                                            <div className="col-6">
                                                {res.inputType === 'rating' ? res.rating : res.comment}
                                            </div>
                                        </div>
                                    ))}
                                    <div className="row col-12 m-3">
                                        <div className="col-12 fw-bold">General comment</div>
                                        <div className="col-12">{selectedResult.generalComment ?? '-'}</div>
                                    </div>
                                </>
                            ) : (
                                <>Result not found</>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModal()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </>
            </Modal>
        </main>
    );
};

export default EmployeeResults;
