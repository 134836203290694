// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-employees-documents-list-table th {
  position: sticky !important;
  background-color: white;
  border-top: #000 1px solid;
  top: 0;
}
.view-employees-documents-list-table td.success {
  color: green !important;
}
.view-employees-documents-list-table td.failed {
  color: red !important;
}
.view-employees-documents-list-table .status-requested {
  color: rgba(var(--bs-primary-rgb));
}
.view-employees-documents-list-table .status-approved {
  color: rgba(var(--bs-success-rgb));
}
.view-employees-documents-list-table .status-rejected {
  color: rgba(var(--bs-danger-rgb));
}
.view-employees-documents-list .search-container {
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/EmployeeDocuments/ReporteeDocumentList/ReporteeDocumentList.scss"],"names":[],"mappings":"AAEQ;EACI,2BAAA;EACA,uBAAA;EACA,0BAAA;EACA,MAAA;AADZ;AAIQ;EACI,uBAAA;AAFZ;AAKQ;EACI,qBAAA;AAHZ;AAOY;EACI,kCAAA;AALhB;AAOY;EACI,kCAAA;AALhB;AAOY;EACI,iCAAA;AALhB;AAUI;EACI,aAAA;AARR","sourcesContent":[".view-employees-documents-list {\n    &-table {\n        th {\n            position: sticky !important;\n            background-color: white;\n            border-top: #000 1px solid;\n            top: 0;\n        }\n    \n        td.success {\n            color: green !important;\n        }\n    \n        td.failed {\n            color: red !important;\n        }\n\n        .status {\n            &-requested {\n                color: rgba(var(--bs-primary-rgb));\n            }\n            &-approved {\n                color: rgba(var(--bs-success-rgb));\n            }\n            &-rejected {\n                color: rgba(var(--bs-danger-rgb));\n            }\n        }\n    }\n\n    .search-container {\n        padding:10px;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
