/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { OnlineStatus } from '../../types/employee';
import { useWebSocketContext } from '../../hooks/useWebsocket';
import { UserProfile } from '../../types/user';
import { IRootState, useSelector } from '../../store';

export const UserOnlineStatus = () => {
    const { isSocketReady, sendWebSocketMessage } = useWebSocketContext();
    const user = useSelector((state: IRootState) => state.user) as UserProfile;
    const [onlineStatus, setOnlineStatus] = useState(OnlineStatus.ONLINE);

    const updateOnlineStatus = useCallback(
        (status: OnlineStatus) => {
            const message = {
                messageType: 'ONLINE_STATUS_UPDATE',
                payload: {
                    status,
                    userId: user.uuid,
                    userRole: user.role,
                },
            };
            sendWebSocketMessage(message);
        },
        [sendWebSocketMessage, user.uuid, user.role]
    );

    useEffect(() => {
        if (isSocketReady) {
            updateOnlineStatus(onlineStatus);
        }
    }, [isSocketReady, updateOnlineStatus, onlineStatus]);

    useEffect(() => {
        // Anything in here is fired on component mount.

        setOnlineStatus(OnlineStatus.ONLINE);
        return () => {
            setOnlineStatus(OnlineStatus.OFFLINE);
            updateOnlineStatus(OnlineStatus.OFFLINE);
        };
    }, [updateOnlineStatus]);

    return (
        <>
            <ButtonGroup>
                <DropdownButton
                    as={ButtonGroup}
                    title={
                        <>
                            <FontAwesomeIcon
                                className={cn({
                                    'text-success': onlineStatus === OnlineStatus.ONLINE,
                                    'text-warning': onlineStatus === OnlineStatus.AWAY,
                                    'text-dark-light': onlineStatus === OnlineStatus.OFFLINE,
                                })}
                                icon={icon({ name: 'circle' })}
                                size="sm"
                            />{' '}
                            <span className="px-1">{onlineStatus}</span>
                        </>
                    }
                    id="bg-nested-dropdown"
                    variant="light"
                >
                    <Dropdown.Item eventKey="1">
                        <div role="button" onClick={() => setOnlineStatus(OnlineStatus.ONLINE)}>
                            <FontAwesomeIcon className="me-2 text-success" icon={icon({ name: 'circle' })} size="sm" />{' '}
                            Online
                        </div>
                    </Dropdown.Item>
                    <hr className="dropdown-divider" role="separator" />
                    <Dropdown.Item eventKey="1">
                        <div role="button" onClick={() => setOnlineStatus(OnlineStatus.AWAY)}>
                            <FontAwesomeIcon className="me-2 text-warning" icon={icon({ name: 'circle' })} size="sm" /> Away
                        </div>
                    </Dropdown.Item>
                    <hr className="dropdown-divider" role="separator" />
                    <Dropdown.Item eventKey="2">
                        <div role="button" onClick={() => setOnlineStatus(OnlineStatus.OFFLINE)}>
                            <FontAwesomeIcon className="me-2 text-dark-light" icon={icon({ name: 'circle' })} size="sm" />{' '}
                            Offline
                        </div>
                    </Dropdown.Item>
                </DropdownButton>
            </ButtonGroup>
        </>
    );
};
