import { request } from '../api/';
import { OrgHoliday, OrgHolidayEntry } from '../types/orgData';

export const getOrgHolidays = async (year: number, countryCode: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/org-holidays/holiday-list/${year}/${countryCode}`,
    };
    return request<{ data: { orgHolidays: OrgHoliday[] } }>(requestOptions);
};

export const saveOrgHolidays = async (orgHolidays: OrgHolidayEntry[]) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/org-holidays/add-holidays`,
        data: { orgHolidays },
    };
    return request<{ [x: string]: string }>(requestOptions);
};
