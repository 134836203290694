// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timesheet-weekly-data .data-row__read-only-bg {
  background-color: #9acbf6;
  border-bottom: 1px solid #73b7f2 !important;
  color: #2b2b2b;
}
.timesheet-weekly-data .data-row .data-col {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
.timesheet-weekly-data .data-row .data-col__is-today {
  background-color: #d8d8d8;
}
.timesheet-weekly-data .data-row .data-col__no-value {
  background-color: #fff094;
}
.timesheet-weekly-data .data-row .data-col__value {
  background-color: #86d6a1;
}
.timesheet-weekly-data .data-row .data-col__normal-text {
  font-size: 14px;
  font-weight: normal;
}
.timesheet-weekly-data .data-row__is-editing {
  background-color: #d8e6f0;
}
.timesheet-weekly-data .data-row .row-total {
  font-weight: 16px;
  font-weight: bold;
}
.timesheet-weekly-data .saved-entries-row {
  border-bottom: 1px solid #b3b3b3;
}`, "",{"version":3,"sources":["webpack://./src/components/EmployeeTimesheet/TimeSheetData.scss"],"names":[],"mappings":"AAEQ;EACI,yBAAA;EACA,2CAAA;EACA,cAAA;AADZ;AAIQ;EACI,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;AAFZ;AAGY;EACI,yBAAA;AADhB;AAIY;EACI,yBAAA;AAFhB;AAKY;EACI,yBAAA;AAHhB;AAQY;EAEI,eAAA;EACA,mBAAA;AAPhB;AAWQ;EACI,yBAAA;AATZ;AAYQ;EACI,iBAAA;EACA,iBAAA;AAVZ;AAcI;EACI,gCAAA;AAZR","sourcesContent":[".timesheet-weekly-data{\n    .data-row {\n        &__read-only-bg {\n            background-color: #9acbf6;\n            border-bottom: 1px solid #73b7f2 !important;\n            color: #2b2b2b;\n        }\n\n        .data-col {\n            font-size: 14px;\n            font-weight: 500;\n            text-align: left;\n            padding-top: 5px;\n            padding-bottom: 5px;\n            &__is-today {\n                background-color: #d8d8d8;\n            }\n\n            &__no-value {\n                background-color: #fff094\n            }\n\n            &__value {\n                background-color: #86d6a1\n            }\n\n            \n\n            &__normal-text {\n\n                font-size: 14px;\n                font-weight:normal\n            }\n        }\n\n        &__is-editing {\n            background-color: #d8e6f0;\n        }\n\n        .row-total {\n            font-weight: 16px;\n            font-weight: bold;\n        }\n    }\n    \n    .saved-entries-row {\n        border-bottom: 1px solid #b3b3b3;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
