/* eslint-disable no-console */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

const HelpCenter = () => {
    return (
        <main className="content user-registration">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon icon={icon({ name: 'circle-info' })} /> HELP CENTER
                        </h3>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body col-12">
                                <h3 className="bg-info text-white p-2">Generic help information</h3>
                                <div className="p-2">
                                    <p>
                                        <h4>Beta Stage Notice:</h4>
                                        This application is currently in the beta stage of development. If you encounter any
                                        issues or have suggestions for improvement, please reach out to our HR department for
                                        assistance.
                                    </p>

                                    <p>
                                        <h4>Data Management:</h4>
                                        Use this application to manage the data you share with the company, including
                                        personal email, contacts, and other relevant information. This data will be processed
                                        for various checks and utilized in accordance with employment contracts.
                                    </p>

                                    <p>
                                        <h4>Data Maintenance:</h4>
                                        It&apos;s important to keep your data up to date. Regularly verify and update your
                                        information to ensure accuracy and efficiency in our systems.
                                    </p>

                                    <p>
                                        <h4>Application Features:</h4>
                                        - Apply for leaves and submit timesheets directly through the application.
                                        <br />- Access additional features such as viewing your leave history, checking your
                                        leave allocation, and staying informed about upcoming holidays.
                                    </p>

                                    <p>
                                        <h4>Policy Documents:</h4>
                                        For specific details regarding leaves, timesheets, and other policies, please refer
                                        to the policy documents available within the application.
                                    </p>

                                    <p>
                                        <h4>Contact HR:</h4>
                                        If you need further assistance, have questions, or would like to learn more about any
                                        aspect of the application, feel free to contact our HR department.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default HelpCenter;
