/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { capitalize } from 'lodash';
import * as Icon from 'react-feather';
import InfiniteScroll from 'react-infinite-scroller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import moment from 'moment';
import { getAllEmployeeDocuments } from '../../../services/employeeDocuments';
import { UserProfile } from '../../../types/user';
import { IRootState, useSelector } from '../../../store';
import { EmployeeDocumentsFilters, EmployeeDocuments } from '../../../types/employee';
import { StatusIndicator } from '../../StatusIndicator/StatusIndicator';
import { DocumentDownload } from '../DocumentDownload';
import './ReporteeDocumentList.scss';
import { DocumentActionModal } from './DocumentActionModal';
import { DocumentDetailModal } from './DocumentDetailModal';

// TODO: LEAVE META DATA, like total applied leaves, rejected total, approved total, leave balance.

const ReporteeDocumentList = () => {
    const loggedInUser = useSelector((state: IRootState) => state.user) as UserProfile;
    const [employeeDocuments, setEmployeeDocuments] = useState<EmployeeDocuments[]>([]);
    const [filters, setFilters] = useState<EmployeeDocumentsFilters>({});
    const [error, setError] = useState<string | null>(null);
    const [showDetailModalId, setShowDetailModalId] = useState<string | null>(null);
    const [showModalId, setShowModalId] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const fetchEmployeeDocuments = useCallback(async (page: number, searchFilters: EmployeeDocumentsFilters) => {
        try {
            setLoading(true);
            const { data } = await getAllEmployeeDocuments(page, loggedInUser.uuid, searchFilters);
            if (data.length > 0) {
                setHasMore(true);
                setEmployeeDocuments((prevData) => [...prevData, ...data]);
            } else {
                setHasMore(false);
            }
            setLoading(false);
        } catch (err: unknown) {
            setError('Something went wrong');
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const filterType = event.target.name; // Get the id of the select element as filterType
        const newFilters: EmployeeDocumentsFilters = { ...filters };
        if (event.target.value !== '0') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            newFilters[filterType as keyof EmployeeDocumentsFilters] = event.target.value as any;
            setFilters(newFilters);
        } else {
            delete newFilters[filterType as keyof EmployeeDocumentsFilters];
            setFilters(newFilters);
        }
    };

    const openModal = (id: string) => {
        setShowModalId(id);
    };

    const handleModalClose = () => {
        setShowModalId(null);
    };

    const openDetailModal = (id: string) => {
        setShowDetailModalId(id);
    };

    const handleDetailModalClose = () => {
        setShowDetailModalId(null);
    };

    const handleSearchClick = () => {
        setEmployeeDocuments([]);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (filters.hrApprovalStatus === ('0' as any)) {
            delete filters.hrApprovalStatus;
        }
        fetchEmployeeDocuments(1, filters);
    };

    const handleClearFilter = () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setFilters({ hrApprovalStatus: '0' as any });
        setEmployeeDocuments([]);
        fetchEmployeeDocuments(1, {});
    };

    useEffect(() => {
        fetchEmployeeDocuments(1, filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <main className="content view-employees-documents-list">
            <div className="container-fluid p-0">
                <div className="row mb-2 mb-xl-3">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon icon={icon({ name: 'file' })} /> Employee Documents
                        </h3>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-info">
                                <p>This page list all uploaded documents for employee. </p>
                            </div>
                            <div className="card-body">
                                <div
                                    id="datatables-reponsive_wrapper"
                                    className="dataTables_wrapper dt-bootstrap5 no-footer"
                                >
                                    {error && (
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div
                                                    className="alert alert-danger alert-outline alert-dismissible"
                                                    role="alert"
                                                >
                                                    <div className="alert-icon">
                                                        <Icon.Bell className="far fa-fw fa-bell" />
                                                    </div>
                                                    <div className="alert-message">
                                                        <strong>Error</strong> Something went wrong when trying to get your
                                                        leaves data.
                                                        <br /> If the problem persists, please contact support.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {
                                        <>
                                            <div className="search-container">
                                                <div className="mb-3 row">
                                                    <strong>Filter By:</strong>
                                                </div>

                                                {/* TODO: ADD EMPLOYEE SEARCH FIELD */}
                                                <div className="mb-3 row">
                                                    <div className="col-3">
                                                        <select
                                                            name="hrApprovalStatus"
                                                            className="form-control form-control-lg form-select"
                                                            onChange={handleFilterChange}
                                                            value={filters.hrApprovalStatus}
                                                        >
                                                            <option value="0">Select approval status</option>
                                                            <option value="APPROVED">APPROVED</option>
                                                            <option value="REJECTED">REJECTED</option>
                                                            <option value="REQUESTED">REQUESTED</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-1">
                                                        {' '}
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={handleSearchClick}
                                                        >
                                                            Search
                                                        </button>
                                                    </div>
                                                    <div className="col-md-1 text-start pt-1 pl-1">
                                                        <span
                                                            onClick={handleClearFilter}
                                                            className="text-primary cursor-pointer"
                                                        >
                                                            Clear All
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {!loading && !error && employeeDocuments?.length === 0 && (
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div
                                                    className="alert alert-danger alert-outline alert-dismissible mt-5"
                                                    role="alert"
                                                >
                                                    <div className="alert-icon">
                                                        <Icon.Bell className="far fa-fw fa-bell" />
                                                    </div>
                                                    <div className="alert-message">
                                                        <p>
                                                            <strong>
                                                                No documents available yet. <br /> It is possible that there
                                                                is no data added yet.
                                                                <br />
                                                                OR try refining your search filters.
                                                            </strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {employeeDocuments?.length !== 0 && (
                                        <>
                                            <div style={{ height: '500px', overflow: 'auto' }}>
                                                <InfiniteScroll
                                                    pageStart={1}
                                                    loadMore={(page: number) => fetchEmployeeDocuments(page, filters)}
                                                    hasMore={hasMore && !loading}
                                                    loader={
                                                        <div key={0} className="row">
                                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                                <div
                                                                    className="spinner-border text-primary me-2"
                                                                    role="status"
                                                                >
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    useWindow={false}
                                                >
                                                    <table
                                                        id="datatables-reponsive"
                                                        className="table table-striped dataTable no-footer dtr-inline view-employees-leaves-list-table"
                                                        style={{ width: '100%', tableLayout: 'fixed' }}
                                                        aria-describedby="datatables-reponsive_info"
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th>Employee Name</th>
                                                                <th>Document Type</th>
                                                                <th>File</th>
                                                                <th>Upload Date</th>
                                                                <th>Approval Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {employeeDocuments &&
                                                                employeeDocuments.map((doc, index) => (
                                                                    <tr
                                                                        className={cn({
                                                                            odd: index % 2 === 0,
                                                                            even: index % 2 !== 0,
                                                                        })}
                                                                        key={`${doc.uuid}-${index}`}
                                                                    >
                                                                        <td>{capitalize(doc.employeeName)}</td>
                                                                        <td>
                                                                            {capitalize(
                                                                                doc.documentType.replaceAll('_', ' ')
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <DocumentDownload
                                                                                loggedInUserId={loggedInUser.uuid}
                                                                                fileDetails={doc}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            {moment(doc.createdAt).format('DD/MM/YYYY') ??
                                                                                '-'}
                                                                        </td>
                                                                        <td>
                                                                            <StatusIndicator status={doc.hrApprovalStatus} />
                                                                        </td>
                                                                        <td>
                                                                            <FontAwesomeIcon
                                                                                title="View Details"
                                                                                icon={icon({ name: 'info-circle' })}
                                                                                size="lg"
                                                                                onClick={() => openDetailModal(doc.uuid)}
                                                                                className="text-primary cursor-pointer me-2"
                                                                            />
                                                                            {/* TODO: SHOW COMMENT MESSAGE */}
                                                                            <button
                                                                                className="btn btn-primary btn-sm"
                                                                                title="Document Status Action"
                                                                                onClick={() => openModal(doc.uuid)}
                                                                            >
                                                                                Approve/Reject
                                                                            </button>{' '}
                                                                            <DocumentActionModal
                                                                                empDocument={doc}
                                                                                showModal={showModalId === doc.uuid}
                                                                                handleClose={handleModalClose}
                                                                            />
                                                                            <DocumentDetailModal
                                                                                empDocument={doc}
                                                                                showModal={showDetailModalId === doc.uuid}
                                                                                handleClose={handleDetailModalClose}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </InfiniteScroll>
                                            </div>
                                        </>
                                    )}
                                    {loading && (
                                        <div key={0} className="row">
                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                <div className="spinner-border text-primary me-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ReporteeDocumentList;
