/* eslint-disable no-console */
import moment from 'moment';
import { calculateTotalHours } from '../../helper/dateAndTime';
import { TimeSheetDataEntryType, TimeSheetDisplayWeek, WeeklyTaskEntries } from '../../types/timesheetType';

export const calculateColumnTotal = (dataEntries: TimeSheetDataEntryType[]) => {
    const requiredKeys = Object.keys(dataEntries[0]) // Get keys from the first row to represent columns
        .filter((key) => key !== 'task');
    const columnsArray = // Exclude 'task' and 'rowTotal' columns
        requiredKeys.map((column) => dataEntries.map((row) => row[column as keyof TimeSheetDataEntryType].value)); // Map each column key to an array of its values
    let index = 0;
    const dataColTotal = {} as TimeSheetDataEntryType;

    for (const key of requiredKeys) {
        dataColTotal[key as keyof TimeSheetDataEntryType] = {
            value: calculateTotalHours(columnsArray[index++]),
            isLocked: true,
        };
    }
    return dataColTotal;
};

export const reverseTransformIndexEntrycolToDate = (
    taskDetailObject: WeeklyTaskEntries[],
    displayWeeks: TimeSheetDisplayWeek[]
) => {
    const taskDetails = [] as TimeSheetDataEntryType[];
    const displayKeys = displayWeeks.map((week) => `${week.day}-${week.month}-${week.date}`);
    for (const savedDataEntry of taskDetailObject) {
        const updatedKeyEntry = {} as TimeSheetDataEntryType;
        let count = 1;
        displayKeys.map((key) => {
            const newKey = `entrycol${count}`;
            count++;
            updatedKeyEntry[newKey as keyof TimeSheetDataEntryType] = savedDataEntry[key];
        });

        updatedKeyEntry.task = savedDataEntry.task;
        updatedKeyEntry.rowTotal = savedDataEntry.rowTotal;
        taskDetails.push(updatedKeyEntry as TimeSheetDataEntryType);
    }

    return taskDetails;
};

export const getWeekDaysByDate = (date?: moment.Moment) => {
    const dateformat = 'ddd, MMM DD';
    let selectedDate = date ? moment(date) : moment();
    let weeklength = 7;
    const result = [] as TimeSheetDisplayWeek[];
    selectedDate = selectedDate.startOf('isoWeek');
    while (weeklength--) {
        const timeSheetDisplayWeek = {
            day: selectedDate.format('ddd'),
            month: selectedDate.format('MMM'),
            date: selectedDate.format('DD'),
            isPastDate: selectedDate < moment().subtract(2, 'days') ? true : false,
            isToday: selectedDate.format(dateformat) === moment().format(dateformat) ? true : false,
        };

        result.push(timeSheetDisplayWeek);

        selectedDate.add(1, 'day');
    }
    return result;
};
