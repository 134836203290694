/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useNavigate } from 'react-router-dom';
import { IRootState, useSelector } from '../../store';
import { registerCustomer, validateIfCustomerEmailAvailable } from '../../services/customer';
import { getCountryAndCodes, getTimezones } from '../../services/data';
import { useAppContext } from '../../hooks/useAppContext';
import { customerFormValidate } from './CustomerRegistrationValidation';

const CustomerRegistration = () => {
    const globalError = useSelector((state: IRootState) => state.error);
    const pristineForm = {
        name: '',
        email: '',
        primaryContact: '',
        secondaryContact: '',
        country: '',
        timezone: '',
    };
    const { triggerSuccess } = useAppContext();
    const navigate = useNavigate();
    const [countryCodes, setCountryCodes] = useState<{ [x: string]: string }>();
    const [timezones, setTimezones] = useState<{ [x: string]: string }>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultError = {} as any;
    const [errors, setErrors] = useState(defaultError);
    const [formData, setFormData] = useState(pristineForm);
    const [loading, setLoading] = useState(false);
    const [emailAvailableLoading, setEmailAvailableLoading] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
        setLoading(false);
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateIfEmailAvailable = async () => {
        setEmailAvailableLoading(true);
        try {
            if (formData.email) {
                const email = formData.email;
                const res = await validateIfCustomerEmailAvailable(email);
                if (res !== false) {
                    setErrors({ ...defaultError, email: 'Customer with email already exists' });
                } else {
                    delete errors['email'];
                    setErrors({ ...errors });
                }
                setEmailAvailableLoading(false);
            }
            setEmailAvailableLoading(false);
        } catch (_e) {}
    };

    const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setErrors(defaultError);
        const exists = await validateIfCustomerEmailAvailable(formData.email);
        if (exists) {
            setErrors({ ...defaultError, email: 'Customer with email already exists' });
            setLoading(false);
            return;
        }
        const validateErrors = customerFormValidate(formData);
        if (Object.keys(validateErrors).length === 0 && Object.keys(errors).length === 0) {
            setLoading(true);
            const data = {
                ...formData,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any;
            try {
                // TODO: Need to clean up for handling this better
                await registerCustomer(data);
                triggerSuccess(`Customer added successfully`);
                setFormData(pristineForm);
                navigate('/customer/list');
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (_e: any) {
                setLoading(false);
            }
        } else {
            setLoading(false);
            setErrors(validateErrors);
        }
    };

    useEffect(() => {
        const getCountryCodes = async () => {
            const [coutryCodeList, timezoneList] = await Promise.all([getCountryAndCodes(), getTimezones()]);
            if (coutryCodeList) {
                setCountryCodes(coutryCodeList);
            }
            if (timezoneList) {
                setTimezones(timezoneList);
            }
        };
        getCountryCodes();
    }, []);

    return (
        <main className="content customer-registration">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon icon={icon({ name: 'pencil' })} /> Register Customer
                        </h3>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-info">
                                <p>
                                    Please enter all the information for an customer which is mandatory marked with an
                                    asterisk (<span className="required-field">*</span>).
                                </p>
                                <p>
                                    Once you have filled out all the fields, press the &quot;Save Details&quot; button to
                                    save the information.
                                </p>
                            </div>
                            <div className="card-body col-8">
                                {Object.keys(errors).length > 0 && (
                                    <p className="error-message">
                                        <FontAwesomeIcon className="me-2" icon={icon({ name: 'warning' })} /> Looks like you
                                        have some errors on the form, check all the tabs before saving the details.
                                    </p>
                                )}
                                <label>
                                    Full Name <span className="required-field">*</span>
                                </label>
                                <input
                                    className={`form-control form-control-lg mb-3 ${errors.name ? 'is-invalid' : ''}`}
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                                {errors.name && <div className="invalid-feedback mb-3">{errors.name}</div>}
                                <label>
                                    Email <span className="required-field">*</span>
                                </label>
                                <input
                                    className={`form-control form-control-lg mb-3 ${errors.email ? 'is-invalid' : ''}`}
                                    type="text"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    onBlur={validateIfEmailAvailable}
                                />
                                {errors.email && <div className="invalid-feedback mb-3">{errors.email}</div>}
                                {emailAvailableLoading && (
                                    <div className="email-availability-search mb-1">
                                        Searching for availability{' '}
                                        <div className="spinner-border spinner-border-sm me-2" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                                <label>
                                    Primary Contact Number <span className="required-field">*</span>
                                </label>
                                <input
                                    className={`form-control form-control-lg mb-3 ${
                                        errors.primaryContact ? 'is-invalid' : ''
                                    }`}
                                    type="text"
                                    name="primaryContact"
                                    value={formData.primaryContact}
                                    onChange={handleChange}
                                    placeholder="+1 0000000000 or +91 0000000000"
                                />
                                {errors.primaryContact && (
                                    <div className="invalid-feedback mb-3">{errors.primaryContact}</div>
                                )}

                                <label>Secondary Contact Number</label>
                                <input
                                    className={`form-control form-control-lg mb-3 ${
                                        errors.secondaryContact ? 'is-invalid' : ''
                                    }`}
                                    type="text"
                                    name="secondaryContact"
                                    value={formData.secondaryContact}
                                    onChange={handleChange}
                                    placeholder="+1 0000000000 or +91 0000000000"
                                />
                                {errors.secondaryContact && (
                                    <div className="invalid-feedback mb-3">{errors.secondaryContact}</div>
                                )}

                                <label>
                                    Country <span className="required-field">*</span>
                                </label>
                                <select
                                    name="country"
                                    onChange={handleChange}
                                    className={`form-control form-control-lg form-select mb-3 ${
                                        errors.country ? 'is-invalid' : ''
                                    }`}
                                    value={formData.country}
                                >
                                    <option value="">Select Country</option>
                                    {countryCodes &&
                                        Object.keys(countryCodes).map((code, i) => (
                                            <option key={i} value={`${countryCodes[`${code}`]}`}>
                                                {countryCodes[`${code}`]}
                                            </option>
                                        ))}
                                </select>

                                {errors.country && <div className="invalid-feedback mb-3">{errors.country}</div>}

                                <label>Timezone</label>
                                <select
                                    name="timezone"
                                    onChange={handleChange}
                                    className={`form-control form-control-lg form-select mb-3 ${
                                        errors.timezone ? 'is-invalid' : ''
                                    }`}
                                    value={formData.timezone}
                                >
                                    <option value="">Select Timezone</option>
                                    {timezones &&
                                        Object.keys(timezones).map((tz, i) => (
                                            <option key={i} value={`${tz}`}>
                                                {timezones[`${tz}`]}
                                            </option>
                                        ))}
                                </select>

                                {errors.timezone && <div className="invalid-feedback mb-3">{errors.timezone}</div>}

                                <div className="text-end mt-2">
                                    <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                                        Save Details{' '}
                                        {loading && !globalError.isError && (
                                            <div className="spinner-border spinner-border-sm   me-2" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default CustomerRegistration;
