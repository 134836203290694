// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FileDropzone {
  padding: 10px;
  font-family: "Roboto";
  border: 2px dashed #e5e5e5;
  border-radius: 10px;
  background-color: #ffffff;
  color: rgba(34, 46, 60, 0.4);
  display: flex;
  font-size: 1.4em;
  height: 160px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: color 0.2s ease-in-out border-color 0.2s ease-in-out;
}
.FileDropzone .button {
  display: inline-block;
  margin: 0 6px;
  padding: 8px 10px;
  background-color: rgba(34, 46, 60, 0.4);
  border-radius: 4px;
  color: #ffffff;
  font-weight: lighter;
}
.FileDropzone .button:hover {
  background-color: #222e3c;
}
.FileDropzone:hover {
  color: #222e3c;
  border-color: rgba(34, 46, 60, 0.4);
}
.FileDropzone--active {
  color: #00a664;
}
.FileDropzone--reject {
  color: #d71d4f;
}
.FileDropzone--disabled, .FileDropzone--disabled:hover {
  border: 2px dashed #dfe7ea;
  color: #dfe7ea;
  cursor: no-drop;
}
.FileDropzone--disabled .button,
.FileDropzone--disabled .button:hover, .FileDropzone--disabled:hover .button,
.FileDropzone--disabled:hover .button:hover {
  background-color: #dfe7ea;
}`, "",{"version":3,"sources":["webpack://./src/components/FileDropzone/FileDropzone.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,qBAAA;EACA,0BAAA;EACA,mBAAA;EACA,yBAAA;EACA,4BAAA;EACA,aAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,gEAAA;AACJ;AACI;EACI,qBAAA;EACA,aAAA;EACA,iBAAA;EACA,uCAAA;EACA,kBAAA;EACA,cAAA;EACA,oBAAA;AACR;AAAQ;EACI,yBAAA;AAEZ;AAEI;EACI,cAAA;EACA,mCAAA;AAAR;AAGI;EACI,cAAA;AADR;AAII;EACI,cAAA;AAFR;AAKI;EAEI,0BAAA;EACA,cAAA;EACA,eAAA;AAJR;AAKQ;;;EAEI,yBAAA;AAFZ","sourcesContent":[".FileDropzone {\n    padding: 10px;\n    font-family: 'Roboto';\n    border: 2px dashed #e5e5e5;\n    border-radius: 10px;\n    background-color: #ffffff;\n    color: rgba(#222e3c, 0.4);\n    display: flex;\n    font-size: 1.4em;\n    height: 160px;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n    transition: color 0.2s ease-in-out border-color 0.2s ease-in-out;\n\n    .button {\n        display: inline-block;\n        margin: 0 6px;\n        padding: 8px 10px;\n        background-color: rgba(#222e3c, 0.4);\n        border-radius: 4px;\n        color: #ffffff;\n        font-weight: lighter;\n        &:hover {\n            background-color: #222e3c;\n        }\n    }\n\n    &:hover {\n        color: #222e3c;\n        border-color: rgba(#222e3c, 0.4);\n    }\n\n    &--active {\n        color: #00a664;\n    }\n\n    &--reject {\n        color: #d71d4f;\n    }\n\n    &--disabled,\n    &--disabled:hover {\n        border: 2px dashed #dfe7ea;\n        color: #dfe7ea;\n        cursor: no-drop;\n        .button,\n        .button:hover {\n            background-color: #dfe7ea;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
