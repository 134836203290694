/* eslint-disable @typescript-eslint/naming-convention */
import { UserRole } from './user';

export enum ActionStatus {
    REQUESTED = 'REQUESTED',
    REJECTED = 'REJECTED',
    APPROVED = 'APPROVED',
    PENDING = 'PENDING',
    SAVED = 'SAVED',
}

export interface Employee {
    uuid: string;
    name: string;
    gender: 'Male' | 'Female' | 'Other';
    dateOfBirth: string | null;
    contactNumber: string;
    personalEmail: string;
    bloodGroup: string | null;
    maritalStatus: string | null;
    permanentAddress: string | null;
    temporaryAddress: string | null;
    emergencyContactNumber1Of: string | null;
    emergencyContactNumber1: string | null;
    emergencyContactNumber2Of: string | null;
    emergencyContactNumber2: string | null;
    employeeId: string;
    officialEmailId: string;
    dateOfJoining: string;
    reportingManagerId: string | null;
    reportingDirectorId: string | null;
    currentCtc: string | null;
    designation: string | null;
    panCardNumber: string | null;
    bankAccountNumber: string | null;
    ifscCode: string | null;
    aadhaarCardNumber: string | null;
    pfAccountNumber: string | null;
    bankPassbookImage: string | null;
    recentPhotograph: string | null;
    highestEducationalQualification: string | null;
    totalWorkExperience: string | null;
    department: string | null;
    linkedIn: string | null;
    createdAt: string;
    createdBy: string;
    updatedAt: string | null;
    updatedBy: string;
    employmentStatus: 'ACTIVE' | 'INACTIVE';
    managerDetail?: string;
    directorDetail?: string;
    role: UserRole;
    onlineStatus: OnlineStatus;
}

export interface EmployeeFilters {
    designation?: string;
    department?: string;
    reportingManagerId?: string;
    reportingManagerRole?: string;
}

export interface LeaveApprover {
    approvalStatus: ActionStatus;
    approvalDate?: string;
    approverId: string;
    approverComments?: string;
    approverDetail: string;
    approverRole: UserRole;
}
export interface EmployeeLeave {
    uuid: string;
    employeeId: string;
    requestNumber: number;
    leaveType: string;
    reason: string;
    leaveStartDate: string;
    leaveEndDate: string;
    requestedDate: string;
    isHalfDay: boolean;
    leaveDays: number;
    leaveApprovers: LeaveApprover[];
    leaveApprovalStatus: ActionStatus;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
}

export type EmployeeInsertLeave = Pick<
    EmployeeLeave,
    'employeeId' | 'leaveType' | 'isHalfDay' | 'reason' | 'leaveStartDate' | 'leaveEndDate' | 'requestedDate'
> & {
    primaryApproverId: string;
    secondaryApproverId?: string;
};

export interface EmployeeLeaveFilters {
    leaveType?: string;
    year?: string;
    leaveApprovalStatus?: ActionStatus;
}
export interface ReporteeLeave extends EmployeeLeave {
    employeeName: string;
}

export interface LeaveBalance {
    leaveType: string;
    leaveBalance: number;
}

export interface AppliedLeaves {
    leaveType: string;
    leavesApplied: number;
}

export interface AllocatedLeaves {
    leaveType: string;
    allocatedLeaves: number;
}

export interface CombinedLeaveBalance {
    leaveType: string;
    leaveBalance: number;
    leavesApplied: number;
}

export interface EmployeeLeaveBalance {
    employeeId: string;
    year: string;
    leaveBalance: CombinedLeaveBalance[];
    organizationAllotedLeaves: AllocatedLeaves[];
}

export interface EmployeeDocuments {
    uuid: string;
    employeeId: string;
    documentType: string;
    fileName: string;
    hrApprovalStatus: ActionStatus;
    hrApproverId: string;
    hrApprovalDate: string;
    hrApproverComments: string;
    hrDetail?: string;
    employeeName?: string;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
}

export interface EmployeeDocumentsFilters {
    hrApprovalStatus?: ActionStatus;
}

export interface EmployeeLeaveAllocation {
    uuid: string;
    employeeId: string;
    leaveType: string;
    year: string;
    leavesApplied: number;
    leavesAllocated: number;
    leavesCarryForwarded: number;
    leavesBalance: number;
}

/* eslint-disable @typescript-eslint/naming-convention */
export enum OnlineStatus {
    ONLINE = 'ONLINE',
    OFFLINE = 'OFFLINE',
    AWAY = 'AWAY',
}

export interface EmployeeOnlineStatus {
    employeeId: string;
    onlineStatus: OnlineStatus;
    updatedAt: string;
}

export interface EmployeePreference {
    uuid: string;
    employeeId: string;
    preference: string;
    value: string;
    createdAt: string;
    createdBy: string;
    updatedBy: string;
    updatedAt: string;
}

export type EmployeePreferenceInsert = Pick<EmployeePreference, 'employeeId' | 'preference' | 'value'>;
