/* eslint-disable no-console */
import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import moment from 'moment';
import { useAppContext } from '../../hooks/useAppContext';
import { registerEmployee, validateIfEmailAvailable } from '../../services/employee';
import { IRootState, useSelector } from '../../store';
import { UserProfile } from '../../types/user';
import { objectToPlainString } from '../../helper/stringHelper';
import employeeFormValidate from './EmployeeFormValidation';
import GeneralInfo from './TabContent/GeneralInfo';
import ContactInfo from './TabContent/ContactInfo';
import OfficialInfo from './TabContent/OfficialInfo';
import BankingInfo from './TabContent/BankingInfo';
import EducationAndExperienceInfo from './TabContent/EducationAndExperienceInfo';
import './EmployeeRegistration.scss';

const EmployeeRegistrationForm = () => {
    const [key, setKey] = useState('1');
    const globalError = useSelector((state: IRootState) => state.error);
    const user = useSelector((state: IRootState) => state.user) as UserProfile;
    const [loading, setLoading] = useState(false);
    const [emailAvailableLoading, setEmailAvailableLoading] = useState(false);
    const [ofcEmailAvailableLoading, setOfcEmailAvailableLoading] = useState(false);
    const navigate = useNavigate();
    const { triggerSuccess } = useAppContext();
    const pristineForm = {
        name: '',
        gender: '',
        dateOfBirth: '',
        contactNumber: '',
        personalEmail: '',
        bloodGroup: '',
        maritalStatus: '',
        permanentAddress: '',
        temporaryAddress: '',
        emergencyContactNumber1Of: '',
        emergencyContactNumber1: '',
        emergencyContactNumber2Of: '',
        emergencyContactNumber2: '',
        employeeId: '',
        officialEmailId: '',
        dateOfJoining: '',
        reportingManagerId: '',
        currentCtc: '',
        designation: '',
        department: '',
        panCardNumber: '',
        bankAccountNumber: '',
        ifscCode: '',
        pfAccountNumber: '',
        bankPassbookImage: '',
        aadhaarCardNumber: '',
    };
    const [formData, setFormData] = useState(pristineForm);
    const [activeTabs, setActiveTabs] = useState(['1']);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultError = {} as any;
    const [errors, setErrors] = useState(defaultError);

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
        setLoading(false);
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateIfPersonalEmailAvailable = async () => {
        setEmailAvailableLoading(true);
        try {
            if (formData.personalEmail) {
                const email = formData.personalEmail;
                const res = await validateIfEmailAvailable(email, 'personalEmail');
                if (res !== false) {
                    setErrors({ ...defaultError, personalEmail: 'Employee with email already exists' });
                } else {
                    delete errors['personalEmail'];
                    setErrors({ ...errors });
                }
                setEmailAvailableLoading(false);
            }
            setEmailAvailableLoading(false);
        } catch (_e) {}
    };

    const validateIfOfficialEmailAvailable = async () => {
        setOfcEmailAvailableLoading(true);
        try {
            if (formData.officialEmailId) {
                const email = formData.officialEmailId;
                const res = await validateIfEmailAvailable(email, 'officialEmailId');
                if (res !== false) {
                    setErrors({ ...defaultError, officialEmailId: 'Employee with email already exists' });
                } else {
                    delete errors['officialEmailId'];
                    setErrors({ ...errors });
                }
                setOfcEmailAvailableLoading(false);
            }
            setOfcEmailAvailableLoading(false);
        } catch (_e) {}
    };

    const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setErrors(defaultError);
        let exists = await validateIfEmailAvailable(formData.personalEmail, 'personalEmail');
        if (exists) {
            setErrors({ ...defaultError, personalEmail: 'Employee with email already exists' });
            setLoading(false);
            return;
        }
        exists = await validateIfEmailAvailable(formData.officialEmailId, 'officialEmailId');
        if (exists) {
            setErrors({ ...defaultError, officialEmailId: 'Employee with email already exists' });
            setLoading(false);
            return;
        }
        const validateErrors = employeeFormValidate(formData, user.role);
        if (Object.keys(validateErrors).length === 0 && Object.keys(errors).length === 0) {
            setLoading(true);
            const dateOfBirth = moment(formData.dateOfBirth).format(`YYYY-MM-DD`);
            const dateOfJoining = moment(formData.dateOfJoining).format(`YYYY-MM-DD`);
            const data = {
                ...formData,
                dateOfBirth,
                dateOfJoining,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any;
            try {
                // TODO: Need to clean up for handling this better
                await registerEmployee(data);
                triggerSuccess(`Employee added successfully`);
                setFormData(pristineForm);
                navigate('/employee/list');
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (_e: any) {
                setLoading(false);
            }
        } else {
            setLoading(false);
            setErrors(validateErrors);
        }
    };

    const handleTabSelect = (k: string | null) => {
        if (k) {
            setKey(k);
            if (!activeTabs.includes(k)) {
                const updatedActiveTabs = [...activeTabs, k];
                setActiveTabs(updatedActiveTabs);
            }
        }
    };

    return (
        <main className="content employee-registration">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon icon={icon({ name: 'pencil' })} /> Register Employee
                        </h3>
                    </div>
                    <div className="col-auto ms-auto text-end mt-n1">
                        <NavLink to={`/employee/list`} className="btn btn-primary">
                            <FontAwesomeIcon icon={icon({ name: 'angle-left' })} className="far fa-fw fa-bell" /> Back
                        </NavLink>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-info">
                                <p>
                                    Please enter all the information for an employee which is mandatory marked with an
                                    asterisk (<span className="required-field">*</span>).
                                </p>
                                <p>
                                    Once you have filled out all the fields, press the &quot;Save Details&quot; button to
                                    save the information.
                                </p>
                            </div>
                            <div className="card-body col-8">
                                {Object.keys(errors).length > 0 && (
                                    <div className="error-message">
                                        <p>
                                            <FontAwesomeIcon className="me-2" icon={icon({ name: 'warning' })} /> Looks like
                                            you have some errors on the form, check all the tabs before saving the details.
                                        </p>
                                        <p>
                                            Check below fields:
                                            <br />
                                            {Object.keys(errors)
                                                .map((errorKey) => objectToPlainString(errorKey))
                                                .join(', ')}
                                        </p>
                                    </div>
                                )}
                                <Tabs activeKey={key} onSelect={handleTabSelect}>
                                    <Tab eventKey={'1'} title="General Info" disabled={!activeTabs.includes('1')}>
                                        <div className="tab-content">
                                            <GeneralInfo
                                                formData={formData}
                                                formErrors={errors}
                                                inputChangeHandler={handleChange}
                                                validateIfAvailable={validateIfPersonalEmailAvailable}
                                                emailAvailableLoading={emailAvailableLoading}
                                                setFormData={setFormData}
                                                userRole={user.role}
                                            />
                                            <div className="text-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => handleTabSelect('2')}
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey={'2'} title="Contact Info" disabled={!activeTabs.includes('2')}>
                                        <div className="tab-content">
                                            <ContactInfo
                                                formData={formData}
                                                formErrors={errors}
                                                inputChangeHandler={handleChange}
                                                userRole={user.role}
                                            />
                                            <div className="text-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => handleTabSelect('1')}
                                                >
                                                    Previous
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => handleTabSelect('3')}
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab
                                        eventKey={'3'}
                                        title="Education & Experience Info"
                                        disabled={!activeTabs.includes('3')}
                                    >
                                        <div className="tab-content">
                                            <EducationAndExperienceInfo
                                                formData={formData}
                                                formErrors={errors}
                                                inputChangeHandler={handleChange}
                                                userRole={user.role}
                                            />
                                            <div className="text-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => handleTabSelect('2')}
                                                >
                                                    Previous
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => handleTabSelect('4')}
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey={'4'} title="Official Info" disabled={!activeTabs.includes('4')}>
                                        <div className="tab-content">
                                            <OfficialInfo
                                                formData={formData}
                                                formErrors={errors}
                                                inputChangeHandler={handleChange}
                                                setFormData={setFormData}
                                                userRole={user.role}
                                                ofcEmailAvailableLoading={ofcEmailAvailableLoading}
                                                validateIfAvailable={validateIfOfficialEmailAvailable}
                                                setErrors={setErrors}
                                            />
                                            <div className="text-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => handleTabSelect('3')}
                                                >
                                                    Previous
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => handleTabSelect('5')}
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey={'5'} title="Banking Info" disabled={!activeTabs.includes('5')}>
                                        <div className="tab-content">
                                            <BankingInfo
                                                formData={formData}
                                                formErrors={errors}
                                                inputChangeHandler={handleChange}
                                                userRole={user.role}
                                            />

                                            <div className="text-end mt-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => handleTabSelect('4')}
                                                >
                                                    Previous
                                                </button>
                                                <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                                                    Save Details{' '}
                                                    {loading && !globalError.isError && (
                                                        <div
                                                            className="spinner-border spinner-border-sm   me-2"
                                                            role="status"
                                                        >
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default EmployeeRegistrationForm;
