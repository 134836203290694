/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import * as Icon from 'react-feather';
import InfiniteScroll from 'react-infinite-scroller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import moment from 'moment';
import { capitalize } from 'lodash';
import { getCustomerResourceLeaves, getReporteeLeaves } from '../../../services/employeeLeaves';
import './EmployeeLeavesList.scss';
import { ActionStatus, EmployeeLeaveFilters, ReporteeLeave } from '../../../types/employee';
import { LeavesApprovalModal } from '../LeaveApprovalModal/LeaveApprovalModal';
import { IRootState, useSelector } from '../../../store';
import { ADMIN_USERS, CUSTOMER_USERS, EMP_MANAGERS, UserProfile, UserRole } from '../../../types/user';
import { EmployeeLeaveTypes } from '../../../constants/data';
import { StatusIndicator } from '../../StatusIndicator/StatusIndicator';
import { LeaveDetailModal } from './LeaveDetailModal';

// TODO: LEAVE META DATA, like total applied leaves, rejected total, approved total, leave balance.

const EmployeeLeavesApprovalList = () => {
    const currentYear = new Date().getFullYear().toString();
    const loggedInUser = useSelector((state: IRootState) => state.user) as UserProfile;
    const [employeeLeaves, setEmployeeLeaves] = useState<ReporteeLeave[]>([]);
    const [showModalId, setShowModalId] = useState<string | null>(null);
    const [showApprovalModalId, setApprovalShowModalId] = useState<string | null>(null);
    const [filters, setFilters] = useState<EmployeeLeaveFilters>({ year: currentYear });
    const [error, setError] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const isCustomerView = loggedInUser.role === UserRole.customer;
    const fetchEmployeeLeaves = async (page: number, searchFilters: EmployeeLeaveFilters) => {
        try {
            setLoading(true);
            let response = {
                data: [] as ReporteeLeave[],
            };
            if (loggedInUser.role === UserRole.customer) {
                response = await getCustomerResourceLeaves(page, searchFilters);
            } else {
                response = await getReporteeLeaves(page, searchFilters);
            }
            if (response.data.length > 0) {
                setHasMore(true);
                setEmployeeLeaves((prevData) => [...prevData, ...response.data]);
            } else {
                setHasMore(false);
            }
            setLoading(false);
        } catch (err: unknown) {
            setError('Something went wrong');
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const filterType = event.target.name; // Get the id of the select element as filterType
        const newFilters: EmployeeLeaveFilters = { ...filters };

        if (event.target.value !== '0') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            newFilters[filterType as keyof EmployeeLeaveFilters] = event.target.value as any;
            setFilters(newFilters);
        } else {
            delete newFilters[filterType as keyof EmployeeLeaveFilters];
            setFilters(newFilters);
        }
    };

    const openModal = (id: string) => {
        setShowModalId(id);
    };

    const handleModalClose = () => {
        setShowModalId(null);
    };

    const openApprovalModal = (id: string) => {
        setApprovalShowModalId(id);
    };

    const handleApprovalModalClose = () => {
        setApprovalShowModalId(null);
    };

    const handleSearchClick = () => {
        setEmployeeLeaves([]);
        if (filters.leaveType === '0') {
            delete filters.leaveType;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (filters.leaveApprovalStatus === ('0' as any)) {
            delete filters.leaveApprovalStatus;
        }
        fetchEmployeeLeaves(1, filters);
    };

    const handleClearFilter = () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setFilters({ year: currentYear, leaveType: '0', leaveApprovalStatus: '0' as any });
        setEmployeeLeaves([]);
        fetchEmployeeLeaves(1, {});
    };

    useEffect(() => {
        if (loggedInUser.uuid) {
            fetchEmployeeLeaves(1, filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInUser.uuid]);
    const allLeaveType = [...EmployeeLeaveTypes.basic, ...EmployeeLeaveTypes.special];
    return (
        <main className="content view-employees-leaves-list">
            <div className="container-fluid p-0">
                <div className="row mb-2 mb-xl-3">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon size="lg" icon={icon({ name: 'paper-plane' })} />{' '}
                            {isCustomerView ? 'Resource Leaves' : ' Reportee Leaves'}
                        </h3>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-info">
                                <p>This page provides overview of leaves applied of employees reporting to you. </p>
                                <p className="mb-0">
                                    <strong>Note:</strong>
                                </p>
                                <ul>
                                    <li>By default leaves from current year are shown.</li>
                                </ul>
                            </div>
                            <div className="card-body">
                                <div
                                    id="datatables-reponsive_wrapper"
                                    className="dataTables_wrapper dt-bootstrap5 no-footer"
                                >
                                    {error && (
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div
                                                    className="alert alert-danger alert-outline alert-dismissible"
                                                    role="alert"
                                                >
                                                    <div className="alert-icon">
                                                        <Icon.Bell className="far fa-fw fa-bell" />
                                                    </div>
                                                    <div className="alert-message">
                                                        <strong>Error</strong> Something went wrong when trying to get leaves
                                                        data for your reportees.
                                                        <br /> If the problem persists, please contact support.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {
                                        <>
                                            <div className="search-container">
                                                <div className="mb-3 row">
                                                    <strong>Filter By:</strong>
                                                </div>
                                                <div className="mb-3 row">
                                                    <div className="col-2">
                                                        <select
                                                            name="year"
                                                            className="form-control form-control-lg form-select"
                                                            onChange={handleFilterChange}
                                                            value={filters.year}
                                                        >
                                                            <option value="2024">2024</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-3">
                                                        <select
                                                            name="leaveType"
                                                            className="form-control form-control-lg form-select"
                                                            onChange={handleFilterChange}
                                                            value={filters.leaveType}
                                                        >
                                                            <option value="0">Select leave type</option>
                                                            {allLeaveType.map((type, i) => (
                                                                <option key={i} value={`${type}`}>
                                                                    {capitalize(type.replaceAll('_', ' '))}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-3">
                                                        <select
                                                            name="leaveApprovalStatus"
                                                            className="form-control form-control-lg form-select"
                                                            onChange={handleFilterChange}
                                                            value={filters.leaveApprovalStatus}
                                                        >
                                                            <option value="0">Select leave status</option>
                                                            <option value="APPROVED">APPROVED</option>
                                                            <option value="REJECTED">REJECTED</option>
                                                            <option value="REQUESTED">REQUESTED</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-1">
                                                        {' '}
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={handleSearchClick}
                                                        >
                                                            Search
                                                        </button>
                                                    </div>
                                                    <div className="col-md-1 text-start pt-1 pl-1">
                                                        <span
                                                            onClick={handleClearFilter}
                                                            className="text-primary cursor-pointer"
                                                        >
                                                            Clear All
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {!loading && !error && employeeLeaves?.length === 0 && (
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div
                                                    className="alert alert-danger alert-outline alert-dismissible mt-5"
                                                    role="alert"
                                                >
                                                    <div className="alert-icon">
                                                        <Icon.Bell className="far fa-fw fa-bell" />
                                                    </div>
                                                    <div className="alert-message">
                                                        <p>
                                                            <strong>
                                                                No leaves data available. <br /> It is possible that there is
                                                                no data added yet.
                                                                <br />
                                                                OR try refining your search filters.
                                                            </strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {employeeLeaves?.length !== 0 && (
                                        <>
                                            <div style={{ height: '500px', overflow: 'auto' }}>
                                                <InfiniteScroll
                                                    pageStart={1}
                                                    loadMore={(page: number) => fetchEmployeeLeaves(page, filters)}
                                                    hasMore={hasMore && !loading}
                                                    loader={
                                                        <div key={0} className="row">
                                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                                <div
                                                                    className="spinner-border text-primary me-2"
                                                                    role="status"
                                                                >
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    useWindow={false}
                                                >
                                                    <table
                                                        id="datatables-reponsive"
                                                        className="table table-striped dataTable no-footer dtr-inline view-employees-leaves-list-table"
                                                        style={{ width: '100%', tableLayout: 'fixed' }}
                                                        aria-describedby="datatables-reponsive_info"
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '100px' }}>Request #</th>
                                                                <th>Employee Name</th>
                                                                <th style={{ width: '100px' }}>Leave Type</th>
                                                                <th>Leave Dates</th>
                                                                <th style={{ width: '80px' }}>Total Days</th>
                                                                <th>Leave Status</th>
                                                                <th style={{ width: '100px' }}>Applied on</th>
                                                                {[
                                                                    ...ADMIN_USERS,
                                                                    ...EMP_MANAGERS,
                                                                    ...CUSTOMER_USERS,
                                                                ].includes(loggedInUser.role) && <th>Action</th>}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {employeeLeaves &&
                                                                employeeLeaves.map((leave, index) => (
                                                                    <tr
                                                                        className={cn({
                                                                            odd: index % 2 === 0,
                                                                            even: index % 2 !== 0,
                                                                        })}
                                                                        key={`${leave.uuid}-${index}`}
                                                                    >
                                                                        <td>
                                                                            <LeaveDetailModal
                                                                                showModal={showModalId === leave.uuid}
                                                                                leaveDetails={leave}
                                                                                handleClose={handleModalClose}
                                                                            />
                                                                            {leave.requestNumber}
                                                                        </td>
                                                                        <td>{leave.employeeName}</td>
                                                                        <td>{leave.leaveType}</td>
                                                                        <td>
                                                                            <p>
                                                                                From:{' '}
                                                                                {moment(leave.leaveStartDate).format(
                                                                                    'DD/MM/YYYY'
                                                                                ) ?? '-'}
                                                                            </p>
                                                                            <p>
                                                                                To:{' '}
                                                                                {moment(leave.leaveEndDate).format(
                                                                                    'DD/MM/YYYY'
                                                                                ) ?? '-'}
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            {parseFloat(`${leave.leaveDays}`)
                                                                                .toFixed(1)
                                                                                .replace(/\.0+$/, '')}
                                                                            {leave.leaveDays > 1 ? ` days` : ` day`}
                                                                        </td>
                                                                        <td>
                                                                            <StatusIndicator
                                                                                status={leave.leaveApprovalStatus}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            {moment(leave.requestedDate).format(
                                                                                'DD/MM/YYYY'
                                                                            ) ?? '-'}
                                                                        </td>
                                                                        <td>
                                                                            <FontAwesomeIcon
                                                                                title="View Details"
                                                                                icon={icon({ name: 'info-circle' })}
                                                                                size="lg"
                                                                                className="text-primary cursor-pointer me-2"
                                                                                onClick={() => openModal(leave.uuid)}
                                                                            />
                                                                            {leave.leaveApprovalStatus !=
                                                                                ActionStatus.REJECTED &&
                                                                                [
                                                                                    ...ADMIN_USERS,
                                                                                    ...EMP_MANAGERS,
                                                                                    ...CUSTOMER_USERS,
                                                                                ].includes(loggedInUser.role) && (
                                                                                    <>
                                                                                        {' '}
                                                                                        <button
                                                                                            className="btn btn-primary btn-sm"
                                                                                            title="Leave Action"
                                                                                            onClick={() =>
                                                                                                openApprovalModal(leave.uuid)
                                                                                            }
                                                                                        >
                                                                                            Approve/Reject
                                                                                        </button>
                                                                                        <LeavesApprovalModal
                                                                                            leave={leave}
                                                                                            showModal={
                                                                                                showApprovalModalId ===
                                                                                                leave.uuid
                                                                                            }
                                                                                            loggedInUserId={
                                                                                                loggedInUser.uuid
                                                                                            }
                                                                                            handleClose={
                                                                                                handleApprovalModalClose
                                                                                            }
                                                                                            handleSearchClick={
                                                                                                handleSearchClick
                                                                                            }
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </InfiniteScroll>
                                            </div>
                                        </>
                                    )}

                                    {loading && (
                                        <div key={0} className="row">
                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                <div className="spinner-border text-primary me-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default EmployeeLeavesApprovalList;
