/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import cn from 'classnames';
import { Tabs, Tab } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { AlphaNumericAndCharactersRegex, AlphaNumericRegex } from '../../../constants/validations';
import { SurveyConfig, SurveyFormConfig } from '../../../types/survey';
import { objectToPlainString } from '../../../helper/stringHelper';
import { createSurveyForm, getSurveyFromDetails, updateSurveyForm } from '../../../services/controlCenter';
import { useAppContext } from '../../../hooks/useAppContext';
import { searchEmployeeByKeyword } from '../../../services/employee';
import { searchCustomerByKeyword } from '../../../services/customer';
import EmployeeSearchInput from '../../EmployeeSearchInput/EmployeeSearchInput';

const SurveyForm = () => {
    const { id } = useParams();
    const pristineEntryForm = {
        question: '',
        inputType: '0',
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const childRef = useRef() as any;
    const { triggerSuccess } = useAppContext();
    const navigate = useNavigate(); // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultError = {} as any;
    const [errors, setErrors] = useState(defaultError);
    const [loading, setLoading] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(true);
    const [updateMode, setUpdateMode] = useState(false);
    const [cloneMode, setCloneMode] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [entryForm, setEntryForm] = useState<any>(pristineEntryForm);
    const [key, setKey] = useState('1');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [entryFormErrors, setEntryFormErrors] = useState(defaultError);
    const [savedSurveyQuestion, setSavedSurveyQuestion] = useState<SurveyFormConfig[]>([]);
    const [selectedEmployees, setSelectedEmployees] = useState<{ employeeId: string; employeeName: string }[]>([]);

    const pristineForm = {
        surveyTitle: '',
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [formData, setFormData] = useState<any>(pristineForm);
    const handleTabSelect = (k: string | null) => {
        if (k) {
            setKey(k);
        }
    };
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
        setLoading(false);
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        setLoading(true);
        e.preventDefault();
        setErrors(defaultError);
        setEntryFormErrors(defaultError);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const tempErrors = {} as any;
        // Add validations.
        if (!formData.surveyType) {
            tempErrors.surveyType = 'Type is a required field';
        }
        if (!formData.surveyTitle) {
            tempErrors.surveyTitle = 'Title is a required field';
        } else if (!AlphaNumericRegex.test(formData.surveyTitle)) {
            tempErrors.surveyTitle = 'Should only contain alphanumeric characters. No special characters allowed.';
        }
        if (!formData[`startDate`]) {
            tempErrors.startDate = 'Start date is a required field';
        }

        if (!formData[`endDate`]) {
            tempErrors.endDate = 'End date is a required field';
        }

        if (savedSurveyQuestion.length <= 0) {
            tempErrors.savedSurveyQuestion = 'Survey questions are required to complete config';
        } else if (savedSurveyQuestion.length > 250) {
            tempErrors.savedSurveyQuestion = 'Survey questions cannot be more than 250 characters';
        }

        if (Object.keys(tempErrors).length === 0) {
            const formConfigData = {
                surveyType: formData.surveyType,
                surveyTitle: formData.surveyTitle,
                startDate: formData.startDate,
                endDate: formData.endDate,
                formDetails: savedSurveyQuestion,
                recipientEmails: selectedEmployees.map((s) => s.employeeName),
            } as SurveyConfig;

            try {
                if (updateMode && id) {
                    await updateSurveyForm(id, formConfigData);
                    triggerSuccess(`Survey form is updated successfully`);
                } else {
                    await createSurveyForm(formConfigData);
                    triggerSuccess(`Survey form is configured successfully`);
                }

                setFormData(pristineForm);
                navigate('/control-center/survey-config');
            } catch (err: unknown) {
                setLoading(false);
            }
        } else {
            setErrors(tempErrors);
        }
        setLoading(false);
    };

    const handleEntryForm = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        childRef.current?.clearInputs();
        setEntryFormErrors(defaultError);
        setErrors(defaultError);
        setEntryForm({ ...entryForm, [e.target.name]: e.target.value });
    };

    const handleAddEntry = () => {
        setEntryFormErrors(defaultError);
        setErrors(defaultError);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const tempErrors = {} as any;
        if (!entryForm.question) {
            tempErrors.question = 'Description is a required field';
        } else if (!AlphaNumericAndCharactersRegex.test(entryForm.question)) {
            tempErrors.question = 'Should only contain alphanumeric characters, hyphens, underscores, dot and spaces';
        }

        if (entryForm.inputType === '0') {
            tempErrors.inputType = 'Select input type';
        }
        if (Object.keys(tempErrors).length > 0) {
            setEntryFormErrors(tempErrors);
        } else {
            const entryData: SurveyFormConfig = {
                question: entryForm.question,
                inputType: entryForm.inputType,
            };
            setSavedSurveyQuestion([...savedSurveyQuestion, entryData]);
            setEntryForm(pristineEntryForm);
        }
    };

    const handleRecipientEntry = () => {
        const { employeeId, entityValue } = formData;
        if (!entityValue && !employeeId) {
            setEntryFormErrors({ recipientError: 'Recipient needs to be selected' });
        } else {
            setEntryFormErrors(defaultError);
            childRef.current?.clearInputs();
            setSelectedEmployees([
                ...selectedEmployees,
                {
                    employeeId,
                    employeeName: entityValue,
                },
            ]);
        }
    };
    const handleRemoveEntry = (index: number) => {
        setSavedSurveyQuestion((prevSurvey) => {
            // Check if the index is valid
            if (index < 0 || index >= prevSurvey.length) {
                throw new Error('Invalid index');
            }

            // Create a new array without the entry at the specified index
            const updatedSurveyQuestionnaire = [...prevSurvey.slice(0, index), ...prevSurvey.slice(index + 1)];

            return updatedSurveyQuestionnaire;
        });
    };

    const handleRemoveEmployeeEntry = (index: number) => {
        setSelectedEmployees((prevSurvey) => {
            // Check if the index is valid
            if (index < 0 || index >= prevSurvey.length) {
                throw new Error('Invalid index');
            }

            // Create a new array without the entry at the specified index
            const updatedValue = [...prevSurvey.slice(0, index), ...prevSurvey.slice(index + 1)];

            return updatedValue;
        });
    };

    const endOfYearDate = moment().endOf('year');
    // Calculate the date 90 days from now
    const maxDate90Days = moment().add(90, 'days');

    // Set the maxDate based on the comparison
    const maxDate = maxDate90Days.isBefore(endOfYearDate) ? maxDate90Days.toDate() : endOfYearDate.toDate();

    useEffect(() => {
        if (id) {
            const getSurveyData = async () => {
                setFetchLoading(true);
                const surveyFormDetails = await getSurveyFromDetails(id);
                const isHistoric = moment(surveyFormDetails.data.survey.endDate).isBefore(moment(), 'day');
                const formConfigData = {
                    surveyType: surveyFormDetails.data.survey.surveyType,
                    surveyTitle: surveyFormDetails.data.survey.surveyTitle,
                    isLive: surveyFormDetails.data.survey.isLive,
                    isHistoric,
                    startDate: Date.parse(surveyFormDetails.data.survey.startDate),
                    endDate: Date.parse(surveyFormDetails.data.survey.endDate),
                };
                setFormData(formConfigData);
                const surveyQuestionnaire = surveyFormDetails.data.survey.formDetails;
                setSavedSurveyQuestion(surveyQuestionnaire);
                const employeeEmails = surveyFormDetails.data.survey.recipientEmails;
                const preSelectedEmployees = employeeEmails.map((email) => ({
                    employeeId: '',
                    employeeName: email,
                }));
                setSelectedEmployees(preSelectedEmployees);
                setFetchLoading(false);
            };
            getSurveyData();
            if (history.state.usr) {
                setCloneMode(history.state.usr.formClone);
                setUpdateMode(false);
            } else {
                setCloneMode(false);
                setUpdateMode(true);
            }
        }
    }, [id]);
    return (
        <main className="content user-registration">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-control-center-info">
                                {' '}
                                <p>
                                    <FontAwesomeIcon className="me-3" size="lg" icon={icon({ name: 'shield-alt' })} />{' '}
                                    Application Control Center{' '}
                                    <FontAwesomeIcon className="me-3 ms-3" icon={icon({ name: 'chevron-right' })} />
                                    Configure Survey Form
                                </p>
                            </div>
                            <div className="card-body col-12">
                                <div className="col-12 text-end">
                                    <button className="btn btn-primary" onClick={() => navigate(-1)}>
                                        <FontAwesomeIcon icon={icon({ name: 'angle-left' })} className="far fa-fw fa-bell" />{' '}
                                        Back
                                    </button>
                                </div>
                                <div className="col-10 py-2 p-3">
                                    {updateMode && (formData.isLive || formData.isHistoric) && (
                                        <div className="alert alert-warning alert-outline   mt-3" role="alert">
                                            <div className="alert-icon">
                                                <FontAwesomeIcon
                                                    size="sm"
                                                    icon={icon({ name: 'bell' })}
                                                    className="far fa-fw fa-bell"
                                                />
                                            </div>
                                            <div className="alert-message">
                                                {' '}
                                                <p className="mb-0">
                                                    This survey is {formData.isHistoric ? `from the past` : `currently live`}
                                                    .
                                                </p>
                                                <p className="mb-0">
                                                    Making any changes to this survey may affect the survey results of
                                                    employees who have already filled in the survey.
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    {cloneMode && (
                                        <div className="alert alert-warning alert-outline   mt-3" role="alert">
                                            <div className="alert-icon">
                                                <FontAwesomeIcon
                                                    size="sm"
                                                    icon={icon({ name: 'bell' })}
                                                    className="far fa-fw fa-bell"
                                                />
                                            </div>
                                            <div className="alert-message">
                                                <p className="mb-0">
                                                    You are about to clone the form <strong>{formData.surveyTitle}</strong>
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    <h3 className="py-2">{updateMode && `Update `}Survey Form Configuration:</h3>
                                    {Object.keys(errors).length > 0 && (
                                        <div className="error-message mb-3">
                                            <p>
                                                <FontAwesomeIcon className="me-2" icon={icon({ name: 'warning' })} /> Looks
                                                like you have some errors on the form, check all the tabs before saving the
                                                details.
                                            </p>
                                            <p>
                                                Check below fields:
                                                <br />
                                                {Object.keys(errors)
                                                    .map((errorKey) => objectToPlainString(errorKey))
                                                    .join(', ')}
                                            </p>
                                        </div>
                                    )}
                                    {fetchLoading && (
                                        <div className="row mt-4">
                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                <div className="spinner-border text-primary me-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {!fetchLoading && (
                                        <Tabs activeKey={key} onSelect={handleTabSelect}>
                                            <Tab eventKey={'1'} title="Survey Form Config">
                                                <div className="tab-content">
                                                    <label>
                                                        Survey Type <span className="required-field">*</span>
                                                    </label>
                                                    <select
                                                        disabled={updateMode}
                                                        name="surveyType"
                                                        onChange={handleChange}
                                                        className={`form-control form-select form-control-lg mb-3 ${
                                                            errors.surveyType ? 'is-invalid' : ''
                                                        }`}
                                                        value={formData.surveyType}
                                                    >
                                                        <option value="0">Select Survey Type</option>
                                                        <option value="EMPLOYEE">Employee</option>
                                                        <option value="CUSTOMER">Customer</option>
                                                    </select>
                                                    {errors.surveyType && (
                                                        <div className="invalid-feedback mb-3">{errors.surveyType}</div>
                                                    )}
                                                    <label>
                                                        Survey Title <span className="required-field">*</span>
                                                    </label>
                                                    <input
                                                        className={`form-control form-control-lg mb-3 ${
                                                            errors.surveyTitle ? 'is-invalid' : ''
                                                        }`}
                                                        type="text"
                                                        name="surveyTitle"
                                                        onChange={handleChange}
                                                        disabled={updateMode}
                                                        value={formData.surveyTitle}
                                                    />
                                                    {errors.surveyTitle && (
                                                        <div className="invalid-feedback mb-3">{errors.surveyTitle}</div>
                                                    )}{' '}
                                                    <div className="col-md-12 row">
                                                        <div className="col-md-6">
                                                            <label>
                                                                Start Date <span className="required-field">*</span>
                                                            </label>
                                                            <DatePicker
                                                                className={`form-control  form-control-lg mb-3 ${
                                                                    errors.startDate ? 'is-invalid' : ''
                                                                }`}
                                                                name="startDate"
                                                                format="dd/MM/y"
                                                                maxDate={maxDate}
                                                                minDate={moment(new Date()).subtract(60, 'days').toDate()}
                                                                value={formData.startDate}
                                                                onChange={(date) =>
                                                                    date && setFormData({ ...formData, startDate: date })
                                                                }
                                                            />
                                                            {errors.startDate && (
                                                                <div className="invalid-feedback mb-3">
                                                                    {errors.startDate}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label>
                                                                End Date <span className="required-field">*</span>
                                                            </label>
                                                            <DatePicker
                                                                className={`form-control form-control-lg mb-3 ${
                                                                    errors.endDate ? 'is-invalid' : ''
                                                                }`}
                                                                name="endDate"
                                                                format="dd/MM/y"
                                                                maxDate={maxDate}
                                                                minDate={moment(new Date(formData.startDate)).toDate()}
                                                                value={formData.endDate}
                                                                onChange={(date) => {
                                                                    date &&
                                                                        setFormData({
                                                                            ...formData,
                                                                            endDate: date,
                                                                        });
                                                                }}
                                                            />
                                                            {errors.endDate && (
                                                                <div className="invalid-feedback mb-3">{errors.endDate}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="text-end mt-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => handleTabSelect('2')}
                                                        >
                                                            Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey={'2'} title="Survey Questionnaire">
                                                <div className="tab-content">
                                                    <div className="px-3 m-0">
                                                        <div>
                                                            <FontAwesomeIcon
                                                                className="text-info"
                                                                icon={icon({ name: 'circle-info' })}
                                                            />{' '}
                                                            <strong>Tips:</strong>
                                                        </div>
                                                        <ul>
                                                            <li>
                                                                <strong>Comment:</strong> If you want user to add comment for
                                                                the question select input type as Comment.
                                                            </li>{' '}
                                                            <li>
                                                                <strong>Rating:</strong> If you want user to rate the
                                                                question select input type as Rating (1 to 5).
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="row  pt-1 p-4 col-12 control-center-container">
                                                        <div className="row">
                                                            <div className="col-8 fw-bold p-2 bg-app-header text-white">
                                                                Question
                                                            </div>
                                                            <div className="col-3 fw-bold p-2 bg-app-header text-white">
                                                                Input Type
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-8 p-2 pb-0 align-bottom bg-app-row ">
                                                                <input
                                                                    className={`form-control form-control-lg p-2 mb-3 ${
                                                                        entryFormErrors.question ? 'is-invalid' : ''
                                                                    }`}
                                                                    type="text"
                                                                    name="question"
                                                                    onChange={handleEntryForm}
                                                                    value={entryForm.question}
                                                                />
                                                                {entryFormErrors.question && (
                                                                    <div className="invalid-feedback mb-3 bg-white p-2">
                                                                        {entryFormErrors.question}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-3 p-2 pb-0 align-bottom bg-app-row ">
                                                                <select
                                                                    name="inputType"
                                                                    onChange={handleEntryForm}
                                                                    value={entryForm.inputType}
                                                                    className={`form-control form-select form-control-lg mb-3 ${
                                                                        entryFormErrors.inputType ? 'is-invalid' : ''
                                                                    }`}
                                                                >
                                                                    <option value="0">Select Input</option>
                                                                    <option value="comment">Comment Box</option>
                                                                    <option value="rating">Rating (1 to 5)</option>
                                                                </select>
                                                                {entryFormErrors.inputType && (
                                                                    <div className="invalid-feedback bg-white mb-3 p-2 show">
                                                                        {entryFormErrors.inputType}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-1 p-1 pb-0 align-bottom ">
                                                                {' '}
                                                                <FontAwesomeIcon
                                                                    className={cn('mt-3 ', {
                                                                        'text-secondary':
                                                                            Object.keys(entryFormErrors).length > 0 ||
                                                                            entryForm.question.length === 0,
                                                                        'cursor-pointer text-success':
                                                                            Object.keys(entryFormErrors).length === 0 &&
                                                                            entryForm.question.length !== 0,
                                                                    })}
                                                                    size="lg"
                                                                    icon={icon({ name: 'circle-plus' })}
                                                                    onClick={handleAddEntry}
                                                                />
                                                            </div>
                                                        </div>

                                                        {savedSurveyQuestion.length > 0 && (
                                                            <>
                                                                {savedSurveyQuestion.map((survey, index) => (
                                                                    <div className="row" key={index}>
                                                                        <div className="col-8 p-2 m-0 ps-3 bg-app-row bg-app-row__border-bottom">
                                                                            {survey.question}
                                                                        </div>
                                                                        <div className="col-3 p-2 m-0  bg-app-row bg-app-row__border-bottom">
                                                                            {survey.inputType.toLocaleUpperCase()}
                                                                        </div>

                                                                        <div className="col-1 p-2 ">
                                                                            <FontAwesomeIcon
                                                                                className="cursor-pointer "
                                                                                onClick={() => handleRemoveEntry(index)}
                                                                                size="sm"
                                                                                icon={icon({
                                                                                    style: 'regular',
                                                                                    name: 'xmark-circle',
                                                                                })}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        )}
                                                        {errors.savedSurveyQuestion && (
                                                            <div className="invalid-feedback mb-3 show">
                                                                {errors.savedSurveyQuestion}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="text-end mt-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => handleTabSelect('1')}
                                                        >
                                                            Previous
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => handleTabSelect('3')}
                                                        >
                                                            Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey={'3'} title="Select Recipients">
                                                <div className="tab-content">
                                                    <div className="px-3 m-0">
                                                        <div>
                                                            <FontAwesomeIcon
                                                                className="text-info"
                                                                icon={icon({ name: 'circle-info' })}
                                                            />{' '}
                                                            <strong>Tips:</strong>
                                                        </div>
                                                        <ul>
                                                            <li>Select employees which you want to submit this survey.</li>
                                                            <li>
                                                                If you want all employees to submit this survey, leave this
                                                                section blank.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="row  pt-1 p-4 col-12 control-center-container">
                                                        <div className="row">
                                                            <div className="col-11 fw-bold p-2 bg-app-header text-white">
                                                                {formData.surveyType === 'CUSTOMER'
                                                                    ? `Customer Name`
                                                                    : `Employee Name`}
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-11 p-2  align-bottom bg-app-row ">
                                                                <EmployeeSearchInput
                                                                    errors={entryFormErrors}
                                                                    setErrors={setErrors}
                                                                    formData={formData}
                                                                    setFormData={setFormData}
                                                                    searchFnCallback={
                                                                        formData.surveyType === 'CUSTOMER'
                                                                            ? searchCustomerByKeyword
                                                                            : searchEmployeeByKeyword
                                                                    }
                                                                    inputIndex={'employeeId'}
                                                                    excludedUsers={selectedEmployees.map(
                                                                        (x) => x.employeeId
                                                                    )}
                                                                    ref={childRef}
                                                                />
                                                                {entryFormErrors.recipientError && (
                                                                    <div className="invalid-feedback show mb-3 bg-white p-2">
                                                                        {entryFormErrors.recipientError}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-1 p-1 pb-0 align-bottom ">
                                                                {' '}
                                                                <FontAwesomeIcon
                                                                    className={cn('mt-3 ', {
                                                                        'text-secondary': formData.employeeId === null,
                                                                        'cursor-pointer text-success':
                                                                            formData.employeeId !== null,
                                                                    })}
                                                                    size="lg"
                                                                    icon={icon({ name: 'circle-plus' })}
                                                                    onClick={handleRecipientEntry}
                                                                />
                                                            </div>
                                                        </div>

                                                        {selectedEmployees.length > 0 && (
                                                            <>
                                                                {selectedEmployees.map((employee, index) => (
                                                                    <div className="row" key={index}>
                                                                        <div className="col-11 p-2 m-0 ps-3 bg-app-row bg-app-row__border-bottom">
                                                                            {employee.employeeName}
                                                                        </div>

                                                                        <div className="col-1 p-2 ">
                                                                            <FontAwesomeIcon
                                                                                className="cursor-pointer "
                                                                                onClick={() =>
                                                                                    handleRemoveEmployeeEntry(index)
                                                                                }
                                                                                size="sm"
                                                                                icon={icon({
                                                                                    style: 'regular',
                                                                                    name: 'xmark-circle',
                                                                                })}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className="text-end mt-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={handleSubmit}
                                                        >
                                                            {updateMode ? 'Update ' : 'Submit '} Data{' '}
                                                            {loading && (
                                                                <div
                                                                    className="spinner-border spinner-border-sm   me-2"
                                                                    role="status"
                                                                >
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                            )}
                                                        </button>{' '}
                                                    </div>
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default SurveyForm;
