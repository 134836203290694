import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { NavDropdown } from 'react-bootstrap';
import * as Icon from 'react-feather';
import Avatar from 'react-avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IRootState, AppDispatch } from '../../store';
import { UserProfile, UserRole } from '../../types/user';
import { useAuth } from '../../hooks/useAuth';
import { setAccessToken } from '../../store/token';
import { extendSession } from '../../services/user';
import './Header.scss';
import { handleGlobalError, resetGlobalError } from '../../store/errorHandler';
import { LogoutModal } from '../Modals/LogoutModal';
import { SessionTimeoutwarningModal } from '../Modals/SessionWarningModal';
import { PrivacyPopup } from '../PrivacyStatement/PrivacyPopup';
import { UserOnlineStatus } from './UserOnlineStatus';

type Props = {
    handleSidebarCollapse: () => void;
};
type JWT = {
    exp: number;
};
const expiryNotificationThreshHold = 300; // 5 minutes = 300 seconds

const Header = (props: Props) => {
    const { onLogout } = useAuth();
    const dispatch: AppDispatch = useDispatch();
    const user = useSelector((state: IRootState) => state.user) as UserProfile;
    const [showModal, setModalShow] = useState(false);
    const [modalNudged, setModalNudged] = useState(false);
    const [logoutModal, setLogoutModal] = useState(false);
    const [timer, setTimer] = useState(0);
    const token = useSelector((state: IRootState) => state.token);
    const decoded = token.accessToken && jwt_decode(token.accessToken);
    const decodedToken = decoded as JWT;
    const handleClose = () => setModalShow(false);

    useEffect(() => {
        // Start the timer
        const interval = setInterval(() => {
            // Get the current timestamp in seconds
            const currentTimestamp = Math.floor(Date.now() / 1000);

            // Calculate the remaining time in seconds
            const remainingTime = decodedToken.exp - currentTimestamp;

            // Update the timer state
            setTimer(remainingTime);

            // Stop the timer when it reaches 0
            if (remainingTime <= 0) {
                setLogoutModal(true);
                setTimeout(() => {
                    dispatch(setAccessToken(null));
                }, 5000);
            }

            // Modal Logic
            const expirationThreshold = currentTimestamp + expiryNotificationThreshHold;
            const isTokenAboutToExpire = decodedToken.exp <= expirationThreshold;
            if (isTokenAboutToExpire && !modalNudged) {
                setModalShow(true);
                setModalNudged(true);
            }
        }, 1000);

        // Clean up the interval when the component unmounts
        return () => {
            clearInterval(interval);
        };
    }, [decodedToken, modalNudged, dispatch]);

    // Format the timer value as minutes and seconds
    const formatTimer = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const handleExtendSession = async () => {
        try {
            const authToken = (await extendSession()) as { token: string };
            dispatch(setAccessToken(authToken.token));
            setModalShow(false);
            setModalNudged(false);
        } catch (e) {
            dispatch(resetGlobalError());
            dispatch(handleGlobalError({ message: 'Something went wrong, logging you out right now', isError: true }));
        }
    };

    return (
        <nav className="navbar navbar-expand navbar-light navbar-bg">
            <a className="sidebar-toggle js-sidebar-toggle" onClick={props.handleSidebarCollapse}>
                <i className="hamburger align-self-center" />
            </a>
            <div className="navbar-collapse collapse">
                <ul className="navbar-nav navbar-align">
                    <li className="d-hide-sm login-details col-12 bg-header-light ">
                        <div className="row col-12 m-0 g-0 justify-content-end ">
                            <div className="col-6 p-2 text-end ">
                                <div className="d-inline-block mx-2 text-white">
                                    <strong>Last login:</strong> {moment(user.lastLogin).format('DD-MM-YYYY HH:mm')}
                                </div>

                                {user.role !== UserRole.customer && (
                                    <div className="d-inline-block mx-3">
                                        <UserOnlineStatus />
                                    </div>
                                )}
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="timer">
                            <FontAwesomeIcon className="me-2" icon={icon({ name: 'clock' })} size="lg" />

                            {formatTimer(timer)}
                        </div>
                    </li>
                    <NavDropdown
                        as="li"
                        title={
                            <>
                                <Avatar name={user.name} className="avatar img-fluid rounded me-1" size="36" />{' '}
                                <span className="text-dark username">{user.name}</span>
                            </>
                        }
                        id="basic-nav-dropdown"
                        className="dropdown-menu-right"
                    >
                        {user.role === UserRole.customer ? (
                            <NavDropdown.Item as={NavLink} to="/customer/account/profile">
                                <Icon.User className="align-middle me-1" data-feather="user" />
                                My Account
                            </NavDropdown.Item>
                        ) : (
                            <NavDropdown.Item as={NavLink} to="/user/profile">
                                <Icon.User className="align-middle me-1" data-feather="user" /> Profile
                            </NavDropdown.Item>
                        )}
                        {(user.role === UserRole.employee || user.role === UserRole.superUser) && (
                            <NavDropdown.Item as={NavLink} to="/help-center">
                                <Icon.HelpCircle className="align-middle me-1" data-feather="help-circle" /> Help Center
                            </NavDropdown.Item>
                        )}
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={onLogout}>Log out</NavDropdown.Item>
                    </NavDropdown>
                </ul>
            </div>
            <SessionTimeoutwarningModal
                showModal={showModal}
                handleClose={handleClose}
                handleExtendSession={handleExtendSession}
            />
            <LogoutModal showModal={logoutModal} />
            {user.role !== UserRole.customer && <PrivacyPopup user={user} />}
        </nav>
    );
};

export default Header;
