import React from 'react';
import * as Icon from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import vodichron from '../../assets/img/vodichron-og.png';
import './sidebar.scss';
import { ADMIN_USERS, CUSTOMER_USERS, EMP_MANAGERS, ORG_USERS, SUPER_USERS, UserRole } from '../../types/user';

type Props = {
    sidebarCollapsed: boolean;
    userRole: UserRole;
};

const Sidebar = (props: Props) => {
    const toggleSubMenu = (menu: string) => {
        const subMenu = document.getElementById(menu);
        subMenu?.classList.toggle('show');
        const tag = document.querySelector(`[data-bs-target='#${menu}']`);
        tag?.classList.toggle('collapsed');
        if (subMenu?.classList.contains('show')) {
            tag?.setAttribute('aria-expanded', 'false');
        } else {
            tag?.setAttribute('aria-expanded', 'true');
        }
    };
    const { sidebarCollapsed } = props;
    return (
        <nav
            id="sidebar"
            className={cn('sidebar js-sidebar', {
                collapsed: sidebarCollapsed,
            })}
        >
            <div className="sidebar-content js-simplebar">
                <NavLink className="sidebar-brand" to="/">
                    <span className="align-middle">
                        <img src={vodichron} alt="Logo" className="img-fluid" width="200" />
                    </span>
                </NavLink>

                <ul className="sidebar-nav">
                    <li className="sidebar-item">
                        <NavLink className="sidebar-link" to="/">
                            <FontAwesomeIcon size="lg" icon={icon({ name: 'dashboard' })} />{' '}
                            <span className="align-middle">Dashboard</span>
                        </NavLink>
                    </li>

                    {SUPER_USERS.includes(props.userRole) && (
                        <li className="sidebar-item">
                            <a
                                data-bs-target="#customers"
                                data-bs-toggle="collapse"
                                className="sidebar-link " //collapsed
                                aria-expanded="true"
                                onClick={() => toggleSubMenu('customers')}
                            >
                                <FontAwesomeIcon size="lg" icon={icon({ name: 'user-tie' })} />
                                <span className="align-middle">Customers</span>
                            </a>
                            <ul id="customers" className="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                                <li className="sidebar-item">
                                    <NavLink className="sidebar-link" to="/customer/register">
                                        <span className="align-middle">Register New</span>
                                    </NavLink>
                                </li>
                                <li className="sidebar-item">
                                    <NavLink className="sidebar-link" to="/customer/list">
                                        <span className="align-middle">Customer List</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                    )}
                    {ADMIN_USERS.includes(props.userRole) && (
                        <>
                            <li className="sidebar-item">
                                <a
                                    data-bs-target="#employees"
                                    data-bs-toggle="collapse"
                                    className="sidebar-link " //collapsed
                                    aria-expanded="true"
                                    onClick={() => toggleSubMenu('employees')}
                                >
                                    <Icon.Users className="feather align-middle" data-feather="sliders" />{' '}
                                    <span className="align-middle">Employees</span>
                                </a>
                                <ul
                                    id="employees"
                                    className="sidebar-dropdown list-unstyled collapse"
                                    data-bs-parent="#sidebar"
                                >
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/employee/register">
                                            <span className="align-middle">Register New</span>
                                        </NavLink>
                                    </li>
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/employee/list">
                                            <span className="align-middle">Employee List</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="sidebar-item">
                                <a
                                    data-bs-target="#users"
                                    data-bs-toggle="collapse"
                                    className="sidebar-link " //collapsed
                                    aria-expanded="true"
                                    onClick={() => toggleSubMenu('users')}
                                >
                                    <Icon.Key className="feather align-middle" data-feather="sliders" />{' '}
                                    <span className="align-middle">Application Access</span>
                                </a>
                                <ul id="users" className="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/user/list">
                                            <span className="align-middle">View Users</span>
                                        </NavLink>
                                    </li>
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/user/register/access">
                                            <span className="align-middle">Grant Access</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>

                            <li className="sidebar-item">
                                <a
                                    data-bs-target="#control-center"
                                    data-bs-toggle="collapse"
                                    className="sidebar-link " //collapsed
                                    aria-expanded="true"
                                    onClick={() => toggleSubMenu('control-center')}
                                >
                                    <Icon.Shield className="feather align-middle" data-feather="sliders" />{' '}
                                    <span className="align-middle">Control Center</span>
                                </a>
                                <ul
                                    id="control-center"
                                    className="sidebar-dropdown list-unstyled collapse"
                                    data-bs-parent="#sidebar"
                                >
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/control-center/manage-org-holidays">
                                            <span className="align-middle">Manage Org Holidays</span>
                                        </NavLink>
                                    </li>
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/control-center/data-hub">
                                            <span className="align-middle">Application Data Hub</span>
                                        </NavLink>
                                    </li>
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/control-center/leave-allocation">
                                            <span className="align-middle">Yearly Leave Allocations</span>
                                        </NavLink>
                                    </li>
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/control-center/employee-leave-allocation">
                                            <span className="align-middle">Employee Leave Allocation</span>
                                        </NavLink>
                                    </li>
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/control-center/employee-preferences">
                                            <span className="align-middle">Employee Preferences</span>
                                        </NavLink>
                                    </li>
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/control-center/survey-config">
                                            <span className="align-middle">Survey Form Configuration</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                        </>
                    )}
                    {[...ORG_USERS].includes(props.userRole) && (
                        <>
                            <li className="sidebar-item">
                                <a
                                    data-bs-target="#my-data"
                                    data-bs-toggle="collapse"
                                    className="sidebar-link " //collapsed
                                    aria-expanded="true"
                                    onClick={() => toggleSubMenu('my-data')}
                                >
                                    <Icon.Aperture className="feather align-middle" data-feather="sliders" />{' '}
                                    <span className="align-middle">Account</span>
                                </a>
                                <ul
                                    id="my-data"
                                    className="sidebar-dropdown list-unstyled collapse"
                                    data-bs-parent="#sidebar"
                                >
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/user/profile">
                                            <span className="align-middle">Profile</span>
                                        </NavLink>
                                    </li>
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/employee/documents">
                                            <span className="align-middle">Documents</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="sidebar-item">
                                <a
                                    data-bs-target="#manage-attendance"
                                    data-bs-toggle="collapse"
                                    className="sidebar-link " //collapsed
                                    aria-expanded="true"
                                    onClick={() => toggleSubMenu('manage-attendance')}
                                >
                                    <Icon.Calendar className="feather align-middle" data-feather="sliders" />{' '}
                                    <span className="align-middle">Manage Attendance</span>
                                </a>
                                <ul
                                    id="manage-attendance"
                                    className="sidebar-dropdown list-unstyled collapse"
                                    data-bs-parent="#sidebar"
                                >
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/employee/weekly-timesheet/list">
                                            <span className="align-middle">Weekly Timesheets</span>
                                        </NavLink>
                                    </li>
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/employee/timesheet/weekly/log-hours">
                                            <span className="align-middle">Submit Weekly Timesheet</span>
                                        </NavLink>
                                    </li>
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/employee/leaves/list">
                                            <span className="align-middle">Leaves</span>
                                        </NavLink>
                                    </li>
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/employee/leave/apply">
                                            <span className="align-middle">Apply Leave</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="sidebar-item">
                                <a
                                    data-bs-target="#excellence-center"
                                    data-bs-toggle="collapse"
                                    className="sidebar-link " //collapsed
                                    aria-expanded="true"
                                    onClick={() => toggleSubMenu('excellence-center')}
                                >
                                    <Icon.Award className="feather align-middle" data-feather="sliders" />{' '}
                                    <span className="align-middle">Excellence Center</span>
                                </a>
                                <ul
                                    id="excellence-center"
                                    className="sidebar-dropdown list-unstyled collapse"
                                    data-bs-parent="#sidebar"
                                >
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/mock-1">
                                            <span className="align-middle">Appraisals</span>
                                        </NavLink>
                                    </li>
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/employee/excellence-center/survey/submission">
                                            <span className="align-middle">Surveys</span>
                                        </NavLink>
                                    </li>
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/mock-1">
                                            <span className="align-middle">Grievances</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                        </>
                    )}

                    {[...ADMIN_USERS, ...EMP_MANAGERS].includes(props.userRole) && (
                        <li className="sidebar-item">
                            <a
                                data-bs-target="#manage-reportees"
                                data-bs-toggle="collapse"
                                className="sidebar-link " //collapsed
                                aria-expanded="true"
                                onClick={() => toggleSubMenu('manage-reportees')}
                            >
                                <Icon.Users className="feather align-middle" data-feather="sliders" />{' '}
                                <span className="align-middle">Manage Reportees</span>
                            </a>
                            <ul
                                id="manage-reportees"
                                className="sidebar-dropdown list-unstyled collapse"
                                data-bs-parent="#sidebar"
                            >
                                {EMP_MANAGERS.includes(props.userRole) && (
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/employee/reportee/list">
                                            <span className="align-middle">My Reportees</span>
                                        </NavLink>
                                    </li>
                                )}
                                <li className="sidebar-item">
                                    <NavLink className="sidebar-link" to="/employee/reportee/leaves/list">
                                        <span className="align-middle">Reportee Leaves</span>
                                    </NavLink>
                                </li>

                                <li className="sidebar-item">
                                    <NavLink className="sidebar-link" to="/employee/reportee/weekly-timesheet/list">
                                        <span className="align-middle">Reportee Timesheets</span>
                                    </NavLink>
                                </li>

                                {ADMIN_USERS.includes(props.userRole) && (
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/employee/documents/all">
                                            <span className="align-middle">Reportee Documents</span>
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}

                    {/* CUSTOMER LINKS */}
                    {CUSTOMER_USERS.includes(props.userRole) && (
                        <>
                            <li className="sidebar-item">
                                <a
                                    data-bs-target="#manage-resources"
                                    data-bs-toggle="collapse"
                                    className="sidebar-link " //collapsed
                                    aria-expanded="true"
                                    onClick={() => toggleSubMenu('manage-resources')}
                                >
                                    <Icon.Users className="feather align-middle" data-feather="sliders" />{' '}
                                    <span className="align-middle">Manage Resources</span>
                                </a>
                                <ul
                                    id="manage-resources"
                                    className="sidebar-dropdown list-unstyled collapse"
                                    data-bs-parent="#sidebar"
                                >
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/customer/account/allocated/resources">
                                            <span className="align-middle">Allocated Resources</span>
                                        </NavLink>
                                    </li>
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/customer/resource/applied/leaves/list">
                                            <span className="align-middle">Leave Requests</span>
                                        </NavLink>
                                    </li>
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/customer/resource/timesheet/submission/list">
                                            <span className="align-middle">Timesheet Submissions</span>
                                        </NavLink>
                                    </li>
                                    <li className="sidebar-item">
                                        <NavLink className="sidebar-link" to="/customer/resource/calendar">
                                            <span className="align-middle">Calendar</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                        </>
                    )}
                    {[...ORG_USERS].includes(props.userRole) && (
                        <li className="sidebar-item">
                            <NavLink className="sidebar-link" to="/org-policies">
                                <Icon.FileText className="feather align-middle" data-feather="sliders" />{' '}
                                <span className="align-middle">Org Policies</span>
                            </NavLink>
                        </li>
                    )}

                    {[...ADMIN_USERS].includes(props.userRole) && (
                        <li className="sidebar-item">
                            <NavLink className="sidebar-link" to="/employees/calendar">
                                <Icon.Calendar className="feather align-middle" data-feather="sliders" />{' '}
                                <span className="align-middle">Calendar</span>
                            </NavLink>
                        </li>
                    )}
                </ul>
            </div>
        </nav>
    );
};

export default Sidebar;
