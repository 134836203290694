// dataSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { MasterField } from '../types/orgData';

const dataSlice = createSlice({
    name: 'data',
    initialState: {} as { [x: string]: string[] },
    reducers: {
        setMasterFields: (state, action) => {
            action.payload.forEach((field: MasterField) => {
                state[field.name] = field.value;
            });
        },
    },
});

export const { setMasterFields } = dataSlice.actions;
export default dataSlice.reducer;
