import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { deleteEmployeeImage, getEmployeeImage } from '../../services/employeeDocuments';
import { useAppContext } from '../../hooks/useAppContext';

type ImageProp = {
    userId: string;
    showDeleteButton?: boolean;
};

export const EmployeeImage = (props: ImageProp) => {
    const [error, setError] = useState<string | undefined>(undefined);
    const [loadingData, setLoadingData] = useState(true);
    const { triggerSuccess } = useAppContext();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [image, setImage] = useState<any>();
    const deleteImage = async () => {
        try {
            setLoadingData(true);
            await deleteEmployeeImage(props.userId);
            setLoadingData(false);
            triggerSuccess(`Your photo was uploaded successfully`);
            setTimeout(() => window.location.reload(), 400);
        } catch (err: unknown) {
            setError('Unable to delete');
            setLoadingData(false);
        }
    };
    const fetchEmployeeImage = async () => {
        try {
            setLoadingData(true);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response: any = await getEmployeeImage(props.userId);
            const url = window.URL.createObjectURL(response);
            setImage(url);
            setLoadingData(false);
        } catch (err: unknown) {
            setError('Something went wrong');
            setLoadingData(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    useEffect(() => {
        if (props.userId) {
            setLoadingData(true);
            fetchEmployeeImage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.userId]);

    return (
        <div className="">
            {loadingData && (
                <div className="row mt-4">
                    <div className="col-sm-12 mt-2 mb-2 text-center">
                        <div className="spinner-border text-primary me-2" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            )}
            {error && <span>{error}</span>}
            {!loadingData && !error && <img className="p-2 border bg-white" src={image} width={180} height={160} />}
            {props.showDeleteButton && (
                <div className="text-end cursor mt-2">
                    <button className="btn btn-primary btn-sm" title="Delete" onClick={deleteImage}>
                        <FontAwesomeIcon className="icons" size="sm" icon={icon({ name: 'trash-can' })} /> Delete Photo
                    </button>
                </div>
            )}
        </div>
    );
};
