import { request } from '../api/';
import { CustomerDashboardMeta, EmployeeDashboardMeta, SuperUserDashBoardMeta } from '../types/dashboard';

export const getSuperUserDashboardData = async (employeeId: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/dashboard/data/${employeeId}`,
    };
    return request<{ data: { dashboardData: SuperUserDashBoardMeta } }>(requestOptions);
};

export const getManagerDashboardData = async (employeeId: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/manager-dashboard/data/${employeeId}`,
    };
    return request<{ data: { dashboardData: SuperUserDashBoardMeta } }>(requestOptions);
};

export const getEmployeeDashboardData = async (employeeId: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee-dashboard/data/${employeeId}`,
    };
    return request<{ data: { dashboardData: EmployeeDashboardMeta } }>(requestOptions);
};

export const getCustomerDashboardData = async (customerId: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/customer-dashboard/data/${customerId}`,
    };
    return request<{ data: { dashboardData: CustomerDashboardMeta } }>(requestOptions);
};
