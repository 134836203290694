// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.control-center .control-center-container {
  border: 1ps solid #ccc;
}
.control-center .data-row ul {
  list-style: none;
}
.control-center .data-row ul li {
  padding: 10px;
  background: #c6d2e5;
  border-bottom: 2px solid #eaebf2;
  color: #26292e;
  font-weight: 500;
}
.control-center .data-row ul li.active {
  background: #636683;
  color: #ffffff;
}
.control-center .data-row ul li:last-child {
  border-bottom: 0px;
}
.control-center .data-row .field-col {
  background: #636683;
}
.control-center .data-row .field-col .field-values {
  padding: 5px;
  color: white;
  border-bottom: 1px solid rgba(240, 241, 245, 0.29);
}
.control-center .data-row .field-col .field-values:last-child {
  border-bottom: 0px;
}
.control-center .data-row .field-value-container {
  max-height: 400px;
  overflow-y: scroll;
}`, "",{"version":3,"sources":["webpack://./src/components/ControlCenter/ControlCenter.scss"],"names":[],"mappings":"AAEI;EACI,sBAAA;AADR;AAKQ;EACI,gBAAA;AAHZ;AAIY;EACI,aAAA;EACA,mBAAA;EACA,gCAAA;EACA,cAAA;EACA,gBAAA;AAFhB;AAKY;EACI,mBAAA;EACA,cAAA;AAHhB;AAMY;EACI,kBAAA;AAJhB;AAQQ;EACI,mBAAA;AANZ;AAOY;EACI,YAAA;EACA,YAAA;EACA,kDAAA;AALhB;AAOY;EACI,kBAAA;AALhB;AASQ;EACI,iBAAA;EACA,kBAAA;AAPZ","sourcesContent":[".control-center {\n\n    .control-center-container {\n        border: 1ps solid #ccc;\n    }\n\n    .data-row{\n        ul {\n            list-style: none;\n            li {\n                padding: 10px;\n                background: #c6d2e5;\n                border-bottom: 2px solid #eaebf2;\n                color: #26292e;\n                font-weight: 500;\n            }\n\n            li.active {\n                background: #636683;\n                color: #ffffff;\n            }\n\n            li:last-child {\n                border-bottom: 0px;\n            }\n        }\n\n        .field-col {\n            background: #636683;\n            .field-values {\n                padding: 5px;\n                color: white;\n                border-bottom: 1px solid rgba(240, 241, 245, 0.29);\n            }\n            .field-values:last-child {\n                border-bottom: 0px;\n            }\n        }\n       \n        .field-value-container {\n            max-height: 400px;\n            overflow-y: scroll;\n        }\n    }\n   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
