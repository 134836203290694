/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IRootState, useSelector } from '../../../store';
import { UserProfile } from '../../../types/user';
import EmployeeWeeklyTimesheetList from './EmployeeWeeklyTimesheetList';

const EmployeeWeeklyTimesheetListContainer = () => {
    const { id } = useParams();
    const [listViewProp, setListViewProp] = useState({
        id: '',
        approverView: false,
        employeeName: '',
    });
    const loggedInUser = useSelector((state: IRootState) => state.user) as UserProfile;

    useEffect(() => {
        if (loggedInUser.uuid) {
            if (id) {
                let employeeName = '';
                if (history.state.usr) {
                    employeeName = history.state.usr.employeeName;
                }
                setListViewProp({ id, approverView: true, employeeName });
            } else {
                setListViewProp({ id: loggedInUser.uuid, approverView: false, employeeName: '' });
            }
        }
    }, [loggedInUser.uuid, id]);

    return (
        <>
            {listViewProp.id != '' && (
                <EmployeeWeeklyTimesheetList
                    employeeId={listViewProp.id}
                    approverView={listViewProp.approverView}
                    employeeName={listViewProp.employeeName}
                />
            )}
        </>
    );
};

export default EmployeeWeeklyTimesheetListContainer;
