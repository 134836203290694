import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type Prop = {
    message: string;
};
export const HelpTip = (prop: Prop) => {
    const tooltip = <Tooltip id="tooltip">{prop.message}</Tooltip>;
    return (
        <OverlayTrigger placement="top" overlay={tooltip}>
            <FontAwesomeIcon className="text-info cursor-pointer" icon={icon({ name: 'circle-info' })} />
        </OverlayTrigger>
    );
};
