/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Tabs, Tab, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useAppContext } from '../../../hooks/useAppContext';
import { deleteUser, getApplicationUserdetails, updateUserData } from '../../../services/user';
import { IRootState, useSelector } from '../../../store';
import { ADMIN_USERS, UserAccountStatus, UserProfile, UserRole } from '../../../types/user';
import { userFormValidateInUpdateFlow } from './UserFormValidation';
import './ApplicationAccess.scss';

const UserAccessUpdateForm = () => {
    const { id } = useParams();
    const [key, setKey] = useState('1');
    const loggedInUser = useSelector((state: IRootState) => state.user) as UserProfile;

    const globalError = useSelector((state: IRootState) => state.error);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteTriggered, setDeleteTriggered] = useState(false);

    const [showDeActivationConfirmation, setShowDeActivationConfirmation] = useState(false);
    const [deActivationTriggered, setDeActivationTriggered] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const navigate = useNavigate();
    const { triggerSuccess } = useAppContext();
    const pristineForm = {
        employeeId: '',
        role: '' as UserRole,
        password: '',
        confirmPassword: '',
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [formData, setFormData] = useState<any>(pristineForm);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultError = {} as any;
    const [errors, setErrors] = useState(defaultError);

    const fetchAppUserDetails = useCallback(
        async () => {
            setLoadingData(true);
            const user = await getApplicationUserdetails(id as string);
            setFormData({ ...user.data });
            setLoadingData(false);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        fetchAppUserDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
        setLoading(false);
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        setLoading(true);
        e.preventDefault();
        setErrors(defaultError);
        const validateErrors = userFormValidateInUpdateFlow(formData);
        if (Object.keys(validateErrors).length === 0) {
            const data = {
                ...formData,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any;
            try {
                // TODO: Need to clean up for handling this better
                await updateUserData(data);
                triggerSuccess(`Application user updated successfully`);
                setFormData(pristineForm);
                navigate('/user/list');
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (_e: any) {
                setLoading(false);
            }
        } else {
            setLoading(false);
            setErrors(validateErrors);
        }
    };

    const handleDeleteConfirm = async () => {
        //deleting now
        setDeleteTriggered(true);
        await deleteUser(id as string);
        triggerSuccess(`User access Rremoved successfully`);
        setDeleteTriggered(false);
        setShowDeleteConfirmation(false);
        navigate('/user/list');
    };

    const handleDeActivateConfirm = async () => {
        setLoading(true);
        //deleting now
        setDeActivationTriggered(true);
        try {
            const data = {
                ...formData,
                status: UserAccountStatus.INACTIVE,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any;
            // TODO: Need to clean up for handling this better
            await updateUserData(data);
            triggerSuccess(`User deactivated successfully`);
            setDeActivationTriggered(false);
            setShowDeActivationConfirmation(false);
            fetchAppUserDetails();
            setLoading(false);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (_e: any) {
            setLoading(false);
        }
    };

    const activateUserAccount = async () => {
        try {
            setLoading(true);
            const data = {
                ...formData,
                status: UserAccountStatus.ACTIVE,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any;
            // TODO: Need to clean up for handling this better
            await updateUserData(data);
            triggerSuccess(`User activated successfully`);
            fetchAppUserDetails();
            setLoading(true);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (_e: any) {
            setLoading(false);
        }
    };

    return (
        <main className="content user-registration">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon icon={icon({ name: 'pencil' })} /> Update Access
                        </h3>
                    </div>
                    <div className="col-auto ms-auto text-end mt-n1">
                        <NavLink to={`/user/list`} className="btn btn-primary">
                            <FontAwesomeIcon icon={icon({ name: 'angle-left' })} className="far fa-fw fa-bell" /> Back
                        </NavLink>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-info">
                                <p>You can change the role of the employee through this form.</p>
                                <p className="mb-0">
                                    <strong>Note:</strong>
                                </p>
                                <ul>
                                    <li>Employee can loose or get higher access when roles are changed.</li>
                                    <li>Enter password only if you wish to change it. </li>
                                    <li>If you DO NOT wish to change the password, keep it blank.</li>
                                </ul>
                            </div>
                            <div className="card-body col-8">
                                {Object.keys(errors).length > 0 && (
                                    <p className="error-message">
                                        <FontAwesomeIcon className="me-2" icon={icon({ name: 'warning' })} /> Looks like you
                                        have some errors on the form, check all the tabs before saving the details.
                                    </p>
                                )}

                                {loadingData && (
                                    <div key={0} className="row">
                                        <div className="col-sm-12 mt-2 mb-2 text-center">
                                            <div className="spinner-border text-primary me-2" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {!loadingData && Object.keys(formData).length > 0 && (
                                    <>
                                        {' '}
                                        <div className="col-md-12  mb-3 text-start">
                                            {[...ADMIN_USERS].includes(loggedInUser.role) ? (
                                                <>
                                                    <button
                                                        className="btn btn-danger me-2"
                                                        onClick={() => setShowDeleteConfirmation(true)}
                                                    >
                                                        <FontAwesomeIcon
                                                            className="icons"
                                                            size="lg"
                                                            icon={icon({ name: 'xmark' })}
                                                        />
                                                        <strong className="p-2 ">Remove User Access</strong>
                                                    </button>

                                                    {formData.status === UserAccountStatus.ACTIVE ? (
                                                        <button
                                                            className="btn btn-warning text-dark ms-2"
                                                            onClick={() => setShowDeActivationConfirmation(true)}
                                                        >
                                                            <FontAwesomeIcon
                                                                className="icons"
                                                                size="lg"
                                                                icon={icon({ name: 'ban' })}
                                                            />
                                                            <strong className="p-2 ">Deactivate Account</strong>
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-success s-2"
                                                            onClick={activateUserAccount}
                                                        >
                                                            <FontAwesomeIcon
                                                                className="icons"
                                                                size="lg"
                                                                icon={icon({ name: 'check' })}
                                                            />
                                                            <strong className="p-2 ">Activate Account</strong>
                                                        </button>
                                                    )}
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <Tabs activeKey={key} onSelect={(k) => k && setKey(k)}>
                                            <Tab eventKey={'1'} title="Application Access">
                                                <form>
                                                    <div className="tab-content">
                                                        <label>
                                                            {' '}
                                                            <strong className="me-2">Updating details for:</strong>{' '}
                                                            {formData.name}
                                                        </label>
                                                        <hr />
                                                        <label className="mt-2">
                                                            Role <span className="required-field">*</span>
                                                        </label>
                                                        <select
                                                            value={formData.role}
                                                            name="role"
                                                            onChange={handleChange}
                                                            className={`form-control form-control-lg mb-3 ${
                                                                errors.role ? 'is-invalid' : ''
                                                            }`}
                                                        >
                                                            <option value="0">Select user role</option>
                                                            {loggedInUser.role === UserRole.superUser && (
                                                                <option value="super_user">Super User</option>
                                                            )}
                                                            <option value="hr">HR Executive</option>
                                                            <option value="employee">Employee</option>
                                                            <option value="manager">Manager</option>
                                                            <option value="director">Director</option>
                                                        </select>
                                                        {errors.role && (
                                                            <div className="invalid-feedback mb-3">{errors.role}</div>
                                                        )}
                                                        <label>Password</label>
                                                        <input
                                                            className={`form-control form-control-lg mb-3 ${
                                                                errors.password ? 'is-invalid' : ''
                                                            }`}
                                                            type="password"
                                                            name="password"
                                                            value={formData.password}
                                                            autoComplete="false"
                                                            onChange={handleChange}
                                                        />
                                                        {errors.password && (
                                                            <div className="invalid-feedback mb-3">{errors.password}</div>
                                                        )}
                                                        <label>Confirm Password</label>
                                                        <input
                                                            className={`form-control form-control-lg mb-3 ${
                                                                errors.confirmPassword ? 'is-invalid' : ''
                                                            }`}
                                                            type="password"
                                                            name="confirmPassword"
                                                            value={formData.confirmPassword}
                                                            onChange={handleChange}
                                                            autoComplete="false"
                                                        />
                                                        {errors.confirmPassword && (
                                                            <div className="invalid-feedback mb-3">
                                                                {errors.confirmPassword}
                                                            </div>
                                                        )}

                                                        <div className="text-end mt-2">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={handleSubmit}
                                                            >
                                                                Save Details{' '}
                                                                {loading && !globalError.isError && (
                                                                    <div
                                                                        className="spinner-border spinner-border-sm   me-2"
                                                                        role="status"
                                                                    >
                                                                        <span className="visually-hidden">Loading...</span>
                                                                    </div>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </Tab>
                                        </Tabs>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showDeleteConfirmation} centered={true} onHide={() => setShowDeleteConfirmation(false)}>
                {!deleteTriggered ? (
                    <>
                        {' '}
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} size="lg" />
                                <span className="ms-2">Remove Application User Access</span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                You are about to remove the application access of <strong>{formData.name}</strong>
                            </p>
                            <p>
                                In case you need to grant access for this user again, you can do so by adding the user again.
                            </p>
                            <p>Do you wish to continue?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleDeleteConfirm}>
                                Remove Access
                            </Button>
                        </Modal.Footer>
                    </>
                ) : (
                    <>
                        {' '}
                        <Modal.Header>
                            <Modal.Title>
                                <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} size="lg" />
                                <span className="ms-2">Remove Application User Access</span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h3 className="text-center">Your request is being processed</h3>
                            <p className="text-center">
                                <div className="spinner-border spinner-border-sm me-2" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </p>
                        </Modal.Body>
                    </>
                )}
            </Modal>

            <Modal show={showDeActivationConfirmation} centered={true} onHide={() => setShowDeActivationConfirmation(false)}>
                {!deActivationTriggered ? (
                    <>
                        {' '}
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} size="lg" />
                                <span className="ms-2">Deactivate User Access</span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                You are about to deactivate application access for <strong>{formData.name}</strong>
                            </p>
                            <p>
                                Once you deactivate the application access for this user, he or she will not be able to
                                access the application.
                            </p>

                            <p>
                                <strong>Don&apos;t worry</strong>, by doing this user will still be part of the user access
                                list and you can re-activate the user any time again. You should be able to see a{' '}
                                <strong>Activate Account</strong> button on <strong>Update Access</strong> screen.
                            </p>
                            <p>Do you wish to continue?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleDeActivateConfirm}>
                                Deactivate User
                            </Button>
                        </Modal.Footer>
                    </>
                ) : (
                    <>
                        {' '}
                        <Modal.Header>
                            <Modal.Title>
                                <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} size="lg" />
                                <span className="ms-2">Remove Application User Access</span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h3 className="text-center">Your request is being processed</h3>
                            <p className="text-center">
                                <div className="spinner-border spinner-border-sm me-2" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </p>
                        </Modal.Body>
                    </>
                )}
            </Modal>
        </main>
    );
};

export default UserAccessUpdateForm;
