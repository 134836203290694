import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { icon as fIcon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NavLink } from 'react-router-dom';
import { objectToPlainString } from '../../helper/stringHelper';

type DataItem = Record<string, string | number>; // Adjust this type based on the actual structure of your data items

type GenericCardProps = {
    label: string;
    note: string;
    data: DataItem[] | string[];
    icon: IconProp;
    actionLink?: string;
    bgClass?: string;
};
const ReminderCard: React.FC<GenericCardProps> = ({ label, note, data, icon, actionLink, bgClass }) => {
    return (
        <div className={`${bgClass} card flex-fill pb-3`}>
            <div className="card-header">
                {actionLink && (
                    <div className="card-actions float-end">
                        <NavLink to={actionLink} className="btn btn-sm btn-light">
                            View All
                        </NavLink>
                    </div>
                )}
                <h5 className="card-title mb-0">
                    <FontAwesomeIcon
                        size="sm"
                        icon={icon as IconProp}
                        className="far fa-fw fa-bell p-2 bg-light"
                        style={{ borderRadius: '50%', verticalAlign: 'middle' }}
                    />{' '}
                    {label}
                </h5>
                <span className="text-muted ps-3">{note}</span>
            </div>

            <div className="card-body pt-0 fixed-height-col">
                {data.length > 0 ? (
                    <table className="table table-sm table-striped my-0">
                        <thead>
                            <tr>
                                {Object.keys(data[0]).map((key) => (
                                    <th key={key}>{objectToPlainString(key)}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    {Object.values(item).map((value, i) => (
                                        <td key={i}>
                                            <FontAwesomeIcon
                                                size="sm"
                                                icon={fIcon({ name: 'circle' })}
                                                className="far fa-fw fa-bell fa-beat text-danger p-2"
                                                style={{ borderRadius: '50%', verticalAlign: 'middle' }}
                                            />{' '}
                                            {value}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className="p-3 fw-bold mt-4 text-center bg-light">No data available</div>
                )}
            </div>
        </div>
    );
};

export default ReminderCard;
