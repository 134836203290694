// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container .brand {
  font-family: goldman;
}
.login-container .card {
  background-color: #DEDEDE;
}
.login-container .lead {
  color: #374151;
}
.login-container .error {
  color: #f66c6c;
  text-align: center;
  background: #374151;
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/Login/Login.scss"],"names":[],"mappings":"AAEI;EACI,oBAAA;AADR;AAII;EACI,yBAAA;AAFR;AAKI;EACI,cAAA;AAHR;AAMI;EACI,cAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;AAJR","sourcesContent":["\n.login-container{\n    .brand{\n        font-family:goldman;\n    }\n\n    .card {\n        background-color: #DEDEDE;\n    }\n\n    .lead {\n        color: #374151;\n    }\n\n    .error {\n        color: #f66c6c;\n        text-align: center;\n        background: #374151;\n        padding: 5px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
