import { AlphaNumericRegex } from '../../constants/validations';
import { calculateLeaveDays } from '../../helper/dateAndTime';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const applyLeaveFormValidate = (formData: any, leaveCategory: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tempErrors = {} as any;
    if (!formData[`${leaveCategory}LeaveType`] || formData[`${leaveCategory}LeaveType`] === '0') {
        tempErrors[`${leaveCategory}LeaveType`] = 'Leave type is a required field';
    }

    if (!formData[`${leaveCategory}LeaveReason`]) {
        tempErrors[`${leaveCategory}LeaveReason`] = 'Please add some comment on reason for leave';
    } else if (formData[`${leaveCategory}LeaveReason`].length > 100) {
        tempErrors[`${leaveCategory}LeaveReason`] = 'Reason comments too long.';
    } else if (!AlphaNumericRegex.test(formData[`${leaveCategory}LeaveReason`])) {
        tempErrors[`${leaveCategory}LeaveReason`] = 'Reason should only contain alphanumeric characters only';
    }

    if (!formData[`${leaveCategory}LeaveStartDate`]) {
        tempErrors[`${leaveCategory}LeaveStartDate`] = 'Start date is a required field';
    }
    if (!formData[`${leaveCategory}SecondaryAppoverId`]) {
        tempErrors[`${leaveCategory}SecondaryAppoverId`] = 'Add secondary approver';
    }

    if (!formData[`${leaveCategory}LeaveEndDate`]) {
        tempErrors[`${leaveCategory}LeaveEndDate`] = 'End date is a required field';
    }
    if (leaveCategory === 'basic' && formData[`${leaveCategory}HalfDay`]) {
        const leaveDays = calculateLeaveDays(
            formData[`${leaveCategory}LeaveStartDate`],
            formData[`${leaveCategory}LeaveEndDate`]
        );
        if (leaveDays > 1) {
            tempErrors[`${leaveCategory}LeaveEndDate`] = 'When applying for half day, end date should be same as start date';
        }
    }
    return tempErrors;
};
