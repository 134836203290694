import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { CustomerResourceAllocation } from '../../../types/customer';
import { EmployeeImage } from '../../EmployeeImage/EmployeeImage';
import { HelpTip } from '../../HelpTip/HelpTip';
import { ADMIN_USERS, CUSTOMER_USERS, UserRole } from '../../../types/user';

type Props = {
    resourceAllocations: CustomerResourceAllocation[];
    isCustomerView: boolean;
    loggedInUserRole: UserRole;
};

export const AllocatedResourceList = (props: Props) => {
    const { resourceAllocations, isCustomerView, loggedInUserRole } = props;
    return resourceAllocations.length > 0 ? (
        <div className="row m-auto g-0 col-md-12  ">
            {resourceAllocations.map((resource) => (
                <div key={resource.uuid} className="card col-md-3 m-2 pb-3 position-relative">
                    {[...CUSTOMER_USERS].includes(loggedInUserRole) && resource.status === 'INACTIVE' && (
                        <div className="card-img-overlay  text-center col-12 bg-light bg-opacity-50">
                            <div className="col-12 fw-bold bg-dark-light text-white py-2 p-3">DE-ALLOCATED</div>
                        </div>
                    )}
                    {[...ADMIN_USERS].includes(loggedInUserRole) && resource.status === 'INACTIVE' && (
                        <div className="col-12 fw-bold bg-dark-light text-white py-2 p-3">DE-ALLOCATED</div>
                    )}
                    <div className="col-12 p-3 text-center">
                        <EmployeeImage userId={resource.employeeId} showDeleteButton={false} />
                    </div>
                    <div className="col-12 p-3 py-0 mb-1 text-center">
                        <strong>{resource.employeeName}</strong>
                    </div>
                    <div className="col-12 p-3 py-1 text-start">
                        <strong>Allocation Code: </strong>
                        {resource.allocationCode}
                    </div>
                    <div className="col-12 p-3 py-1 text-start">
                        <strong>Allocation Role: </strong>
                        {resource.role}
                    </div>
                    <div className="col-12 p-3 py-1 text-start">
                        <strong>Start Date: </strong>
                        {moment(resource.startDate).format('DD/MM/YYYY')}
                    </div>
                    <div className="col-12 p-3 py-1 text-start">
                        <strong>End Date: </strong>
                        {moment(resource.endDate).format('DD/MM/YYYY')}
                    </div>

                    <div className="col-12 p-3 py-1 text-start">
                        <strong>
                            Customer As Approver{' '}
                            <HelpTip message={`Customer marked as approver for leaves, timesheet etc.`} />:
                        </strong>
                        {resource.customerApprover ? (
                            <FontAwesomeIcon
                                className=" text-success px-3 "
                                size="lg"
                                icon={icon({ name: 'circle-check' })}
                            />
                        ) : (
                            <FontAwesomeIcon
                                className=" text-danger px-3  "
                                size="lg"
                                icon={icon({ name: 'circle-xmark' })}
                            />
                        )}
                    </div>
                    {!isCustomerView && (
                        <div className="col-12 my-2 text-center">
                            <NavLink
                                className={cn('btn btn-primary', {
                                    'btn-warning': resource.status === 'INACTIVE',
                                })}
                                to={`/customer/update/resource/allocation/${resource.uuid}`}
                            >
                                <FontAwesomeIcon size="lg" icon={icon({ name: 'edit' })} /> Update Allocation
                            </NavLink>
                        </div>
                    )}
                    {isCustomerView && (
                        <div className="col-12 my-2 text-center">
                            <NavLink
                                to={`/employee/details/${resource.employeeId}`}
                                title="View Details"
                                className="me-2 btn btn-primary"
                            >
                                View Details
                            </NavLink>
                        </div>
                    )}
                </div>
            ))}
        </div>
    ) : (
        <div className="text-center">
            <strong>RESOURCES ARE NOT ALLOCATED YET</strong>
        </div>
    );
};
