import React from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

type LogoutModalProp = {
    showModal: boolean;
};
export const LogoutModal = (prop: LogoutModalProp) => {
    return (
        <Modal show={prop.showModal} centered={true}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} size="lg" />
                    <span className="ms-2"> Session Timed Out</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>You session is expired</p>
                <p>
                    You will be logged out now.
                    <div className="spinner-border spinner-border-sm   me-2" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </p>
            </Modal.Body>
        </Modal>
    );
};
