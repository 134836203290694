/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import * as Icon from 'react-feather';
import { Tabs, Tab, Modal, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { SurveyConfig, SurveyFormConfig } from '../../../types/survey';
import { deleteSurveyForm, getCustomerSurveyForms, getEmployeeSurveyForms } from '../../../services/controlCenter';
import { useAppContext } from '../../../hooks/useAppContext';
import { SurveyFormDetail } from './SurveyFormDetail';
import SurveyFormList from './SurveyFormList';

const SurveyFormConfigure = () => {
    const { triggerSuccess } = useAppContext();
    const [employeeSurveyForms, setEmployeeSurveyForms] = useState<SurveyConfig[]>([]);
    const [customerSurveyForms, setCustomerSurveyForms] = useState<SurveyConfig[]>([]);
    const [selectedSurveyQuestion, setSelectedSurveyQuestion] = useState<SurveyFormConfig[]>([]);
    const [showSurveyFormModal, setShowSurveyFormModal] = useState(false);
    const [key, setKey] = useState('1');
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const handleTabSelect = (k: string | null) => {
        if (k) {
            setKey(k);
        }
    };
    const showSurveyQuestionnaire = (index: number, type: string) => {
        if (type === 'EMPLOYEE') {
            setSelectedSurveyQuestion(employeeSurveyForms[index].formDetails);
        } else {
            setSelectedSurveyQuestion(customerSurveyForms[index].formDetails);
        }
        setShowSurveyFormModal(true);
    };
    const closeModal = () => {
        setSelectedSurveyQuestion([]);
        setShowSurveyFormModal(false);
    };
    const getFormDetails = async () => {
        setLoading(true);
        try {
            const [employeeSurvey, customerSurvey] = await Promise.all([getEmployeeSurveyForms(), getCustomerSurveyForms()]);
            if (employeeSurvey.data.survey.length > 0) {
                setEmployeeSurveyForms(employeeSurvey.data.survey);
            }
            if (customerSurvey.data.survey.length > 0) {
                setCustomerSurveyForms(customerSurvey.data.survey);
            }
        } catch (err: unknown) {
            setError('Something went wrong');
            setLoading(false);
        }

        setLoading(false);
    };
    const deleteFormDetails = async (id: string) => {
        setLoading(true);
        try {
            await deleteSurveyForm(id);
            await getFormDetails();
            triggerSuccess(`Survey deleted successfully`);
        } catch (err: unknown) {
            setError('Something went wrong');
            setLoading(false);
        }
        setLoading(false);
    };

    useEffect(() => {
        getFormDetails();
    }, []);

    return (
        <main className="content user-registration">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-control-center-info">
                                {' '}
                                <p>
                                    <FontAwesomeIcon className="me-3" size="lg" icon={icon({ name: 'shield-alt' })} />{' '}
                                    Application Control Center{' '}
                                    <FontAwesomeIcon className="me-3 ms-3" icon={icon({ name: 'chevron-right' })} />
                                    Survey Configuration
                                </p>
                            </div>
                            <div className="card-body col-12">
                                <div className="container bg-light p-3">
                                    <p>
                                        Survey configuration helps you to create dynamic survey forms which are crucials for
                                        getting better insights from Employees and Customers.
                                    </p>
                                    <ul>
                                        <li>
                                            <strong>Dynamic configuration:</strong>
                                            <ul>
                                                <li>You can create as many survey forms with different set of questions.</li>
                                                <li>
                                                    Only one survey form will be enable for employee and a customer at a
                                                    given time.
                                                </li>
                                                <li>
                                                    Form will be automatically enabled between the start and end dates
                                                    (including both the date)
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Visibility for Employees:</strong>
                                            <ul>
                                                <li>
                                                    Once the survey form for employee is enabled, employee will start getting
                                                    reminders to fill the survey everyday untill they fill the survey.
                                                </li>
                                                <li>
                                                    Employees will also see the notifcation on the application when the
                                                    login.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Visibility for Customers:</strong>
                                            <ul>
                                                <li>Survey form can be created for customers as well.</li>
                                                <li>
                                                    Form will be automatically enabled between the start and end dates
                                                    (including both the date)
                                                </li>
                                                <li>
                                                    As customer would have to fill survey for all resources allocated,
                                                    customer will get one generic mail to fill survey.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-12 p-3 text-center text-md-end">
                                    <NavLink className="btn btn-primary" to={`/control-center/add/survey-form`}>
                                        <FontAwesomeIcon size="lg" icon={icon({ name: 'plus' })} /> Add Survey Form
                                    </NavLink>
                                </div>
                                <div className="col-12 py-2 p-3">
                                    {loading && (
                                        <div className="row mt-4">
                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                <div className="spinner-border text-primary me-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {error && (
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div
                                                    className="alert alert-danger alert-outline alert-dismissible"
                                                    role="alert"
                                                >
                                                    <div className="alert-icon">
                                                        <Icon.Bell className="far fa-fw fa-bell" />
                                                    </div>
                                                    <div className="alert-message">
                                                        <strong>Error</strong>Something went wrong when trying to get survey
                                                        configurations
                                                        <br /> If the problem persists, please contact support.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {!error && !loading && (
                                        <Tabs activeKey={key} onSelect={handleTabSelect}>
                                            <Tab eventKey={'1'} title="Employee Survey">
                                                <SurveyFormList
                                                    surveyList={employeeSurveyForms}
                                                    showSurveyQuestionnaire={showSurveyQuestionnaire}
                                                    deleteFormDetails={deleteFormDetails}
                                                    surveyType="EMPLOYEE"
                                                />
                                            </Tab>
                                            <Tab eventKey={'2'} title="Customer Survey">
                                                <SurveyFormList
                                                    surveyList={customerSurveyForms}
                                                    showSurveyQuestionnaire={showSurveyQuestionnaire}
                                                    deleteFormDetails={deleteFormDetails}
                                                    surveyType="CUSTOMER"
                                                />
                                            </Tab>
                                        </Tabs>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal size="lg" show={showSurveyFormModal} centered={true} onHide={() => closeModal()}>
                <>
                    {' '}
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FontAwesomeIcon icon={icon({ name: 'info-circle' })} size="lg" />
                            <span className="ms-2">Survey Form Questionnaire</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SurveyFormDetail surveyQuestion={selectedSurveyQuestion} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModal()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </>
            </Modal>
        </main>
    );
};

export default SurveyFormConfigure;
