import { OnProgress, request } from '../api';
import { PageLimit } from '../constants/data';
import { EmployeeDocuments, EmployeeDocumentsFilters } from '../types/employee';

export const uploadEmplyeeDocuments = async (file: File, userId: string, documentType: string, onProgress: OnProgress) => {
    // eslint-disable-next-line no-console
    const formData = new FormData();
    formData.append('fileupload', file);
    formData.append('userId', userId);
    formData.append('documentType', documentType);
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/document/upload`,
        onProgress,
        file: formData,
        noContentType: true,
    };

    return request(requestOptions);
};

export const getEmployeeDocument = async (uuid: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/documents/${uuid}`,
    };
    return request<{ data: EmployeeDocuments[] }>(requestOptions);
};

export const deleteEmployeeDocument = async (empId: string, docId: string) => {
    const requestOptions = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/document/${empId}/${docId}`,
    };
    return request<{ data: EmployeeDocuments[] }>(requestOptions);
};

export const downloadEmployeeDocument = async (empId: string, docId: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/document/download/${empId}/${docId}`,
    };
    return request(requestOptions);
};

export const getAllEmployeeDocuments = async (page: number, uuid: string, filters: EmployeeDocumentsFilters = {}) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/all/documents`,
        data: {
            pagination: {
                page,
                pageLimit: PageLimit,
            },
            filters,
        },
    };
    return request<{ data: EmployeeDocuments[] }>(requestOptions);
};

export const updateDocumentStatus = async (
    documentId: string,
    comment: string,
    approvalStatus: 'REQUESTED' | 'APPROVED' | 'REJECTED'
) => {
    const requestOptions = {
        method: 'PATCH',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/document/approve/${documentId}`,
        data: {
            comment,
            approvalStatus,
        },
    };
    return request(requestOptions);
};

export const getEmployeeImage = async (empId: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/image/${empId}`,
    };
    return request(requestOptions);
};

export const deleteEmployeeImage = async (empId: string) => {
    const requestOptions = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/image/${empId}`,
    };
    return request(requestOptions);
};
