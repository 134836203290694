import { request } from '../api/';
import { InsertEmployeeResult, SurveyConfig } from '../types/survey';

export const getLiveSurvey = async () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/survey/live`,
    };
    return request<{
        data: {
            survey: SurveyConfig | null;
        };
    }>(requestOptions);
};

export const checkIfSurveySubmitted = async (employeeId: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/is/submitted/${employeeId}`,
    };
    return request<{
        data: boolean;
    }>(requestOptions);
};

export const submitEmployeeSurveyResult = async (surveyResult: InsertEmployeeResult) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/survey/submit`,
        data: {
            ...surveyResult,
        },
    };
    return request(requestOptions);
};
