/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IRootState } from '../../store';
import { passwordResetRequest, validateResetLink } from '../../services/user';
import './Login.scss';
import { PasswordRegex } from '../../constants/validations';

const ResetPassword = () => {
    const params = useParams();
    // eslint-disable-next-line no-console
    const { sec, d } = params;
    const globalError = useSelector((state: IRootState) => state.error);
    const [loading, setLoading] = useState(false);
    const [validating, setValidating] = useState(false);
    const [email, setEmail] = useState<null | string>(null);
    const [error, setError] = useState<string>('');
    const [submitted, setSubmitted] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [formData, setFormData] = useState<any>({});
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultError = {} as any;
    const [formErrors, setFormErrors] = useState(defaultError);

    const validateForm = () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const tempErrors = {} as any;
        if (!PasswordRegex.test(formData.password)) {
            tempErrors.password = 'Check above criteria to set a ideal password';
        }

        if (formData.password !== formData.confirmPassword) {
            tempErrors.confirmPassword = 'Confirm password not matched';
        }
        return tempErrors;
    };
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
        setLoading(false);
        const value = e.target.value;
        setFormData({ ...formData, [e.target.name]: value });
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            setSubmitted(false);
            setError('');
            const validationErrors = validateForm();
            if (Object.keys(validationErrors).length > 0) {
                setFormErrors(validationErrors);
            } else {
                setFormErrors(defaultError);
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                await passwordResetRequest(email!, formData.password, d!);
                setError('Password reset request submitted');
                setSubmitted(true);
            }
            setLoading(false);
        } catch (_e) {}
    };
    const handleKeyPress = (event: React.KeyboardEvent<Element>) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    useEffect(() => {
        const validateLink = async () => {
            setValidating(false);
            setEmail(null);
            if (sec && d) {
                const res = await validateResetLink(sec, d);
                if (res.email) {
                    setEmail(res.email);
                }
                setValidating(false);
            }
            return false;
        };
        validateLink();
    }, [sec, d]);

    return (
        <main className="d-flex w-100">
            <div className="login-container container d-flex flex-column">
                <div className="row vh-100">
                    <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                        <div className="d-table-cell align-middle">
                            <div className="brand text-center mt-4">
                                <h1 className="h2">Vodichron Resource Management System</h1>
                                <p className="lead">Embed Square Solutions Pvt. Ltd.</p>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    {error && <p className="error">{error}</p>}
                                    {globalError.isError && <p className="error">{globalError.message}</p>}
                                    <div className="m-sm-4">
                                        <form>
                                            <div className="mb-3">
                                                <p className="fw-bold">Password Reset:</p>
                                                <p>To create a secure password, consider the following guidelines:</p>
                                                <ul>
                                                    <li>Minimum 8 characters</li>
                                                    <li>Include at least one uppercase letter (A-Z)</li>
                                                    <li>Include at least one lowercase letter (a-z)</li>
                                                    <li>Include at least one number (0-9)</li>
                                                    <li>Include at least one special character (e.g., @$!%*?&)</li>
                                                </ul>
                                            </div>
                                            {validating && (
                                                <div className="row mt-2">
                                                    <div className="col-sm-12 mt-2 mb-2 text-center">
                                                        Validating Link
                                                        <br />
                                                        <div className="spinner-border text-primary me-2" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {!email && !validating && (
                                                <div className="row mt-2 p-3 ">
                                                    <div className="col-sm-12 p-3 text-start bg-danger text-white">
                                                        <p className="fw-bold">Invalid link</p>
                                                        <p>
                                                            It could be due to link expiration. Password reset link expires
                                                            in 15 min, you can try re-generating the link through{' '}
                                                            <NavLink className="fw-bold text-light" to="/forgot-password">
                                                                Forgot Password
                                                            </NavLink>
                                                            .
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                            {email && !validating && (
                                                <>
                                                    {submitted ? (
                                                        <>
                                                            <div className="text-center mt-3">
                                                                <NavLink className="btn btn-primary" to="/login">
                                                                    <FontAwesomeIcon
                                                                        className="me-2"
                                                                        icon={icon({ name: 'arrow-left' })}
                                                                        size="sm"
                                                                    />{' '}
                                                                    Back to login
                                                                </NavLink>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="mb-3">
                                                                <label>
                                                                    Password <span className="required-field">*</span>
                                                                </label>
                                                                <input
                                                                    className={`form-control form-control-lg mb-3 ${
                                                                        formErrors.password ? 'is-invalid' : ''
                                                                    }`}
                                                                    type="password"
                                                                    name="password"
                                                                    value={formData.password}
                                                                    autoComplete="false"
                                                                    onChange={handleChange}
                                                                />
                                                                {formErrors.password && (
                                                                    <div className="invalid-feedback mb-3">
                                                                        {formErrors.password}
                                                                    </div>
                                                                )}
                                                                <label>
                                                                    Confirm Password{' '}
                                                                    <span className="required-field">*</span>
                                                                </label>
                                                                <input
                                                                    className={`form-control form-control-lg mb-3 ${
                                                                        formErrors.confirmPassword ? 'is-invalid' : ''
                                                                    }`}
                                                                    type="password"
                                                                    name="confirmPassword"
                                                                    value={formData.confirmPassword}
                                                                    onChange={handleChange}
                                                                    autoComplete="false"
                                                                    onKeyDown={handleKeyPress}
                                                                />
                                                                {formErrors.confirmPassword && (
                                                                    <div className="invalid-feedback mb-3">
                                                                        {formErrors.confirmPassword}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="text-center mt-3">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-lg btn-primary"
                                                                    onClick={handleSubmit}
                                                                >
                                                                    Reset Password
                                                                    {loading && !globalError.isError && (
                                                                        <div
                                                                            className="spinner-border spinner-border-sm me-2 mx-2"
                                                                            role="status"
                                                                        >
                                                                            <span className="visually-hidden">
                                                                                Loading...
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ResetPassword;
