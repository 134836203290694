/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { UserProfile } from '../types/user';
// Slice
const slice = createSlice({
    name: 'user',
    initialState: {} as UserProfile,
    reducers: {
        setProfile: (_state, action) => action.payload,
    },
});
export default slice.reducer;

// Actions
const { setProfile } = slice.actions;
export const setUserProfile = (user: UserProfile) => async (dispatch: any) => {
    try {
        return dispatch(setProfile(user));
    } catch (e: any) {
        return console.error(e.message);
    }
};
