/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { TaskDetails } from '../../../types/timesheetType';
import { submitTimesheet } from '../../../services/employeeTimesheet';
import { useAppContext } from '../../../hooks/useAppContext';
import { IRootState, useSelector } from '../../../store';
import { UserProfile } from '../../../types/user';
import './EmployeeTimesheetEntryForm.scss';

const EmployeeTimesheetEntryForm = () => {
    const loggedInUser = useSelector((state: IRootState) => state.user) as UserProfile;
    const globalError = useSelector((state: IRootState) => state.error);
    const selectHours = Array.from(Array(17).keys());
    const [submitLoading, setSubmitLoading] = useState(false);
    const [taskDetails, setTaskDetails] = useState<TaskDetails[]>([]);
    const [totalHours, setTotalHours] = useState('00 hrs 00 min');
    const pristineForm = {
        task: '-',
        selectedDate: new Date(),
        hours: '0',
        minutes: '00',
        comments: '',
    };
    const { triggerSuccess } = useAppContext();
    const navigate = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [formData, setFormData] = useState<any>(pristineForm);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultError = {} as any;
    const [errors, setErrors] = useState(defaultError);
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
        if (e.target.name === 'task') {
            const match = taskDetails.filter((taskDetail) => taskDetail.task === e.target.value);
            if (match.length > 0) {
                setErrors({ ...errors, task: 'Task already exists' });
            } else {
                delete errors.task;
                setErrors({ ...errors });
            }
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const addTask = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setErrors(defaultError);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const tempErrors = {} as any;
        if (formData.task === '-') {
            tempErrors.task = 'Task is required.';
        }

        if (formData.hours === '0' && formData.minutes === '0') {
            tempErrors.hours = 'Hours is required.';
        }
        if (formData.task === 'Other' && !formData.comments) {
            tempErrors.comments = 'Comments is required when selected task is Other';
        }
        if (formData.comments && formData.comments.length > 100) {
            tempErrors.comments = 'Comments should be shorter than 100 characters';
        }

        if (Object.keys(tempErrors).length === 0) {
            const hour = formData.hours.length === 1 ? `0${formData.hours}` : formData.hours;
            const task = {
                task: formData.task,
                hours: `${hour}:${formData.minutes}`,
                comments: formData.comments,
            };

            setTaskDetails([...taskDetails, task]);
            setFormData({ ...pristineForm, selectedDate: formData.selectedDate });
        }
        setErrors(tempErrors);
        return;
    };

    const removeTaskDetails = (index: number) => {
        taskDetails.splice(index, 1);
        setTaskDetails([...taskDetails]);
    };

    const getTotalHours = () => {
        const taskHours = taskDetails.map((taskDetail) => taskDetail.hours);
        const sum = taskHours.reduce((acc, time) => acc.add(moment.duration(time)), moment.duration());
        return sum;
    };

    const submitTimesheetHandler = async (e: React.MouseEvent<HTMLElement>) => {
        try {
            e.preventDefault();
            setErrors(defaultError);
            // 'employeeId' |'timesheetDate' | 'taskDetails' | 'totalHours'
            setSubmitLoading(true);
            const sum = getTotalHours();
            const decimalString = `${Math.floor(sum.asHours()).toString().padStart(2, '0')}.${sum.minutes()}`;
            const timesheetDate = moment(formData.selectedDate).format(`YYYY-MM-DD`);
            const data = {
                employeeId: loggedInUser.uuid,
                timesheetDate,
                taskDetails,
                totalHours: parseFloat(decimalString),
            };
            // TODO: Need to clean up for handling this better
            await submitTimesheet(data);
            triggerSuccess(`Timesheet submitted.`);
            setFormData(pristineForm);
            navigate('/employee/timesheet/submissions');
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (_e: any) {
            setSubmitLoading(false);
        }
    };
    useEffect(() => {
        const sum = getTotalHours();
        const total = `${Math.floor(sum.asHours()).toString().padStart(2, '0')} hrs ${sum
            .minutes()
            .toString()
            .padStart(2, '0')} min`;
        setTotalHours(total);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskDetails]);
    return (
        <main className="content timesheet-entry-form">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon icon={icon({ name: 'user-clock' })} />
                            Timesheet Submission
                        </h3>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-info">
                                <p>
                                    {' '}
                                    Fill your work hours for the day using below timesheet for. Required fields marked with
                                    an asterisk (<span className="required-field">*</span>).
                                </p>
                                <p className="mb-0">
                                    <strong>Note:</strong>
                                </p>
                                <ul>
                                    <li>You can fill total hours for one task or split between multiple tasks.</li>
                                    <li>Once you have added all the tasks for a day, submit the timesheet.</li>
                                </ul>
                            </div>
                            <div className="card-body  col-md-12">
                                <form>
                                    <div className="row">
                                        {' '}
                                        <div className="col-md-6">
                                            <div className="tab-content">
                                                <label>
                                                    Date <span className="required-field">*</span>
                                                </label>
                                                <DatePicker
                                                    className={`form-control form-control-lg mb-3  ${
                                                        errors.selectedDate ? 'is-invalid' : ''
                                                    }`}
                                                    // disabled={!!taskDetails.length}
                                                    name="selectedDate"
                                                    format="dd/MM/y"
                                                    value={formData.selectedDate}
                                                    maxDate={moment(new Date()).toDate()}
                                                    minDate={moment(new Date()).subtract(14, 'days').toDate()}
                                                    onChange={(date) =>
                                                        date && setFormData({ ...formData, selectedDate: date })
                                                    }
                                                />
                                                <hr />
                                                <label>
                                                    Task <span className="required-field">*</span>
                                                </label>
                                                <select
                                                    name="task"
                                                    className={`form-control form-control-lg form-select mb-3 ${
                                                        errors.task ? 'is-invalid' : ''
                                                    }`}
                                                    value={formData.task}
                                                    onChange={handleChange}
                                                >
                                                    <option value="-">Select Task</option>
                                                    <option value="Development">Development</option>
                                                    <option value="Testing">Testing</option>
                                                    <option value="Meeting">Meeting</option>
                                                    <option value="Break">Break</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {errors.task && <div className="invalid-feedback mb-3">{errors.task}</div>}

                                                <label>
                                                    Hours Spent <span className="required-field">*</span>
                                                </label>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <select
                                                            name="hours"
                                                            className={`form-control form-control-lg mb-3 form-select  ${
                                                                errors.hours ? 'is-invalid' : ''
                                                            }`}
                                                            value={formData.hours}
                                                            onChange={handleChange}
                                                        >
                                                            {selectHours.map((hr) => (
                                                                <option key={hr} value={`${hr}`}>
                                                                    {hr}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {errors.hours && (
                                                            <div className="invalid-feedback mb-3">{errors.hours}</div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-6">
                                                        <select
                                                            name="minutes"
                                                            className={`form-control form-control-lg mb-3 form-select  ${
                                                                errors.minutes ? 'is-invalid' : ''
                                                            }`}
                                                            value={formData.minutes}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="00">00</option>
                                                            <option value="30">30</option>
                                                        </select>
                                                        {errors.minutes && (
                                                            <div className="invalid-feedback mb-3">{errors.minutes}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                {formData.task === 'Other' && (
                                                    <>
                                                        {' '}
                                                        <label>
                                                            Comments <span className="required-field">*</span>
                                                        </label>
                                                        <input
                                                            className={`form-control form-control-lg mb-3 ${
                                                                errors.comments ? 'is-invalid' : ''
                                                            }`}
                                                            type="text"
                                                            name="comments"
                                                            onChange={handleChange}
                                                            maxLength={100}
                                                        />
                                                        {errors.comments && (
                                                            <div className="invalid-feedback mb-3">{errors.comments}</div>
                                                        )}
                                                    </>
                                                )}
                                                <div className="text-end mt-2">
                                                    <button
                                                        className="btn btn-primary btn-sm me-2"
                                                        disabled={submitLoading || Object.keys(errors).length > 0}
                                                        onClick={addTask}
                                                    >
                                                        <FontAwesomeIcon
                                                            className="me-2"
                                                            size="lg"
                                                            icon={icon({ name: 'circle-plus' })}
                                                        />{' '}
                                                        ADD TASK
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-4">
                                            {taskDetails.length > 0 && (
                                                <>
                                                    <div className="row ms-md-5 col-md-8 p-1 header-row border">
                                                        <div className="col-4  header-col">
                                                            <div className=" heading"> Task</div>
                                                        </div>
                                                        <div className="col-4 header-col">
                                                            <div className=" heading">Hours Spent</div>
                                                        </div>
                                                        <div className="col-2 header-col">
                                                            <div className=" heading">{''}</div>
                                                        </div>
                                                    </div>
                                                    {taskDetails.map((detail, index) => (
                                                        <div
                                                            key={`${detail.task}${index}`}
                                                            className="row ms-md-5   p-1 col-md-8 data-row"
                                                        >
                                                            <div className="col-4  data-col">
                                                                {' '}
                                                                {detail.task === 'Other' ? (
                                                                    <>
                                                                        {detail.task}
                                                                        <FontAwesomeIcon
                                                                            title={detail.comments}
                                                                            className="icons ms-2 text-primary cursor-pointer"
                                                                            size="sm"
                                                                            icon={icon({ name: 'message' })}
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    detail.task
                                                                )}
                                                            </div>
                                                            <div className="col-4 data-col"> {detail.hours}</div>
                                                            <div className="col-2 data-col ">
                                                                <FontAwesomeIcon
                                                                    onClick={() => removeTaskDetails(index)}
                                                                    className="icons cursor-pointer"
                                                                    size="sm"
                                                                    icon={icon({ name: 'trash-can' })}
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row col-md-12">
                                        <div className="row col-md-3 mt-sm-2">
                                            <div className="col-md-3">
                                                <strong>Date:</strong>
                                            </div>
                                            <div className="col-md-8">
                                                {moment(formData.selectedDate).format('Do MMMM YYYY')}
                                            </div>
                                        </div>
                                        <div className="row col-md-6 mt-sm-2">
                                            <div className="col-md-4">
                                                <strong>Total Hours Spent:</strong>
                                            </div>
                                            <div className="col-md-3">{totalHours}</div>
                                        </div>
                                        <div className="text-end mt-4">
                                            <button
                                                onClick={submitTimesheetHandler}
                                                disabled={!taskDetails.length}
                                                className="btn btn-primary me-2"
                                            >
                                                Submit Timesheet{' '}
                                                {submitLoading && !globalError.isError && (
                                                    <div className="spinner-border spinner-border-sm   me-2" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default EmployeeTimesheetEntryForm;
