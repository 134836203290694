import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';
import { WeeklyTimesheet } from '../../../types/timesheetType';
import '../Submit/EmployeeTimesheetEntryForm.scss';
import { calculateColumnTotal, getWeekDaysByDate, reverseTransformIndexEntrycolToDate } from '../TimeSheetFn';
import { TimeSheetData } from '../TimeSheetData';

type WeeklyTimesheetDetailModalProp = {
    timesheetDetails: WeeklyTimesheet;
    approverView: boolean;
    showModal: boolean;
    handleClose: () => void;
    refreshData: () => void;
};
export const WeeklyTimesheetDetailModal = (prop: WeeklyTimesheetDetailModalProp) => {
    const date = moment(prop.timesheetDetails.weekStartDate);
    const displayWeeks = getWeekDaysByDate(date);
    const updatedTaskDetails = reverseTransformIndexEntrycolToDate(prop.timesheetDetails.taskDetails, displayWeeks);
    const columnTotals = calculateColumnTotal(updatedTaskDetails);
    return (
        <Modal size="lg" centered={true} show={prop.showModal}>
            <Modal.Header>
                <Modal.Title>
                    <h3 className="ms-2">Timesheet - Request #: {prop.timesheetDetails.requestNumber}</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="col-12" style={{ background: '#eaeaea' }}>
                        {' '}
                        <div className="d-flex header-row">
                            <div className="col-3 me-4 header-col fw-bold text-start">
                                <div className="p-3 heading">Task Details</div>
                            </div>
                            {displayWeeks.map((week) => (
                                <div key={`${week.date}`} className="col-1 fw-bold header-col">
                                    <div
                                        className={cn('p-2 px-3 heading', {
                                            'heading__is-today': week.isToday === true,
                                        })}
                                    >
                                        {week.day}, {week.month} {week.date}
                                    </div>
                                </div>
                            ))}
                            <div className="col-1 fw-bolder header-col">
                                <div className="p-2 heading">Total</div>
                            </div>
                        </div>
                    </div>
                    <TimeSheetData
                        timeSheetData={updatedTaskDetails}
                        timeSheetDataTotalRow={columnTotals}
                        displayWeeks={displayWeeks}
                        isReadOnlyView={true}
                        approverView={prop.approverView}
                        timesheetId={prop.timesheetDetails.uuid}
                        refreshData={prop.refreshData}
                    />
                </div>
                <div className="p-3">
                    <div
                        className={cn('alert', {
                            'alert-danger': prop.timesheetDetails.approvalStatus === 'REJECTED',
                            'alert-success': prop.timesheetDetails.approvalStatus === 'APPROVED',
                            'alert-info': prop.timesheetDetails.approvalStatus === 'REQUESTED',
                        })}
                    >
                        <div className="alert-message">
                            <p className="mb-1">
                                <strong className="pe-2">Approval Status:</strong> {prop.timesheetDetails.approvalStatus}
                            </p>
                            {(prop.timesheetDetails.approvalStatus === 'APPROVED' ||
                                prop.timesheetDetails.approvalStatus === 'REJECTED') && (
                                <>
                                    <p className="mt-1 mb-1">
                                        <strong className="pe-2">Approver:</strong> {prop.timesheetDetails.approverDetail}
                                    </p>
                                    <p className="mb-2">
                                        <strong className="pe-2">Action Date:</strong>{' '}
                                        {moment(prop.timesheetDetails.approvalDate).format('DD/MM/YYYY')}
                                    </p>
                                    <p className="mb-1">
                                        <strong className="pe-2">Approver Comments:</strong>
                                    </p>
                                    <p className="mb-2">{prop.timesheetDetails.approverComments ?? '-'}</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => prop.handleClose()}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
