import { request } from '../api';
import { PageLimit } from '../constants/data';
import { ActionStatus } from '../types/employee';
import {
    EmployeeInsertTimesheet,
    WeeklyTimesheetInsert,
    EmployeeTimesheet,
    EmployeeTimesheetFilters,
    WeeklyTimesheet,
} from '../types/timesheetType';

export const submitTimesheet = async (timesheet: EmployeeInsertTimesheet) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/timesheet/submit`,
        data: { ...timesheet },
    };
    return request(requestOptions);
};

export const getEmployeeTimesheet = async (page: number, uuid: string, filters: EmployeeTimesheetFilters = {}) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/timesheets/${uuid}`,
        data: {
            pagination: {
                page,
                pageLimit: PageLimit,
            },
            filters,
        },
    };
    return request<{ data: EmployeeTimesheet[] }>(requestOptions);
};

export const checkIfTimesheetExists = async (employeeId: string, weekStartDate: string) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/weekly-timesheet/exists`,
        data: { employeeId, weekStartDate },
    };
    return request<{ data: { requestNumber: string | null; timesheetId: string | null } }>(requestOptions);
};

export const submitWeeklyTimesheet = async (timesheet: WeeklyTimesheetInsert) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/weekly-timesheet/submit`,
        data: { ...timesheet },
    };
    return request(requestOptions);
};

export const updateWeeklyTimesheet = async (timesheet: WeeklyTimesheetInsert) => {
    const requestOptions = {
        method: 'PATCH',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/weekly-timesheet/update`,
        data: { ...timesheet },
    };
    return request(requestOptions);
};

export const getEmployeeWeeklyTimesheet = async (page: number, uuid: string, filters: EmployeeTimesheetFilters = {}) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/weekly-timesheets/${uuid}`,
        data: {
            pagination: {
                page,
                pageLimit: PageLimit,
            },
            filters,
        },
    };
    return request<{ data: WeeklyTimesheet[] }>(requestOptions);
};

export const getReporteeWeeklyTimesheet = async (page: number, filters: EmployeeTimesheetFilters = {}) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/reportee/weekly-timesheets`,
        data: {
            pagination: {
                page,
                pageLimit: PageLimit,
            },
            filters,
        },
    };
    return request<{ data: WeeklyTimesheet[] }>(requestOptions);
};

export const getCustomerWeeklyTimesheet = async (page: number, filters: EmployeeTimesheetFilters = {}) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/customer/resource/timesheets`,
        data: {
            pagination: {
                page,
                pageLimit: PageLimit,
            },
            filters,
        },
    };
    return request<{ data: WeeklyTimesheet[] }>(requestOptions);
};

export const getEmployeeWeeklyTimesheetDetail = async (uuid: string, loggedInUserId: string) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/weekly-timesheet/${uuid}`,
        data: {
            employeeId: loggedInUserId,
        },
    };
    return request<{ data: WeeklyTimesheet }>(requestOptions);
};

export const updateTimesheetStatus = async (
    timesheetId: string,
    employeeId: string,
    comment: string,
    approvalStatus: ActionStatus
) => {
    const requestOptions = {
        method: 'PATCH',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/weekly-timesheet/action/${timesheetId}`,
        data: {
            employeeId,
            comment,
            approvalStatus,
        },
    };
    return request(requestOptions);
};

export const lockToggleTaskTime = async (timesheetId: string, uuid: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/timesheet/task/toggle/${timesheetId}/${uuid}`,
    };
    return request(requestOptions);
};
