/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import * as Icon from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import cn from 'classnames';
import moment from 'moment';
import { capitalize } from 'lodash';
import { EmployeeDocumentTypes, ImageMimeType, MAX_FILE_UPLOAD_SIZE, PdfMimeType } from '../../constants/fileType';
import { deleteEmployeeDocument, getEmployeeDocument, uploadEmplyeeDocuments } from '../../services/employeeDocuments';
import { IRootState, useSelector } from '../../store';
import { UserProfile } from '../../types/user';
import { useAppContext } from '../../hooks/useAppContext';
import FileDropzone from '../FileDropzone/FileDropzone';
import { EmployeeDocuments } from '../../types/employee';
import { StatusIndicator } from '../StatusIndicator/StatusIndicator';
import { DocumentDownload } from './DocumentDownload';

const EmployeeDocumentUpload = () => {
    const loggedInUser = useSelector((state: IRootState) => state.user) as UserProfile;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [uploadingFiles, setUploadingFiles] = useState<any[]>([]);
    const [employeeDocuments, setEmployeeDocuments] = useState<EmployeeDocuments[]>([]);

    const [selectedFileType, setSelectedFileType] = useState('0');
    const [uploadLoading, setUploadLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [hideDropZone, setHideDropZone] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { triggerSuccess } = useAppContext();

    const fetchEmployeeDocuments = async () => {
        try {
            setLoadingData(true);
            const { data } = await getEmployeeDocument(loggedInUser.uuid);
            setEmployeeDocuments(data);
            setLoadingData(false);
        } catch (err: unknown) {
            setError('Something went wrong');
            setLoadingData(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const removeFileFromState = (uuid?: string) => {
        if (uuid) {
            const updatedUploadingFiles = uploadingFiles.filter((f) => f.uuid !== uuid);
            setUploadingFiles(updatedUploadingFiles);
        }
    };

    const uploadFile = async (file: File, uuid: string) => {
        setUploadLoading(true);
        const tempFile = {
            uuid,
            filesize: file.size,
            filename: file.name,
            progress: 0,
            ...file,
        };
        setUploadingFiles([tempFile, ...uploadingFiles]);

        const onProgress = (progress: number) => {
            const updatedUploadingFiles = uploadingFiles.map((f) => (f.uuid === uuid ? { ...f, progress } : f));
            setUploadingFiles(updatedUploadingFiles);
        };

        try {
            await uploadEmplyeeDocuments(file, loggedInUser.uuid, selectedFileType, onProgress);
            setUploadLoading(false);
            setHideDropZone(true);
            setSelectedFileType('0');
            triggerSuccess(`Your file was uploaded successfully`);
            fetchEmployeeDocuments();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            setUploadLoading(false);
            const errors = { system: err.message };
            const updatedUploadingFiles = uploadingFiles.map((f) =>
                f.uuid === uuid ? { ...f, progress: undefined, errors } : f
            );
            setUploadingFiles(updatedUploadingFiles);
            return;
        }
        // When done uploading, move file from state to store
        removeFileFromState(uuid);
    };

    const handleUpload = async (file: File, uuid: string) => {
        const fileSizeKiloBytes = file.size / 1024;
        if (fileSizeKiloBytes > MAX_FILE_UPLOAD_SIZE) {
            setError('File size is greater than maximum limit');
            return;
        }
        const isSameFileInStateWithError = uploadingFiles.find((f) => f.filename === file.name && !!f.errors);
        if (isSameFileInStateWithError && isSameFileInStateWithError.uuid) {
            removeFileFromState(isSameFileInStateWithError.uuid);
            uploadFile(file, isSameFileInStateWithError.uuid);
            return;
        }
        uploadFile(file, uuid);
    };
    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value !== '0') {
            setHideDropZone(false);
        } else {
            setHideDropZone(true);
        }
        setSelectedFileType(event.target.value);
    };

    const handleDrop = async (acceptedFiles: File[]) => {
        acceptedFiles.forEach((file) => {
            const uuid = v4();
            handleUpload(file, uuid);
        });
    };

    useEffect(() => {
        if (loggedInUser.uuid) {
            setLoadingData(true);
            fetchEmployeeDocuments();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInUser]);

    const handleDelete = async (documentId: string) => {
        try {
            await deleteEmployeeDocument(loggedInUser.uuid, documentId);
            fetchEmployeeDocuments();
        } catch (_e) {}
    };

    return (
        <main className="content">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon icon={icon({ name: 'file' })} /> My Documents
                        </h3>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-info">
                                <p>
                                    {' '}
                                    Select the file to upload from the dropdown below (
                                    <span className="required-field">*</span>).
                                </p>
                                <p className="mb-0">
                                    <strong>Note:</strong>
                                </p>
                                <ul>
                                    <li>If upload the same file again, previous file will be replaced with the new one.</li>
                                    <li>If you do not see the file option to upload, please report to the HR.</li>
                                </ul>
                            </div>
                            <div className="card-body card-body__min-height">
                                <>
                                    <div className="search-container">
                                        <div className="mb-3 row">
                                            <strong>Upload Files:</strong>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-md-3">
                                                <select
                                                    name="documentType"
                                                    className="form-control form-control-lg form-select"
                                                    onChange={handleSelectChange}
                                                    value={selectedFileType}
                                                >
                                                    <option value="0">Select File Type</option>
                                                    {EmployeeDocumentTypes.map((type, i) => (
                                                        <option key={i} value={`${type}`}>
                                                            {capitalize(type.replaceAll('_', ' '))}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-4 text-danger pt-2">
                                                {employeeDocuments?.length > 0 &&
                                                    employeeDocuments.filter(
                                                        (employeeDocument) =>
                                                            employeeDocument.documentType === selectedFileType
                                                    ).length > 0 && (
                                                        <strong>
                                                            <FontAwesomeIcon size="sm" icon={icon({ name: 'bell' })} />{' '}
                                                            Already uploaded, existing file will be replaced.
                                                        </strong>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                                {uploadLoading && (
                                    <div className="row mt-4">
                                        <div className="col-sm-12 mt-2 mb-2 text-center">
                                            <div className="spinner-border text-primary me-2" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {!uploadLoading && !hideDropZone && (
                                    <FileDropzone
                                        acceptedMimeTypes={{
                                            ...ImageMimeType,
                                            ...PdfMimeType,
                                        }}
                                        title={
                                            <>
                                                Drag &#39;n drop file here or <span className="button">browse</span>
                                            </>
                                        }
                                        onDrop={handleDrop}
                                        multiple={false}
                                        note="Upload .png, .jpeg, .jpg and .pdf file with less than 1 MB size."
                                    />
                                )}
                                {error && (
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="alert alert-danger alert-outline alert-dismissible" role="alert">
                                                <div className="alert-icon">
                                                    <Icon.Bell className="far fa-fw fa-bell" />
                                                </div>
                                                <div className="alert-message">
                                                    <strong>Error</strong> Something went wrong when trying to get leaves
                                                    data for your reportees.
                                                    <br /> If the problem persists, please contact support.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {loadingData && (
                                    <div className="row mt-4">
                                        <div className="col-sm-12 mt-2 mb-2 text-center">
                                            <div className="spinner-border text-primary me-2" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {!loadingData && employeeDocuments?.length === 0 && (
                                    <div className="row mt-4">
                                        <div className="col-sm-12">
                                            <div
                                                className="alert alert-danger alert-outline alert-dismissible mt-5"
                                                role="alert"
                                            >
                                                <div className="alert-icon">
                                                    <Icon.Bell className="far fa-fw fa-bell" />
                                                </div>
                                                <div className="alert-message">
                                                    <p>
                                                        <strong>No documents uploaded yet.</strong>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {!loadingData && employeeDocuments?.length !== 0 && (
                                    <div className="row mt-5">
                                        <table
                                            id="datatables-reponsive"
                                            className="table table-striped dataTable no-footer dtr-inline view-employees-leaves-list-table"
                                            style={{ width: '100%', tableLayout: 'fixed' }}
                                            aria-describedby="datatables-reponsive_info"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>Document Type</th>
                                                    <th>File</th>
                                                    <th>Upload Date</th>
                                                    <th>Approval Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {employeeDocuments &&
                                                    employeeDocuments.map((doc, index) => (
                                                        <tr
                                                            className={cn({
                                                                odd: index % 2 === 0,
                                                                even: index % 2 !== 0,
                                                            })}
                                                            key={`${doc.uuid}-${index}`}
                                                        >
                                                            <td>{capitalize(doc.documentType.replaceAll('_', ' '))}</td>
                                                            <td>
                                                                <DocumentDownload
                                                                    loggedInUserId={loggedInUser.uuid}
                                                                    fileDetails={doc}
                                                                />
                                                            </td>
                                                            <td>{moment(doc.createdAt).format('DD/MM/YYYY') ?? '-'}</td>
                                                            <td>
                                                                <StatusIndicator status={doc.hrApprovalStatus} />
                                                            </td>
                                                            <td>
                                                                {/* TODO: SHOW COMMENT MESSAGE */}
                                                                <FontAwesomeIcon
                                                                    className="cursor-pointer"
                                                                    onClick={() => handleDelete(doc.uuid)}
                                                                    size="lg"
                                                                    icon={icon({ name: 'trash-can' })}
                                                                />{' '}
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default EmployeeDocumentUpload;
