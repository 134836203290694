import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NavLink } from 'react-router-dom';

const Dashboard = () => {
    return (
        <main className="content">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="alert alert-danger alert-dismissible" role="alert">
                                    <div className="alert-message">
                                        <h3 className="alert-heading">
                                            {' '}
                                            <FontAwesomeIcon
                                                className="icons"
                                                size="xl"
                                                icon={icon({ name: 'warning' })}
                                            />{' '}
                                            Unauthorized Access!
                                        </h3>
                                        <p>You do not have permission to access this page</p>
                                        <hr />
                                        <p className="mb-0">
                                            Please check how you landed on this page or contact the administrator for
                                            assistance
                                        </p>
                                        <p>
                                            <NavLink className="btn btn-danger mt-2" to="/">
                                                Go to main page
                                            </NavLink>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Dashboard;
