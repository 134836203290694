import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

type WarningUnSavedChangesModalProp = {
    showModal: boolean;
    handleClose: () => void;
    handleProceed: () => void;
};
export const WarningUnSavedChangesModal = (prop: WarningUnSavedChangesModalProp) => {
    return (
        <Modal show={prop.showModal} centered onHide={prop.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon className="text-danger" icon={icon({ name: 'triangle-exclamation' })} size="lg" />
                    <span className="ms-2">UNSAVED CHANGES WARNING</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Hey there! Looks like you&apos;ve made some changes that haven&apos;t been saved.</p>{' '}
                <p>If you continue, these changes will be lost. What would you like to do?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={prop.handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={prop.handleProceed}>
                    Continue
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
