import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useAppContext } from '../../../hooks/useAppContext';
import { updateTimesheetStatus } from '../../../services/employeeTimesheet';
import { ActionStatus } from '../../../types/employee';
import { WeeklyTimesheet } from '../../../types/timesheetType';
import { AlphaNumericRegex } from '../../../constants/validations';
// import { UserRole } from '../../../types/user';

type TimesheetApprovalModalProp = {
    timesheet: WeeklyTimesheet;
    showModal: boolean;
    loggedInUserId: string;
    handleClose: () => void;
};
// TODO: Better Design and complete Data.
export const TimesheetApprovalModal = (prop: TimesheetApprovalModalProp) => {
    const { loggedInUserId, timesheet } = prop;
    const [submitLoading, setSubmitLoading] = useState(false);
    const [error, setError] = useState('');
    const [comments, setComments] = useState('');
    const { triggerSuccess } = useAppContext();

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setComments(value);
    };

    const handleSubmit = async (status: ActionStatus) => {
        if (!comments) {
            setError('Please add some comment');
        } else if (comments.length > 100) {
            setError('comments too long.');
        } else if (!AlphaNumericRegex.test(comments)) {
            setError('Comments should only contain alphanumeric characters only');
        } else {
            setSubmitLoading(true);
            try {
                await updateTimesheetStatus(timesheet.uuid, timesheet.employeeId, comments, status);
                setSubmitLoading(false);
                prop.handleClose();
                setComments('');
                triggerSuccess(`Timesheet status updated`);
                setTimeout(() => window.location.reload(), 700);
            } catch (_e) {
                setSubmitLoading(false);
                prop.handleClose();
                setComments('');
            }
        }
    };

    return (
        <Modal centered={true} show={prop.showModal} onHide={() => prop.handleClose()}>
            <Modal.Header>
                <Modal.Title>
                    <h3 className="ms-2">Employee Timesheet Approval</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="p-3">
                    <div className="mb-3 bg-info p-3 -2 text-white">
                        <h4 className="text-white">
                            <FontAwesomeIcon className="icons" size="sm" icon={icon({ name: 'circle-info' })} /> Important
                            Info:
                        </h4>
                        <p>
                            Approve or reject timesheets at the end of the week to ensure that employees can continue making
                            updates throughout the workweek without interruption.
                        </p>
                    </div>
                    {timesheet.approverId === loggedInUserId &&
                        (timesheet.approvalStatus === ActionStatus.APPROVED ||
                            timesheet.approvalStatus === ActionStatus.REJECTED) && (
                            <div className="alert alert-danger alert-dismissible fine-print" role="alert">
                                <div className="alert-message">
                                    <p className="alert-heading">
                                        {' '}
                                        <FontAwesomeIcon className="icons" size="xl" icon={icon({ name: 'warning' })} />{' '}
                                        <strong>Already {timesheet.approvalStatus}</strong>
                                    </p>
                                    <p className="mb-1">
                                        You have already {timesheet.approvalStatus} the timsheet for this employee.
                                    </p>
                                    <p className="mb-1">
                                        If you are trying to update the previously {timesheet.approvalStatus} timesheet it
                                        will overwrite the previous status.
                                    </p>
                                    <p className="mb-1">
                                        Employee may or may not be aware of this action, please reach out to employee to
                                        inform him/her about this new update.
                                    </p>
                                </div>
                            </div>
                        )}

                    <h4>Comments</h4>
                    <div className="">
                        {submitLoading ? (
                            <div key={0} className="row">
                                <div className="col-sm-12 mt-2 mb-2 text-center">
                                    <div className="spinner-border text-primary me-2" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <textarea
                                    rows={5}
                                    className={`form-control form-control-lg mb-3 ${error ? 'is-invalid' : ''}`}
                                    name="comments"
                                    value={comments}
                                    onChange={handleChange}
                                />
                                {error && <div className="invalid-feedback mb-3">{error}</div>}
                                <div className="text-end mt-2">
                                    <button
                                        type="button"
                                        className="btn btn-danger me-2"
                                        onClick={() => handleSubmit(ActionStatus.REJECTED)}
                                    >
                                        Reject
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-success ms-2"
                                        onClick={() => handleSubmit(ActionStatus.APPROVED)}
                                    >
                                        Approve
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => prop.handleClose()}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
