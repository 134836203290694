import React, { useCallback, useState } from 'react';
import { startCase } from 'lodash';
import Avatar from 'react-avatar';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useRequest } from '../../../api/useRequest';
import { deleteCustomer, getCustomerAndResouceAllocationByCustomerId } from '../../../services/customer';
import { useAppContext } from '../../../hooks/useAppContext';
import { IRootState, useSelector } from '../../../store';
import { ADMIN_USERS, UserProfile, UserRole } from '../../../types/user';
import { sendCustomerAccessRequest } from '../../../services/user';
import './CustomerDetails.scss';
import { AllocatedResourceList } from '../AllocateResource/AllocatedResourceList';

const CustomerDetails = () => {
    const { id: customerId } = useParams();

    const user = useSelector((state: IRootState) => state.user) as UserProfile;
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteTriggered, setDeleteTriggered] = useState(false);
    const [activationLoading, setActivationLoading] = useState(false);
    const fetchCustomerDetails = useCallback(
        () => getCustomerAndResouceAllocationByCustomerId(customerId as string),
        [customerId]
    );
    const [loading, error, customerDetails] = useRequest(fetchCustomerDetails);
    const { triggerSuccess } = useAppContext();
    const navigate = useNavigate();

    const handleDeleteConfirm = async () => {
        //deleting now
        setDeleteTriggered(true);
        await deleteCustomer(customerId as string);
        triggerSuccess(`Customer data removed successfully`);
        setDeleteTriggered(false);
        setShowDeleteConfirmation(false);
        navigate('/customer/list');
    };

    const handleRedirectResourceAllocation = () => {
        navigate(`/customer/resource/allocate`, {
            state: {
                customerName: customerDetails?.customer?.name,
                customerId: customerDetails?.customer?.uuid,
            },
        });
    };

    const handleActivationRequest = async () => {
        try {
            if (customerId) {
                setActivationLoading(true);
                await sendCustomerAccessRequest(customerId);
                setActivationLoading(false);
                triggerSuccess(`Customer account activation email sent.`);
            }
        } catch (_e) {}
    };
    return (
        <main className="content">
            <div className="container-fluid p-0 customer-details-page">
                <div className="row">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            {' '}
                            <FontAwesomeIcon icon={icon({ name: 'user-tie' })} className="far fa-fw fa-bell" /> Customer
                            Details
                        </h3>
                    </div>

                    <div className="col-auto ms-auto text-end mt-n1">
                        <button className="btn btn-primary" onClick={() => navigate(-1)}>
                            <FontAwesomeIcon icon={icon({ name: 'angle-left' })} className="far fa-fw fa-bell" /> Back
                        </button>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            {loading && (
                                <div className="col-sm-12 mt-2 mb-2 text-center">
                                    <div className="spinner-border text-primary me-2" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            )}
                            {error && (
                                <div className="col-sm-12">
                                    <div className="alert alert-danger alert-outline alert-dismissible" role="alert">
                                        <div className="alert-icon">
                                            <FontAwesomeIcon size="lg" icon={icon({ name: 'bell' })} />
                                        </div>
                                        <div className="alert-message">
                                            <strong>Error</strong> Something went wrong.
                                            <br /> Unable to get the customer details.
                                            <br /> If the problem persists, please contact support.
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!loading && !error && customerDetails?.customer && (
                                <>
                                    <div className="card-header detail-header mb-0">
                                        <div className="row">
                                            <div className="col-md-12 text-sm-end text-start">
                                                {ADMIN_USERS.includes(user.role) ? (
                                                    <NavLink to={`/customer/update/${customerDetails?.customer.uuid}`}>
                                                        <FontAwesomeIcon
                                                            className="icons"
                                                            size="lg"
                                                            icon={icon({ name: 'edit' })}
                                                        />
                                                    </NavLink>
                                                ) : (
                                                    ''
                                                )}

                                                {user.role === UserRole.superUser ? (
                                                    <FontAwesomeIcon
                                                        onClick={() => setShowDeleteConfirmation(true)}
                                                        className="icons"
                                                        size="lg"
                                                        icon={icon({ name: 'trash-can' })}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body customer-details mb-0">
                                        <div className="row mb-2">
                                            <div className="col-md-8 mt-md-0 mt-sm-3">
                                                <Avatar
                                                    name={customerDetails?.customer.name}
                                                    className="avatar img-fluid rounded me-2"
                                                    size="40"
                                                />{' '}
                                                <span className="customer-name">
                                                    {startCase(customerDetails?.customer.name)}{' '}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="row p-2">
                                            <div className="col-12 col-md-6 customer-details detail-separator">
                                                <div className="customer-details-row">
                                                    <div className="label">Email:</div>
                                                    <div className="detail">{customerDetails?.customer.email}</div>
                                                </div>
                                                <div className="customer-details-row">
                                                    <div className="label">Primary Contact:</div>
                                                    <div className="detail">{customerDetails?.customer.primaryContact}</div>
                                                </div>
                                                <div className="customer-details-row">
                                                    <div className="label">Secondary Contact:</div>
                                                    <div className="detail">
                                                        {customerDetails?.customer.secondaryContact ?? '-'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 customer-details">
                                                <div className="customer-details-row">
                                                    <div className="label">Country:</div>
                                                    <div className="detail">{customerDetails?.customer.country}</div>
                                                </div>
                                                <div className="customer-details-row">
                                                    <div className="label">Timezone:</div>
                                                    <div className="detail">{customerDetails?.customer.timezone ?? '-'}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row col-12 p-2">
                                            <div className="col-md-12 text-md-end">
                                                <button
                                                    className="btn btn-primary mx-2"
                                                    onClick={handleActivationRequest}
                                                    disabled={activationLoading}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={icon({ name: 'envelope' })}
                                                        className="far fa-fw fa-bell"
                                                    />{' '}
                                                    {customerDetails.customer.hasAppAccess
                                                        ? 'Re-Send Activation Email'
                                                        : 'Send Activation Email'}
                                                    {activationLoading && (
                                                        <div className="spinner-border spinner-border-sm ms-2" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    )}
                                                </button>
                                                <button
                                                    className="btn btn-primary mx-2"
                                                    onClick={handleRedirectResourceAllocation}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={icon({ name: 'plus' })}
                                                        className="far fa-fw fa-bell"
                                                    />{' '}
                                                    Allocate Resource
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        {!loading && !error && customerDetails?.resourceAllocations && (
                            <AllocatedResourceList
                                resourceAllocations={customerDetails?.resourceAllocations}
                                isCustomerView={false}
                                loggedInUserRole={user.role}
                            />
                        )}
                    </div>
                </div>
            </div>
            <Modal show={showDeleteConfirmation} centered={true} onHide={() => setShowDeleteConfirmation(false)}>
                {!deleteTriggered ? (
                    <>
                        {' '}
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} size="lg" />
                                <span className="ms-2">Delete Customer Data</span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                You are about to delete the details of <strong>{customerDetails?.customer?.name}</strong>
                            </p>
                            <p>This operation is irreversible. Once the data is deleted it cannot be recovered back.</p>
                            <p>
                                Which includes customer information, customer resource mappings and other sensitive
                                information.
                            </p>
                            <p>Do you wish to continue?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleDeleteConfirm}>
                                Delete Now
                            </Button>
                        </Modal.Footer>
                    </>
                ) : (
                    <>
                        {' '}
                        <Modal.Header>
                            <Modal.Title>
                                <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} size="lg" />
                                <span className="ms-2">Delete Customer Data</span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h3 className="text-center">Your delete request is being processed</h3>
                            <p className="text-center">
                                <div className="spinner-border spinner-border-sm me-2" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </p>
                        </Modal.Body>
                    </>
                )}
            </Modal>
        </main>
    );
};

export default CustomerDetails;
