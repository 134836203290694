import { HolidayEvent } from '../../types/calendar';
import { CalendarEvent } from './LeaveCalendar';

export const transformEvent = (backendEvent: HolidayEvent): CalendarEvent => {
    const parseDate = (dateString: string): Date => {
        const [day, month, year] = dateString.split('/').map(Number);
        // Months are 0-based in JavaScript Date, so subtract 1 from the month
        return new Date(year, month - 1, day);
    };

    const startDate = parseDate(backendEvent.start);
    const endDate = parseDate(backendEvent.end);
    return {
        title: backendEvent.title,
        type: backendEvent.type,
        start: startDate,
        end: endDate,
        allDay: true,
    };
};
