/* eslint-disable no-console */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

const Blank = () => {
    return (
        <main className="content user-registration">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon icon={icon({ name: 'star-of-life' })} /> BLANK
                        </h3>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-info">
                                <p>
                                    {' '}
                                    This is random screen for mock pirpose ignore the specifics of this. (
                                    <span className="required-field">*</span>).
                                </p>
                                <p className="mb-0">
                                    <strong>Note:</strong>
                                </p>
                                <ul>
                                    <li>Random note for mock screen test.</li>
                                </ul>
                            </div>
                            <div className="card-body col-12">
                                <div className="row">THIS IS TEST</div>
                                <div>
                                    <p className="error-message text-center">
                                        <FontAwesomeIcon className="me-2" icon={icon({ name: 'warning' })} /> This feature is
                                        still under construction. Expect errors.{' '}
                                        <FontAwesomeIcon className="me-2" icon={icon({ name: 'warning' })} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Blank;
