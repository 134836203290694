import React, { useState } from 'react';
import { BrowserRouter, Route, Routes as Switch } from 'react-router-dom';
import AppContext from '../components/AppContext/AppContext';
import WebSocketContext from '../components/WebsocketContext/WebsocketContext';
import Authorizer from '../components/Authorizer/Authorizer';
import { AccessVerifier } from '../components/Layout/Layout';
import Login from '../components/Login/Login';
import EmployeeProfile from '../components/EmployeeProfile/EmployeeProfile';
import Dashboard from '../components/Dashboard/Dashboard';
import Blank from '../components/Blank/Blank';
import EmployeeRegistrationForm from '../components/Employees/EmployeeRegistration';
import GlobalErrorHandler from '../components/GlobalError/GlobalError';
import NotFound from '../components/NotFound/NotFound';
import EmployeeList from '../components/Employees/List/EmployeeList';
import EmployeeDetails from '../components/Employees/Details/EmployeeDetails';
import UserList from '../components/User/List/UserList';
import { CUSTOMER_USERS, REGULAR_USERS, UserRole } from '../types/user';
import EmployeeUpdateForm from '../components/Employees/EmployeeUpdate';
import UserAccessUpdateForm from '../components/User/Access/UserUpdate';
import ApplyLeaveForm from '../components/EmployeeLeaves/ApplyLeave';
import EmployeeLeavesApprovalList from '../components/EmployeeLeaves/List/EmployeeLeavesApprovalList';
import EmployeeDocuments from '../components/EmployeeDocuments/EmployeeDocuments';
import ReporteeDocumentList from '../components/EmployeeDocuments/ReporteeDocumentList/ReporteeDocumentList';
import ReporteesList from '../components/Reportees/ReporteeList';
import EmployeeTimesheetEntryForm from '../components/EmployeeTimesheet/Submit/EmployeeTimesheetEntryForm';
import EmployeeTimesheetList from '../components/EmployeeTimesheet/List/EmployeeTimesheetList';
import ApplicationAccess from '../components/User/Access/ApplicationAccess';
import EmployeeLeavesListContainer from '../components/EmployeeLeaves/List/EmployeeLeavesListContainer';
import EmployeeTimesheetMultiWeek from '../components/EmployeeTimesheet/Submit/EmployeeTimesheetMultiWeek';
import EmployeeUpdateTimesheetMultiWeek from '../components/EmployeeTimesheet/Submit/EmployeeUpdateTimesheetMultiWeek';
import ReporteesWeeklyTimesheetList from '../components/EmployeeTimesheet/List/ReporteesWeeklyTimesheetList';
import LeaveAllocations from '../components/ControlCenter/LeaveAllocations/LeaveAllocations';
import EmployeeLeaveAllocation from '../components/ControlCenter/LeaveAllocations/EmployeeLeaveAllocation';
import ForgotPassword from '../components/Login/ForgotPassword';
import ResetPassword from '../components/Login/ResetPassword';
import EmployeeWeeklyTimesheetListContainer from '../components/EmployeeTimesheet/List/EmployeeWeeklyTimesheetListContainer';
import AddOrgHolidays from '../components/ControlCenter/OrgHolidays/AddOrgHolidays';
import CustomerRegistration from '../components/Customer/CustomerRegistration';
import CustomerList from '../components/Customer/List/CustomerList';
import CustomerDetails from '../components/Customer/Details/CustomerDetails';
import AllocateResource from '../components/Customer/AllocateResource/AllocateResource';
import UpdateAllocateResource from '../components/Customer/AllocateResource/UpdateResourceAllocation';
import HelpCenter from '../components/HelpCenter/HelpCenter';
import PrivacyStatement from '../components/PrivacyStatement/PrivacyStatement';
import TermsConditions from '../components/HelpCenter/TermsConditions';
import CustomerUpdateContainer from '../components/Customer/CustomerUpdateContainer';
import CustomerAllocatedResources from '../components/Customer/CustomerAllocatedResources';
import OrgPolicies from '../components/OrgPolicies/OrgPolicies';
import EmployeePreferences from '../components/ControlCenter/EmployeePreferences/EmployeePreferences';
import ManageMasterData from '../components/ControlCenter/ManageMasterData/ManageMasterData';
import LeaveCalendar from '../components/LeaveCalendar/LeaveCalendar';
import SurveyFormConfigure from '../components/ControlCenter/SurveyConfig/SurveyConfig';
import SurveyForm from '../components/ControlCenter/SurveyConfig/SurveyForm';
import SubmitLiveSurvey from '../components/EmployeeSurvey/SubmitLiveSurvey';
import EmployeeResults from '../components/ControlCenter/SurveyResults/EmployeeResults';

export default function Routes() {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const handleSidebarCollapse = () => {
        setSidebarCollapsed(!sidebarCollapsed);
    };

    type DefaultLayoutProps = { children: JSX.Element; excludeRoles: UserRole[] };

    const DefaultLayout = ({ children, excludeRoles }: DefaultLayoutProps) => {
        return (
            <>
                <GlobalErrorHandler />
                <AccessVerifier
                    sidebarCollapsed={sidebarCollapsed}
                    handleSidebarCollapse={handleSidebarCollapse}
                    excludeRoles={excludeRoles}
                >
                    {children}
                </AccessVerifier>
            </>
        );
    };

    return (
        <BrowserRouter>
            <Authorizer>
                <AppContext>
                    <WebSocketContext>
                        <Switch>
                            <Route
                                index
                                element={
                                    <DefaultLayout excludeRoles={[]}>
                                        <Dashboard />
                                    </DefaultLayout>
                                }
                            />
                            {/* <Route
                                path="/project/list"
                                element={
                                    <DefaultLayout excludeRoles={[]}>
                                        <Projects />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/project/add"
                                element={
                                    <DefaultLayout excludeRoles={[]}>
                                        <AddProjectForm />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/project/details/:id"
                                element={
                                    <DefaultLayout excludeRoles={[]}>
                                        <ProjectDetails />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/project/update/:id"
                                element={
                                    <DefaultLayout excludeRoles={[]}>
                                        <UpdateProjectForm />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/project/:id/resource/allocation"
                                element={
                                    <DefaultLayout excludeRoles={[]}>
                                        <AddAllocation />
                                    </DefaultLayout>
                                }
                            /> */}
                            <Route
                                path="/user/profile"
                                element={
                                    <DefaultLayout excludeRoles={[]}>
                                        <EmployeeProfile />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/user/register/access"
                                element={
                                    <DefaultLayout excludeRoles={REGULAR_USERS}>
                                        <ApplicationAccess />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/user/list"
                                element={
                                    <DefaultLayout excludeRoles={REGULAR_USERS}>
                                        <UserList />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/user/update/:id"
                                element={
                                    <DefaultLayout excludeRoles={[UserRole.employee]}>
                                        <UserAccessUpdateForm />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/employee/register"
                                element={
                                    <DefaultLayout excludeRoles={REGULAR_USERS}>
                                        <EmployeeRegistrationForm />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/employee/list"
                                element={
                                    <DefaultLayout excludeRoles={REGULAR_USERS}>
                                        <EmployeeList />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/employee/documents"
                                element={
                                    <DefaultLayout excludeRoles={[]}>
                                        <EmployeeDocuments />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/employee/documents/all"
                                element={
                                    <DefaultLayout excludeRoles={[UserRole.employee, UserRole.manager]}>
                                        <ReporteeDocumentList />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/employee/details/:id"
                                element={
                                    <DefaultLayout excludeRoles={[UserRole.employee]}>
                                        <EmployeeDetails />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/employee/update/:id"
                                element={
                                    <DefaultLayout excludeRoles={REGULAR_USERS}>
                                        <EmployeeUpdateForm />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/employee/leave/apply"
                                element={
                                    <DefaultLayout excludeRoles={[]}>
                                        <ApplyLeaveForm />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/employee/leaves/list"
                                element={
                                    <DefaultLayout excludeRoles={[]}>
                                        <EmployeeLeavesListContainer />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/employee/reportee/list"
                                element={
                                    <DefaultLayout excludeRoles={[UserRole.employee]}>
                                        <ReporteesList />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/employee/reportee/leaves/list"
                                element={
                                    <DefaultLayout excludeRoles={[UserRole.employee]}>
                                        <EmployeeLeavesApprovalList />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/employee/reportee/applied/leaves/list/:id"
                                element={
                                    <DefaultLayout excludeRoles={[UserRole.employee]}>
                                        <EmployeeLeavesListContainer />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/employee/timesheet/log-hours"
                                element={
                                    <DefaultLayout excludeRoles={[]}>
                                        <EmployeeTimesheetEntryForm />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/employee/timesheet/weekly/log-hours"
                                element={
                                    <DefaultLayout excludeRoles={[]}>
                                        <EmployeeTimesheetMultiWeek />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/employee/timesheet/submissions"
                                element={
                                    <DefaultLayout excludeRoles={[]}>
                                        <EmployeeTimesheetList />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/employee/weekly-timesheet/list"
                                element={
                                    <DefaultLayout excludeRoles={[]}>
                                        <EmployeeWeeklyTimesheetListContainer />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/employee/reportee/submitted/weekly-timesheet/list/:id"
                                element={
                                    <DefaultLayout excludeRoles={[UserRole.employee]}>
                                        <EmployeeWeeklyTimesheetListContainer />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/employee/weekly-timesheet/update/:id"
                                element={
                                    <DefaultLayout excludeRoles={[]}>
                                        <EmployeeUpdateTimesheetMultiWeek />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/employee/reportee/weekly-timesheet/list"
                                element={
                                    <DefaultLayout excludeRoles={[UserRole.employee]}>
                                        <ReporteesWeeklyTimesheetList />
                                    </DefaultLayout>
                                }
                            />
                            {/* EXCELLENCE CENTER ROUTES */}
                            <Route
                                path="/employee/excellence-center/survey/submission"
                                element={
                                    <DefaultLayout excludeRoles={[...CUSTOMER_USERS]}>
                                        <SubmitLiveSurvey />
                                    </DefaultLayout>
                                }
                            />

                            {/* CONTROL-CENTER ROUTES */}
                            <Route
                                path="/control-center/leave-allocation"
                                element={
                                    <DefaultLayout excludeRoles={[...REGULAR_USERS, ...CUSTOMER_USERS]}>
                                        <LeaveAllocations />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/control-center/employee-leave-allocation"
                                element={
                                    <DefaultLayout excludeRoles={[...REGULAR_USERS, ...CUSTOMER_USERS]}>
                                        <EmployeeLeaveAllocation />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/control-center/employee-preferences"
                                element={
                                    <DefaultLayout excludeRoles={[...REGULAR_USERS, ...CUSTOMER_USERS]}>
                                        <EmployeePreferences />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/control-center/data-hub"
                                element={
                                    <DefaultLayout excludeRoles={[...REGULAR_USERS, ...CUSTOMER_USERS]}>
                                        <ManageMasterData />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/control-center/manage-org-holidays"
                                element={
                                    <DefaultLayout excludeRoles={[...REGULAR_USERS, ...CUSTOMER_USERS]}>
                                        <AddOrgHolidays />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/control-center/survey-config"
                                element={
                                    <DefaultLayout excludeRoles={[...REGULAR_USERS, ...CUSTOMER_USERS]}>
                                        <SurveyFormConfigure />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/control-center/add/survey-form"
                                element={
                                    <DefaultLayout excludeRoles={[...REGULAR_USERS, ...CUSTOMER_USERS]}>
                                        <SurveyForm />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/control-center/update/survey-form/:id"
                                element={
                                    <DefaultLayout excludeRoles={[...REGULAR_USERS, ...CUSTOMER_USERS]}>
                                        <SurveyForm />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/control-center/employee/survey-result/:id"
                                element={
                                    <DefaultLayout excludeRoles={[...REGULAR_USERS, ...CUSTOMER_USERS]}>
                                        <EmployeeResults />
                                    </DefaultLayout>
                                }
                            />
                            {/* CUSTOMER ROUTES */}
                            <Route
                                path="/customer/register"
                                element={
                                    <DefaultLayout excludeRoles={[...REGULAR_USERS, UserRole.hr]}>
                                        <CustomerRegistration />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/customer/list"
                                element={
                                    <DefaultLayout excludeRoles={[...REGULAR_USERS, ...CUSTOMER_USERS, UserRole.hr]}>
                                        <CustomerList />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/customer/details/:id"
                                element={
                                    <DefaultLayout excludeRoles={[...REGULAR_USERS, UserRole.hr]}>
                                        <CustomerDetails />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/customer/update/:id"
                                element={
                                    <DefaultLayout excludeRoles={[...REGULAR_USERS, UserRole.hr]}>
                                        <CustomerUpdateContainer />
                                    </DefaultLayout>
                                }
                            />

                            <Route
                                path="/customer/resource/allocate"
                                element={
                                    <DefaultLayout excludeRoles={[...REGULAR_USERS, UserRole.hr]}>
                                        <AllocateResource />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/customer/update/resource/allocation/:id"
                                element={
                                    <DefaultLayout excludeRoles={[...REGULAR_USERS, UserRole.hr, ...CUSTOMER_USERS]}>
                                        <UpdateAllocateResource />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/customer/account/profile"
                                element={
                                    <DefaultLayout excludeRoles={[...REGULAR_USERS, UserRole.hr]}>
                                        <CustomerUpdateContainer />
                                    </DefaultLayout>
                                }
                            />

                            <Route
                                path="/customer/account/allocated/resources"
                                element={
                                    <DefaultLayout excludeRoles={[...REGULAR_USERS, UserRole.hr]}>
                                        <CustomerAllocatedResources />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/customer/resource/applied/leaves/list"
                                element={
                                    <DefaultLayout excludeRoles={[...REGULAR_USERS, UserRole.hr]}>
                                        <EmployeeLeavesApprovalList />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/customer/resource/timesheet/submission/list"
                                element={
                                    <DefaultLayout excludeRoles={[...REGULAR_USERS, UserRole.hr]}>
                                        <ReporteesWeeklyTimesheetList />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/customer/resource/calendar"
                                element={
                                    <DefaultLayout excludeRoles={[...REGULAR_USERS, UserRole.hr]}>
                                        <LeaveCalendar />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/employees/calendar"
                                element={
                                    <DefaultLayout excludeRoles={[...REGULAR_USERS, ...CUSTOMER_USERS]}>
                                        <LeaveCalendar />
                                    </DefaultLayout>
                                }
                            />

                            {/* GENERAL ROUTES */}
                            <Route
                                path="/blank"
                                element={
                                    <DefaultLayout excludeRoles={[]}>
                                        <Blank />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/org-policies"
                                element={
                                    <DefaultLayout excludeRoles={[]}>
                                        <OrgPolicies />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="*"
                                element={
                                    <DefaultLayout excludeRoles={[]}>
                                        <NotFound />
                                    </DefaultLayout>
                                }
                            />

                            <Route
                                path="/help-center"
                                element={
                                    <DefaultLayout excludeRoles={[]}>
                                        <HelpCenter />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/privacy"
                                element={
                                    <DefaultLayout excludeRoles={[]}>
                                        <PrivacyStatement />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/terms-and-conditions"
                                element={
                                    <DefaultLayout excludeRoles={[]}>
                                        <TermsConditions />
                                    </DefaultLayout>
                                }
                            />
                            <Route path="login" element={<Login />} />
                            <Route path="forgot-password" element={<ForgotPassword />} />
                            <Route path="reset-password/:sec/:d" element={<ResetPassword />} />
                        </Switch>
                    </WebSocketContext>
                </AppContext>
            </Authorizer>
        </BrowserRouter>
    );
}
