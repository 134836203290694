import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useAppContext } from '../../../hooks/useAppContext';
import { updateDocumentStatus } from '../../../services/employeeDocuments';
import { EmployeeDocuments } from '../../../types/employee';
import { AlphaNumericRegex } from '../../../constants/validations';

type DocumentActionModalProp = {
    empDocument: EmployeeDocuments;
    showModal: boolean;
    handleClose: () => void;
};
// TODO: Better Design and complete Data.
export const DocumentActionModal = (prop: DocumentActionModalProp) => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const [error, setError] = useState('');
    const [comments, setComments] = useState('');
    const { triggerSuccess } = useAppContext();

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setComments(value);
    };

    const handleSubmit = async (status: 'REQUESTED' | 'APPROVED' | 'REJECTED') => {
        if (!comments) {
            setError('Please add some comment');
        } else if (comments.length > 40) {
            setError('comments too long.');
        } else if (!AlphaNumericRegex.test(comments)) {
            setError('Comments should only contain alphanumeric characters only');
        } else {
            setSubmitLoading(true);
            //SUBMIT IT
            try {
                await updateDocumentStatus(prop.empDocument.uuid, comments, status);
                setSubmitLoading(false);
                prop.handleClose();
                setComments('');
                triggerSuccess(`Document status updated`);
                setTimeout(() => window.location.reload(), 700);
            } catch (_e) {
                setSubmitLoading(false);
                prop.handleClose();
                setComments('');
            }
        }
    };

    return (
        <Modal centered={true} show={prop.showModal} onHide={() => prop.handleClose()}>
            <Modal.Header>
                <Modal.Title>
                    <h3 className="ms-2">
                        {' '}
                        {prop.empDocument.employeeName} - {capitalize(prop.empDocument.documentType.replaceAll('_', ' '))}
                    </h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="p-3">
                    <h4>Comments</h4>
                    <div className="">
                        {submitLoading ? (
                            <div key={0} className="row">
                                <div className="col-sm-12 mt-2 mb-2 text-center">
                                    <div className="spinner-border text-primary me-2" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <textarea
                                    rows={5}
                                    className={`form-control form-control-lg mb-3 ${error ? 'is-invalid' : ''}`}
                                    name="temporaryAddress"
                                    value={comments}
                                    onChange={handleChange}
                                />
                                {error && <div className="invalid-feedback mb-3">{error}</div>}
                                <div className="text-end mt-2">
                                    <button
                                        type="button"
                                        className="btn btn-danger me-2"
                                        onClick={() => handleSubmit('REJECTED')}
                                    >
                                        Reject
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-success ms-2"
                                        onClick={() => handleSubmit('APPROVED')}
                                    >
                                        Approve
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => prop.handleClose()}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
