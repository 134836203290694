import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import vodichron_logo from '../../assets/img/vodichron_logo.png';
import { useAuth } from '../../hooks/useAuth';
import { IRootState } from '../../store';
import './Login.scss';

const Login = () => {
    const { onLogin } = useAuth();
    const globalError = useSelector((state: IRootState) => state.error);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(false);
        setUsername(event.currentTarget.value);
    };

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(false);
        // 👇 Get input value from "event"
        setPassword(event.currentTarget.value);
    };

    const onClickLogin = () => {
        setLoading(true);
        if (username === '' || password === '') {
            setError(`Invalid username or password`);
        } else {
            setError('');
            onLogin({ username, password });
        }
        setLoading(false);
    };
    const handleKeyPress = (event: React.KeyboardEvent<Element>) => {
        if (event.key === 'Enter') {
            onClickLogin();
        }
    };

    return (
        <main className="d-flex w-100">
            <div className="login-container container d-flex flex-column">
                <div className="row vh-100">
                    <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                        <div className="d-table-cell align-middle">
                            <div className="brand text-center mt-4">
                                <h1 className="h2">Vodichron Resource Management System</h1>
                                <p className="lead">Embed Square Solutions Pvt. Ltd.</p>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    {error && <p className="error">{error}</p>}
                                    {globalError.isError && <p className="error">{globalError.message}</p>}
                                    <div className="m-sm-4">
                                        <div className="text-center">
                                            <img
                                                src={vodichron_logo}
                                                alt="Embed Square Solutions Pvt. Ltd."
                                                className="img-fluid rounded-circle"
                                                width="100"
                                                height="100"
                                            />
                                        </div>
                                        <form>
                                            <div className="mb-3">
                                                <label className="form-label">Email</label>
                                                <input
                                                    className="form-control form-control-lg"
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter your email"
                                                    value={username}
                                                    onChange={handleChangeUsername}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Password</label>
                                                <input
                                                    className="form-control form-control-lg"
                                                    type="password"
                                                    name="password"
                                                    placeholder="Enter your password"
                                                    value={password}
                                                    onChange={handleChangePassword}
                                                    autoComplete="false"
                                                    onKeyDown={handleKeyPress}
                                                />
                                                <span>
                                                    <NavLink to="/forgot-password">Forgot Password?</NavLink>
                                                </span>
                                            </div>
                                            <div className="text-center mt-3">
                                                <button
                                                    type="button"
                                                    className="btn btn-lg btn-primary"
                                                    onClick={onClickLogin}
                                                >
                                                    Sign in
                                                    {loading && !globalError.isError && (
                                                        <div
                                                            className="spinner-border spinner-border-sm me-2 mx-2"
                                                            role="status"
                                                        >
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    )}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Login;
