import React, { useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import * as Icon from 'react-feather';
import { NavLink, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { SurveyConfig } from '../../../types/survey';

type Props = {
    surveyList: SurveyConfig[];
    showSurveyQuestionnaire: (i: number, s: string) => void;
    deleteFormDetails: (s: string) => void;
    surveyType: 'EMPLOYEE' | 'CUSTOMER';
};
const SurveyFormList = ({ surveyList, showSurveyQuestionnaire, deleteFormDetails, surveyType }: Props) => {
    const [deleteTriggered, setDeleteTriggered] = useState(false);
    const [selectedId, setSelectedId] = useState<string | null>(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const navigate = useNavigate();

    const showDeleteConfirmationModal = (surveyId: string) => {
        setSelectedId(surveyId);
        setShowDeleteConfirmation(true);
    };
    const handleDeleteConfirm = async () => {
        //deleting now
        setDeleteTriggered(true);
        await deleteFormDetails(selectedId as string);
        setDeleteTriggered(false);
        setShowDeleteConfirmation(false);
    };

    const cloneAndCreateNew = (id: string) => {
        navigate(`/control-center/update/survey-form/${id}`, {
            state: {
                formClone: true,
            },
        });
    };

    return (
        <div className="tab-content">
            {surveyList.length > 0 ? (
                <>
                    <div className="row gx-0 col-12 bg-app-header text-white fw-bold p-2">
                        <div className="col-2 p-2">Survey Type</div>
                        <div className="col-2 p-2">Survey Name</div>
                        <div className="col-2 p-2">Start Date</div>
                        <div className="col-2 p-2">End Date</div>
                        <div className="col-1 p-2">Live</div>
                        <div className="col-1 p-2">Results</div>
                        <div className="col-2 p-2">Actions</div>
                    </div>
                    <div className="row gx-0 bg-app-row p-2">
                        {surveyList?.map((form, index) => (
                            <div key={form.uuid} className="row gx-0 col-12 py-1">
                                <div className="col-2 py-1 px-2">{form.surveyType}</div>
                                <div className="col-2 py-1 px-2">{form.surveyTitle}</div>
                                <div className="col-2 py-1 px-2">{moment(form.startDate).format('DD/MM/YYYY')}</div>
                                <div className="col-2 py-1 px-2">{moment(form.endDate).format('DD/MM/YYYY')}</div>{' '}
                                <div className="col-1 py-1 px-3">
                                    {form.isLive ? (
                                        <FontAwesomeIcon
                                            icon={icon({ name: 'circle' })}
                                            size="sm"
                                            className="text-success"
                                        />
                                    ) : (
                                        <FontAwesomeIcon icon={icon({ name: 'circle' })} size="sm" className="text-danger" />
                                    )}
                                </div>
                                <div className="col-1 py-1 px-3">
                                    <NavLink
                                        to={`/control-center/employee/survey-result/${form.uuid}`}
                                        title="View Details"
                                        className="ms-2 "
                                    >
                                        <FontAwesomeIcon icon={icon({ name: 'square-poll-vertical' })} size="lg" />
                                    </NavLink>
                                </div>
                                <div className="col-2 py-1 px-2">
                                    <span
                                        title="View Details"
                                        className="me-2 cursor-pointer"
                                        onClick={() => showSurveyQuestionnaire(index, surveyType)}
                                    >
                                        <FontAwesomeIcon
                                            title="View Details"
                                            icon={icon({ name: 'info-circle' })}
                                            size="lg"
                                            className="text-primary"
                                        />
                                    </span>
                                    <NavLink
                                        to={`/control-center/update/survey-form/${form.uuid}`}
                                        title="View Details"
                                        className="ms-2"
                                    >
                                        <FontAwesomeIcon icon={icon({ name: 'pen' })} size="lg" />
                                    </NavLink>
                                    <FontAwesomeIcon
                                        className="icons mx-3 cursor-pointer"
                                        size="lg"
                                        icon={icon({ name: 'trash-can' })}
                                        onClick={() => showDeleteConfirmationModal(form.uuid)}
                                    />
                                    <FontAwesomeIcon
                                        className="icons ms-2 cursor-pointer text-info"
                                        size="lg"
                                        icon={icon({ name: 'clone' })}
                                        onClick={() => cloneAndCreateNew(form.uuid)}
                                        title="Copy details and create new form."
                                    />
                                </div>
                            </div>
                        ))}
                    </div>

                    <Modal show={showDeleteConfirmation} centered={true} onHide={() => setShowDeleteConfirmation(false)}>
                        {!deleteTriggered ? (
                            <>
                                {' '}
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} size="lg" />
                                        <span className="ms-2">Delete Survey Data</span>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>You are about to delete the survey.</p>
                                    <p>
                                        This operation is irreversible. Once the data is deleted it cannot be recovered back.
                                    </p>
                                    <p>Which includes survey configuration, survey results and associated historic data.</p>
                                    <p>Do you wish to continue?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={handleDeleteConfirm}>
                                        Delete Now
                                    </Button>
                                </Modal.Footer>
                            </>
                        ) : (
                            <>
                                {' '}
                                <Modal.Header>
                                    <Modal.Title>
                                        <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} size="lg" />
                                        <span className="ms-2">Delete Survey Data</span>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <h3 className="text-center">Your delete request is being processed</h3>
                                    <p className="text-center">
                                        <div className="spinner-border spinner-border-sm me-2" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </p>
                                </Modal.Body>
                            </>
                        )}
                    </Modal>
                </>
            ) : (
                <div className="row">
                    <div className="col-sm-12">
                        <div className="alert alert-danger alert-outline alert-dismissible mt-5" role="alert">
                            <div className="alert-icon">
                                <Icon.Bell className="far fa-fw fa-bell" />
                            </div>
                            <div className="alert-message">
                                <p>
                                    <strong>Currently there no survey forms added yet for {surveyType}.</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}{' '}
        </div>
    );
};

export default SurveyFormList;
