import moment from 'moment';

export const calculateLeaveDays = (startDate: string, endDate: string) => {
    if (startDate && endDate) {
        const a = moment(Date.parse(startDate));
        const b = moment(Date.parse(endDate));
        const diff = b.diff(a, 'days'); // =1
        if (diff > 0) {
            return diff + 1;
        }
    }

    return 1;
};

// Helper function to convert time in HH:mm format to decimal
export const getHoursFromString = (timeString: string) => {
    const hours = timeString.replace(':', '.');
    return parseFloat(hours);
};

const getTotalHours = (timeString: string) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const minInHours = minutes / 60;
    const totalHours = hours + minInHours;
    return totalHours;
};

export const calculateTotalHours = (timeInputs: string[]): string => {
    const total = timeInputs.reduce((sum, entry) => {
        const colValue = entry ? getTotalHours(entry) : 0;
        return sum + colValue;
    }, 0);
    const hours = total.toFixed(2);
    const [wholeHours, decimal] = hours.split('.');
    const minutes = decimal ? String(Math.round(parseFloat(`0.${decimal}`) * 60)) : '00';
    const formattedValue = `${String(wholeHours).padStart(2, '0')}:${String(minutes).padEnd(2, '0')}`;
    return formattedValue;
};
