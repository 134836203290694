/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import * as Icon from 'react-feather';
import InfiniteScroll from 'react-infinite-scroller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { capitalize } from 'lodash';
import { getUserList } from '../../../services/user';
import { ApplicationUser, UserAccountStatus, UserFilters, UserProfile, UserRole } from '../../../types/user';
import { IRootState, useSelector } from '../../../store';
import './UserList.scss';

const UserList = () => {
    const loggedInUser = useSelector((state: IRootState) => state.user) as UserProfile;
    const [users, setUsers] = useState<ApplicationUser[]>([]);
    const [filters, setFilters] = useState<UserFilters>({});
    const [error, setError] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const fetchUsers = useCallback(async (page: number, searchFilters: UserFilters) => {
        try {
            setLoading(true);
            const { data } = await getUserList(page, searchFilters);
            if (data.length > 0) {
                setHasMore(true);
                setUsers((prevData) => [...prevData, ...data]);
            } else {
                setHasMore(false);
            }
            setLoading(false);
        } catch (err: unknown) {
            setError('Something went wrong');
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const filterType = event.target.name; // Get the id of the select element as filterType
        const newFilters: UserFilters = { ...filters };
        if (event.target.value !== '0') {
            newFilters[filterType as keyof UserFilters] = event.target.value;
            setFilters(newFilters);
        } else {
            delete newFilters[filterType as keyof UserFilters];
            setFilters(newFilters);
        }
    };

    const handleSearchClick = () => {
        setUsers([]);
        fetchUsers(1, filters);
    };

    const handleClearFilter = () => {
        setFilters({ role: '0' });
        setUsers([]);
        fetchUsers(1, {});
    };

    useEffect(() => {
        fetchUsers(1, filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasValidFilters = () => {
        let validFilters = false;
        for (const key in filters) {
            if (filters.hasOwnProperty(key) && filters[key as keyof UserFilters] === '0') {
                // Found a property with value '0', so consider it a valid filter
                validFilters = true;
                break; // Exit the loop, no need to continue checking
            }
        }
        return validFilters;
    };
    return (
        <main className="content view-app-users">
            <div className="container-fluid p-0">
                <div className="row mb-2 mb-xl-3">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon size="lg" icon={icon({ name: 'paper-plane' })} /> Application Access
                        </h3>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-info">
                                <p>
                                    This page provides overview of application employees which have access to this
                                    application.{' '}
                                </p>
                                <p className="mb-0">
                                    <strong>Note:</strong>
                                </p>
                                <ul>
                                    <li>
                                        Only <strong>Super User</strong> can see other <strong>Super User</strong> role
                                        access.
                                    </li>
                                </ul>
                            </div>
                            <div className="card-body">
                                <div
                                    id="datatables-reponsive_wrapper"
                                    className="dataTables_wrapper dt-bootstrap5 no-footer"
                                >
                                    {error && (
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div
                                                    className="alert alert-danger alert-outline alert-dismissible"
                                                    role="alert"
                                                >
                                                    <div className="alert-icon">
                                                        <Icon.Bell className="far fa-fw fa-bell" />
                                                    </div>
                                                    <div className="alert-message">
                                                        <strong>Error</strong> Something went wrong when trying to get the
                                                        User data.
                                                        <br /> If the problem persists, please contact support.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {(users?.length !== 0 || !hasValidFilters()) && (
                                        <>
                                            <div className="search-container">
                                                <div className="mb-3 row">
                                                    <strong>Filter By:</strong>
                                                </div>
                                                <div className="mb-3 row">
                                                    <div className="col-3">
                                                        <select
                                                            name="role"
                                                            className="form-control form-control-lg form-select"
                                                            onChange={handleFilterChange}
                                                            value={filters.role}
                                                        >
                                                            <option value="0">Select user role</option>
                                                            {loggedInUser.role === UserRole.superUser && (
                                                                <option value="super_user">Super User</option>
                                                            )}

                                                            <option value="hr">HR Executive</option>
                                                            <option value="employee">Employee</option>
                                                            <option value="manager">Manager</option>
                                                            <option value="director">Director</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-1">
                                                        {' '}
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={handleSearchClick}
                                                        >
                                                            Search
                                                        </button>
                                                    </div>
                                                    <div className="col-md-1 text-start pt-1 pl-1">
                                                        <span
                                                            onClick={handleClearFilter}
                                                            className="text-primary cursor-pointer"
                                                        >
                                                            Clear All
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {!loading && !error && users?.length === 0 && (
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div
                                                    className="alert alert-danger alert-outline alert-dismissible mt-5"
                                                    role="alert"
                                                >
                                                    <div className="alert-icon">
                                                        <Icon.Bell className="far fa-fw fa-bell" />
                                                    </div>
                                                    <div className="alert-message">
                                                        <p>
                                                            {hasValidFilters() ? (
                                                                <strong>
                                                                    No data available for application users. <br /> It is
                                                                    possible that there is no data added yet.
                                                                </strong>
                                                            ) : (
                                                                <strong>Search did not return any data.</strong>
                                                            )}{' '}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {users?.length !== 0 && (
                                        <>
                                            <div style={{ height: '500px', overflow: 'auto' }}>
                                                <InfiniteScroll
                                                    pageStart={1}
                                                    loadMore={(page: number) => fetchUsers(page, filters)}
                                                    hasMore={hasMore && !loading}
                                                    loader={
                                                        <div key={0} className="row">
                                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                                <div
                                                                    className="spinner-border text-primary me-2"
                                                                    role="status"
                                                                >
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    useWindow={false}
                                                >
                                                    <table
                                                        id="datatables-reponsive"
                                                        className="table table-striped dataTable no-footer dtr-inline view-users-table"
                                                        style={{ width: '100%', tableLayout: 'fixed' }}
                                                        aria-describedby="datatables-reponsive_info"
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Role</th>
                                                                <th>Department</th>
                                                                <th>Designation</th>
                                                                <th>Official Email</th>
                                                                <th>Access status</th>
                                                                <th style={{ width: '80px' }}>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {users &&
                                                                users.map((user, index) => (
                                                                    <tr
                                                                        className={cn({
                                                                            odd: index % 2 === 0,
                                                                            even: index % 2 !== 0,
                                                                        })}
                                                                        key={`${user.uuid}-${index}`}
                                                                    >
                                                                        <td>{user.name}</td>
                                                                        <td>
                                                                            {capitalize(user.role).replace('_', ' ') ?? '-'}
                                                                        </td>
                                                                        <td>{user.department ?? '-'}</td>
                                                                        <td>{user.designation ?? '-'}</td>
                                                                        <td>{user.officialEmailId ?? '-'}</td>
                                                                        <td>
                                                                            {user.status === UserAccountStatus.ACTIVE ? (
                                                                                <span className="text-white fw-bold bg-success p-1 px-2">
                                                                                    {user.status}
                                                                                </span>
                                                                            ) : (
                                                                                <span className="text-dark fw-bold bg-warning p-1 px-2">
                                                                                    {user.status}
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <NavLink to={`/user/update/${user.uuid}`}>
                                                                                <FontAwesomeIcon
                                                                                    icon={icon({ name: 'pen' })}
                                                                                    size="lg"
                                                                                />
                                                                            </NavLink>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </InfiniteScroll>
                                            </div>
                                        </>
                                    )}
                                    {loading && (
                                        <div key={0} className="row">
                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                <div className="spinner-border text-primary me-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default UserList;
