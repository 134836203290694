// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.org-policies .doc-container {
  box-shadow: rgb(162, 164, 167) 0px 2px 8px 0px;
  font-size: 16px;
  background: #dae5f1;
}`, "",{"version":3,"sources":["webpack://./src/components/OrgPolicies/OrgPolicies.scss"],"names":[],"mappings":"AACI;EACI,8CAAA;EACA,eAAA;EACA,mBAAA;AAAR","sourcesContent":[".org-policies {\n    .doc-container { \n        box-shadow: rgb(162, 164, 167) 0px 2px 8px 0px;\n        font-size: 16px;\n        background: #dae5f1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
