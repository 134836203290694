/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useAppContext } from '../../hooks/useAppContext';
import { getEmployeeById, updateEmployee, validateIfEmailAvailable } from '../../services/employee';
import { IRootState, useSelector } from '../../store';
import { UserProfile } from '../../types/user';
import employeeFormValidate from '../Employees/EmployeeFormValidation';
import GeneralInfo from '../Employees/TabContent/GeneralInfo';
import ContactInfo from '../Employees/TabContent/ContactInfo';
import OfficialInfo from '../Employees/TabContent/OfficialInfo';
import BankingInfo from '../Employees/TabContent/BankingInfo';
import EducationAndExperienceInfo from '../Employees/TabContent/EducationAndExperienceInfo';
import ApplicationAccessInfo from '../Employees/TabContent/ApplicationAccessInfo';
import '../Employees/EmployeeRegistration.scss';
import { EmployeeImage } from '../EmployeeImage/EmployeeImage';
import { Activities } from '../../constants/activityMaster';
import { objectToPlainString } from '../../helper/stringHelper';
import { UploadEmployeePhoto } from './UploadPhoto';

const EmployeeProfile = () => {
    const [key, setKey] = useState('1');
    const [oGPersonalEmail, setOGPersonalEmail] = useState('');
    const [oGOfficialEmail, setOGOfficialEmail] = useState('');
    const [passwordChangeMessage, setPasswordChangeMessage] = useState('');
    const globalError = useSelector((state: IRootState) => state.error);
    const user = useSelector((state: IRootState) => state.user) as UserProfile;
    const [loading, setLoading] = useState(false);
    const [showDropZone, setShowDropZone] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [emailAvailableLoading, setEmailAvailableLoading] = useState(false);
    const [ofcEmailAvailableLoading, setOfcEmailAvailableLoading] = useState(false);
    const { triggerSuccess } = useAppContext();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [formData, setFormData] = useState<any>({});
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultError = {} as any;
    const [errors, setErrors] = useState(defaultError);

    const handleTabSelect = (k: string | null) => {
        if (k) {
            setKey(k);
        }
    };

    useEffect(() => {
        const fetchEmployeeDetails = async () => {
            if (user.uuid) {
                setLoadingData(true);
                const employee = await getEmployeeById(user.uuid as string);
                setFormData({
                    ...employee.data,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    dateOfJoining: Date.parse(employee.data.dateOfJoining!),
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    dateOfBirth: Date.parse(employee.data.dateOfBirth!),
                });
                setOGPersonalEmail(employee.data.personalEmail);
                setOGOfficialEmail(employee.data.officialEmailId);
                setLoadingData(false);
                const passwordWasUpdated = user.activities.find(
                    (activity) => activity.activityName === Activities.FIRST_PASSWORD_CHANGE
                );
                if (!passwordWasUpdated) {
                    setPasswordChangeMessage(
                        'You have not updated your initial password, request you to update it immediately.'
                    );
                    handleTabSelect('6');
                }
            }
        };
        fetchEmployeeDetails();
    }, [user]);

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
        setLoading(false);
        const value = e.target.value;
        setFormData({ ...formData, [e.target.name]: value });
    };

    const validateIfPersonalEmailAvailable = async () => {
        setEmailAvailableLoading(true);
        try {
            if (formData.personalEmail !== oGPersonalEmail) {
                const email = formData.personalEmail;
                const res = await validateIfEmailAvailable(email, 'personalEmail');
                delete errors['personalEmail'];
                setErrors({ ...errors });
                if (res !== false) {
                    setErrors({ ...defaultError, personalEmail: 'Employee with email already exists' });
                }
                setEmailAvailableLoading(false);
            }
            setEmailAvailableLoading(false);
        } catch (_e) {}
    };

    const validateIfOfficialEmailAvailable = async () => {
        setOfcEmailAvailableLoading(true);
        try {
            if (formData.officialEmailId !== oGOfficialEmail) {
                const email = formData.officialEmailId;
                const res = await validateIfEmailAvailable(email, 'officialEmailId');
                delete errors['officialEmailId'];
                setErrors({ ...errors });
                if (res !== false) {
                    setErrors({ ...defaultError, officialEmailId: 'Employee with email already exists' });
                }
                setOfcEmailAvailableLoading(false);
            }
            setOfcEmailAvailableLoading(false);
        } catch (_e) {}
    };

    const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        setLoading(true);
        e.preventDefault();
        setErrors(defaultError);
        if (formData.personalEmail !== oGPersonalEmail) {
            const exists = await validateIfEmailAvailable(formData.personalEmail, 'personalEmail');
            if (exists) {
                setErrors({ ...defaultError, personalEmail: 'Employee with email already exists' });
                setLoading(false);
                return;
            }
        }
        if (formData.officialEmailId !== oGOfficialEmail) {
            const exists = await validateIfEmailAvailable(formData.officialEmailId, 'officialEmailId');
            if (exists) {
                setErrors({ ...defaultError, officialEmailId: 'Employee with email already exists' });
                setLoading(false);
                return;
            }
        }
        const validateErrors = employeeFormValidate(formData, user.role);
        if (Object.keys(validateErrors).length === 0 && Object.keys(errors).length === 0) {
            const data = {
                ...formData,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any;
            try {
                // TODO: Need to clean up for handling this better
                await updateEmployee(data);
                triggerSuccess(`Employee updated successfully`);
                setTimeout(() => window.location.reload(), 1000);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (_e: any) {
                setLoading(false);
            }
        } else {
            setLoading(false);
            setErrors(validateErrors);
        }
    };

    return (
        <main className="content employee-registration">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon icon={icon({ name: 'user' })} /> My Profile
                        </h3>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-info">
                                <p>
                                    Please enter all the information for an employee which is mandatory marked with a
                                    asterisk (<span className="required-field">*</span>).
                                </p>
                                <p>
                                    Once you have filled out all the fields, press the &quot;Save Details&quot; button to
                                    save the information.
                                </p>
                                {/* <p className="text-secondary">
                                    Application account will be created for the employee by default. Email will be sent to
                                    the employee to update his details and manage his/her account.
                                </p> */}
                            </div>
                            <div className="row">
                                <div className="card-body col-8">
                                    {Object.keys(errors).length > 0 && (
                                        <div className="error-message">
                                            <p>
                                                <FontAwesomeIcon className="me-2" icon={icon({ name: 'warning' })} /> Looks
                                                like you have some errors on the form, check all the tabs before saving the
                                                details.
                                            </p>
                                            <p>
                                                Check below fields:
                                                <br />
                                                {Object.keys(errors)
                                                    .map((errorKey) => objectToPlainString(errorKey))
                                                    .join(', ')}
                                            </p>
                                        </div>
                                    )}
                                    {loadingData && (
                                        <div key={0} className="row">
                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                <div className="spinner-border text-primary me-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {passwordChangeMessage && (
                                        <p className="bg-info p-3 text-white">
                                            <FontAwesomeIcon
                                                size="lg"
                                                className="me-2"
                                                icon={icon({ name: 'info-circle' })}
                                            />{' '}
                                            {passwordChangeMessage}
                                        </p>
                                    )}
                                    {!loadingData && Object.keys(formData).length > 0 && (
                                        <Tabs activeKey={key} onSelect={handleTabSelect}>
                                            <Tab eventKey={'1'} title="General Info">
                                                <div className="tab-content">
                                                    <GeneralInfo
                                                        formData={formData}
                                                        formErrors={errors}
                                                        inputChangeHandler={handleChange}
                                                        validateIfAvailable={validateIfPersonalEmailAvailable}
                                                        emailAvailableLoading={emailAvailableLoading}
                                                        setFormData={setFormData}
                                                        updateFlow={true}
                                                        userRole={user.role}
                                                    />
                                                    <div className="text-end">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => handleTabSelect('2')}
                                                        >
                                                            Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey={'2'} title="Contact Info">
                                                <div className="tab-content">
                                                    <ContactInfo
                                                        formData={formData}
                                                        formErrors={errors}
                                                        inputChangeHandler={handleChange}
                                                        userRole={user.role}
                                                        updateFlow={true}
                                                    />
                                                    <div className="text-end">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => handleTabSelect('1')}
                                                        >
                                                            Previous
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => handleTabSelect('3')}
                                                        >
                                                            Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey={'3'} title="Education & Experience Info">
                                                <div className="tab-content">
                                                    <EducationAndExperienceInfo
                                                        formData={formData}
                                                        formErrors={errors}
                                                        inputChangeHandler={handleChange}
                                                        userRole={user.role}
                                                        updateFlow={true}
                                                    />

                                                    <div className="text-end mt-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => handleTabSelect('2')}
                                                        >
                                                            Previous
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => handleTabSelect('4')}
                                                        >
                                                            Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey={'4'} title="Official Info">
                                                <div className="tab-content">
                                                    <OfficialInfo
                                                        formData={formData}
                                                        formErrors={errors}
                                                        inputChangeHandler={handleChange}
                                                        setFormData={setFormData}
                                                        userRole={user.role}
                                                        ofcEmailAvailableLoading={ofcEmailAvailableLoading}
                                                        validateIfAvailable={validateIfOfficialEmailAvailable}
                                                        updateFlow={true}
                                                        setErrors={setErrors}
                                                        selfLogin={true}
                                                    />
                                                    <div className="text-end">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => handleTabSelect('3')}
                                                        >
                                                            Previous
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => handleTabSelect('5')}
                                                        >
                                                            Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey={'5'} title="Banking Info">
                                                <div className="tab-content">
                                                    <BankingInfo
                                                        formData={formData}
                                                        formErrors={errors}
                                                        inputChangeHandler={handleChange}
                                                        userRole={user.role}
                                                        updateFlow={true}
                                                    />

                                                    <div className="text-end mt-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => handleTabSelect('4')}
                                                        >
                                                            Previous
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => handleTabSelect('6')}
                                                        >
                                                            Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey={'6'} title="Credentials">
                                                <div className="tab-content">
                                                    <ApplicationAccessInfo
                                                        formData={formData}
                                                        formErrors={errors}
                                                        inputChangeHandler={handleChange}
                                                        userRole={user.role}
                                                        updateFlow={true}
                                                        selfLogin={true}
                                                    />

                                                    <div className="text-end mt-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => handleTabSelect('')}
                                                        >
                                                            Previous
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={handleSubmit}
                                                        >
                                                            Save Details{' '}
                                                            {loading && !globalError.isError && (
                                                                <div
                                                                    className="spinner-border spinner-border-sm   me-2"
                                                                    role="status"
                                                                >
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    )}
                                </div>
                                <div className="card-body col-4">
                                    {!loadingData && Object.keys(formData).length > 0 && (
                                        <div className="row col-md-12 ">
                                            <div className="col-md-6 mb-3 text-start">
                                                <EmployeeImage userId={user.uuid} showDeleteButton={true} />
                                            </div>
                                            <div className="col-md-6 mb-3 text-end">
                                                <button
                                                    onClick={() => setShowDropZone(!showDropZone)}
                                                    className="btn btn-primary btn-sm"
                                                >
                                                    {showDropZone ? 'Hide Uploader' : 'Upload Photo'}
                                                </button>
                                            </div>
                                            {showDropZone && (
                                                <div className="p-4 mb-3">
                                                    <UploadEmployeePhoto loggedInUserId={user.uuid} />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default EmployeeProfile;
