/* eslint-disable no-console */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { PrivacyStatmenContent } from './PrivacyContent';

const PrivacyStatement = () => {
    return (
        <main className="content user-registration">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon icon={icon({ name: 'shield-halved' })} /> Privacy Statment
                        </h3>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body col-12">
                                <PrivacyStatmenContent />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default PrivacyStatement;
