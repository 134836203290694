import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ActionStatus } from '../../types/employee';

type StatusIndicatorProp = {
    status: ActionStatus;
};

export const StatusIndicator = (prop: StatusIndicatorProp) => {
    if (prop.status === ActionStatus.REJECTED) {
        return (
            <span className="text-danger">
                <FontAwesomeIcon icon={icon({ name: 'xmark-circle' })} size="sm" className="status-rejected" />{' '}
                <strong>{prop.status}</strong>
            </span>
        );
    } else if (prop.status === ActionStatus.APPROVED) {
        return (
            <span className="text-success">
                <FontAwesomeIcon icon={icon({ name: 'check-circle' })} size="sm" className="status-approved" />{' '}
                <strong>{prop.status}</strong>
            </span>
        );
    } else if (prop.status === ActionStatus.PENDING || prop.status === ActionStatus.SAVED) {
        return (
            <span className="text-warning">
                <FontAwesomeIcon icon={icon({ name: 'circle-chevron-down' })} size="sm" className="status-warning" />{' '}
                <strong>{prop.status}</strong>
            </span>
        );
    }

    return (
        <span className="text-primary">
            <FontAwesomeIcon icon={icon({ name: 'dot-circle' })} size="sm" className="status-requested" />{' '}
            <strong>{prop.status}</strong>
        </span>
    );
};
