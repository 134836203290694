/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import * as Icon from 'react-feather';
// import { saveAs } from 'file-saver';
import InfiniteScroll from 'react-infinite-scroller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { startCase } from 'lodash';
import { Customer, CustomerFilters } from '../../../types/customer';
import { getCustomerList } from '../../../services/customer';
import './CustomerList.scss';
import { getCountryAndCodes } from '../../../services/data';

const CustomerList = () => {
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [countryCodes, setCountryCodes] = useState<{ [x: string]: string }>();
    const [filters, setFilters] = useState<CustomerFilters>({});
    const [error, setError] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const fetchCustomer = useCallback(async (page: number, searchFilters: CustomerFilters) => {
        try {
            setLoading(true);
            const { data } = await getCustomerList(page, searchFilters);
            if (data.length > 0) {
                setHasMore(true);
                setCustomers((prevData) => [...prevData, ...data]);
            } else {
                setHasMore(false);
            }
            setLoading(false);
        } catch (err: unknown) {
            setError('Something went wrong');
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const filterType = event.target.name; // Get the id of the select element as filterType
        const newFilters: CustomerFilters = { ...filters };
        if (event.target.value !== '0') {
            newFilters[filterType as keyof CustomerFilters] = event.target.value;
            setFilters(newFilters);
        } else {
            delete newFilters[filterType as keyof CustomerFilters];
            setFilters(newFilters);
        }
    };

    const handleSearchClick = () => {
        setCustomers([]);
        fetchCustomer(1, filters);
    };

    const handleClearFilter = () => {
        setFilters({ country: '', status: '' });
        setCustomers([]);
        fetchCustomer(1, {});
    };

    useEffect(() => {
        fetchCustomer(1, filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const getCountryCodes = async () => {
            const [coutryCodeList] = await Promise.all([getCountryAndCodes()]);
            if (coutryCodeList) {
                setCountryCodes(coutryCodeList);
            }
        };
        getCountryCodes();
    }, []);

    return (
        <main className="content view-customers">
            <div className="container-fluid p-0">
                <div className="row mb-2 mb-xl-3">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon icon={icon({ name: 'user-tie' })} /> Registered Customers
                        </h3>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-info">
                                <p>This page provides overview of registered customers. </p>
                            </div>
                            <div className="card-body">
                                <div
                                    id="datatables-reponsive_wrapper"
                                    className="dataTables_wrapper dt-bootstrap5 no-footer"
                                >
                                    {error && (
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div
                                                    className="alert alert-danger alert-outline alert-dismissible"
                                                    role="alert"
                                                >
                                                    <div className="alert-icon">
                                                        <Icon.Bell className="far fa-fw fa-bell" />
                                                    </div>
                                                    <div className="alert-message">
                                                        <strong>Error</strong> Something went wrong when trying to get the
                                                        customer data.
                                                        <br /> If the problem persists, please contact support.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {
                                        <>
                                            <div className="search-container">
                                                <div className="mb-3 row">
                                                    <strong>Filter By:</strong>
                                                </div>
                                                <div className="mb-3 row">
                                                    <div className="col-3">
                                                        <select
                                                            name="country"
                                                            onChange={handleFilterChange}
                                                            className="form-control form-control-lg form-select"
                                                            value={filters.country}
                                                        >
                                                            <option value="">Select Country</option>
                                                            {countryCodes &&
                                                                Object.keys(countryCodes).map((code, i) => (
                                                                    <option key={i} value={`${countryCodes[`${code}`]}`}>
                                                                        {countryCodes[`${code}`]}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-3">
                                                        <select
                                                            name="status"
                                                            onChange={handleFilterChange}
                                                            className="form-control form-control-lg form-select"
                                                            value={filters.status}
                                                        >
                                                            <option value="">Select Status</option>
                                                            <option value="ACTIVE">ACTIVE</option>
                                                            <option value="INACTIVE">INACTIVE</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-1">
                                                        {' '}
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={handleSearchClick}
                                                        >
                                                            Search
                                                        </button>
                                                    </div>
                                                    <div className="col-md-1 text-start pt-1 pl-1">
                                                        <span
                                                            onClick={handleClearFilter}
                                                            className="text-primary cursor-pointer"
                                                        >
                                                            Clear All
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {!loading && !error && customers?.length === 0 && (
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div
                                                    className="alert alert-danger alert-outline alert-dismissible"
                                                    role="alert"
                                                >
                                                    <div className="alert-icon">
                                                        <Icon.Bell className="far fa-fw fa-bell" />
                                                    </div>
                                                    <div className="alert-message">
                                                        <p>
                                                            <strong>
                                                                No data available for customers. <br /> It is possible that
                                                                there is no data added yet.
                                                                <br />
                                                                OR try refining your search filters.
                                                            </strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {customers?.length !== 0 && (
                                        <>
                                            <div style={{ height: '500px', overflow: 'auto' }}>
                                                <InfiniteScroll
                                                    pageStart={1}
                                                    loadMore={(page: number) => fetchCustomer(page, filters)}
                                                    hasMore={hasMore && !loading}
                                                    loader={
                                                        <div key={0} className="row">
                                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                                <div
                                                                    className="spinner-border text-primary me-2"
                                                                    role="status"
                                                                >
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    useWindow={false}
                                                >
                                                    <table
                                                        id="datatables-reponsive"
                                                        className="table table-striped dataTable no-footer dtr-inline view-customers-table"
                                                        style={{ width: '100%', tableLayout: 'fixed' }}
                                                        aria-describedby="datatables-reponsive_info"
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Email</th>
                                                                <th>Contact</th>
                                                                <th>Country</th>
                                                                <th>Timezone</th>
                                                                <th style={{ width: '80px' }}>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {customers &&
                                                                customers.map((customer, index) => (
                                                                    <tr
                                                                        className={cn({
                                                                            odd: index % 2 === 0,
                                                                            even: index % 2 !== 0,
                                                                        })}
                                                                        key={`${customer.uuid}-${index}`}
                                                                    >
                                                                        <td>{startCase(customer.name)}</td>
                                                                        <td>{customer.email ?? '-'}</td>
                                                                        <td>{customer.primaryContact ?? '-'}</td>
                                                                        <td>{customer.country ?? '-'}</td>
                                                                        <td>{customer.timezone ?? '-'}</td>
                                                                        <td>
                                                                            <NavLink
                                                                                to={`/customer/details/${customer.uuid}`}
                                                                                title="View Details"
                                                                                className="me-2"
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    title="View Details"
                                                                                    icon={icon({ name: 'info-circle' })}
                                                                                    size="lg"
                                                                                    className="text-primary"
                                                                                />
                                                                            </NavLink>
                                                                            <NavLink
                                                                                to={`/customer/update/${customer.uuid}`}
                                                                                title="View Details"
                                                                                className="ms-2"
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    icon={icon({ name: 'pen' })}
                                                                                    size="lg"
                                                                                />
                                                                            </NavLink>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </InfiniteScroll>
                                            </div>
                                        </>
                                    )}

                                    {loading && (
                                        <div key={0} className="row">
                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                <div className="spinner-border text-primary me-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default CustomerList;
