/* eslint-disable @typescript-eslint/naming-convention */
export const Activities = {
    LOGGED_IN: 'LOGGED_IN',
    LOGGED_OUT: 'LOGGED_OUT',
    ACCEPTED_PRIVACY: 'ACCEPTED_PRIVACY',
    PROFILE_ACTIVATED: 'PROFILE_ACTIVATED',
    ALLOCATED_LEAVES: 'ALLOCATED_LEAVES',
    UPDATED_PROFILE: 'UPDATED_PROFILE',
    FIRST_LOGIN: 'FIRST_LOGIN',
    FIRST_PASSWORD_CHANGE: 'FIRST_PASSWORD_CHANGE',
};
