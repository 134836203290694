import React from 'react';
import { capitalize } from 'lodash';
import { Button, Modal } from 'react-bootstrap';
import { EmployeeDocuments } from '../../../types/employee';
import { StatusIndicator } from '../../StatusIndicator/StatusIndicator';

type DocumentActionModalProp = {
    empDocument: EmployeeDocuments;
    showModal: boolean;
    handleClose: () => void;
};
// TODO: Better Design and complete Data.
export const DocumentDetailModal = (prop: DocumentActionModalProp) => {
    return (
        <Modal centered={true} show={prop.showModal} onHide={() => prop.handleClose()}>
            <Modal.Header>
                <Modal.Title>
                    <h3 className="ms-2"> Approval Status</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="p-3">
                    <p>
                        <strong>Employee:</strong> {prop.empDocument.employeeName}
                    </p>
                    <p>
                        <strong>Document:</strong> {capitalize(prop.empDocument.documentType.replaceAll('_', ' '))}
                    </p>
                    <p>
                        {' '}
                        <strong>Status</strong>
                        <StatusIndicator status={prop.empDocument.hrApprovalStatus} />
                    </p>
                    {prop.empDocument.hrApprovalStatus !== 'REQUESTED' && (
                        <>
                            <p>
                                <strong>Approved By:</strong> {prop.empDocument.hrDetail}
                            </p>
                            <p>
                                <strong>Status Comments</strong> {prop.empDocument.hrApproverComments}
                            </p>
                        </>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => prop.handleClose()}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
