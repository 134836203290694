/* eslint-disable no-console */
import React, { ReactElement } from 'react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { IRootState, useSelector } from '../../store';
import { UserProfile, UserRole } from '../../types/user';
import AccessDenied from '../AccessDenied/AccessDenied';

type LayoutProps = {
    sidebarCollapsed: boolean;
    handleSidebarCollapse: () => void;
    children: ReactElement;
    excludeRoles?: UserRole[];
};

export const AccessVerifier = (props: LayoutProps) => {
    const user = useSelector((state: IRootState) => state.user) as UserProfile;
    const { sidebarCollapsed, handleSidebarCollapse, children, excludeRoles } = props;
    return (
        <div className="wrapper">
            <Sidebar sidebarCollapsed={sidebarCollapsed} userRole={user.role} />

            <div className="main">
                <Header handleSidebarCollapse={handleSidebarCollapse} />
                {excludeRoles && excludeRoles.includes(user.role) ? <AccessDenied /> : children}
                <Footer />
            </div>
        </div>
    );
};
