/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import './OrgPolicies.scss';
import moment from 'moment';
import { OrgPolicy } from '../../types/orgData';
import { downloadPolicyDocuments, getOrgPolicies } from '../../services/data';

const OrgPolicies = () => {
    const [loadingData, setLoadingData] = useState(false);
    const [orgPolicies, setOrgPolicies] = useState<OrgPolicy[]>();

    const handleDocumentDownload = async (documentId: string) => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const blob: any = await downloadPolicyDocuments(documentId);
            const pdfURL = window.URL.createObjectURL(blob);

            // Open the PDF in a new browser tab or window
            window.open(pdfURL, '_blank');
        } catch (_e) {}
    };

    useEffect(() => {
        const getPolicyList = async () => {
            setLoadingData(true);
            const res = await getOrgPolicies();
            if (res) {
                setOrgPolicies(res.data);
            }
            setLoadingData(false);
        };
        getPolicyList();
    }, []);
    return (
        <main className="content org-policies">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon icon={icon({ name: 'file-lines' })} /> Organisation Policies
                        </h3>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-info">
                                <p>
                                    This page offers organizational policy documents, providing insights into each policy and
                                    the set of guidelines adhered to within our organization.
                                </p>
                                <p>
                                    Please note that organisational policy is confidential and restricted for outside use.
                                    Exercise caution when using this data.
                                </p>
                            </div>
                            <div className="card-body col-12">
                                <div className=" row col-12">
                                    {loadingData && (
                                        <div key={0} className="row">
                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                <div className="spinner-border text-primary me-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {orgPolicies &&
                                        orgPolicies.length > 0 &&
                                        orgPolicies?.map((policy) => (
                                            <div
                                                key={policy.id}
                                                className="col-3 m-3 p-4 doc-container text-start p-2   fw-bold"
                                            >
                                                <div className="mb-3">{policy.name}</div>
                                                <span
                                                    className="cursor-pointer  btn btn-primary btn-sm"
                                                    onClick={() => handleDocumentDownload(policy.id)}
                                                >
                                                    <FontAwesomeIcon size="sm" icon={icon({ name: 'paperclip' })} /> {''}
                                                    View Document
                                                </span>
                                                <div className="mt-3 text-sm">
                                                    Updated On: {moment(policy.createdAt).format('DD/MM/YYYY') ?? '-'}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default OrgPolicies;
