import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import React from 'react';
import { NavLink } from 'react-router-dom';

const NotFound = () => {
    return (
        <main className="content">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title mb-0" style={{ color: 'white' }}>
                                    NODE ENV {process.env.NODE_ENV}
                                </h5>
                                <h5 className="card-title mb-0" style={{ color: 'white' }}>
                                    {process.env.REACT_APP_API_ENDPOINT}
                                </h5>
                            </div>
                            <div className="card-body">
                                <div className="alert alert-warning alert-outline-coloured alert-dismissible" role="alert">
                                    <div className="alert-icon">
                                        <FontAwesomeIcon icon={icon({ name: 'bell' })} className="far fa-fw fa-bell" />
                                    </div>
                                    <div className="alert-message">
                                        <strong>Not Found</strong> Looks like you&apos;ve followed a broken link or entered a
                                        URL that doesn&apos;t exist.
                                        <br />
                                        <br />
                                        You can check the link or head back to{' '}
                                        <NavLink to={`/`}>
                                            <FontAwesomeIcon icon={icon({ name: 'home' })} className="far fa-fw fa-bell" />{' '}
                                            Home
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default NotFound;
