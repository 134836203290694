// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resource-allocation .employee-detail-card {
  border: 1px solid #dee1e6;
}
.resource-allocation .allocate-container {
  border: 1px solid #dee1e6;
}`, "",{"version":3,"sources":["webpack://./src/components/Customer/AllocateResource/AllocateResource.scss"],"names":[],"mappings":"AAEI;EACI,yBAAA;AADR;AAII;EACI,yBAAA;AAFR","sourcesContent":[".resource-allocation {\n\n    .employee-detail-card {\n        border: 1px solid #dee1e6;\n    }\n\n    .allocate-container {\n        border: 1px solid #dee1e6;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
