// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notyf__toast {
  max-width: 50em !important;
}
.notyf__toast .notyf__ripple {
  height: 60em;
  width: 60em;
}`, "",{"version":3,"sources":["webpack://./src/components/AppContext/AppContext.scss"],"names":[],"mappings":"AACA;EACI,0BAAA;AAAJ;AACI;EACE,YAAA;EACA,WAAA;AACN","sourcesContent":["// Overriding the max width of the Notyf alert pop up.\n.notyf__toast {\n    max-width: 50em !important;\n    .notyf__ripple {\n      height: 60em;\n      width: 60em;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
