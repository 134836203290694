/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { getCountryAndCodes } from '../../../services/data';
import { getOrgHolidays, saveOrgHolidays } from '../../../services/holidays';
import { OrgHolidayEntry } from '../../../types/orgData';
import { useAppContext } from '../../../hooks/useAppContext';
import '../ControlCenter.scss';
import { AlphaNumericAndCharactersRegex } from '../../../constants/validations';

const dateFormat = 'DD/MM/YYYY';
const AddOrgHolidays = () => {
    const pristineForm = {
        year: '0',
        countryCode: '0',
    };
    const pristineEntryForm = {
        holidayDescription: '',
        holidayDate: '',
    };
    const today = new Date();

    const { triggerSuccess } = useAppContext();
    const currentYear = today.getFullYear();
    const yearList = [currentYear - 2, currentYear - 1, currentYear, currentYear + 1, currentYear + 2];

    const [countryCodes, setCountryCodes] = useState<{ [x: string]: string }>();
    const [maxDate, setMaxDate] = useState(new Date(currentYear, 11, 31));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [formData, setFormData] = useState<any>(pristineForm);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [entryForm, setEntryForm] = useState<any>(pristineEntryForm);
    const [savedOrgHolidays, setSavedOrgHolidays] = useState<OrgHolidayEntry[]>([]);
    const [loadingData, setLoadingData] = useState(false);
    const [dataFetched, setDataFetched] = useState(false);
    const [addHolidays, setAddHolidays] = useState(false);
    const [dbHadData, setDBHadData] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultError = {} as any;
    const [errors, setErrors] = useState(defaultError);
    const [entryFormErrors, setEntryFormErrors] = useState(defaultError);

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
        setLoadingData(false);
        setSubmitLoading(false);
        setFormData({ ...formData, [e.target.name]: e.target.value });
        if (e.target.name === 'year') {
            const lastDayOfYear = new Date(parseInt(e.target.value), 11, 31); // 11 represents December (months are 0-indexed)
            setMaxDate(lastDayOfYear);
        }
    };
    const isHistoricYear = () => {
        return formData.year < currentYear;
    };
    const handleHolidayDescChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEntryFormErrors(defaultError);
        setEntryForm({ ...entryForm, holidayDescription: e.target.value });
    };

    const formatDate = (date: string): string => {
        return moment(date).format(dateFormat).toString();
    };

    const checkForDuplicates = (description: string, date: string, holidays: OrgHolidayEntry[]): boolean => {
        const formattedDate = formatDate(date);
        return holidays.some((obj) => obj.name === description || obj.date === formattedDate);
    };

    const handleAddEntry = () => {
        setEntryFormErrors(defaultError);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const tempErrors = {} as any;
        if (!entryForm.holidayDescription) {
            tempErrors.holidayDescription = 'Description is a required field';
        } else if (entryForm.holidayDescription.length > 50) {
            tempErrors.holidayDescription = 'Description should be less than 50 characters.';
        } else if (!AlphaNumericAndCharactersRegex.test(entryForm.holidayDescription)) {
            tempErrors.holidayDescription =
                'Should only contain alphanumeric characters, hyphens, underscores, dot and spaces';
        } else if (!entryForm.holidayDate) {
            tempErrors.holidayDate = 'Date is a required field';
        } else if (savedOrgHolidays.length) {
            const isDuplicate = checkForDuplicates(entryForm.holidayDescription, entryForm.holidayDate, savedOrgHolidays);
            if (isDuplicate) {
                tempErrors.holidayDescription = 'Date or description already exists';
            }
        }

        if (Object.keys(tempErrors).length > 0) {
            setEntryFormErrors(tempErrors);
        } else {
            const entryData: OrgHolidayEntry = {
                name: entryForm.holidayDescription,
                date: formatDate(entryForm.holidayDate),
                year: formData.year,
                countryCode: formData.countryCode,
            };
            setSavedOrgHolidays([...savedOrgHolidays, entryData]);
            setEntryForm(pristineEntryForm);
        }
    };

    const handleRemoveEntry = (index: number) => {
        setSavedOrgHolidays((prevHolidays) => {
            // Check if the index is valid
            if (index < 0 || index >= prevHolidays.length) {
                throw new Error('Invalid index');
            }

            // Create a new array without the entry at the specified index
            const updatedHolidays = [...prevHolidays.slice(0, index), ...prevHolidays.slice(index + 1)];

            return updatedHolidays;
        });
    };

    const fetchOrgHolidays = async () => {
        try {
            setLoadingData(true);
            const { data } = await getOrgHolidays(formData.year, formData.countryCode);

            setSavedOrgHolidays(data.orgHolidays);
            setSubmitLoading(false);
            setLoadingData(false);
            setDataFetched(true);
            setDBHadData(!!data.orgHolidays.length);
        } catch (err: unknown) {
            setLoadingData(false);
            setDataFetched(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const validateSearchForm = () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const tempErrors = {} as any;
        if (formData.year === '0') {
            tempErrors[`year`] = 'Select a year.';
        }

        if (formData.countryCode === '0') {
            tempErrors[`countryCode`] = 'Select a country code.';
        }
        return tempErrors;
    };
    const handleGetHolidays = () => {
        setDataFetched(false);
        setErrors(defaultError);
        const validationErrors = validateSearchForm();
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            fetchOrgHolidays();
        }
    };

    const clearData = () => {
        setDataFetched(false);
        setSavedOrgHolidays([]);
        setAddHolidays(false);
        setLoadingData(false);
        setFormData({ year: '0', countryCode: '0' });
    };

    const handleSubmit = async () => {
        try {
            setSubmitLoading(true);
            if (savedOrgHolidays.length) {
                await saveOrgHolidays(savedOrgHolidays);
                triggerSuccess(`Holidays have been saved successfully`);
            }
            setSubmitLoading(false);
            setLoadingData(false);
        } catch (_e) {
            setSubmitLoading(false);
            setLoadingData(false);
        }
    };

    useEffect(() => {
        const getCountryCodes = async () => {
            const res = await getCountryAndCodes();
            if (res) {
                setCountryCodes(res);
            }
        };
        getCountryCodes();
    }, []);
    return (
        <main className="content control-center  org-holidays">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-control-center-info">
                                {' '}
                                <p>
                                    <FontAwesomeIcon className="me-3" size="lg" icon={icon({ name: 'shield-alt' })} />{' '}
                                    Application Control Center{' '}
                                    <FontAwesomeIcon className="me-3 ms-3" icon={icon({ name: 'chevron-right' })} /> Manage
                                    Organisation Holidays
                                </p>
                            </div>
                            <div className="card-body col-12">
                                <div className="container bg-light p-3 pb-1">
                                    <p>This feature allows you to efficiently manage holidays for a country and year.</p>
                                    <ul>
                                        <li>
                                            <strong>Visible to everyone:</strong>
                                            <ul>
                                                <li>
                                                    Holidays will be visible for all employees for the current ongoing year.
                                                </li>
                                                <li>
                                                    Holidays for a past year cannot be updated, it&apos;s view only data.
                                                </li>
                                            </ul>
                                        </li>{' '}
                                    </ul>
                                </div>
                                <div className="form-container bg-light p-4 pt-1">
                                    <div className="row col-12">
                                        <label className="fw-bold pb-2">
                                            Select Options <span className="required-field">*</span>{' '}
                                        </label>
                                        <div className="col-md-3">
                                            <select
                                                name="year"
                                                onChange={handleChange}
                                                className={`form-control form-control-lg form-select mb-3 ${
                                                    errors.year ? 'is-invalid' : ''
                                                }`}
                                                value={formData.year}
                                                disabled={dataFetched}
                                            >
                                                <option value="0">Select Year</option>
                                                {yearList.map((aYear, i) => (
                                                    <option key={i} value={`${aYear}`}>
                                                        {aYear}
                                                    </option>
                                                ))}
                                            </select>
                                            <div
                                                className={cn('invalid-feedback mb-3 ', {
                                                    show: errors.year,
                                                })}
                                            >
                                                {errors.year}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <select
                                                name="countryCode"
                                                onChange={handleChange}
                                                className={`form-control form-control-lg form-select mb-3 ${
                                                    errors.countryCode ? 'is-invalid' : ''
                                                }`}
                                                value={formData.countryCode}
                                                disabled={dataFetched}
                                            >
                                                <option value="0">Select Country Code</option>
                                                {countryCodes &&
                                                    Object.keys(countryCodes).map((code, i) => (
                                                        <option key={i} value={`${code}`}>
                                                            {countryCodes[`${code}`]}
                                                        </option>
                                                    ))}
                                            </select>
                                            <div
                                                className={cn('invalid-feedback mb-3 show', {
                                                    show: errors.countryCode,
                                                })}
                                            >
                                                {errors.countryCode}
                                            </div>
                                        </div>
                                        <div className="col-md-4 mt-md-0 mt-3 align-bottom text-start">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={handleGetHolidays}
                                                disabled={dataFetched}
                                            >
                                                Get Holidays
                                            </button>
                                            <span onClick={clearData} className="text-primary cursor-pointer ms-3">
                                                Clear All
                                            </span>
                                        </div>
                                    </div>
                                    {loadingData && (
                                        <div className="row mt-4">
                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                <div className="spinner-border text-primary me-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {dataFetched && !dbHadData && !isHistoricYear() && (
                                        <div className="row mt-3 col-12">
                                            <div className=" col-md-8">
                                                <p className="p-2 bg-info text-start text-white">
                                                    Holidays for {countryCodes && countryCodes[`${formData.countryCode}`]}{' '}
                                                    and year {formData.year} are not added yet. Click on{' '}
                                                    <strong>Add Holidays</strong> to add them now.
                                                </p>
                                            </div>
                                            <div className="col-md-4 align-bottom text-start">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => setAddHolidays(true)}
                                                >
                                                    <FontAwesomeIcon size="sm" icon={icon({ name: 'plus' })} /> Add Holidays
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                    {dataFetched && !dbHadData && isHistoricYear() && (
                                        <div
                                            className="mt-2 alert alert-warning alert-outline-coloured alert-dismissible"
                                            role="alert"
                                        >
                                            <div className="alert-icon">
                                                <FontAwesomeIcon
                                                    icon={icon({ name: 'bell' })}
                                                    className="far fa-fw fa-bell"
                                                />
                                            </div>
                                            <div className="alert-message">
                                                <p className="note">
                                                    <strong>Note:</strong> <br /> Holidays for{' '}
                                                    {countryCodes && countryCodes[`${formData.countryCode}`]} and year{' '}
                                                    {formData.year} were not added. As this is a hirtoric year you cannot add
                                                    holidays for this year.
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    {((!loadingData && savedOrgHolidays.length > 0) || addHolidays) && (
                                        <div className="row  p-4 col-12 control-center-container">
                                            <div className="row">
                                                <div className="col-4 fw-bold p-2 bg-app-header text-white">
                                                    Short Description
                                                </div>
                                                <div className="col-4 fw-bold p-2 bg-app-header text-white">Date</div>
                                            </div>
                                            {!isHistoricYear() && (
                                                <div className="row">
                                                    <div className="col-4 p-2 pb-0 align-bottom bg-app-row ">
                                                        <input
                                                            className={`form-control form-control-lg p-2 mb-3 ${
                                                                entryFormErrors.holidayDescription ? 'is-invalid' : ''
                                                            }`}
                                                            type="text"
                                                            name="holidayDescription"
                                                            onChange={handleHolidayDescChange}
                                                            value={entryForm.holidayDescription}
                                                            maxLength={50}
                                                        />
                                                        {entryFormErrors.holidayDescription && (
                                                            <div className="invalid-feedback mb-3 bg-white p-2">
                                                                {entryFormErrors.holidayDescription}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-4 p-2 pb-0 align-bottom bg-app-row ">
                                                        <DatePicker
                                                            className={`form-control form-control-lg ${
                                                                entryFormErrors.holidayDate ? 'is-invalid' : ''
                                                            }`}
                                                            name="holidayDate"
                                                            format="dd/MM/yyyy"
                                                            maxDate={maxDate}
                                                            onChange={(date) =>
                                                                date && setEntryForm({ ...entryForm, holidayDate: date })
                                                            }
                                                            value={entryForm.holidayDate}
                                                            isOpen={false}
                                                        />
                                                        {entryFormErrors.holidayDate && (
                                                            <div className="invalid-feedback bg-white p-2">
                                                                {entryFormErrors.holidayDate}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-4 p-1 pb-0 align-bottom ">
                                                        {' '}
                                                        <FontAwesomeIcon
                                                            className={cn('mt-3 ', {
                                                                'text-secondary':
                                                                    Object.keys(entryFormErrors).length > 0 ||
                                                                    entryForm.holidayDescription.length === 0,
                                                                'cursor-pointer text-success':
                                                                    Object.keys(entryFormErrors).length === 0 &&
                                                                    entryForm.holidayDescription.length !== 0,
                                                            })}
                                                            size="lg"
                                                            icon={icon({ name: 'circle-plus' })}
                                                            onClick={handleAddEntry}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {savedOrgHolidays.length > 0 && (
                                                <>
                                                    {savedOrgHolidays.map((holiday, index) => (
                                                        <div className="row" key={index}>
                                                            <div className="col-4 p-2 m-0 ps-3 bg-app-row bg-app-row__border-bottom">
                                                                {holiday.name}
                                                            </div>
                                                            <div className="col-4 p-2 m-0  bg-app-row bg-app-row__border-bottom">
                                                                {moment(holiday.date, dateFormat).format('Do MMMM YYYY')}
                                                            </div>
                                                            {!isHistoricYear() && (
                                                                <div className="col-4 p-2 ">
                                                                    <FontAwesomeIcon
                                                                        className="cursor-pointer "
                                                                        onClick={() => handleRemoveEntry(index)}
                                                                        size="sm"
                                                                        icon={icon({
                                                                            style: 'regular',
                                                                            name: 'xmark-circle',
                                                                        })}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                            <div className="row">
                                                <div className="col-6 p-2 pb-0  pt-4 text-start  ">
                                                    <strong>Total Holidays:</strong> {savedOrgHolidays.length}
                                                </div>
                                                {!isHistoricYear() && (
                                                    <div className="col-2 p-2 pb-0 text-end ">
                                                        <button
                                                            type="button"
                                                            className="mt-2 btn  btn-primary"
                                                            disabled={!savedOrgHolidays.length || submitLoading}
                                                            onClick={handleSubmit}
                                                        >
                                                            Save Changes{' '}
                                                            {submitLoading && (
                                                                <div
                                                                    className="spinner-border spinner-border-sm ms-2"
                                                                    role="status"
                                                                >
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                            )}
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default AddOrgHolidays;
