import React, { useCallback, useEffect, useState } from 'react';
import * as Icon from 'react-feather';
import { faBell, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NavLink } from 'react-router-dom';
import { EmployeeDashboardMeta } from '../../types/dashboard';
import { IRootState, useSelector } from '../../store';
import { UserProfile } from '../../types/user';
import { getEmployeeDashboardData } from '../../services/dashboard';
import GenericCard from './GenericCard';
import './Dashboard.scss';
import ReminderCard from './ReminderCard';

const EmployeeDashboard = () => {
    const loggedInUser = useSelector((state: IRootState) => state.user) as UserProfile;
    const { uuid } = loggedInUser;
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [dashboardData, setDashboardData] = useState<EmployeeDashboardMeta>();
    const fetchDashboardData = useCallback(async () => {
        try {
            setLoading(true);
            const { data } = await getEmployeeDashboardData(uuid);
            setDashboardData(data.dashboardData);
            setLoading(false);
        } catch (err: unknown) {
            setError('Something went wrong');
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDashboardRefresh = () => {
        fetchDashboardData();
    };

    useEffect(() => {
        if (uuid) {
            fetchDashboardData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);
    return (
        <>
            {loading && (
                <div className="row mt-4">
                    <div className="col-sm-12 mt-2 mb-2 text-center">
                        <div className="spinner-border text-primary me-2" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            )}

            {error && (
                <div className="row mt-4">
                    <div className="col-sm-12">
                        <div className="alert alert-danger alert-outline alert-dismissible mt-5" role="alert">
                            <div className="alert-icon">
                                <Icon.Bell className="far fa-fw fa-bell" />
                            </div>
                            <div className="alert-message">
                                <p>
                                    <strong>Dashboard not available yet</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {!loading && !error && dashboardData && (
                <>
                    <div className="row">
                        <div className="col-xl-6 col-xxl-4 order-md-1 ">
                            <div className="w-100">
                                <div className="row">
                                    <div className="col-12 d-flex">
                                        <div className="card flex-fill quick-link">
                                            <div className="card-body text-center">
                                                <button className="btn btn-primary" onClick={handleDashboardRefresh}>
                                                    <FontAwesomeIcon
                                                        size="sm"
                                                        icon={icon({ name: 'refresh' })}
                                                        className="far fa-fw fa-bell"
                                                    />{' '}
                                                    Refresh Dashboard Data
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12   d-flex">
                                        <div className="card flex-fill quick-link">
                                            <div className="card-header">
                                                <h5 className="card-title mb-0">
                                                    <FontAwesomeIcon
                                                        size="sm"
                                                        icon={icon({ name: 'link' })}
                                                        className="far fa-fw fa-bell p-2  bg-light"
                                                        style={{ borderRadius: '50%', verticalAlign: 'middle' }}
                                                    />{' '}
                                                    Quick Actions
                                                </h5>
                                            </div>

                                            <div className="card-body pt-0">
                                                <div className="col-12 bg-light p-2 link-item">
                                                    <NavLink to="/employee/weekly-timesheet/list">
                                                        <FontAwesomeIcon
                                                            size="sm"
                                                            icon={icon({ name: 'angle-right' })}
                                                            className="far fa-fw fa-bell"
                                                        />{' '}
                                                        My Timesheet
                                                    </NavLink>
                                                </div>
                                                <div className="col-12 bg-light p-2 link-item">
                                                    <NavLink to="/user/profile">
                                                        <FontAwesomeIcon
                                                            size="sm"
                                                            icon={icon({ name: 'angle-right' })}
                                                            className="far fa-fw fa-bell"
                                                        />{' '}
                                                        My Profile
                                                    </NavLink>
                                                </div>
                                                <div className="col-12 bg-light p-2 link-item">
                                                    <NavLink to="/employee/documents">
                                                        <FontAwesomeIcon
                                                            size="sm"
                                                            icon={icon({ name: 'angle-right' })}
                                                            className="far fa-fw fa-bell"
                                                        />{' '}
                                                        My Documents
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-xxl-8  order-md-0">
                            <div className="w-100">
                                <div className="row">
                                    <div className="col-12 col-lg-4 col-xxl">
                                        <GenericCard
                                            label="Leave Approvals"
                                            note="Pending approval requests"
                                            data={dashboardData.pendingLeaveApprovals}
                                            actionLink="/employee/leaves/list"
                                            icon={faBell}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-4 col-xxl">
                                        <GenericCard
                                            label="Timesheet Approvals"
                                            note="Pending approval requests"
                                            data={dashboardData.pendingTimesheetApprovals}
                                            actionLink="/employee/weekly-timesheet/list"
                                            icon={faBell}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-4 col-xxl">
                            <ReminderCard
                                label="Reminders"
                                note="Actionable task reminders"
                                data={dashboardData.reminderTasks}
                                icon={faBell}
                            />
                        </div>
                        <div className="col-12 col-lg-4 col-xxl">
                            <GenericCard
                                label="Holidays"
                                note="Holidays for this year in India"
                                data={dashboardData.currentYearHolidays}
                                icon={faCalendarDays}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default EmployeeDashboard;
