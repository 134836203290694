import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

type PrivacyModalProp = {
    showModal: boolean;
    handleClose: () => void;
    handleSubmit: (status: boolean) => void;
    privacyStatement: React.ReactNode;
};

export const PrivacyModal = (prop: PrivacyModalProp) => {
    const [submitLoading, setSubmitLoading] = useState(false);

    return (
        <Modal size={'xl'} centered={true} show={prop.showModal} onHide={() => prop.handleClose()}>
            <Modal.Header>
                <Modal.Title>
                    <h3 className="ms-2">Privacy Statement</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="p-3">{prop.privacyStatement}</div>
            </Modal.Body>
            <Modal.Footer>
                <div className="">
                    {submitLoading ? (
                        <div key={0} className="row">
                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                <div className="spinner-border text-primary me-2" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <p>
                                <strong>
                                    Do you agree with above privacy statment in regards to collection of your personal data
                                    for official usage?
                                </strong>
                            </p>

                            <div className="text-end mt-2">
                                <button
                                    type="button"
                                    className="btn btn-primary me-2"
                                    onClick={() => {
                                        setSubmitLoading(true);
                                        prop.handleSubmit(true);
                                    }}
                                >
                                    I agree
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary ms-2"
                                    onClick={() => {
                                        setSubmitLoading(true);
                                        prop.handleSubmit(false);
                                    }}
                                >
                                    I don&apos;t agree
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </Modal.Footer>
        </Modal>
    );
};
