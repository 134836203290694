import React from 'react';
// import cn from 'classnames';
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';
import { capitalize } from 'lodash';
import { ActionStatus, EmployeeLeave } from '../../../types/employee';
import { StatusIndicator } from '../../StatusIndicator/StatusIndicator';

type LeaveDetailModalProp = {
    leaveDetails: EmployeeLeave;
    showModal: boolean;
    handleClose: () => void;
};
// TODO: Better Design and complete Data.
export const LeaveDetailModal = (prop: LeaveDetailModalProp) => {
    return (
        <Modal centered={true} show={prop.showModal}>
            <Modal.Header>
                <Modal.Title>
                    <h3 className="ms-2">Leave Request Details - Request #: {prop.leaveDetails.requestNumber}</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="p-3 pb-1">
                    <strong>Leave Reason:</strong> {prop.leaveDetails.reason}
                </div>
                <div className="p-3 pt-1 pb-1 mb-2">
                    <strong>Approval status:</strong> <StatusIndicator status={prop.leaveDetails.leaveApprovalStatus} />
                </div>
                {prop.leaveDetails.leaveApprovers.length > 0 && (
                    <div className="p-3 pt-1">
                        {prop.leaveDetails.leaveApprovers.map((approver, index) => (
                            <div key={index}>
                                <p className="text-bg-secondary p-2">
                                    <strong>{capitalize(approver.approverRole).replace('_', ' ')} Approver</strong>
                                </p>
                                <p className=" mb-1 ps-2">
                                    <strong className="pe-2">Approver:</strong>
                                    {approver.approverDetail}
                                </p>
                                <p className="ps-2 mb-1">
                                    <strong className="pe-2">Status:</strong>{' '}
                                    <StatusIndicator status={approver.approvalStatus} />
                                </p>
                                {(approver.approvalStatus === ActionStatus.APPROVED ||
                                    approver.approvalStatus === ActionStatus.REJECTED) && (
                                    <>
                                        <p className="ps-2 mb-2">
                                            <strong className="pe-2">Comments:</strong> <br />
                                            {approver.approverComments ?? 'This is test comments'}
                                        </p>
                                        <p className=" ps-2">
                                            <strong className="pe-2">Date:</strong>{' '}
                                            {moment(approver.approvalDate).format('DD/MM/YYYY')}
                                        </p>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => prop.handleClose()}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
