// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leave-balance-table .header-row {
  background-color: #935050;
}
.leave-balance-table .header-row .heading {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  text-align: left;
}
.leave-balance-table .data-row {
  background-color: #e6b5b5;
  border-bottom: 1px solid #fff;
}
.leave-balance-table .data-row .data-col {
  color: #643c3c;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/EmployeeLeaves/LeavesBalanceTable.scss"],"names":[],"mappings":"AACI;EACI,yBAAA;AAAR;AACQ;EACI,eAAA;EACA,gBAAA;EAEA,WAAA;EACA,gBAAA;AAAZ;AAII;EACI,yBAAA;EACA,6BAAA;AAFR;AAGQ;EACI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AADZ","sourcesContent":[".leave-balance-table {\n    .header-row {\n        background-color: #935050;\n        .heading {\n            font-size: 16px;\n            font-weight: 500;\n            // height: 70px;\n            color: #fff;\n            text-align: left;\n        }\n    }\n\n    .data-row {\n        background-color: #e6b5b5;\n        border-bottom: 1px solid #fff;\n        .data-col {\n            color: #643c3c;\n            font-size: 14px;\n            font-weight: 500;\n            text-align: left;\n        }\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
