/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import * as Icon from 'react-feather';
import { startCase } from 'lodash';
import { SurveyConfig, SurveyFormResult } from '../../types/survey';
import { checkIfSurveySubmitted, getLiveSurvey, submitEmployeeSurveyResult } from '../../services/survey';
import { UserProfile } from '../../types/user';
import { IRootState, useSelector } from '../../store';
import { useAppContext } from '../../hooks/useAppContext';
import { AlphaNumericAndCharactersRegex } from '../../constants/validations';

const SubmitLiveSurvey = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultError = {} as any;
    const [errors, setErrors] = useState(defaultError);
    const { triggerSuccess } = useAppContext();
    const loggedInUser = useSelector((state: IRootState) => state.user) as UserProfile;
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [isSurveySubmitted, setIsSurveySubmitted] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [liveSurvey, setLiveSurvey] = useState<SurveyConfig | null>(null);
    const [employeeResponse, setEmployeeResponse] = useState<Record<string, string>>();
    const getLiveSurveyDetails = async () => {
        setLoading(true);
        try {
            const [response, isSubmitted] = await Promise.all([getLiveSurvey(), checkIfSurveySubmitted(loggedInUser.uuid)]);
            if (response.data.survey) {
                setLiveSurvey(response.data.survey);
            }
            setIsSurveySubmitted(isSubmitted.data);
        } catch (err: unknown) {
            setError('Something went wrong');
            setLoading(false);
        }

        setLoading(false);
    };
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>) => {
        if (e.target.name === 'generalComment') {
            setEmployeeResponse({ ...employeeResponse, ['generalComment']: e.target.value });
        } else {
            setEmployeeResponse({ ...employeeResponse, [e.target.name]: e.target.value });
        }
    };
    const submitSurvey = async () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const tempErrors = {} as any;
        setError(null);
        setErrors(defaultError);
        if (liveSurvey && liveSurvey.formDetails) {
            setSubmitLoading(true);
            for (const survey of liveSurvey?.formDetails) {
                const { uuid } = survey;
                const response = uuid && employeeResponse && employeeResponse[uuid];
                if (!response) {
                    tempErrors[`${uuid}`] = 'Add your input';
                } else if (!AlphaNumericAndCharactersRegex.test(employeeResponse[uuid])) {
                    tempErrors[`${uuid}`] =
                        'Comment can contain only alphanumeric characters, hyphens, underscores, dot and spaces';
                }
            }

            if (Object.keys(tempErrors).length === 0 && employeeResponse) {
                const surveyResult: SurveyFormResult[] = [];
                for (const survey of liveSurvey?.formDetails) {
                    const { uuid, inputType, question } = survey;
                    if (uuid) {
                        if (inputType === 'rating') {
                            surveyResult.push({
                                uuid,
                                inputType,
                                question,
                                rating: parseInt(employeeResponse[uuid], 10),
                            });
                        } else {
                            surveyResult.push({
                                uuid,
                                inputType,
                                question,
                                comment: employeeResponse[uuid],
                            });
                        }
                    }
                }
                const data = {
                    employeeId: loggedInUser.uuid,
                    surveyId: liveSurvey?.uuid,
                    surveyResult,
                    generalComment: employeeResponse['generalComment'],
                };
                await submitEmployeeSurveyResult(data);
                triggerSuccess(`Thank you for submitting the survey.`);
                setSubmitLoading(false);
                setIsSurveySubmitted(true);
            }
        }
        setErrors(tempErrors);
        setSubmitLoading(false);
    };
    useEffect(() => {
        if (loggedInUser.uuid) {
            getLiveSurveyDetails();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInUser.uuid]);

    return (
        <main className="content user-registration">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon icon={icon({ name: 'square-poll-horizontal' })} /> Survey Form Submission
                        </h3>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-info">
                                <p>
                                    Thank you for taking the time to participate! Your feedback is crucial in shaping our
                                    organization&apos;s future.
                                </p>
                                <p className="mb-0">
                                    <strong>Note:</strong>
                                </p>
                                <ul>
                                    <li>Take your time to reflect on each question and provide thoughtful responses.</li>
                                    <li>Your input helps us understand your needs better and improve our processes.</li>
                                    <li>Let&apos;s work together to create a better workplace for everyone.</li>
                                </ul>
                            </div>
                            <div className="card-body col-12">
                                {loading && (
                                    <div className="row mt-4">
                                        <div className="col-sm-12 mt-2 mb-2 text-center">
                                            <div className="spinner-border text-primary me-2" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {error && (
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="alert alert-danger alert-outline alert-dismissible" role="alert">
                                                <div className="alert-icon">
                                                    <Icon.Bell className="far fa-fw fa-bell" />
                                                </div>
                                                <div className="alert-message">
                                                    <strong>Error</strong> {error}
                                                    <br /> If the problem persists, please contact support.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {(isSurveySubmitted || !liveSurvey) && (
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div
                                                className="alert alert-success alert-outline alert-dismissible"
                                                role="alert"
                                            >
                                                <div className="alert-icon">
                                                    <Icon.Bell className="far fa-fw fa-bell" />
                                                </div>
                                                <div className="alert-message">
                                                    You are all done. There are no more surveys to submit.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {!loading && !isSurveySubmitted && liveSurvey && (
                                    <>
                                        <p className="text-info p-2 fw-bold">{liveSurvey.surveyTitle}</p>
                                        <table
                                            id="datatables-reponsive"
                                            className="table table-striped dataTable no-footer dtr-inline view-employees-table"
                                            style={{ width: '100%', tableLayout: 'fixed' }}
                                            aria-describedby="datatables-reponsive_info"
                                        >
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '50px' }}>{'Sr. #'}</th>
                                                    <th>Question</th>
                                                    <th>Response</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {liveSurvey.formDetails.map((survey, index) => (
                                                    <tr
                                                        className={cn({
                                                            odd: index % 2 === 0,
                                                            even: index % 2 !== 0,
                                                        })}
                                                        key={`${survey.uuid}-${index}`}
                                                    >
                                                        <td className="align-top">{index + 1}</td>
                                                        <td className="align-top">
                                                            {startCase(survey.question)}{' '}
                                                            <span className="required-field">*</span>{' '}
                                                        </td>
                                                        <td className="align-top">
                                                            {survey.inputType === 'rating' ? (
                                                                <>
                                                                    <div>
                                                                        <select
                                                                            name={`${survey.uuid}`}
                                                                            className={`form-control form-select mb-3`}
                                                                            value={
                                                                                employeeResponse &&
                                                                                employeeResponse[`${survey.uuid}`]
                                                                            }
                                                                            onChange={handleChange}
                                                                        >
                                                                            <option value="0">Select rating</option>
                                                                            <option value="1">1</option>
                                                                            <option value="2">2</option>
                                                                            <option value="3">3</option>
                                                                            <option value="4">4</option>
                                                                            <option value="5">5</option>
                                                                        </select>
                                                                    </div>
                                                                    <div
                                                                        className={cn('invalid-feedback mb-1', {
                                                                            show: errors[`${survey.uuid}`],
                                                                        })}
                                                                    >
                                                                        {errors[`${survey.uuid}`]}
                                                                    </div>
                                                                    <span className="text-muted text-info">
                                                                        <FontAwesomeIcon
                                                                            title="View Details"
                                                                            icon={icon({ name: 'info-circle' })}
                                                                            size="sm"
                                                                            className="text-primary"
                                                                        />{' '}
                                                                        Please select rating from dropdown list. 1 being the
                                                                        Lowest & 5 being Highest
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                <div>
                                                                    <textarea
                                                                        rows={3}
                                                                        className={`form-control form-control-lg mb-3 ${
                                                                            error ? 'is-invalid' : ''
                                                                        }`}
                                                                        name={`${survey.uuid}`}
                                                                        value={
                                                                            employeeResponse &&
                                                                            employeeResponse[`${survey.uuid}`]
                                                                        }
                                                                        onChange={handleChange}
                                                                    />
                                                                    <div
                                                                        className={cn('invalid-feedback mb-1', {
                                                                            show: errors[`${survey.uuid}`],
                                                                        })}
                                                                    >
                                                                        {errors[`${survey.uuid}`]}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td colSpan={2}>Do you wish to add any other comments?</td>
                                                    <td colSpan={1}>
                                                        <textarea
                                                            rows={3}
                                                            className={`form-control form-control-lg mb-3`}
                                                            name="generalComment"
                                                            value={employeeResponse && employeeResponse['generalComment']}
                                                            onChange={handleChange}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}>
                                                        <div className="col-12 text-end">
                                                            <button
                                                                type="button"
                                                                className="mt-2 btn  btn-primary"
                                                                onClick={submitSurvey}
                                                            >
                                                                Submit Survey
                                                                {submitLoading && (
                                                                    <div
                                                                        className="spinner-border spinner-border-sm ms-2"
                                                                        role="status"
                                                                    >
                                                                        <span className="visually-hidden">Loading...</span>
                                                                    </div>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default SubmitLiveSurvey;
