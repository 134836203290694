import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer, Event, EventPropGetter, Views } from 'react-big-calendar';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Modal } from 'react-bootstrap';
import { capitalize } from 'lodash';
import { getLeavesAndHolidays } from '../../services/calendar';
import { transformEvent } from './LeaveCalendarFn';

const localizer = momentLocalizer(moment);

export interface CalendarEvent extends Event {
    title: string;
    allDay?: boolean;
    type: string;
    style?: {
        backgroundColor: string;
    };
}

const LeaveCalendar = () => {
    const leaveColor = '#2a6898';
    const holidayColor = '#f5531d';

    const views = [Views.DAY, Views.WEEK, Views.MONTH];
    const [eventList, setEventList] = useState<CalendarEvent[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);

    const handleSelectEvent = (event: CalendarEvent, e: React.SyntheticEvent): void => {
        e.preventDefault(); // Prevent the default behavior to avoid navigating to a new page
        setSelectedEvent(event);
    };

    const eventStyleGetter: EventPropGetter<CalendarEvent> = (event: CalendarEvent) => {
        const backgroundColor = event.type === 'leave' ? leaveColor : holidayColor;
        const style = {
            backgroundColor,
            color: 'white',
        };

        return { style };
    };

    const handleCloseModal = (): void => {
        setSelectedEvent(null);
    };

    useEffect(() => {
        const getEvents = async () => {
            setLoading(true);
            const leavesAndHolidayList = await getLeavesAndHolidays();
            if (leavesAndHolidayList.data.length > 0) {
                const transformedEvents = leavesAndHolidayList.data.map(transformEvent);
                setEventList(transformedEvents);
            }
            setLoading(false);
        };
        getEvents();
    }, []);

    return (
        <main className="content user-registration">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon icon={icon({ name: 'calendar-days' })} /> Holiday Calendar
                        </h3>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body col-12">
                                {loading && (
                                    <div className="row mt-4">
                                        <div className="col-sm-12 mt-2 mb-2 text-center">
                                            <div className="spinner-border text-primary me-2" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {!loading && eventList && (
                                    <>
                                        <div className="row col-md-6 mt-2">
                                            <div className="col-md-6">
                                                <span className="m-1">
                                                    <FontAwesomeIcon
                                                        style={{ color: leaveColor }}
                                                        icon={icon({ name: 'square' })}
                                                    />{' '}
                                                    LEAVE
                                                </span>{' '}
                                                <span className="m-1">
                                                    <FontAwesomeIcon
                                                        style={{ color: holidayColor }}
                                                        icon={icon({ name: 'square' })}
                                                    />{' '}
                                                    HOLIDAY
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row col-12 mt-4">
                                            <Calendar
                                                localizer={localizer}
                                                events={eventList}
                                                startAccessor="start"
                                                endAccessor="end"
                                                eventPropGetter={eventStyleGetter}
                                                style={{ height: 500 }}
                                                views={views}
                                                onSelectEvent={handleSelectEvent}
                                                popup
                                            />
                                            <Modal
                                                size={'sm'}
                                                show={!!selectedEvent}
                                                centered={true}
                                                onHide={handleCloseModal}
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title>
                                                        {' '}
                                                        <FontAwesomeIcon
                                                            icon={icon({ style: 'regular', name: 'calendar' })}
                                                        />{' '}
                                                        {capitalize(selectedEvent?.type)} Details
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="row m-auto mt-1 col-12 p-3 py-1">
                                                        {selectedEvent?.type === 'leave' ? (
                                                            <div className="col-5 fw-bold">Employee:</div>
                                                        ) : (
                                                            <div className="col-5 fw-bold">Holiday:</div>
                                                        )}
                                                        <div className="col-6">{selectedEvent?.title ?? '-'}</div>
                                                    </div>
                                                    <div className="row m-auto mt-1 col-12 p-3 py-1">
                                                        <div className="col-5 fw-bold">Start Date:</div>
                                                        <div className="col-5">
                                                            {selectedEvent?.start
                                                                ? moment(selectedEvent?.start).format('Do MMMM YYYY')
                                                                : '-'}
                                                        </div>
                                                    </div>
                                                    <div className="row m-auto mt-1 col-12 p-3 py-1">
                                                        <div className="col-5 fw-bold">End Date:</div>
                                                        <div className="col-5">
                                                            {selectedEvent?.start
                                                                ? moment(selectedEvent?.end).format('Do MMMM YYYY')
                                                                : '-'}
                                                        </div>
                                                    </div>
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default LeaveCalendar;
