/* eslint-disable no-console */
import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import EmployeeSearchInput from '../../EmployeeSearchInput/EmployeeSearchInput';
import { searchEmployeeByKeyword } from '../../../services/employee';
import { EmployeeLeaveAllocation as EmployeeLeaveAllocationType } from '../../../types/employee';
import { getEmployeeLeavesAllocation, updateEmployeeLeaveAllocations } from '../../../services/employeeLeaves';
import { IRootState, useSelector } from '../../../store';
import { UserProfile } from '../../../types/user';
import { useAppContext } from '../../../hooks/useAppContext';

const EmployeeLeaveAllocation = () => {
    const loggedInUser = useSelector((state: IRootState) => state.user) as UserProfile;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultError = {} as any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const childRef = useRef() as any;
    const { triggerSuccess } = useAppContext();

    const currentYear = new Date().getFullYear().toString();
    const [loadingData, setLoadingData] = useState(false);
    const [errors, setErrors] = useState(defaultError);
    const [dataLocked, setDataLocked] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [dataFetched, setDataFetched] = useState(false);
    const pristineForm = {
        employeeId: '',
        leaveAllocation: [] as EmployeeLeaveAllocationType[],
    };
    const [formData, setFormData] = useState(pristineForm);
    const fetchEmployeeAllocaton = async () => {
        try {
            setLoadingData(true);
            const { data } = await getEmployeeLeavesAllocation(formData.employeeId, { year: currentYear });

            setFormData({ ...formData, leaveAllocation: data });
            setSubmitLoading(false);
            setDataFetched(true);
            setLoadingData(false);
            setDataLocked(true);
        } catch (err: unknown) {
            setLoadingData(false);
            setDataFetched(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };
    const updatedEmployeeLeaveAllocation = async () => {
        try {
            setErrors(defaultError);
            setSubmitLoading(true);
            const updatedAllocation = formData.leaveAllocation;
            const { data } = await updateEmployeeLeaveAllocations(loggedInUser.uuid, updatedAllocation);
            triggerSuccess(`Leave allocation updated successfully.`);
            setSubmitLoading(false);
            setFormData({ ...formData, leaveAllocation: data });
            setDataLocked(true);
        } catch (err: unknown) {
            setSubmitLoading(false);
            setDataLocked(true);
        }
    };
    const getemployeeAllocation = () => {
        if (!formData.employeeId) {
            setErrors({ employeeId: 'Search and select employee.' });
        } else {
            setErrors(defaultError);
            fetchEmployeeAllocaton();
        }
    };
    const clearData = () => {
        childRef.current?.clearInputs();
        setDataLocked(true);
        setDataFetched(false);
        setFormData({ ...pristineForm });
    };
    const unlockData = () => {
        setDataLocked(false);
    };

    const leaveAllocationChangeHandler = (
        e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>
    ) => {
        const fieldName = e.target.name;
        const changeId = fieldName.split('_')[1];
        const changeIndex = fieldName.split('_')[0];
        const selectedLeaveIndex = formData.leaveAllocation.findIndex((leave) => leave.uuid === changeId);
        if (selectedLeaveIndex !== -1) {
            const selectedLeave = formData.leaveAllocation[selectedLeaveIndex];
            const value = e.target.value;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { inputType } = e.nativeEvent as any;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { selectionStart, selectionEnd } = e.target as any;
            const isBackspaceOrDelete = inputType === 'deleteContentBackward' || inputType === 'deleteContentForward';
            const isBackspace = inputType === 'deleteContentBackward';
            const isDelete = inputType === 'deleteContentForward';
            let newValue = '0';
            if (
                isBackspaceOrDelete ||
                (isBackspace && selectionStart === 0) ||
                (isDelete && selectionEnd === value.length)
            ) {
                // Handle backspace or delete actions
                newValue = value.slice(0, -1); // Remove the last character
            } else if (/^\d*\.?\d*$/.test(value)) {
                // Check if the input is a valid number
                newValue = value;
            }
            if (changeIndex === 'leavesAllocated') {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                selectedLeave.leavesAllocated = newValue as any;
            } else if (changeIndex === 'leavesCarryForwarded') {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                selectedLeave.leavesCarryForwarded = newValue as any;
            }
            const updatedLeaveAllocation = formData.leaveAllocation;
            updatedLeaveAllocation[selectedLeaveIndex] = selectedLeave;
            setFormData({ ...formData, leaveAllocation: updatedLeaveAllocation });
        }
    };

    const handleBlur = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
        const fieldName = e.target.name;
        const changeId = fieldName.split('_')[1];
        const changeIndex = fieldName.split('_')[0];
        const selectedLeaveIndex = formData.leaveAllocation.findIndex((leave) => leave.uuid === changeId);
        delete errors.leaveAllocation;
        setErrors({ ...errors });
        if (selectedLeaveIndex !== -1) {
            const selectedLeave = formData.leaveAllocation[selectedLeaveIndex];
            let rawValue = e.target.value;

            // Check if the input is a valid number
            const isValidNumber = /^\d+(\.\d+)?$/.test(rawValue);
            if (!isValidNumber) {
                rawValue = '0';
            }
            if (parseInt(rawValue, 10) > 15) {
                setErrors({ ...errors, leaveAllocation: `Cannot exceed 15 leaves for ${selectedLeave.leaveType}` });
            }
            // Convert the input to a float, round to 2 decimal places
            const hours = parseFloat(rawValue).toFixed(2);
            if (changeIndex === 'leavesAllocated') {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                selectedLeave.leavesAllocated = hours as any;
            } else if (changeIndex === 'leavesCarryForwarded') {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                selectedLeave.leavesCarryForwarded = hours as any;
            }
            const balance =
                parseFloat(`${selectedLeave.leavesAllocated}`) +
                parseFloat(`${selectedLeave.leavesCarryForwarded}`) -
                selectedLeave.leavesApplied;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            selectedLeave.leavesBalance = balance.toFixed(2) as any;
            const updatedLeaveAllocation = formData.leaveAllocation;
            updatedLeaveAllocation[selectedLeaveIndex] = selectedLeave;
            setFormData({ ...formData, leaveAllocation: updatedLeaveAllocation });
        }
    };

    return (
        <main className="content leave-allocations">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-control-center-info">
                                {' '}
                                <p>
                                    <FontAwesomeIcon className="me-3" size="lg" icon={icon({ name: 'shield-alt' })} />{' '}
                                    Application Control Center{' '}
                                    <FontAwesomeIcon className="me-3 ms-3" icon={icon({ name: 'chevron-right' })} /> Employee
                                    Current Leave Allocation
                                </p>
                            </div>
                            <div className="card-body col-12">
                                <>
                                    <div className="container bg-light p-3 pb-1">
                                        <p>
                                            This feature allows you to efficiently allocate leaves for a individual employee
                                            for current year.
                                        </p>

                                        <ul>
                                            <li>
                                                <strong>Allocate Leaves for single employee:</strong>
                                                <ul>
                                                    <li>Updated employee&apos;s current leave allocation.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Caution - Overwrites Current Allocation:</strong>
                                                <ul>
                                                    <li>
                                                        Please exercise caution and refrain from updating the current
                                                        allocations.
                                                    </li>
                                                    <li>
                                                        Before you update any employee&apos;s leave allocation, make sure
                                                        that you have all the leave data and allocations checked with
                                                        employee.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="form-container bg-light p-4 pt-1">
                                        <div className="row col-12">
                                            <label className="fw-bold pb-2">
                                                Search Employee <span className="required-field">*</span>{' '}
                                            </label>
                                            <div className="col-md-6">
                                                <EmployeeSearchInput
                                                    errors={errors}
                                                    setErrors={setErrors}
                                                    formData={formData}
                                                    setFormData={setFormData}
                                                    searchFnCallback={searchEmployeeByKeyword}
                                                    inputIndex={'employeeId'}
                                                    ref={childRef}
                                                />
                                                <div
                                                    className={cn('invalid-feedback mb-3', {
                                                        show: errors.employeeId,
                                                    })}
                                                >
                                                    {errors.employeeId}
                                                </div>
                                            </div>
                                            <div className="col-md-4 mt-md-0 mt-3 align-bottom text-start">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={getemployeeAllocation}
                                                >
                                                    Get Leave Allocation
                                                </button>
                                                <span onClick={clearData} className="text-primary cursor-pointer ms-3">
                                                    Clear All
                                                </span>
                                            </div>
                                        </div>
                                        {loadingData ||
                                            (submitLoading && (
                                                <div className="row mt-4">
                                                    <div className="col-sm-12 mt-2 mb-2 text-center">
                                                        <div className="spinner-border text-primary me-2" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        {!loadingData &&
                                            !submitLoading &&
                                            formData.leaveAllocation &&
                                            formData.leaveAllocation?.length > 0 && (
                                                <div
                                                    className={cn('col-12 my-3 position-relative', {
                                                        'p-2': dataLocked,
                                                    })}
                                                >
                                                    {' '}
                                                    {dataLocked && (
                                                        <>
                                                            <div className="card-img-overlay col-12 bg-dark bg-opacity-75" />
                                                            <div
                                                                className="card-img-overlay col-12 text-light text-center"
                                                                role="button"
                                                            >
                                                                <FontAwesomeIcon
                                                                    className="align-middle"
                                                                    size="4x"
                                                                    icon={icon({ name: 'lock' })}
                                                                    onClick={unlockData}
                                                                />
                                                                <div className="fw-bold p-1">
                                                                    If you want to update allocation for this emplooyee
                                                                </div>
                                                                <div className="fw-bold p-1">Click to unlock</div>
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className="row gx-0 col-12 bg-app-header text-white fw-bold p-2">
                                                        <div className="col-3 p-2">Leave Type</div>
                                                        <div className="col-2 p-2">Leaves Allocated</div>
                                                        <div className="col-2 p-2">Leaves Carryforward</div>
                                                        <div className="col-2 p-2">Leaves Applied</div>
                                                        <div className="col-2 p-2">Leaves Balance</div>
                                                    </div>
                                                    <div className="row gx-0 bg-app-row p-2">
                                                        {formData.leaveAllocation?.map((leave, index) => (
                                                            <div key={leave.uuid} className="row gx-0 col-12 py-1">
                                                                <div className="col-3 py-1 px-2">{leave.leaveType}</div>
                                                                <div className="col-2 py-1 px-2">
                                                                    <input
                                                                        className="col-4"
                                                                        name={`leavesAllocated_${leave.uuid}`}
                                                                        onChange={leaveAllocationChangeHandler}
                                                                        onBlur={handleBlur}
                                                                        value={
                                                                            formData.leaveAllocation[index].leavesAllocated
                                                                        }
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                <div className="col-2 py-1 px-2">
                                                                    <input
                                                                        className="col-4"
                                                                        name={`leavesCarryForwarded_${leave.uuid}`}
                                                                        onChange={leaveAllocationChangeHandler}
                                                                        onBlur={handleBlur}
                                                                        disabled={
                                                                            leave.leaveType !==
                                                                            'Casual Leave_Privileged Leave'
                                                                        }
                                                                        value={
                                                                            formData.leaveAllocation[index]
                                                                                .leavesCarryForwarded
                                                                        }
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                <div className="col-2 py-1 px-2">{leave.leavesApplied}</div>
                                                                <div className="col-2 py-1 px-2">{leave.leavesBalance}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="col-12 mt-3 align-bottom text-end">
                                                        <div
                                                            className={cn('invalid-feedback mb-1', {
                                                                show: errors.leaveAllocation,
                                                            })}
                                                        >
                                                            {errors.leaveAllocation}
                                                        </div>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            disabled={Object.keys(errors).length > 0}
                                                            onClick={updatedEmployeeLeaveAllocation}
                                                        >
                                                            Update Allocation
                                                        </button>
                                                    </div>
                                                </div>
                                            )}

                                        {dataFetched && formData.leaveAllocation.length === 0 && (
                                            <div className="mt-3 col-md-8">
                                                <p className="p-2 bg-info text-start text-white">
                                                    Leave data is not available for this employee. <br />
                                                    This is highly unlikely situation, please report this problem to the HR.
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default EmployeeLeaveAllocation;
