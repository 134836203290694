/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch, IRootState } from '../../store';
import { resetGlobalError } from '../../store/errorHandler';
import { setAccessToken } from '../../store/token';
import { getUserProfile, loginUser, logoutUser } from '../../services/user';
// import { setUserProfile } from '../../store/user';
import { ADMIN_USERS, EMP_MANAGERS, UserCreds } from '../../types/user';
import { setUserProfile } from '../../store/user';
import { getMasterData } from '../../services/data';
import { setMasterFields } from '../../store/data';

type AuthorizerProps = {
    children: ReactElement;
};

type AuthContextType = {
    token: string | null;
    onLogin: (creds: UserCreds) => void;
    onLogout: () => void;
};

export const AuthContext = React.createContext<Partial<AuthContextType> & Pick<AuthContextType, 'onLogin' | 'onLogout'>>({
    onLogin: async () => undefined,
    onLogout: async () => undefined,
});
const allowedPaths = ['/login', '/forgot-password', '/reset-password'];
const Authorizer = (props: AuthorizerProps) => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const location = useLocation();
    const accessToken = useSelector((state: IRootState) => state.token.accessToken);
    const email = useSelector((state: IRootState) => state.user.officialEmailId);

    useEffect(() => {
        const initializeClient = async () => {
            try {
                const isResetPasswordPath = location.pathname.startsWith('/reset-password/');
                const loginPath = '/login';
                if (accessToken && !allowedPaths.includes(location.pathname)) {
                    navigate(location.pathname);
                } else if (!accessToken && (allowedPaths.includes(location.pathname) || isResetPasswordPath)) {
                    navigate(location.pathname);
                } else {
                    navigate(loginPath);
                }

                if (accessToken && !email) {
                    dispatch(setAccessToken(accessToken));
                    const response = await getUserProfile();
                    dispatch(setUserProfile(response.data));
                    if ([...ADMIN_USERS, ...EMP_MANAGERS].includes(response.data.role)) {
                        const masterResponse = await getMasterData();
                        dispatch(setMasterFields(masterResponse.data));
                    }
                    dispatch(resetGlobalError());
                }
            } catch (err: unknown) {
                dispatch(setAccessToken(null));
            }
        };
        initializeClient();
    }, [accessToken, dispatch, email, location.pathname, navigate]);

    const handleLogin = async (credentials: UserCreds) => {
        try {
            const authToken = (await loginUser(credentials)) as { token: string };
            dispatch(setAccessToken(authToken.token));
            const response = await getUserProfile();
            dispatch(setUserProfile(response.data));
            dispatch(resetGlobalError());
            navigate('/');
        } catch (_err: any) {
            dispatch(setAccessToken(null));
        }
    };

    const handleLogout = async () => {
        await logoutUser();
        dispatch(setAccessToken(null));
    };

    const value = {
        accessToken,
        onLogin: handleLogin,
        onLogout: handleLogout,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    };

    return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};

export default Authorizer;
