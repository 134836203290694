import React, { useState } from 'react';
import { v4 } from 'uuid';
import { ImageMimeType, MAX_FILE_UPLOAD_SIZE } from '../../constants/fileType';
import { useAppContext } from '../../hooks/useAppContext';
import { uploadEmpolyeePhoto } from '../../services/employee';
import FileDropzone from '../FileDropzone/FileDropzone';

type UploadPhotoProps = {
    loggedInUserId: string;
};

export const UploadEmployeePhoto = (props: UploadPhotoProps) => {
    const [error, setError] = useState('');
    const [uploadLoading, setUploadLoading] = useState(false);
    const { triggerSuccess } = useAppContext();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [uploadingFiles, setUploadingFiles] = useState<any[]>([]);

    const removeFileFromState = (uuid?: string) => {
        if (uuid) {
            const updatedUploadingFiles = uploadingFiles.filter((f) => f.uuid !== uuid);
            setUploadingFiles(updatedUploadingFiles);
        }
    };

    const uploadFile = async (file: File, uuid: string) => {
        setUploadLoading(true);
        const tempFile = {
            uuid,
            filesize: file.size,
            filename: file.name,
            progress: 0,
            ...file,
        };
        setUploadingFiles([tempFile, ...uploadingFiles]);

        const onProgress = (progress: number) => {
            const updatedUploadingFiles = uploadingFiles.map((f) => (f.uuid === uuid ? { ...f, progress } : f));
            setUploadingFiles(updatedUploadingFiles);
        };

        try {
            await uploadEmpolyeePhoto(file, props.loggedInUserId, onProgress);
            setUploadLoading(false);
            triggerSuccess(`Your photo was uploaded successfully`);
            setTimeout(() => window.location.reload(), 700);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            setUploadLoading(false);
            // const errors = { system: err.message };
            const updatedUploadingFiles = uploadingFiles.map((f) =>
                f.uuid === uuid ? { ...f, progress: undefined, error } : f
            );
            setUploadingFiles(updatedUploadingFiles);
            return;
        }
        // When done uploading, move file from state to store
        removeFileFromState(uuid);
    };

    const handleUpload = async (file: File, uuid: string) => {
        const fileSizeKiloBytes = file.size / 1024;
        if (fileSizeKiloBytes > MAX_FILE_UPLOAD_SIZE) {
            setError('File size is greater than maximum limit');
            return;
        }
        const isSameFileInStateWithError = uploadingFiles.find((f) => f.filename === file.name && !!f.errors);
        if (isSameFileInStateWithError && isSameFileInStateWithError.uuid) {
            removeFileFromState(isSameFileInStateWithError.uuid);
            uploadFile(file, isSameFileInStateWithError.uuid);
            return;
        }
        uploadFile(file, uuid);
    };

    const handleDrop = async (acceptedFiles: File[]) => {
        acceptedFiles.forEach((file) => {
            const uuid = v4();
            handleUpload(file, uuid);
        });
    };

    return (
        <>
            {error && (
                <div className="mb-2 error">
                    <strong>{error}</strong>
                </div>
            )}
            {uploadLoading && (
                <div className="row mt-4">
                    <div className="col-sm-12 mt-2 mb-2 text-center">
                        <div className="spinner-border text-primary me-2" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            )}
            {!uploadLoading && (
                <FileDropzone
                    acceptedMimeTypes={{
                        ...ImageMimeType,
                    }}
                    title={
                        <>
                            Drag &#39;n drop file here or <span className="button">browse</span>
                        </>
                    }
                    onDrop={handleDrop}
                    multiple={false}
                    note="Upload .png, .jpeg, and .jpg file with less than 1 MB size."
                />
            )}
        </>
    );
};
