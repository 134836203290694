import { AlphaNumericAndCharactersRegex, EmailRegex, PasswordRegex, PhoneRegex } from '../../constants/validations';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const customerFormValidate = (formData: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tempErrors = {} as any;
    // General Info
    if (!formData.name) {
        tempErrors.name = 'Name is a required field';
    } else if (!AlphaNumericAndCharactersRegex.test(formData.name)) {
        tempErrors.name = 'Name should only contain alphanumeric characters, hyphens, underscores, dot and spaces';
    }

    if (!formData.email) {
        tempErrors.email = 'Email is a required field';
    } else if (!EmailRegex.test(formData.email)) {
        tempErrors.email = 'Enter valid Email Address';
    }

    if (!formData.primaryContact) {
        tempErrors.primaryContact = 'Primary contact  is a required field';
    } else if (!PhoneRegex.test(formData.primaryContact)) {
        tempErrors.primaryContact = 'Enter valid primary contact number';
    }

    if (formData.secondaryContact && !PhoneRegex.test(formData.secondaryContact)) {
        tempErrors.secondaryContact = 'Enter valid secondary contact number';
    }

    if (!formData.country) {
        tempErrors.country = 'Country is a required field';
    }

    if (formData.password && !PasswordRegex.test(formData.password)) {
        tempErrors.password =
            'Password must be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character';
    }

    if (formData.password && formData.password !== formData.confirmPassword) {
        tempErrors.confirmPassword = 'Confirm password not matched';
    }

    return tempErrors;
};
