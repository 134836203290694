/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Autosuggest from 'react-autosuggest';
import { Employee } from '../../types/employee';
import './EmployeeSearchInput.scss';
import { AlphaNumericRegex } from '../../constants/validations';

type employeeIdInputProps = {
    setFormData: any;
    setErrors: any;
    errors: any;
    formData: any;
    searchFnCallback: (keyword: string, excludedUsers?: string[]) => any;
    inputIndex: string;
    inputData?: string;
    excludedUsers?: string[];
};

type userEntry = {
    name: string;
    uuid: string;
    officialEmailId: string;
};

const EmployeeSearchInput = forwardRef((props: employeeIdInputProps, ref: React.ForwardedRef<unknown>) => {
    const { setFormData, formData, searchFnCallback, inputIndex, inputData, excludedUsers } = props;
    let defaultValue = '';
    if (formData && formData[`${inputIndex}`]) {
        if (inputData) {
            defaultValue = inputData;
        } else {
            defaultValue = formData[`${inputIndex}`];
        }
    }
    const [value, setValue] = useState(defaultValue);
    const [suggestedValues, setSuggestedValues] = useState<string[]>([]);
    const [suggestions, setSuggestions] = useState<userEntry[]>([]);

    useImperativeHandle(ref, () => ({
        clearInputs() {
            setValue('');
            setFormData({ ...formData, [`${inputIndex}`]: '' });
        },
    }));

    const getSuggestions = async (inputValue: string) => {
        const inputValueLower = inputValue.trim().toLowerCase();
        const inputLength = inputValueLower.length;
        const { data } = excludedUsers
            ? await searchFnCallback(inputValueLower, excludedUsers)
            : await searchFnCallback(inputValueLower);

        const fetchedSuggestions = data.map((user: Employee) => `${user.name} <${user.officialEmailId}>`);
        setSuggestions(data);
        const suggestionList = inputLength === 0 ? [] : fetchedSuggestions;
        return suggestionList;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSuggestionSelected = (_: any, { suggestionValue }: { suggestionValue: string }) => {
        const selectedUser = suggestions.find((obj) => `${obj.name} <${obj.officialEmailId}>` === suggestionValue);
        setFormData({ ...formData, [`${inputIndex}`]: selectedUser?.uuid, [`entityValue`]: suggestionValue });
        setValue(suggestionValue);
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const onSuggestionsFetchRequested = async ({ value }: { value: string }) => {
        if (value.trim() !== '') {
            const suggestedValue = await getSuggestions(value);
            setSuggestedValues(suggestedValue);
        } else {
            setSuggestedValues([]);
        }
    };

    const onSuggestionsClearRequested = () => {
        setSuggestedValues([]);
    };

    const inputProps = {
        value,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange: (_: any, { newValue }: { newValue: string }) => {
            if (AlphaNumericRegex.test(newValue)) {
                setValue(newValue);
            } else {
                setValue('');
            }
        },
    };

    return (
        <div className="auto-suggest-input">
            <Autosuggest
                id={`${inputIndex}`}
                suggestions={suggestedValues}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={(suggestion) => suggestion}
                renderSuggestion={(suggestion) => <div className="auto-suggest-item">{suggestion}</div>}
                onSuggestionSelected={onSuggestionSelected}
                inputProps={inputProps}
            />
        </div>
    );
});

export default EmployeeSearchInput;
