// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-details-page .icons {
  padding: 5px 10px;
  margin: 0px 2px;
  cursor: pointer;
}
.customer-details-page .detail-header .meta-data {
  padding: 10px;
}
.customer-details-page .detail-header .project-dates {
  padding-top: 5px;
  padding-left: 20px;
}
.customer-details-page .card-body {
  padding-top: 0px;
}
.customer-details-page .card-body .customer-details {
  margin-top: 20px;
}
.customer-details-page .card-body .customer-details-row {
  margin-bottom: 10px;
}
.customer-details-page .card-body .customer-details .label {
  font-weight: bold;
  width: 50%;
  display: inline-block;
}
.customer-details-page .card-body .customer-details .detail {
  font-weight: normal;
  width: 50%;
  display: inline-block;
}
.customer-details-page .card-body .customer-name {
  font-size: 24px;
  font-weight: 500;
  color: #2a2c30;
  padding-right: 20px;
}
.customer-details-page .card-body .detail-separator {
  border-right: 1px solid #e7e7e7;
}
.customer-details-page .card-body h4 {
  background: #eff8ff;
  padding: 10px;
}
@media (min-width: 768px) {
  .customer-details-page .card-body .customer-details {
    margin-top: 0px;
  }
  .customer-details-page .card-body .customer-details .label {
    width: 30%;
  }
  .customer-details-page .card-body .customer-details .detail {
    width: 65%;
  }
  .customer-details-page .detail-separator {
    border-right: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Customer/Details/CustomerDetails.scss"],"names":[],"mappings":"AACI;EACI,iBAAA;EACA,eAAA;EACA,eAAA;AAAR;AAIQ;EACI,aAAA;AAFZ;AAIQ;EACI,gBAAA;EACA,kBAAA;AAFZ;AAMI;EACI,gBAAA;AAJR;AAKQ;EACI,gBAAA;AAHZ;AAIY;EACI,mBAAA;AAFhB;AAKY;EAAQ,iBAAA;EAAmB,UAAA;EAAY,qBAAA;AAAnD;AACY;EAAS,mBAAA;EAAsB,UAAA;EAAa,qBAAA;AAIxD;AAFQ;EACI,eAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;AAIZ;AADQ;EACI,+BAAA;AAGZ;AADQ;EACI,mBAAA;EACA,aAAA;AAGZ;AACI;EAEQ;IACI,eAAA;EAAd;EACc;IAAS,UAAA;EAEvB;EADc;IAAU,UAAA;EAIxB;EAAM;IACI,eAAA;EAEV;AACF","sourcesContent":[".customer-details-page {\n    .icons {\n        padding:5px 10px;\n        margin: 0px 2px;\n        cursor: pointer;\n    }\n\n    .detail-header {\n        .meta-data {\n            padding:10px;\n        }\n        .project-dates {\n            padding-top: 5px;\n            padding-left: 20px;\n        }\n    }\n\n    .card-body {\n        padding-top: 0px;\n        .customer-details {\n            margin-top:20px;\n            &-row {\n                margin-bottom: 10px;\n            }\n\n            .label{ font-weight: bold; width: 50%; display: inline-block;}\n            .detail{ font-weight: normal;  width: 50%;  display: inline-block;}\n        }\n        .customer-name {\n            font-size: 24px;\n            font-weight: 500;\n            color: #2a2c30;\n            padding-right: 20px;\n        }\n \n        .detail-separator {\n            border-right: 1px solid #e7e7e7;\n        }\n        h4 {\n            background: #eff8ff;\n            padding: 10px;\n        }\n    } \n\n    @media (min-width: 768px) {\n        .card-body {\n            .customer-details {\n                margin-top:0px;\n                .label{  width: 30%; }\n                .detail{  width: 65%; }\n            }\n        }\n\n        .detail-separator {\n            border-right: 0;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
