import { Activities } from '../constants/activityMaster';
import { Employee } from './employee';

export type ActivityType = keyof typeof Activities;

export interface UserActivity {
    uuid: string;
    employeeId: string;
    activityName: ActivityType;
    value: {
        status: boolean;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        meta?: any;
    };
}

export enum UserRole {
    superUser = 'super_user',
    manager = 'manager',
    employee = 'employee',
    director = 'director',
    hr = 'hr',
    customer = 'customer',
}

export interface UserProfile extends Employee {
    uuid: string;
    companyName: string;
    lastLogin: string;
    activities: UserActivity[];
}

export const SUPER_USERS = [UserRole.superUser];
export const ADMIN_USERS = [UserRole.superUser, UserRole.hr];
export const REGULAR_USERS = [UserRole.employee, UserRole.manager, UserRole.director];
export const EMP_MANAGERS = [UserRole.manager, UserRole.director];
export const CUSTOMER_USERS = [UserRole.customer];
export const ORG_USERS = [...SUPER_USERS, ...ADMIN_USERS, ...REGULAR_USERS];
export const ALL_USERS = [...ORG_USERS, ...CUSTOMER_USERS];

export interface UserCreds {
    username: string;
    password: string;
}

export enum UserAccountStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export interface ApplicationUser extends Employee {
    uuid: string;
    employeeId: string;
    password: string;
    role: UserRole;
    lastLogin: string;
    createdBy: string;
    status: UserAccountStatus;
}

export interface UserFilters {
    role?: string;
}
