/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row text-muted">
                    <div className="col-6 text-start">
                        <p className="mb-0">
                            <a className="text-muted" href="https://embeds2.com/" target="_blank">
                                <strong>Developed By - Embed Square Solutions Pvt. Ltd.</strong>
                            </a>{' '}
                            &copy;
                        </p>
                    </div>
                    <div className="col-6 text-end">
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <NavLink className="text-muted" to="/help-center">
                                    Support
                                </NavLink>
                            </li>
                            <li className="list-inline-item">
                                <NavLink className="text-muted" to="/privacy">
                                    Privacy
                                </NavLink>
                            </li>
                            <li className="list-inline-item">
                                <NavLink className="text-muted" to="/terms-and-conditions">
                                    Terms &amp; Conditions
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
