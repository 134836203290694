/* eslint-disable no-console */
import React from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { OnlineStatus } from '../../types/employee';

type EmployeeOnlineStatusProp = {
    onlineStatus: OnlineStatus;
    showLabel: boolean;
};

export const EmployeeOnlineStatus = (prop: EmployeeOnlineStatusProp) => {
    const { onlineStatus, showLabel } = prop;
    const status: OnlineStatus = onlineStatus as OnlineStatus;
    return (
        <span
            className={cn('p-2', {
                'bg-light': showLabel === true,
            })}
            title={onlineStatus}
        >
            <FontAwesomeIcon
                className={cn({
                    'text-success': status === OnlineStatus.ONLINE,
                    'text-warning': status === OnlineStatus.AWAY,
                    'text-dark-light': status === OnlineStatus.OFFLINE,
                })}
                icon={icon({ name: 'circle' })}
                size="sm"
            />
            {showLabel && <span className="px-1">{onlineStatus}</span>}
        </span>
    );
};
