/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IRootState, useSelector } from '../../store';
import { UserProfile } from '../../types/user';
import CustomerUpdate from './CustomerUpdate';

const CustomerUpdateContainer = () => {
    const { id } = useParams();
    const [customerId, setCustomerId] = useState('');
    const [isCustomerView, setisCustomerView] = useState(false);
    const loggedInUser = useSelector((state: IRootState) => state.user) as UserProfile;

    useEffect(() => {
        if (loggedInUser) {
            if (id) {
                setCustomerId(id);
                setisCustomerView(false);
            } else {
                setCustomerId(loggedInUser.uuid);
                setisCustomerView(true);
            }
        }
    }, [loggedInUser, id]);
    return <>{customerId && <CustomerUpdate customerId={customerId} isCustomerView={isCustomerView} />}</>;
};

export default CustomerUpdateContainer;
