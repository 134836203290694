import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { downloadEmployeeDocument } from '../../services/employeeDocuments';
import { EmployeeDocuments } from '../../types/employee';

type DocumentDownloadProps = {
    loggedInUserId: string;
    fileDetails: EmployeeDocuments;
};

export const DocumentDownload = (props: DocumentDownloadProps) => {
    const handleDocumentDownload = async (documentId: string) => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const blob: any = await downloadEmployeeDocument(props.loggedInUserId, documentId);
            const csvURL = window.URL.createObjectURL(blob);
            const tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', props.fileDetails.fileName);
            tempLink.click();
            tempLink.remove();
        } catch (_e) {}
    };

    return (
        <span className="cursor-pointer" onClick={() => handleDocumentDownload(props.fileDetails.uuid)}>
            {' '}
            <FontAwesomeIcon size="xl" icon={icon({ name: 'download' })} /> Download File
        </span>
    );
};
