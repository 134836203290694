// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timesheet-entry-form .week-label {
  font-size: 20px;
}
.timesheet-entry-form .btn:disabled {
  color: #eaeaea;
  background-color: #868686;
  border-color: #444444;
}
.timesheet-entry-form .header-row {
  background-color: #eaeaea;
}
.timesheet-entry-form .header-row .heading {
  font-size: 16px;
  font-weight: 600;
  color: #444444;
  text-align: left;
}
.timesheet-entry-form .header-row .heading__is-today {
  background-color: #444444;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/EmployeeTimesheet/Submit/EmployeeTimesheetMultiWeek.scss"],"names":[],"mappings":"AACI;EACI,eAAA;AAAR;AAEI;EACI,cAAA;EACA,yBAAA;EACA,qBAAA;AAAR;AAEI;EACI,yBAAA;AAAR;AACQ;EACI,eAAA;EACA,gBAAA;EAEA,cAAA;EACA,gBAAA;AAAZ;AAEY;EACI,yBAAA;EACA,cAAA;AAAhB","sourcesContent":[".timesheet-entry-form {\n    .week-label {\n        font-size: 20px;\n    }\n    .btn:disabled {\n        color:#eaeaea;\n        background-color: #868686;\n        border-color:#444444;\n    }\n    .header-row {\n        background-color: #eaeaea;\n        .heading {\n            font-size: 16px;\n            font-weight: 600;\n            // height: 70px;\n            color: #444444;\n            text-align: left;\n\n            &__is-today {\n                background-color: #444444;\n                color: #ffffff;\n            }\n        }\n\n        \n    }    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
