/* eslint-disable @typescript-eslint/no-explicit-any */

import {
    AlphaNumericAndCharactersRegex,
    AlphaNumericRegex,
    EmailRegex,
    PasswordRegex,
    PhoneRegex,
} from '../../constants/validations';
import { UserRole } from '../../types/user';

const employeeFormValidate = (formData: any, loggedInUserRole: UserRole) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tempErrors = {} as any;

    // General Info
    if (!formData.name) {
        tempErrors.name = 'Name is a required field';
    } else if (!AlphaNumericAndCharactersRegex.test(formData.name)) {
        tempErrors.name = 'Name should only contain alphanumeric characters, hyphens, underscores, dot and spaces';
    }

    if (!formData.gender) {
        tempErrors.gender = 'Gender is a required field';
    }

    if (!formData.dateOfBirth) {
        tempErrors.dateOfBirth = 'Date Of Birth is a required field';
    }

    if (formData.contactNumber && !PhoneRegex.test(formData.contactNumber)) {
        tempErrors.contactNumber = 'Enter valid Contact Number';
    }

    if (!formData.personalEmail) {
        tempErrors.personalEmail = 'Email is a required field';
    } else if (!EmailRegex.test(formData.personalEmail)) {
        tempErrors.personalEmail = 'Enter valid Email Address';
    }

    if (!formData.employeeId) {
        tempErrors.employeeId = 'Employee Id is a required field';
    } else if (!AlphaNumericRegex.test(formData.employeeId)) {
        tempErrors.employeeId = 'Employee Id should only contain alphanumeric characters only';
    }

    if (!formData.officialEmailId) {
        tempErrors.officialEmailId = 'Email is a required field';
    } else if (!EmailRegex.test(formData.officialEmailId)) {
        tempErrors.officialEmailId = 'Enter valid Email Address';
    }
    if (!formData.dateOfJoining) {
        tempErrors.dateOfJoining = 'Date Of Joining is a required field';
    }

    if (!formData.reportingManagerId) {
        tempErrors.reportingManagerId = 'Reporting Manager is a required field';
    } else if (!AlphaNumericAndCharactersRegex.test(formData.reportingManagerId)) {
        tempErrors.reportingManagerId =
            'Manager should only contain alphanumeric characters, hyphens, underscores, dot and spaces';
    }

    if (formData.reportingDirectorId && !AlphaNumericAndCharactersRegex.test(formData.reportingDirectorId)) {
        tempErrors.reportingDirectorId =
            'Director should only contain alphanumeric characters, hyphens, underscores, dot and spaces';
    }

    if (!formData.designation) {
        tempErrors.designation = 'Designation is a required field';
    }

    if (!formData.department) {
        tempErrors.department = 'Department is a required field';
    }

    if (loggedInUserRole === UserRole.employee) {
        if (!formData.permanentAddress) {
            tempErrors.permanentAddress = 'Enter your permanent address';
        } else if (formData.permanentAddress.length < 15) {
            tempErrors.permanentAddress = 'Enter valid permanent address';
        }

        if (!formData.temporaryAddress) {
            tempErrors.temporaryAddress = 'Enter your temporary address';
        } else if (formData.temporaryAddress.length < 15) {
            tempErrors.temporaryAddress = 'Enter valid temporary address';
        }

        if (!formData.emergencyContactNumber1Of) {
            tempErrors.emergencyContactNumber1 = 'Select emergency contact of';
        }
        if (!formData.highestEducationalQualification) {
            tempErrors.highestEducationalQualification = 'Select highest qualification';
        }
        if (!formData.totalWorkExperience) {
            tempErrors.totalWorkExperience = 'Select total work experience';
        }

        if (!formData.emergencyContactNumber1) {
            tempErrors.emergencyContactNumber1 = 'Enter emergency contact';
        } else if (formData.emergencyContactNumber1 && !PhoneRegex.test(formData.emergencyContactNumber1)) {
            tempErrors.emergencyContactNumber1 = 'Enter valid Contact Number';
        }

        if (!formData.panCardNumber) {
            tempErrors.panCardNumber = 'Pan number is a required field';
        } else if (!AlphaNumericRegex.test(formData.panCardNumber)) {
            tempErrors.panCardNumber = 'Pan number should only contain alphanumeric characters only';
        } else if (formData.panCardNumber && formData.panCardNumber.length > 11) {
            tempErrors.panCardNumber = 'Enter valid PAN number, should be less than 12 digits';
        }

        if (!formData.bankAccountNumber) {
            tempErrors.bankAccountNumber = 'Bank Account number is a required field';
        } else if (isNaN(formData.bankAccountNumber)) {
            tempErrors.bankAccountNumber = 'Bank Account number should be numbers only';
        } else if (formData.bankAccountNumber && formData.bankAccountNumber.length > 30) {
            tempErrors.bankAccountNumber = 'Enter valid bank account number, should be less than 30 digits';
        }

        if (!formData.aadhaarCardNumber) {
            tempErrors.aadhaarCardNumber = 'Aadhar number is a required field';
        } else if (isNaN(formData.aadhaarCardNumber)) {
            tempErrors.aadhaarCardNumber = 'Aadhar number should be numbers only';
        }

        if (!formData.ifscCode) {
            tempErrors.ifscCode = 'IFSC code is a required field';
        } else if (formData.ifscCode && formData.ifscCode.length > 11) {
            tempErrors.ifscCode = 'Enter valid IFSC code';
        }

        if (formData.pfAccountNumber && isNaN(formData.pfAccountNumber)) {
            tempErrors.pfAccountNumber = 'PF Account number should be numbers only';
        } else if (formData.pfAccountNumber && formData.pfAccountNumber.length > 30) {
            tempErrors.pfAccountNumber = 'Enter valid PF account number, should be less than 30 digits';
        }
    } else {
        if (formData.permanentAddress && formData.permanentAddress.length < 15) {
            tempErrors.permanentAddress = 'Enter valid permanent address';
        }

        if (formData.temporaryAddress && formData.temporaryAddress.length < 15) {
            tempErrors.temporaryAddress = 'Enter valid temporary address';
        }
        if (formData.emergencyContactNumber1 && !PhoneRegex.test(formData.emergencyContactNumber1)) {
            tempErrors.emergencyContactNumber1 = 'Enter valid Contact Number';
        }

        if (formData.panCardNumber && !AlphaNumericRegex.test(formData.panCardNumber)) {
            tempErrors.panCardNumber = 'Pan number should only contain alphanumeric characters only';
        } else if (formData.panCardNumber && formData.panCardNumber.length > 11) {
            tempErrors.panCardNumber = 'Enter valid PAN number, should be less than 12 digits';
        }

        if (formData.bankAccountNumber && isNaN(formData.bankAccountNumber)) {
            tempErrors.bankAccountNumber = 'Bank Account number should be numbers only';
        } else if (formData.bankAccountNumber && formData.bankAccountNumber.length > 30) {
            tempErrors.bankAccountNumber = 'Enter valid bank account number, should be less than 30 digits';
        }

        if (formData.aadhaarCardNumber && isNaN(formData.aadhaarCardNumber)) {
            tempErrors.aadhaarCardNumber = 'Aadhar Account number should be numbers only';
        }

        if (formData.ifscCode && formData.ifscCode.length > 11) {
            tempErrors.ifscCode = 'Enter valid IFSC code';
        }

        if (formData.pfAccountNumber && isNaN(formData.pfAccountNumber)) {
            tempErrors.pfAccountNumber = 'PF Account number should be numbers only';
        } else if (formData.pfAccountNumber && formData.pfAccountNumber.length > 30) {
            tempErrors.pfAccountNumber = 'Enter valid PF account number, should be less than 30 digits';
        }
    }

    if (formData.password && !PasswordRegex.test(formData.password)) {
        tempErrors.password =
            'Password must be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character';
    }

    if (formData.password && formData.password !== formData.confirmPassword) {
        tempErrors.confirmPassword = 'Confirm password not matched';
    }
    return tempErrors;
};
export default employeeFormValidate;
