import { request } from '../api';
import { PageLimit } from '../constants/data';
import { Customer, CustomerFilters, CustomerResourceAllocation, CustomerWithResourcesAllocation } from '../types/customer';
export const validateIfCustomerEmailAvailable = async (email: string) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/customer/exists`,
        data: { email },
    };
    return request<boolean>(requestOptions);
};

export const registerCustomer = async (
    customerData: Omit<Customer, 'uuid' | 'createdAt' | 'hasAppAccess' | 'createdBy' | 'updatedAt' | 'updatedBy'>
) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/customer/register`,
        data: { ...customerData },
    };
    return request(requestOptions);
};

export const getCustomerList = async (page: number, filters: CustomerFilters = {}) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/customer/list`,
        data: {
            pagination: {
                page,
                pageLimit: PageLimit,
            },
            filters,
        },
    };
    return request<{ data: Customer[] }>(requestOptions);
};
export const getCustomerById = async (uuid: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/customer/${uuid}`,
    };
    return request<{ data: Customer }>(requestOptions);
};

export const getCustomerAndResouceAllocationByCustomerId = async (uuid: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/customer/allocation/details/${uuid}`,
    };
    return request<{ data: CustomerWithResourcesAllocation }>(requestOptions);
};
export const deleteCustomer = async (uuid: string) => {
    const requestOptions = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_ENDPOINT}/customer/${uuid}`,
    };
    return request(requestOptions);
};

export const updateCustomer = async (
    customerData: Omit<Customer, 'uuid' | 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy' | 'status'>
) => {
    const requestOptions = {
        method: 'PATCH',
        url: `${process.env.REACT_APP_API_ENDPOINT}/customer/update`,
        data: { ...customerData },
    };
    return request(requestOptions);
};

export const getResouceAllocationById = async (uuid: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/customer/resource/allocation/detail/${uuid}`,
    };
    return request<{ data: CustomerResourceAllocation }>(requestOptions);
};

export const searchCustomerByKeyword = async (keyword: string, excludedUsers?: string[]) => {
    let url = `${process.env.REACT_APP_API_ENDPOINT}/customer/search/list/${keyword}`;

    if (excludedUsers && excludedUsers.length > 0) {
        const excludedQueryString = excludedUsers.join(',');
        url += `?exclude=${excludedQueryString}`;
    }

    const requestOptions = {
        method: 'GET',
        url: url,
    };

    return request<{ data: Customer[] }>(requestOptions);
};
