/* eslint-disable no-console */
import React, { useState } from 'react';
import cn from 'classnames';
import { NavLink, useNavigate } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useAppContext } from '../../../hooks/useAppContext';
import { searchUserRoleAssigmentList } from '../../../services/employee';
import { registerUser } from '../../../services/user';
import { IRootState, useSelector } from '../../../store';
import { UserProfile, UserRole } from '../../../types/user';
import { objectToPlainString } from '../../../helper/stringHelper';
import EmployeeSearchInput from '../../EmployeeSearchInput/EmployeeSearchInput';
import userFormValidate from './UserFormValidation';
import './ApplicationAccess.scss';

const ApplicationAccess = () => {
    const [key, setKey] = useState('1');
    const loggedInUser = useSelector((state: IRootState) => state.user) as UserProfile;
    const globalError = useSelector((state: IRootState) => state.error);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { triggerSuccess } = useAppContext();
    const pristineForm = {
        employeeId: '',
        role: '' as UserRole,
        password: '',
        confirmPassword: '',
    };
    const [formData, setFormData] = useState(pristineForm);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultError = {} as any;
    const [errors, setErrors] = useState(defaultError);

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
        setLoading(false);
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        setLoading(true);
        e.preventDefault();
        setErrors(defaultError);
        const validateErrors = userFormValidate(formData);
        if (Object.keys(validateErrors).length === 0) {
            const data = {
                ...formData,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any;
            try {
                // TODO: Need to clean up for handling this better
                await registerUser(data);
                triggerSuccess(`Application user added successfully`);
                setFormData(pristineForm);
                navigate('/user/list');
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (_e: any) {
                setLoading(false);
            }
        } else {
            setLoading(false);
            setErrors(validateErrors);
        }
    };
    return (
        <main className="content user-registration">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon icon={icon({ name: 'pencil' })} /> Grant Access
                        </h3>
                    </div>
                    <div className="col-auto ms-auto text-end mt-n1">
                        <NavLink to={`/user/list`} className="btn btn-primary">
                            <FontAwesomeIcon icon={icon({ name: 'angle-left' })} className="far fa-fw fa-bell" /> Back
                        </NavLink>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-info">
                                <p>
                                    To assign employee to the application, you have the option to search for a employee based
                                    on their name or offcial email address. The search results may include multiple users,
                                    allowing you to select the specific employee you wish to assign to the project.
                                </p>
                                <p className="mb-0">
                                    <strong>Note:</strong>
                                </p>
                                <ul>
                                    <li>
                                        {' '}
                                        Search will not return result for employee(s) which are already assigned to the
                                        application.
                                    </li>
                                    <li>
                                        Only <strong>Super User</strong> can see other <strong>Super User</strong> access.
                                    </li>
                                </ul>
                            </div>
                            <div className="card-body col-8">
                                {Object.keys(errors).length > 0 && (
                                    <div className="error-message">
                                        <p>
                                            <FontAwesomeIcon className="me-2" icon={icon({ name: 'warning' })} /> Looks like
                                            you have some errors on the form, check all the tabs before saving the details.
                                        </p>
                                        <p>
                                            Check below fields:
                                            <br />
                                            {Object.keys(errors)
                                                .map((errorKey) => objectToPlainString(errorKey))
                                                .join(', ')}
                                        </p>
                                    </div>
                                )}
                                <Tabs activeKey={key} onSelect={(k) => k && setKey(k)}>
                                    <Tab eventKey={'1'} title="Application Access">
                                        <form>
                                            <div className="tab-content">
                                                <label>
                                                    Search Employee <span className="required-field">*</span>
                                                </label>
                                                <EmployeeSearchInput
                                                    errors={errors}
                                                    setErrors={setErrors}
                                                    formData={formData}
                                                    setFormData={setFormData}
                                                    searchFnCallback={searchUserRoleAssigmentList}
                                                    inputIndex={'employeeId'}
                                                />

                                                <div
                                                    className={cn('invalid-feedback mb-3', {
                                                        show: errors.employeeId,
                                                    })}
                                                >
                                                    {errors.employeeId}
                                                </div>

                                                <label className="mt-2">
                                                    Role <span className="required-field">*</span>
                                                </label>
                                                <select
                                                    name="role"
                                                    onChange={handleChange}
                                                    className={`form-control form-control-lg mb-3 ${
                                                        errors.role ? 'is-invalid' : ''
                                                    }`}
                                                >
                                                    <option value="0">Select user role</option>
                                                    {loggedInUser.role === UserRole.superUser && (
                                                        <option value="super_user">Super User</option>
                                                    )}
                                                    <option value="hr">HR Executive</option>
                                                    <option value="employee">Employee</option>
                                                    <option value="manager">Manager</option>
                                                    <option value="director">Director</option>
                                                </select>
                                                {errors.role && <div className="invalid-feedback mb-3">{errors.role}</div>}
                                                <label>
                                                    Password <span className="required-field">*</span>
                                                </label>
                                                <input
                                                    className={`form-control form-control-lg mb-3 ${
                                                        errors.password ? 'is-invalid' : ''
                                                    }`}
                                                    type="password"
                                                    name="password"
                                                    value={formData.password}
                                                    autoComplete="false"
                                                    onChange={handleChange}
                                                    max={8}
                                                />
                                                {errors.password && (
                                                    <div className="invalid-feedback mb-3">{errors.password}</div>
                                                )}
                                                <label>
                                                    Confirm Password <span className="required-field">*</span>
                                                </label>
                                                <input
                                                    className={`form-control form-control-lg mb-3 ${
                                                        errors.confirmPassword ? 'is-invalid' : ''
                                                    }`}
                                                    type="password"
                                                    name="confirmPassword"
                                                    value={formData.confirmPassword}
                                                    onChange={handleChange}
                                                    autoComplete="false"
                                                    max={8}
                                                />
                                                {errors.confirmPassword && (
                                                    <div className="invalid-feedback mb-3">{errors.confirmPassword}</div>
                                                )}

                                                <div className="text-end mt-2">
                                                    <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                                                        Save Details{' '}
                                                        {loading && !globalError.isError && (
                                                            <div
                                                                className="spinner-border spinner-border-sm   me-2"
                                                                role="status"
                                                            >
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ApplicationAccess;
