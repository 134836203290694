import React, { ReactElement } from 'react';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'; // for React, Vue and Svelte
import './AppContext.scss'; // for React, Vue and Svelte

// Create an instance of Notyf
const notyf = new Notyf();

type AppContextProps = {
    children: ReactElement;
};

type AppContextType = {
    triggerSuccess: (message: string) => void;
};

export const AppContext = React.createContext<AppContextType>({
    triggerSuccess: async () => undefined,
});

const handleTriggerSuccess = (message: string) => {
    notyf.success({
        message,
        dismissible: true,
        duration: 10000,
        position: {
            x: 'center',
            y: 'top',
        },
    });
};

export default (props: AppContextProps) => {
    const value = {
        triggerSuccess: handleTriggerSuccess,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    };

    return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
};
