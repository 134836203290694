/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import { capitalize } from 'lodash';
import cn from 'classnames';
import { Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import moment from 'moment';
import { useAppContext } from '../../hooks/useAppContext';
import { IRootState, useSelector } from '../../store';
import { UserProfile, UserRole } from '../../types/user';
import { applyEmployeeleave, getEmployeeLeavesAllocation } from '../../services/employeeLeaves';
import { EmployeeLeaveTypes } from '../../constants/data';
import { EmployeeInsertLeave } from '../../types/employee';
import EmployeeSearchInput from '../EmployeeSearchInput/EmployeeSearchInput';
import { searchReportingLeaveApproverList } from '../../services/employee';
import { EmployeeLeaveAllocation, EmployeeLeaveFilters } from '../../types/employee';
import { calculateLeaveDays } from '../../helper/dateAndTime';
import { applyLeaveFormValidate } from './LeaveFormValidation';
import './LeavesBalanceTable.scss';

const ApplyLeaveForm = () => {
    const loggedInUser = useSelector((state: IRootState) => state.user) as UserProfile;
    const currentYear = new Date().getFullYear().toString();
    const [key, setKey] = useState('1');
    const globalError = useSelector((state: IRootState) => state.error);
    const [loading, setLoading] = useState(false);
    const [leaveBalance, setLeaveBalance] = useState<EmployeeLeaveAllocation[]>([]);
    const [balanceDateLoading, setBalanceDateLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const { triggerSuccess } = useAppContext();
    const pristineForm = {
        employeeId: '',
        role: '' as UserRole,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [formData, setFormData] = useState<any>(pristineForm);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultError = {} as any;
    const [errors, setErrors] = useState(defaultError);

    const handleTabSelect = (k: string | null) => {
        if (k) {
            setKey(k);
        }
    };

    const fetchEmployeeBalance = async (balanceDataFilter: Pick<EmployeeLeaveFilters, 'year'>) => {
        try {
            setBalanceDateLoading(true);
            setBalanceDateLoading(true);
            const { data } = await getEmployeeLeavesAllocation(loggedInUser.uuid, balanceDataFilter);
            setLeaveBalance(data);
            setBalanceDateLoading(false);
        } catch (err: unknown) {
            setError('Something went wrong');
            setBalanceDateLoading(false);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
        setLoading(false);
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setFormData({ ...formData, [e.target.name]: true });
        } else {
            setFormData({ ...formData, [e.target.name]: false });
        }
    };

    const validateLeaveBalance = (leaveAppliedForDays: number, leaveType: string) => {
        if (EmployeeLeaveTypes.basic.includes(leaveType)) {
            const matchedLeaveBalance = leaveBalance.filter((leave) => {
                return leave.leaveType.includes(leaveType);
            });
            if (matchedLeaveBalance.length > 0) {
                const balance = matchedLeaveBalance[0].leavesBalance - leaveAppliedForDays; //assume leave type of unique name (substring too).
                if (balance < 0) {
                    return true; // isOutOfBalance.
                }
            }
        }
        return false;
    };

    const handleSubmit = async (e: React.MouseEvent<HTMLElement>, leaveCategory: string) => {
        setLoading(true);
        e.preventDefault();
        setErrors(defaultError);
        setError(null);
        const validateErrors = applyLeaveFormValidate(formData, leaveCategory);
        if (Object.keys(validateErrors).length === 0) {
            const leaveStartDate = moment(formData[`${leaveCategory}LeaveStartDate`]).format(`YYYY-MM-DD`);
            const leaveEndDate = moment(formData[`${leaveCategory}LeaveEndDate`]).format(`YYYY-MM-DD`);
            const leaveType = formData[`${leaveCategory}LeaveType`];
            const totalLeaveDays = calculateLeaveDays(leaveStartDate, leaveEndDate);
            const isOutOfBalance = validateLeaveBalance(totalLeaveDays, leaveType);
            if (isOutOfBalance) {
                setError('You do not have enough balance to apply for this leave');
                setLoading(false);
                return;
            }
            let isHalfDay = false;
            if (leaveCategory === 'basic') {
                isHalfDay = !!formData[`${leaveCategory}HalfDay`];
            }
            const data = {
                employeeId: loggedInUser.uuid,
                leaveType,
                isHalfDay,
                reason: formData[`${leaveCategory}LeaveReason`],
                primaryApproverId: formData[`${leaveCategory}PrimaryAppoverId`],
                secondaryApproverId: formData[`${leaveCategory}SecondaryAppoverId`],
                leaveStartDate,
                leaveEndDate,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as EmployeeInsertLeave;
            try {
                // TODO: Need to clean up for handling this better
                await applyEmployeeleave(data);
                triggerSuccess(`Your leave request is submitted and awaiting approval`);
                setFormData(pristineForm);
                navigate('/employee/leaves/list');
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (_e: any) {
                setLoading(false);
            }
        } else {
            setLoading(false);
            setErrors(validateErrors);
        }
    };

    useEffect(() => {
        if (loggedInUser.uuid) {
            fetchEmployeeBalance({ year: currentYear });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInUser]);
    const endOfYearDate = moment().endOf('year');

    // Calculate the date 90 days from now
    const maxDate90Days = moment().add(90, 'days');

    // Set the maxDate based on the comparison
    const maxDate = maxDate90Days.isBefore(endOfYearDate) ? maxDate90Days.toDate() : endOfYearDate.toDate();

    return (
        <main className="content user-registration">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon icon={icon({ name: 'star-of-life' })} /> Apply Leave
                        </h3>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-info">
                                <p>
                                    {' '}
                                    Please enter all the required fields marked with an asterisk (
                                    <span className="required-field">*</span>).
                                </p>
                                <p className="mb-0">
                                    <strong>Note:</strong>
                                </p>
                                <ul>
                                    <li>Once you apply for the leave it will be sent for approval.</li>
                                    <li>Approval is required from your Manager and the HR.</li>
                                    <li>For some cases Director approval is needed as well.</li>
                                    <li>Please check the leave policy before applying</li>
                                </ul>
                            </div>
                            <div className="card-body col-md-10 p-3 mb-2 ms-3 leave-balance-table">
                                {balanceDateLoading && (
                                    <div key={0} className="row">
                                        <div className="col-sm-12 mt-2 mb-2 text-center">
                                            <div className="spinner-border text-primary me-2" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {leaveBalance.length > 0 && !balanceDateLoading && (
                                    <>
                                        <em className="text-secondary fw-bolder">
                                            ** Showing for year <span className="text-info">{currentYear}</span>
                                        </em>
                                        <div className="row col-md-12 p-1 pb-2 pt-2 header-row border">
                                            <div className="col-4  header-col">
                                                <div className=" heading">Leave Type</div>
                                            </div>
                                            <div className="col-4 header-col">
                                                <div className=" heading">Leave Applied</div>
                                            </div>
                                            <div className="col-4 header-col">
                                                <div className=" heading">Leave Balance</div>
                                            </div>
                                        </div>
                                        {leaveBalance.map((leave, index) => (
                                            <div
                                                key={`${leave.leaveType}${index}`}
                                                className="row p-1 pb-2 col-md-12 data-row"
                                            >
                                                <div className="col-4 data-col">{leave.leaveType.replace('_', '+')}</div>
                                                <div className="col-4 data-col ps-3"> {leave.leavesApplied}</div>
                                                <div className="col-4 data-col ps-3">
                                                    {' '}
                                                    {leave.leavesBalance === 999 ? '-' : leave.leavesBalance}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                            <div className="card-body col-md-8">
                                {(Object.keys(errors).length > 0 || error) && (
                                    <p className="error-message">
                                        <FontAwesomeIcon className="me-2" icon={icon({ name: 'warning' })} />
                                        {error ??
                                            `Looks like you
                                        have some errors on the form, check all the tabs before saving the details.`}
                                    </p>
                                )}

                                <Tabs activeKey={key} onSelect={handleTabSelect}>
                                    <Tab eventKey={'1'} title="Basic Leaves" disabled={loading}>
                                        <>
                                            <form>
                                                <div className="tab-content">
                                                    <label>
                                                        Leave Type <span className="required-field">*</span>
                                                    </label>
                                                    <select
                                                        name="basicLeaveType"
                                                        onChange={handleChange}
                                                        className={`form-control form-control-lg mb-3 ${
                                                            errors.basicLeaveType ? 'is-invalid' : ''
                                                        }`}
                                                    >
                                                        <option value="0">Select Leave Type</option>
                                                        {EmployeeLeaveTypes.basic.map((type, i) => (
                                                            <option key={i} value={`${type}`}>
                                                                {capitalize(type.replaceAll('_', ' '))}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors.basicLeaveType && (
                                                        <div className="invalid-feedback mb-3">{errors.basicLeaveType}</div>
                                                    )}
                                                    <div className="col-md-12 row">
                                                        <div className="col-md-6">
                                                            <label>
                                                                Start Date <span className="required-field">*</span>
                                                            </label>
                                                            <DatePicker
                                                                className={`form-control form-control-lg mb-3 ${
                                                                    errors.basicLeaveStartDate ? 'is-invalid' : ''
                                                                }`}
                                                                name="basicLeaveStartDate"
                                                                format="dd/MM/y"
                                                                maxDate={maxDate}
                                                                minDate={moment(new Date()).subtract(60, 'days').toDate()}
                                                                value={formData.basicLeaveStartDate}
                                                                onChange={(date) =>
                                                                    date &&
                                                                    setFormData({ ...formData, basicLeaveStartDate: date })
                                                                }
                                                            />
                                                            {errors.basicLeaveStartDate && (
                                                                <div className="invalid-feedback mb-3">
                                                                    {errors.basicLeaveStartDate}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label>
                                                                End Date <span className="required-field">*</span>
                                                            </label>
                                                            <DatePicker
                                                                className={`form-control form-control-lg mb-3 ${
                                                                    errors.basicLeaveEndDate ? 'is-invalid' : ''
                                                                }`}
                                                                name="basicLeaveEndDate"
                                                                format="dd/MM/y"
                                                                maxDate={maxDate}
                                                                minDate={moment(
                                                                    new Date(formData.basicLeaveStartDate)
                                                                ).toDate()}
                                                                value={formData.basicLeaveEndDate}
                                                                onChange={(date) => {
                                                                    date &&
                                                                        setFormData({
                                                                            ...formData,
                                                                            basicLeaveEndDate: date,
                                                                        });
                                                                }}
                                                            />
                                                            {errors.basicLeaveEndDate && (
                                                                <div className="invalid-feedback mb-3">
                                                                    {errors.basicLeaveEndDate}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="bg-primary-light p-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="basicHalfDay"
                                                            onChange={handleChecked}
                                                        />{' '}
                                                        Half day
                                                    </div>
                                                    <label>
                                                        Reason <span className="required-field">*</span>
                                                    </label>
                                                    <input
                                                        className={`form-control form-control-lg mb-3 ${
                                                            errors.basicLeaveReason ? 'is-invalid' : ''
                                                        }`}
                                                        type="text"
                                                        name="basicLeaveReason"
                                                        onChange={handleChange}
                                                        maxLength={100}
                                                    />
                                                    {errors.basicLeaveReason && (
                                                        <div className="invalid-feedback mb-3">
                                                            {errors.basicLeaveReason}
                                                        </div>
                                                    )}{' '}
                                                    <label>
                                                        Primary Approver <span className="required-field">*</span>
                                                    </label>
                                                    <input
                                                        className={`form-control form-control-lg mb-3 disabled`}
                                                        type="text"
                                                        disabled={true}
                                                        name="specialLeaveReason"
                                                        value={loggedInUser.managerDetail}
                                                        maxLength={100}
                                                    />
                                                    <label>
                                                        Secondary Approver <span className="required-field">*</span>
                                                    </label>
                                                    <EmployeeSearchInput
                                                        errors={errors}
                                                        setErrors={setErrors}
                                                        formData={formData}
                                                        setFormData={setFormData}
                                                        searchFnCallback={searchReportingLeaveApproverList}
                                                        inputIndex={'basicSecondaryAppoverId'}
                                                        inputData={formData.directorDetail}
                                                    />
                                                    <div
                                                        className={cn('invalid-feedback mb-3', {
                                                            show: errors.basicSecondaryAppoverId,
                                                        })}
                                                    >
                                                        {errors.basicSecondaryAppoverId}
                                                    </div>
                                                    <div className="text-end mt-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={(e) => handleSubmit(e, 'basic')}
                                                        >
                                                            Apply Leave{' '}
                                                            {loading && !globalError.isError && (
                                                                <div
                                                                    className="spinner-border spinner-border-sm   me-2"
                                                                    role="status"
                                                                >
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </>
                                    </Tab>
                                    <Tab eventKey={'2'} title="Special Leaves" disabled={loading}>
                                        <>
                                            <form>
                                                <div className="tab-content">
                                                    <label>
                                                        Leave Type <span className="required-field">*</span>
                                                    </label>
                                                    <select
                                                        name="specialLeaveType"
                                                        onChange={handleChange}
                                                        className={`form-control form-control-lg mb-3 ${
                                                            errors.specialLeaveType ? 'is-invalid' : ''
                                                        }`}
                                                    >
                                                        <option value="0">Select Leave Type</option>
                                                        {EmployeeLeaveTypes.special.map((type, i) => (
                                                            <option key={i} value={`${type}`}>
                                                                {capitalize(type.replaceAll('_', ' '))}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors.specialLeaveType && (
                                                        <div className="invalid-feedback mb-3">
                                                            {errors.specialLeaveType}
                                                        </div>
                                                    )}

                                                    <div className="col-md-12 row">
                                                        <div className="col-md-6">
                                                            <label>
                                                                Start Date <span className="required-field">*</span>
                                                            </label>
                                                            <DatePicker
                                                                className={`form-control form-control-lg mb-3 ${
                                                                    errors.specialLeaveStartDate ? 'is-invalid' : ''
                                                                }`}
                                                                name="specialLeaveStartDate"
                                                                format="dd/MM/y"
                                                                maxDate={moment(new Date()).add(90, 'days').toDate()}
                                                                minDate={moment(new Date()).subtract(60, 'days').toDate()}
                                                                value={formData.specialLeaveStartDate}
                                                                onChange={(date) =>
                                                                    date &&
                                                                    setFormData({ ...formData, specialLeaveStartDate: date })
                                                                }
                                                            />
                                                            {errors.specialLeaveStartDate && (
                                                                <div className="invalid-feedback mb-3">
                                                                    {errors.specialLeaveStartDate}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label>
                                                                End Date <span className="required-field">*</span>
                                                            </label>
                                                            <DatePicker
                                                                className={`form-control form-control-lg mb-3 ${
                                                                    errors.specialLeaveEndDate ? 'is-invalid' : ''
                                                                }`}
                                                                name="specialLeaveEndDate"
                                                                format="dd/MM/y"
                                                                maxDate={moment(new Date()).add(90, 'days').toDate()}
                                                                minDate={moment(
                                                                    new Date(formData.specialLeaveStartDate)
                                                                ).toDate()}
                                                                value={formData.specialLeaveEndDate}
                                                                onChange={(date) => {
                                                                    date &&
                                                                        setFormData({
                                                                            ...formData,
                                                                            specialLeaveEndDate: date,
                                                                        });
                                                                }}
                                                            />
                                                            {errors.specialLeaveEndDate && (
                                                                <div className="invalid-feedback mb-3">
                                                                    {errors.specialLeaveEndDate}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <label>
                                                        Reason <span className="required-field">*</span>
                                                    </label>
                                                    <input
                                                        className={`form-control form-control-lg mb-3 ${
                                                            errors.specialLeaveReason ? 'is-invalid' : ''
                                                        }`}
                                                        type="text"
                                                        name="specialLeaveReason"
                                                        onChange={handleChange}
                                                        maxLength={100}
                                                    />
                                                    {errors.specialLeaveReason && (
                                                        <div className="invalid-feedback mb-3">
                                                            {errors.specialLeaveReason}
                                                        </div>
                                                    )}
                                                    <label>
                                                        Primary Approver <span className="required-field">*</span>
                                                    </label>
                                                    <input
                                                        className={`form-control form-control-lg mb-3 disabled`}
                                                        type="text"
                                                        disabled={true}
                                                        name="specialLeaveReason"
                                                        value={loggedInUser.managerDetail}
                                                        maxLength={100}
                                                    />
                                                    <label>
                                                        Secondary Approver <span className="required-field">*</span>
                                                    </label>
                                                    <EmployeeSearchInput
                                                        errors={errors}
                                                        setErrors={setErrors}
                                                        formData={formData}
                                                        setFormData={setFormData}
                                                        searchFnCallback={searchReportingLeaveApproverList}
                                                        inputIndex={'specialSecondaryAppoverId'}
                                                        inputData={loggedInUser.directorDetail}
                                                    />
                                                    <div
                                                        className={cn('invalid-feedback mb-3', {
                                                            show: errors.specialSecondaryAppoverId,
                                                        })}
                                                    >
                                                        {errors.specialSecondaryAppoverId}
                                                    </div>
                                                    <div className="text-end mt-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={(e) => handleSubmit(e, 'special')}
                                                        >
                                                            Apply Leave{' '}
                                                            {loading && !globalError.isError && (
                                                                <div
                                                                    className="spinner-border spinner-border-sm   me-2"
                                                                    role="status"
                                                                >
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ApplyLeaveForm;
