// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timesheet-entry-form .header-row {
  background-color: #eaeaea;
}
.timesheet-entry-form .header-row .heading {
  font-size: 16px;
  font-weight: 500;
  color: #935050;
  text-align: left;
}
.timesheet-entry-form .data-row .data-col {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/EmployeeTimesheet/Submit/EmployeeTimesheetEntryForm.scss"],"names":[],"mappings":"AACI;EACI,yBAAA;AAAR;AACQ;EACI,eAAA;EACA,gBAAA;EAEA,cAAA;EACA,gBAAA;AAAZ;AAKQ;EACI,eAAA;EACA,gBAAA;EACA,gBAAA;AAHZ","sourcesContent":[".timesheet-entry-form {\n    .header-row {\n        background-color: #eaeaea;\n        .heading {\n            font-size: 16px;\n            font-weight: 500;\n            // height: 70px;\n            color: #935050;\n            text-align: left;\n        }\n    }\n\n    .data-row {\n        .data-col {\n            font-size: 16px;\n            font-weight: 500;\n            text-align: left;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
