import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import cn from 'classnames';
import { TimeSheetDataEntryType, TimeSheetDisplayWeek } from '../../types/timesheetType';
import './TimeSheetData.scss';
import { lockToggleTaskTime } from '../../services/employeeTimesheet';
import { useAppContext } from '../../hooks/useAppContext';

type TimeSheetProp = {
    timeSheetData: TimeSheetDataEntryType[];
    timeSheetDataTotalRow: TimeSheetDataEntryType;
    displayWeeks: TimeSheetDisplayWeek[];
    handleDelete?: (key: number) => void;
    handleRowEdits?: (key: TimeSheetDataEntryType, rowIndex: number) => void;
    timesheetId?: string;
    approverView?: boolean;
    editingRow?: number;
    isReadOnlyView?: boolean;
    refreshData?: () => void;
};

export const TimeSheetData = (prop: TimeSheetProp) => {
    const { triggerSuccess } = useAppContext();
    const {
        timeSheetData,
        displayWeeks,
        timeSheetDataTotalRow,
        handleDelete,
        handleRowEdits,
        editingRow,
        isReadOnlyView,
        approverView,
        timesheetId,
        refreshData,
    } = prop;

    const handlelockToggle = async (status: string, taskCellId?: string) => {
        if (approverView && timesheetId && taskCellId) {
            try {
                await lockToggleTaskTime(timesheetId, taskCellId);
                triggerSuccess(`Cell ${status} for the employee.`);
                if (refreshData) {
                    refreshData();
                }
            } catch (_e) {}
        }
    };

    return (
        <div className="timesheet-weekly-data">
            {timeSheetData.map((savedEntry, key) => {
                return (
                    <div
                        key={key}
                        className={cn('col-12 gx-0 d-flex saved-entries-row data-row', {
                            'data-row__is-editing': editingRow === key,
                            'data-row__read-only-bg': isReadOnlyView,
                        })}
                    >
                        <div className="col-3 ps-3 me-4 data-col data-col__normal-text pe-1 ">
                            <div className="m-2">{savedEntry.task.value}</div>
                        </div>
                        {displayWeeks.map((week, index) => (
                            <div
                                key={index}
                                className={cn('col-1 text-center data-col  pe-1 ', {
                                    'data-col__is-today': week.isToday === true && !isReadOnlyView,
                                    'data-col__no-value':
                                        savedEntry[`entrycol${index + 1}` as keyof TimeSheetDataEntryType].value ===
                                            '00:00' && isReadOnlyView,
                                    'data-col__value':
                                        savedEntry[`entrycol${index + 1}` as keyof TimeSheetDataEntryType].value !==
                                            '00:00' && isReadOnlyView,
                                })}
                            >
                                <div className="m-2">
                                    {savedEntry[`entrycol${index + 1}` as keyof TimeSheetDataEntryType].value}
                                    {savedEntry[`entrycol${index + 1}` as keyof TimeSheetDataEntryType].isLocked ? (
                                        <FontAwesomeIcon
                                            className={cn('ms-2 ', {
                                                'cursor-pointer': approverView,
                                            })}
                                            size="2xs"
                                            icon={icon({ name: 'lock' })}
                                            onClick={() =>
                                                handlelockToggle(
                                                    'un locked',
                                                    savedEntry[`entrycol${index + 1}` as keyof TimeSheetDataEntryType].uuid
                                                )
                                            }
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            className={cn('ms-2 ', {
                                                'cursor-pointer': approverView,
                                            })}
                                            size="2xs"
                                            icon={icon({ name: 'lock-open' })}
                                            onClick={() =>
                                                handlelockToggle(
                                                    'locked',
                                                    savedEntry[`entrycol${index + 1}` as keyof TimeSheetDataEntryType].uuid
                                                )
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                        <div className="col-2 data-col">
                            <div className="m-2 col-8">
                                {savedEntry.rowTotal.value}{' '}
                                {!isReadOnlyView && (
                                    <>
                                        <FontAwesomeIcon
                                            className={cn('ms-2 cursor-pointer')}
                                            size="sm"
                                            icon={icon({
                                                style: 'regular',
                                                name: 'xmark-circle',
                                            })}
                                            onClick={() => {
                                                if (handleDelete) {
                                                    handleDelete(key);
                                                }
                                            }}
                                        />
                                        <FontAwesomeIcon
                                            className={cn('ms-2 cursor-pointer')}
                                            size="sm"
                                            icon={icon({
                                                name: 'pen',
                                            })}
                                            onClick={() => {
                                                if (handleRowEdits) {
                                                    handleRowEdits(savedEntry, key);
                                                }
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                );
            })}
            {/* COL TOTAL */}
            <div
                className={cn('col-12 gx-0 d-flex saved-entries-row data-row', {
                    'data-row__read-only-bg': isReadOnlyView,
                })}
            >
                <div className="col-3 ps-3 me-4 data-col">
                    <div className="m-2">{'-'}</div>
                </div>
                {displayWeeks.map((week, index: number) => (
                    <div
                        key={index}
                        className={cn('col-1 text-center data-col  pe-1 ', {
                            'data-col__is-today': week.isToday === true && !isReadOnlyView,
                            'data-col__no-value':
                                timeSheetDataTotalRow[`entrycol${index + 1}` as keyof TimeSheetDataEntryType].value ===
                                    '00:00' && isReadOnlyView,
                            'data-col__value':
                                timeSheetDataTotalRow[`entrycol${index + 1}` as keyof TimeSheetDataEntryType].value !==
                                    '00:00' && isReadOnlyView,
                        })}
                    >
                        <div className="m-2">
                            {timeSheetDataTotalRow[`entrycol${index + 1}` as keyof TimeSheetDataEntryType].value}
                        </div>
                    </div>
                ))}
                <div className="col-2 data-col ">
                    <div className="m-2 col-8">
                        {timeSheetDataTotalRow.rowTotal.value}{' '}
                        <FontAwesomeIcon
                            className={cn('ms-2 cursor-pointer')}
                            size="sm"
                            icon={icon({
                                name: 'hashtag',
                            })}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
