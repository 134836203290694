import React, { useCallback, useState } from 'react';
import { startCase } from 'lodash';
import moment from 'moment';
import Avatar from 'react-avatar';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useRequest } from '../../../api/useRequest';
import { deleteEmployee, getEmployeeById } from '../../../services/employee';
import { useAppContext } from '../../../hooks/useAppContext';
import './EmployeeDetails.scss';
import { IRootState, useSelector } from '../../../store';
import { ADMIN_USERS, CUSTOMER_USERS, EMP_MANAGERS, UserProfile, UserRole } from '../../../types/user';
import { EmployeeImage } from '../../EmployeeImage/EmployeeImage';
import { EmployeeOnlineStatus } from '../../EmployeeOnlineStatus/EmployeeOnlineStatus';

const EmployeeDetails = () => {
    const { id } = useParams();

    const user = useSelector((state: IRootState) => state.user) as UserProfile;
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteTriggered, setDeleteTriggered] = useState(false);
    const fetchemployeeDetails = useCallback(() => getEmployeeById(id as string), [id]);
    const [loading, error, employeeDetails] = useRequest(fetchemployeeDetails);
    const { triggerSuccess } = useAppContext();
    const navigate = useNavigate();
    const handleDeleteConfirm = async () => {
        //deleting now
        setDeleteTriggered(true);
        await deleteEmployee(id as string);
        triggerSuccess(`User data deleted. Now you cannot find employee data again.`);
        setDeleteTriggered(false);
        setShowDeleteConfirmation(false);
        navigate('/employee/list');
    };

    const handleRedirectLeavesList = () => {
        navigate(`/employee/reportee/applied/leaves/list/${employeeDetails?.uuid}`, {
            state: {
                employeeName: employeeDetails?.name,
            },
        });
    };

    const handleRedirectWeeklyTimesheetList = () => {
        navigate(`/employee/reportee/submitted/weekly-timesheet/list/${employeeDetails?.uuid}`, {
            state: {
                employeeName: employeeDetails?.name,
            },
        });
    };

    return (
        <main className="content">
            <div className="container-fluid p-0 employee-details-page">
                <div className="row">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            {' '}
                            <FontAwesomeIcon icon={icon({ name: 'user' })} className="far fa-fw fa-bell" /> Employee Profile
                        </h3>
                    </div>

                    <div className="col-auto ms-auto text-end mt-n1">
                        <button className="btn btn-primary" onClick={() => navigate(-1)}>
                            <FontAwesomeIcon icon={icon({ name: 'angle-left' })} className="far fa-fw fa-bell" /> Back
                        </button>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            {loading && (
                                <div className="col-sm-12 mt-2 mb-2 text-center">
                                    <div className="spinner-border text-primary me-2" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            )}
                            {error && (
                                <div className="col-sm-12">
                                    <div className="alert alert-danger alert-outline alert-dismissible" role="alert">
                                        <div className="alert-icon">
                                            <FontAwesomeIcon size="lg" icon={icon({ name: 'bell' })} />
                                        </div>
                                        <div className="alert-message">
                                            <strong>Error</strong> Something went wrong.
                                            <br /> Unable to get the employee details.
                                            <br /> If the problem persists, please contact support.
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!loading && !error && employeeDetails && (
                                <>
                                    <div className="card-header detail-header mb-0">
                                        <div className="row">
                                            <div className="col-md-12 text-sm-end text-start">
                                                {ADMIN_USERS.includes(user.role) ? (
                                                    <NavLink to={`/employee/update/${employeeDetails.uuid}`}>
                                                        <FontAwesomeIcon
                                                            className="icons"
                                                            size="lg"
                                                            icon={icon({ name: 'edit' })}
                                                        />
                                                    </NavLink>
                                                ) : (
                                                    ''
                                                )}

                                                {user.role === UserRole.superUser ? (
                                                    <FontAwesomeIcon
                                                        onClick={() => setShowDeleteConfirmation(true)}
                                                        className="icons"
                                                        size="lg"
                                                        icon={icon({ name: 'trash-can' })}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body employee-details mb-0">
                                        <div className="row mb-2">
                                            <div className="col-md-2 me-3">
                                                <EmployeeImage userId={employeeDetails.uuid} />
                                            </div>
                                            <div className="col-md-8 mt-md-0 mt-sm-3">
                                                <Avatar
                                                    name={employeeDetails.name}
                                                    className="avatar img-fluid rounded me-1"
                                                    size="40"
                                                />{' '}
                                                <span className="employee-name">{startCase(employeeDetails.name)} </span>
                                                <EmployeeOnlineStatus
                                                    onlineStatus={employeeDetails.onlineStatus}
                                                    showLabel={true}
                                                />
                                                {/* <div className="row mt-2 ">
                                                    <div className="col-12 mt-2 col-md-2 employee-details detail-separator">
                                                        <div className="employee-details-row">
                                                            <FontAwesomeIcon icon={icon({ name: 'calendar' })} /> View
                                                            Calendar
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mt-2 col-md-2 employee-details">
                                                        <div className="employee-details-row">
                                                            <FontAwesomeIcon icon={icon({ name: 'message' })} /> Send Message
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="mt-2 employee-details">
                                                    <div className="col-md-6 employee-details-row">
                                                        <div className="label">Designation:</div>
                                                        <div className="detail">{employeeDetails.designation}</div>
                                                    </div>
                                                    <div className="col-md-6 employee-details-row">
                                                        <div className="label">Department:</div>
                                                        <div className="detail">{employeeDetails.department}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {[...EMP_MANAGERS, ...ADMIN_USERS].includes(user.role) && (
                                            <div className="row p-2">
                                                <div className="col-12 col-md-12">
                                                    <button onClick={handleRedirectLeavesList} className="btn btn-info me-3">
                                                        Leaves Information
                                                    </button>

                                                    <button
                                                        onClick={handleRedirectWeeklyTimesheetList}
                                                        className="btn btn-info me-3"
                                                    >
                                                        Timesheet Submitted
                                                    </button>

                                                    <button disabled={true} className="btn btn-info me-3">
                                                        Documents Uploaded
                                                    </button>
                                                </div>
                                            </div>
                                        )}

                                        <div className="row p-2">
                                            <h4>Official Info:</h4>
                                            <div className="col-12 col-md-6 employee-details detail-separator">
                                                <div className="employee-details-row">
                                                    <div className="label">Official Email:</div>
                                                    <div className="detail">{employeeDetails.officialEmailId}</div>
                                                </div>
                                                <div className="employee-details-row">
                                                    <div className="label">Employee Id:</div>
                                                    <div className="detail">{employeeDetails.employeeId}</div>
                                                </div>
                                                <div className="employee-details-row">
                                                    <div className="label">Reporting Manager:</div>
                                                    <div className="detail">{employeeDetails.managerDetail}</div>
                                                </div>
                                                <div className="employee-details-row">
                                                    <div className="label">Reporting Director:</div>
                                                    <div className="detail">{employeeDetails.directorDetail}</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 employee-details">
                                                <div className="employee-details-row">
                                                    <div className="label">Date of joining:</div>
                                                    <div className="detail">
                                                        {moment(employeeDetails.dateOfJoining).format('Do MMMM YYYY')}
                                                    </div>
                                                </div>
                                                {[...EMP_MANAGERS, ...ADMIN_USERS].includes(user.role) && (
                                                    <div className="employee-details-row">
                                                        <div className="label">Total Experience:</div>
                                                        <div className="detail">
                                                            {employeeDetails.totalWorkExperience
                                                                ? `${employeeDetails.totalWorkExperience} in years`
                                                                : '-'}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="employee-details-row">
                                                    <div className="label">Highest Qualification:</div>
                                                    <div className="detail">
                                                        {employeeDetails.highestEducationalQualification ?? '-'}
                                                    </div>
                                                </div>
                                                {ADMIN_USERS.includes(user.role) && (
                                                    <div className="employee-details-row">
                                                        <div className="label">Current CTC:</div>
                                                        <div className="detail">{employeeDetails.currentCtc ?? '-'}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mt-3 p-2">
                                            <h4>Personal Info:</h4>
                                            {ADMIN_USERS.includes(user.role) && (
                                                <>
                                                    <div className="col-12 col-md-6 employee-details detail-separator">
                                                        <div className="employee-details-row">
                                                            <div className="label">Personal Email:</div>
                                                            <div className="detail">{employeeDetails.personalEmail}</div>
                                                        </div>
                                                        <div className="employee-details-row">
                                                            <div className="label">Date Of Birth</div>
                                                            <div className="detail">
                                                                {moment(employeeDetails.dateOfBirth).format('Do MMMM YYYY')}
                                                            </div>
                                                        </div>
                                                        <div className="employee-details-row">
                                                            <div className="label">Gender:</div>
                                                            <div className="detail">{employeeDetails.gender}</div>
                                                        </div>
                                                        <div className="employee-details-row">
                                                            <div className="label">Permanent Address:</div>
                                                        </div>
                                                        <div className="employee-details-row">
                                                            <div className="detail">{employeeDetails.permanentAddress}</div>
                                                        </div>
                                                        <div className="employee-details-row">
                                                            <div className="label">Temporary Address:</div>
                                                        </div>
                                                        <div className="employee-details-row">
                                                            <div className="detail">{employeeDetails.temporaryAddress}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 employee-details">
                                                        <div className="employee-details-row">
                                                            <div className="label">Contact:</div>
                                                            <div className="detail">{employeeDetails.contactNumber}</div>
                                                        </div>
                                                        <div className="employee-details-row">
                                                            <div className="label">Blood Group:</div>
                                                            <div className="detail">{employeeDetails.bloodGroup ?? '-'}</div>
                                                        </div>
                                                        <div className="employee-details-row">
                                                            <div className="label">Marital Status:</div>
                                                            <div className="detail">
                                                                {employeeDetails.maritalStatus ?? '-'}
                                                            </div>
                                                        </div>
                                                        <div className="employee-details-row">
                                                            <div className="label">Emergency Contact 1:</div>
                                                        </div>
                                                        <div className="employee-details-row">
                                                            <div className="detail">
                                                                {employeeDetails.emergencyContactNumber1Of ?? '-'} -
                                                                {employeeDetails.emergencyContactNumber1 ?? '-'}
                                                            </div>
                                                        </div>
                                                        <div className="employee-details-row">
                                                            <div className="label">Emergency Contact 2:</div>
                                                        </div>
                                                        <div className="employee-details-row">
                                                            <div className="detail">
                                                                {employeeDetails.emergencyContactNumber2Of ?? '-'} -
                                                                {employeeDetails.emergencyContactNumber2 ?? '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {[...EMP_MANAGERS, ...CUSTOMER_USERS].includes(user.role) && (
                                                <>
                                                    <div className="col-12 col-md-6 employee-details">
                                                        <div className="employee-details-row">
                                                            <div className="label">Contact:</div>
                                                            <div className="detail">{employeeDetails.contactNumber}</div>
                                                        </div>

                                                        <div className="employee-details-row">
                                                            <div className="label">Emergency Contact 1:</div>
                                                        </div>
                                                        <div className="employee-details-row">
                                                            <div className="detail">
                                                                {employeeDetails.emergencyContactNumber1Of ?? '-'} -
                                                                {employeeDetails.emergencyContactNumber1 ?? '-'}
                                                            </div>
                                                        </div>
                                                        <div className="employee-details-row">
                                                            <div className="label">Emergency Contact 2:</div>
                                                        </div>
                                                        <div className="employee-details-row">
                                                            <div className="detail">
                                                                {employeeDetails.emergencyContactNumber2Of ?? '-'} -
                                                                {employeeDetails.emergencyContactNumber2 ?? '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        {(user.role === UserRole.hr || user.role === UserRole.superUser) && (
                                            <div className="row mt-3 p-2">
                                                <h4>Banking Info:</h4>
                                                <div className="col-12 col-md-6 employee-details detail-separator">
                                                    <div className="employee-details-row">
                                                        <div className="label">PAN:</div>
                                                        <div className="detail">{employeeDetails.panCardNumber}</div>
                                                    </div>
                                                    <div className="employee-details-row">
                                                        <div className="label">Bank Account:</div>
                                                        <div className="detail">{employeeDetails.bankAccountNumber}</div>
                                                    </div>

                                                    <div className="employee-details-row">
                                                        <div className="label">PF:</div>
                                                        <div className="detail">{employeeDetails.pfAccountNumber}</div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 employee-details">
                                                    <div className="employee-details-row">
                                                        <div className="label">IFSC code:</div>
                                                        <div className="detail">{employeeDetails.ifscCode}</div>
                                                    </div>
                                                    <div className="employee-details-row">
                                                        <div className="label">Adhar Number:</div>
                                                        <div className="detail">{employeeDetails.aadhaarCardNumber}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showDeleteConfirmation} centered={true} onHide={() => setShowDeleteConfirmation(false)}>
                {!deleteTriggered ? (
                    <>
                        {' '}
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} size="lg" />
                                <span className="ms-2">Delete Employee Data</span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                You are about to delete the details of <strong>{employeeDetails?.name}</strong>
                            </p>
                            <p>This operation is irreversible. Once the data is deleted it cannot be recovered back.</p>
                            <p>Do you wish to continue?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleDeleteConfirm}>
                                Delete Now
                            </Button>
                        </Modal.Footer>
                    </>
                ) : (
                    <>
                        {' '}
                        <Modal.Header>
                            <Modal.Title>
                                <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} size="lg" />
                                <span className="ms-2">Delete Employee Data</span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h3 className="text-center">Your delete request is being processed</h3>
                            <p className="text-center">
                                <div className="spinner-border spinner-border-sm me-2" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </p>
                        </Modal.Body>
                    </>
                )}
            </Modal>
        </main>
    );
};

export default EmployeeDetails;
