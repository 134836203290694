/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { capitalize } from 'lodash';
import DatePicker from 'react-date-picker';
import EmployeeSearchInput from '../../EmployeeSearchInput/EmployeeSearchInput';
import { HelpTip } from '../../HelpTip/HelpTip';
import { ResourceAllocation } from '../../../types/customer';
import { getEmployeeById, searchEmployeeByKeyword } from '../../../services/employee';
import { Employee } from '../../../types/employee';
import { EmployeeImage } from '../../EmployeeImage/EmployeeImage';
import { IRootState, useSelector } from '../../../store';
import { checkIfResourceAllocated, customerAllocateResource } from '../../../services/resourceAllocation';
import { useAppContext } from '../../../hooks/useAppContext';
import './AllocateResource.scss';

const AllocateResource = () => {
    const navigate = useNavigate();
    const { designation: stateDesignation } = useSelector((state: IRootState) => state.data);

    const globalError = useSelector((state: IRootState) => state.error);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultError = {} as any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const childRef = useRef() as any;

    const [errors, setErrors] = useState(defaultError);
    const [customerDetail, setCustomerDetail] = useState({
        customerName: '',
        customerId: '',
    });
    const [employeeDetails, setEmployeeDetails] = useState<Employee>();
    const pristineForm = {
        employeeId: '',
        allocationDetails: {
            customerId: '',
            employeeId: '',
            startDate: '',
            endDate: '',
            role: '',
            status: '', // Ended, InProgress
            customerApprover: false,
        } as ResourceAllocation,
    };
    const [formData, setFormData] = useState(pristineForm);
    const [loadingData, setLoadingData] = useState(false);
    const [isAlreadyAllocated, setIsAlreadyAllocated] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [showResourceAllocation, setShowResourceAllocation] = useState(false);
    const { triggerSuccess } = useAppContext();

    const clearData = () => {
        childRef.current?.clearInputs();
        setFormData({ ...pristineForm });
        setEmployeeDetails(undefined);
        setSubmitLoading(false);
        setShowResourceAllocation(false);
    };
    const fetchEmployeeDetails = async () => {
        try {
            setLoadingData(true);
            const [employee, resouceAllocated] = await Promise.all([
                getEmployeeById(formData.employeeId),
                checkIfResourceAllocated(formData.employeeId),
            ]);
            setEmployeeDetails(employee.data);
            setIsAlreadyAllocated(resouceAllocated);
            setSubmitLoading(false);
            setLoadingData(false);
        } catch (err: unknown) {
            setLoadingData(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const getemployeeAllocation = () => {
        if (!formData.employeeId) {
            setErrors({ ...errors, employeeId: 'Search and select employee.' });
        } else {
            setErrors(defaultError);
            fetchEmployeeDetails();
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
        setFormData({
            ...formData,
            allocationDetails: {
                ...formData.allocationDetails,
                [e.target.name]: e.target.value,
            },
        });
    };

    const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setErrors(defaultError); // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const tempErrors = {} as any;
        if (!formData.allocationDetails.role) {
            tempErrors['role'] = 'Select allocation role';
        }
        if (!formData.allocationDetails.startDate) {
            tempErrors['startDate'] = 'Select start date';
        }
        if (!formData.allocationDetails.endDate) {
            tempErrors['endDate'] = 'Select end date';
        }

        if (Object.keys(tempErrors).length === 0) {
            setSubmitLoading(true);
            const leaveStartDate = moment(formData.allocationDetails.startDate).format(`YYYY-MM-DD`);
            const leaveEndDate = moment(formData.allocationDetails.endDate).format(`YYYY-MM-DD`);
            const data = {
                ...formData.allocationDetails,
                customerId: customerDetail.customerId,
                employeeId: employeeDetails?.uuid as string,
                leaveStartDate,
                leaveEndDate,
            };
            try {
                await customerAllocateResource(data);
                triggerSuccess(`Customer data removed successfully`);
                navigate(`/customer/details/${customerDetail.customerId}`);
            } catch (err: unknown) {
                clearData();
            }
            setSubmitLoading(false);
        } else {
            setErrors(tempErrors);
        }
    };
    useEffect(() => {
        if (history.state.usr) {
            const customerName = history.state.usr.customerName;
            const customerId = history.state.usr.customerId;
            setCustomerDetail({ customerName, customerId });
        }
    }, []);

    return (
        <main className="content resource-allocation">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon icon={icon({ name: 'people-arrows' })} /> Allocate Resource
                        </h3>
                    </div>
                    <div className="col-auto ms-auto text-end mt-n1">
                        <button className="btn btn-primary" onClick={() => navigate(-1)}>
                            <FontAwesomeIcon icon={icon({ name: 'angle-left' })} className="far fa-fw fa-bell" /> Back
                        </button>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-info">
                                <p>
                                    Search for an employee to allocate. Use the search functionality to find the desired
                                    employee.
                                </p>
                                <p>
                                    Fill in the required details for the allocation and click the &apos;
                                    <strong>Allocate Resource</strong>&apos; button to complete the process.
                                </p>
                            </div>

                            <div className="card-body card-body__min-height  col-12">
                                <h4>Allocating Resource For Customer</h4>
                                <div className="row mt-4">
                                    <div className="col-12">
                                        <strong>Customer Name:</strong> {customerDetail.customerName}
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="row col-12">
                                        <label className="fw-bold pb-2">
                                            Search Employee <span className="required-field">*</span>{' '}
                                        </label>
                                        <div className="col-md-6">
                                            <EmployeeSearchInput
                                                errors={errors}
                                                setErrors={setErrors}
                                                formData={formData}
                                                setFormData={setFormData}
                                                searchFnCallback={searchEmployeeByKeyword}
                                                inputIndex={'employeeId'}
                                                ref={childRef}
                                            />
                                            <div
                                                className={cn('invalid-feedback mb-3', {
                                                    show: errors.employeeId,
                                                })}
                                            >
                                                {errors.employeeId}
                                            </div>
                                        </div>
                                        <div className="col-md-4 mt-md-0 mt-3 align-bottom text-start">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={getemployeeAllocation}
                                            >
                                                Get Employee Details
                                            </button>
                                            <span onClick={clearData} className="text-primary cursor-pointer ms-3">
                                                Clear All
                                            </span>
                                        </div>
                                    </div>
                                    {(loadingData || submitLoading) && (
                                        <div className="row mt-4">
                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                <div className="spinner-border text-primary me-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {!loadingData && !submitLoading && employeeDetails && (
                                        <div className="row mt-5 ">
                                            <div className="row gx-0 col-12 ms-2   employee-detail-card p-3">
                                                <div className="col-12 p-2">
                                                    <h4 className="bg-light  p-2">Employee Details:</h4>
                                                </div>
                                                <div className="row gx-0 col-12  ">
                                                    <div className="col-12 col-md-3 text-center">
                                                        {' '}
                                                        <EmployeeImage
                                                            userId={formData.employeeId}
                                                            showDeleteButton={false}
                                                        />
                                                    </div>

                                                    <div className="row col-12 col-md-9 ">
                                                        <div className="col-12 col-md-6 ">
                                                            <strong>Name:</strong> {employeeDetails.name}
                                                        </div>
                                                        <div className="col-12 col-md-6  ">
                                                            <strong>Official Email:</strong>{' '}
                                                            {employeeDetails.officialEmailId}
                                                        </div>
                                                        <div className="col-12 col-md-6  ">
                                                            <strong>Department:</strong> {employeeDetails.department}
                                                        </div>
                                                        <div className="col-12 col-md-6  ">
                                                            <strong>Designation:</strong> {employeeDetails.designation}
                                                        </div>
                                                        <div className="col-12 col-md-6  ">
                                                            <strong>Reporting Manager:</strong>{' '}
                                                            {employeeDetails.managerDetail}
                                                        </div>
                                                        <div className="col-12 col-md-6  ">
                                                            <strong>Reporting Director:</strong>{' '}
                                                            {employeeDetails.directorDetail}
                                                        </div>

                                                        <div className="col-12 col-md-6  ">
                                                            <strong>Date Of Joining:</strong>{' '}
                                                            {moment(employeeDetails.dateOfJoining).format('DD/MM/YYYY')}
                                                        </div>
                                                        <div className="col-12 col-md-6  ">
                                                            <strong>Total Experience:</strong>{' '}
                                                            {employeeDetails.totalWorkExperience}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row gx-0 col-12 ms-2 mt-4 ">
                                                <div className="col-12 text-start">
                                                    <button
                                                        className="btn btn-info"
                                                        onClick={() => setShowResourceAllocation(true)}
                                                    >
                                                        <FontAwesomeIcon
                                                            size="sm"
                                                            icon={icon({ name: 'user-check' })}
                                                            className="far fa-fw fa-bell"
                                                        />{' '}
                                                        Allocate {capitalize(employeeDetails.name)}
                                                    </button>
                                                </div>
                                            </div>

                                            {showResourceAllocation && (
                                                <div className="row gx-0 col-10 ms-4 mt-4 p-4 bg-light allocate-container">
                                                    <h4>Fill Allocation Details:</h4>

                                                    {isAlreadyAllocated && (
                                                        <div
                                                            className="alert alert-warning alert-outline   mt-3"
                                                            role="alert"
                                                        >
                                                            <div className="alert-icon">
                                                                <FontAwesomeIcon
                                                                    size="sm"
                                                                    icon={icon({ name: 'bell' })}
                                                                    className="far fa-fw fa-bell"
                                                                />
                                                            </div>
                                                            <div className="alert-message">
                                                                {' '}
                                                                <p className="mb-0">
                                                                    Looks like this resource is already been allocated to
                                                                    some customer.
                                                                </p>
                                                                <p className="mb-0">
                                                                    Before filling the allocation make sure that this
                                                                    employee is not already allocated to this customer or any
                                                                    other customer.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div className="row col-10 mt-2  ">
                                                        <label>
                                                            Role <span className="required-field">*</span>
                                                        </label>
                                                        <select
                                                            name="role"
                                                            onChange={handleChange}
                                                            className={`form-control form-control-lg form-select mb-3 ${
                                                                errors.role ? 'is-invalid' : ''
                                                            }`}
                                                            value={formData.allocationDetails.role}
                                                        >
                                                            <option value="">Select Role</option>
                                                            {stateDesignation &&
                                                                stateDesignation.map((designation, i) => (
                                                                    <option key={i} value={`${designation}`}>
                                                                        {designation}
                                                                    </option>
                                                                ))}
                                                        </select>

                                                        {errors.role && (
                                                            <div className="invalid-feedback mb-3">{errors.role}</div>
                                                        )}

                                                        <label>
                                                            Start Date <span className="required-field">*</span>
                                                        </label>
                                                        <DatePicker
                                                            className={`form-control form-control-lg mb-3 ${
                                                                errors.startDate ? 'is-invalid' : ''
                                                            }`}
                                                            name="startDate"
                                                            format="dd/MM/y"
                                                            minDate={moment(new Date()).subtract(60, 'days').toDate()}
                                                            value={formData.allocationDetails.startDate}
                                                            onChange={(date) =>
                                                                date &&
                                                                setFormData({
                                                                    ...formData,
                                                                    allocationDetails: {
                                                                        ...formData.allocationDetails,
                                                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                                        startDate: date as any,
                                                                    },
                                                                })
                                                            }
                                                        />
                                                        {errors.startDate && (
                                                            <div className="invalid-feedback mb-3">{errors.startDate}</div>
                                                        )}
                                                        <label>
                                                            End Date <span className="required-field">*</span>
                                                        </label>
                                                        <DatePicker
                                                            className={`form-control form-control-lg mb-3 ${
                                                                errors.endDate ? 'is-invalid' : ''
                                                            }`}
                                                            name="endDate"
                                                            format="dd/MM/y"
                                                            minDate={moment(new Date(formData.allocationDetails.startDate))
                                                                .add(1, 'days')
                                                                .toDate()}
                                                            value={formData.allocationDetails.endDate}
                                                            onChange={(date) =>
                                                                date &&
                                                                setFormData({
                                                                    ...formData,
                                                                    allocationDetails: {
                                                                        ...formData.allocationDetails,
                                                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                                        endDate: date as any,
                                                                    },
                                                                })
                                                            }
                                                        />
                                                        {errors.endDate && (
                                                            <div className="invalid-feedback mb-3">{errors.endDate}</div>
                                                        )}

                                                        <div className="text-start mt-1">
                                                            Customer as approver{' '}
                                                            <HelpTip
                                                                message={` If you want customer to manage this employee's leave approvals, timesheet approvals, etc. click on below
            checkbox to mark customer as approver.`}
                                                            />
                                                            <input
                                                                type="checkbox"
                                                                name="customerApprover"
                                                                className="ms-4"
                                                                checked={formData.allocationDetails.customerApprover}
                                                                onChange={(date) =>
                                                                    date &&
                                                                    setFormData({
                                                                        ...formData,
                                                                        allocationDetails: {
                                                                            ...formData.allocationDetails,
                                                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                                            customerApprover:
                                                                                !formData.allocationDetails.customerApprover,
                                                                        },
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-12 text-end">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={handleSubmit}
                                                            >
                                                                Submit Allocation{' '}
                                                                {submitLoading && !globalError.isError && (
                                                                    <div
                                                                        className="spinner-border spinner-border-sm   me-2"
                                                                        role="status"
                                                                    >
                                                                        <span className="visually-hidden">Loading...</span>
                                                                    </div>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default AllocateResource;
