import { request } from '../api/';
import { EmployeePreference } from '../types/employee';
import { EmployeeSurveyResultDetails, InsertSurveyForm, SurveyConfig, SurveyMetric } from '../types/survey';

export const getAllocatedLeaveYears = async () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/control-center/leave-allocations`,
    };
    return request<{ data: string[] }>(requestOptions);
};

export const initiateYearlyLeaveAllocation = async (year: string) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/control-center/leave/initiate-allocation`,
        data: {
            year,
        },
    };
    return request(requestOptions);
};

export const getEmployeePreferences = async (employeeId: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/control-center/employee/preferences/${employeeId}`,
    };
    return request<{ data: { employeePrereferences: EmployeePreference[] } }>(requestOptions);
};

export const updateEmployeePreferences = async (updatedPreferences: EmployeePreference[], employeeId: string) => {
    const requestOptions = {
        method: 'PATCH',
        url: `${process.env.REACT_APP_API_ENDPOINT}/control-center/employee/preferences/update/${employeeId}`,
        data: updatedPreferences,
    };
    return request<{ data: { employeePrereferences: EmployeePreference[] } }>(requestOptions);
};

export const createSurveyForm = async (surveyConfig: InsertSurveyForm) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/control-center/survey-config`,
        data: {
            ...surveyConfig,
        },
    };
    return request(requestOptions);
};

export const updateSurveyForm = async (surveyId: string, surveyConfig: InsertSurveyForm) => {
    const requestOptions = {
        method: 'PATCH',
        url: `${process.env.REACT_APP_API_ENDPOINT}/control-center/survey-config/${surveyId}`,
        data: {
            ...surveyConfig,
        },
    };
    return request(requestOptions);
};

export const deleteSurveyForm = async (surveyId: string) => {
    const requestOptions = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_ENDPOINT}/control-center/survey-config/${surveyId}`,
    };
    return request(requestOptions);
};

export const getEmployeeSurveyForms = async () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/control-center/survey-config/type/EMPLOYEE`,
    };
    return request<{ data: { survey: SurveyConfig[] } }>(requestOptions);
};

export const getCustomerSurveyForms = async () => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/control-center/survey-config/type/CUSTOMER`,
    };
    return request<{ data: { survey: SurveyConfig[] } }>(requestOptions);
};

export const getSurveyFromDetails = async (surveyId: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/control-center/survey-config/${surveyId}`,
    };
    return request<{ data: { survey: SurveyConfig } }>(requestOptions);
};

export const getEmployeeSurveySubmission = async (surveyId: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/control-center/employee/survey-submissions/${surveyId}`,
    };
    return request<{ data: { survey: EmployeeSurveyResultDetails[] } }>(requestOptions);
};

export const getEmployeeSurveyMetric = async (surveyId: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/control-center/employee/survey-metric/${surveyId}`,
    };
    return request<{ data: { survey: SurveyMetric } }>(requestOptions);
};
