/* eslint-disable no-console */
import React, { ReactElement, useEffect, createContext, useState } from 'react';
import { SocketMessage } from '../../types/socket';
import { IRootState, useSelector } from '../../store';
import { UserProfile } from '../../types/user';
import { OnlineStatus } from '../../types/employee';

type WebSocketContextProps = {
    children: ReactElement;
};

type WebSocketContextType = {
    isSocketReady: boolean;
    sendWebSocketMessage: (message: SocketMessage) => void;
};

export const WebSocketContext = createContext<WebSocketContextType>({
    isSocketReady: false,
    sendWebSocketMessage: () => undefined,
});

export default (props: WebSocketContextProps) => {
    const [webSocket, setWebSocket] = useState<WebSocket | null>(null);
    const [isSocketReady, setIsSocketReady] = useState(false);
    const loggedInUser = useSelector((state: IRootState) => state.user) as UserProfile;
    const { uuid, role } = loggedInUser;
    useEffect(() => {
        if (uuid) {
            // Create WebSocket with the provided URL
            const socket = new WebSocket(`${process.env.REACT_APP_WS_ENDPOINT}`);
            setWebSocket(socket);

            // Handle WebSocket open
            socket.onopen = () => {
                console.log('Con opened');
                setTimeout(() => {
                    setIsSocketReady(true);
                }, 5000);
            };

            // Handle WebSocket error
            socket.onerror = (event) => {
                console.error('Con error:', event);
            };

            // Handle WebSocket error
            socket.onclose = (event) => {
                console.log('Con Closed:', event.reason, event.code);
            };

            // Handle received messages
            // socket.onmessage = (event) => {
            //     console.log('WebSocket Message:', event.data);
            // };

            // Clean up WebSocket on component unmount
            return () => {
                if (socket) {
                    socket.close();
                }
            };
        }
        return undefined;
    }, [uuid]);

    const sendWebSocketMessage = (message: SocketMessage) => {
        if (webSocket?.readyState) {
            webSocket.send(JSON.stringify(message));
        }
    };

    // This is to handle page close
    window.addEventListener('beforeunload', () => {
        // Send a message to the server before the page is unloaded
        if (webSocket?.readyState === WebSocket.OPEN) {
            const message = {
                messageType: 'CLOSE_CONNECTION',
                payload: {
                    status: OnlineStatus.OFFLINE,
                    userId: uuid,
                    userRole: role,
                },
            };
            sendWebSocketMessage(message);
        }
    });

    const value = {
        isSocketReady,
        sendWebSocketMessage,
    };

    return <WebSocketContext.Provider value={value}>{props.children}</WebSocketContext.Provider>;
};
