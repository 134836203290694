import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

type SessionTimeoutwarningModalProp = {
    showModal: boolean;
    handleClose: () => void;
    handleExtendSession: () => void;
};
export const SessionTimeoutwarningModal = (prop: SessionTimeoutwarningModalProp) => {
    return (
        <Modal show={prop.showModal} centered>
            <Modal.Header closeButton className="bg-danger">
                <Modal.Title className="text-white">
                    <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} size="lg" />
                    <span className="ms-2"> Session Timeout Warning</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="h5 ps-4">
                <p>Your session is about to expire!</p>
                <p>Do you wish to continue?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={prop.handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={prop.handleExtendSession}>
                    Extend Session
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
