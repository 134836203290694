import React from 'react';
import * as Icon from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IRootState, useSelector } from '../../store';
import { UserProfile, UserRole } from '../../types/user';
import SuperUserDashboard from './SuperUserDashboard';
import './Dashboard.scss';
import ManagerDashboard from './ManagerDashboard';
import EmployeeDashboard from './EmployeeDashboard';
import CustomerDashboard from './CustomerDashboard';

// Get total number of employees
// Get employees who have app access
// Get employees who have not logged in for 24 hours -> use last login status./ ONLINE STATUS
// Get employees which are online currently.
// Employees joined in last month

const Dashboard = () => {
    const loggedInUser = useSelector((state: IRootState) => state.user) as UserProfile;
    const defaultCompnent = (
        <div className="row mt-4">
            <div className="col-sm-12">
                <div className="alert alert-danger alert-outline alert-dismissible mt-5" role="alert">
                    <div className="alert-icon">
                        <Icon.Bell className="far fa-fw fa-bell" />
                    </div>
                    <div className="alert-message">
                        <p>
                            <strong>Dashboard not available yet</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
    let dashboardComponent;
    switch (loggedInUser.role) {
        case UserRole.superUser:
        case UserRole.hr:
            dashboardComponent = <SuperUserDashboard />;
            break;
        case UserRole.manager:
        case UserRole.director:
            dashboardComponent = <ManagerDashboard />;
            break;
        case UserRole.employee:
            dashboardComponent = <EmployeeDashboard />;
            break;
        case UserRole.customer:
            dashboardComponent = <CustomerDashboard />;
            break;
        default:
            dashboardComponent = defaultCompnent;
    }

    return (
        <main className="content app-dashboard">
            <div className="container-fluid p-0">
                <h1 className="h3 mb-3">
                    <FontAwesomeIcon size="lg" icon={icon({ name: 'dashboard' })} /> Dashboard
                </h1>

                {dashboardComponent}
            </div>
        </main>
    );
};

export default Dashboard;
