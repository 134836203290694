/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IRootState, useSelector } from '../../../store';
import { UserProfile } from '../../../types/user';
import { getAllocatedLeaveYears, initiateYearlyLeaveAllocation } from '../../../services/controlCenter';

const LeaveAllocations = () => {
    const currentYear = new Date().getFullYear().toString();
    const loggedInUser = useSelector((state: IRootState) => state.user) as UserProfile;
    const [allocatedYears, setAllocatedYears] = useState<string[]>([]);
    const [loadingData, setLoadingData] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [confirmAllocation, setConfirmAllocation] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const fetchAllocatedYears = async () => {
        try {
            setLoadingData(true);
            const { data } = await getAllocatedLeaveYears();
            setAllocatedYears(data);
            setLoadingData(false);
        } catch (err: unknown) {
            setLoadingData(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };
    const initiateAllocation = async () => {
        try {
            if (currentYear) {
                setSubmitLoading(true);
                await initiateYearlyLeaveAllocation(currentYear);
                setSubmitLoading(false);
                setConfirmAllocation(false);
                fetchAllocatedYears();
            } else {
                setError('Select year to initiate the yearly leave allocations.');
            }
        } catch (err: unknown) {
            setError('Something went wrong while initiating yearly leave allocations.');
            setConfirmAllocation(false);
            setSubmitLoading(false);
        }
    };
    useEffect(() => {
        if (loggedInUser.uuid) {
            setLoadingData(true);
            fetchAllocatedYears();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInUser]);
    const isYearLocked = allocatedYears.includes(currentYear);

    const yearLockedInfo = (
        <Tooltip id="tooltip">
            You cannot run the allocation process for {currentYear} as the leaves are already allocated for this year.
        </Tooltip>
    );
    return (
        <main className="content leave-allocations">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-control-center-info">
                                {' '}
                                <p>
                                    <FontAwesomeIcon className="me-3" size="lg" icon={icon({ name: 'shield-alt' })} />{' '}
                                    Application Control Center{' '}
                                    <FontAwesomeIcon className="me-3 ms-3" icon={icon({ name: 'chevron-right' })} /> Employee
                                    Yearly Leave Allocation
                                </p>
                            </div>
                            <div className="card-body col-12">
                                <>
                                    <div className="container bg-light p-3">
                                        <p>
                                            This feature allows you to efficiently allocate leaves for all registered
                                            employees for the current year. Please review the following guidelines before
                                            proceeding:
                                        </p>

                                        <ul>
                                            <li>
                                                <strong>Allocate Leaves for All Registered Employees:</strong>
                                                <ul>
                                                    <li>
                                                        This action will allocate leaves for all employees in the system for
                                                        the current year.
                                                    </li>
                                                    <li>
                                                        The allocation process takes into account all previously applied
                                                        leaves and adheres to the organization&apos;s leave policy.
                                                    </li>
                                                </ul>
                                            </li>

                                            <li>
                                                <strong>Consideration of Previous Year Leaves:</strong>
                                                <ul>
                                                    <li>
                                                        The system intelligently considers leave history from the previous
                                                        year to ensure fair and accurate leave allocations.
                                                    </li>
                                                    <li>
                                                        This helps in maintaining consistency and fairness in leave
                                                        distribution.
                                                    </li>
                                                </ul>
                                            </li>

                                            <li>
                                                <strong>Visibility for Employees:</strong>
                                                <ul>
                                                    <li>
                                                        Once the allocation process is successfully completed, employees will
                                                        be able to view their allocated leaves for the current year.
                                                    </li>
                                                    <li>
                                                        This transparency ensures that employees are aware of their leave
                                                        balance and can plan their time off accordingly.
                                                    </li>
                                                </ul>
                                            </li>

                                            <li>
                                                <strong>Caution - Avoid Duplicate Allocation:</strong>
                                                <ul>
                                                    <li>
                                                        Please exercise caution and refrain from re-running this allocation
                                                        process if it has already been executed.
                                                    </li>
                                                    <li>
                                                        Duplicate allocations may lead to inaccuracies in leave balances and
                                                        could cause confusion for employees.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <div
                                            className="alert alert-warning alert-outline-coloured alert-dismissible"
                                            role="alert"
                                        >
                                            <div className="alert-icon">
                                                <FontAwesomeIcon
                                                    icon={icon({ name: 'bell' })}
                                                    className="far fa-fw fa-bell"
                                                />
                                            </div>
                                            <div className="alert-message">
                                                <p className="note">
                                                    <strong>Note:</strong> <br /> It is recommended to run this process only
                                                    once at the beginning of the leave year to prevent discrepancies.
                                                    <br />
                                                    If you have any questions or encounter issues, please contact the system
                                                    administrator for assistance.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {loadingData && (
                                        <div className="row mt-4">
                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                <div className="spinner-border text-primary me-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="form-container bg-light p-4">
                                        {submitLoading ? (
                                            <div key={0} className="row">
                                                <div className="col-sm-12 mt-2 mb-2 text-center">
                                                    <div className="spinner-border text-primary me-2" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                {error && (
                                                    <p className="error-message">
                                                        <FontAwesomeIcon className="me-2" icon={icon({ name: 'warning' })} />
                                                        {error}
                                                    </p>
                                                )}
                                                <div className="row col-12">
                                                    <div className="col-md-6">
                                                        {allocatedYears.length > 0 ? (
                                                            <>
                                                                <div className="bg-secondary p-2 ps-1 text-white mb-3 row">
                                                                    <strong>Fullfilled allocation year(s):</strong>
                                                                </div>
                                                                {allocatedYears.map((year) => (
                                                                    <p key={year}>
                                                                        <FontAwesomeIcon
                                                                            className="me-2"
                                                                            size="2xs"
                                                                            icon={icon({ name: 'circle' })}
                                                                        />{' '}
                                                                        <strong>{year}</strong>
                                                                    </p>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <p className="bg-info text-white p-2">
                                                                <FontAwesomeIcon
                                                                    className="me-1"
                                                                    icon={icon({ name: 'circle-info' })}
                                                                />{' '}
                                                                There are no leave allocations added for any year yet.
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div className="col-md-6 text-center text-md-end">
                                                        {isYearLocked ? (
                                                            <div className="col-md-5 col-12 m-auto">
                                                                <OverlayTrigger placement="top" overlay={yearLockedInfo}>
                                                                    <div className="bg-danger btn btn-lg fw-bold text-center text-white p-3">
                                                                        <FontAwesomeIcon
                                                                            icon={icon({ name: 'lock' })}
                                                                            className="text-white"
                                                                        />{' '}
                                                                        Year {currentYear} is locked
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div className="mb-3 row">
                                                                    <strong>
                                                                        Allocate employee leaves for year: {currentYear}
                                                                    </strong>
                                                                </div>
                                                                <div className="mb-3 row">
                                                                    <div className="mt-sm-2">
                                                                        {' '}
                                                                        {confirmAllocation ? (
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-primary"
                                                                                onClick={initiateAllocation}
                                                                            >
                                                                                Confirm?
                                                                            </button>
                                                                        ) : (
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-primary"
                                                                                onClick={() => setConfirmAllocation(true)}
                                                                            >
                                                                                Initiate Allocations
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default LeaveAllocations;
