import { OnProgress, request } from '../api';
import { PageLimit } from '../constants/data';
import { Employee, EmployeeFilters } from '../types/employee';
export const validateIfEmailAvailable = async (email: string, emailType: string) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/exists`,
        data: { email, emailType },
    };
    return request<boolean>(requestOptions);
};

export const registerEmployee = async (
    employeeData: Omit<Employee, 'uuid' | 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy'>
) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/register`,
        data: { ...employeeData },
    };
    return request(requestOptions);
};

export const updateEmployee = async (
    employeeData: Omit<Employee, 'uuid' | 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy'>
) => {
    const requestOptions = {
        method: 'PATCH',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/update`,
        data: { ...employeeData },
    };
    return request(requestOptions);
};

export const getEmployeeList = async (page: number, filters: EmployeeFilters = {}) => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/list`,
        data: {
            pagination: {
                page,
                pageLimit: PageLimit,
            },
            filters,
        },
    };
    return request<{ data: Employee[] }>(requestOptions);
};

export const getEmployeeById = async (uuid: string) => {
    const requestOptions = {
        method: 'GET',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/${uuid}`,
    };
    return request<{ data: Employee }>(requestOptions);
};

export const deleteEmployee = async (uuid: string) => {
    const requestOptions = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/${uuid}`,
    };
    return request(requestOptions);
};

export const searchEmployeeByKeyword = async (keyword: string, excludedUsers?: string[]) => {
    let url = `${process.env.REACT_APP_API_ENDPOINT}/employee/search/list/${keyword}`;

    if (excludedUsers && excludedUsers.length > 0) {
        const excludedQueryString = excludedUsers.join(',');
        url += `?exclude=${excludedQueryString}`;
    }

    const requestOptions = {
        method: 'GET',
        url: url,
    };

    return request<{ data: Employee[] }>(requestOptions);
};

export const searchUserRoleAssigmentList = async (keyword: string, excludedUsers?: string[]) => {
    let url = `${process.env.REACT_APP_API_ENDPOINT}/employee/search/role-assignment/list/${keyword}`;

    if (excludedUsers && excludedUsers.length > 0) {
        const excludedQueryString = excludedUsers.join(',');
        url += `?exclude=${excludedQueryString}`;
    }

    const requestOptions = {
        method: 'GET',
        url: url,
    };

    return request<{ data: Employee[] }>(requestOptions);
};

export const searchReportingManagerAssigmentList = async (keyword: string, excludedUsers?: string[]) => {
    let url = `${process.env.REACT_APP_API_ENDPOINT}/employee/search/manager-assignment/list/${keyword}`;

    if (excludedUsers && excludedUsers.length > 0) {
        const excludedQueryString = excludedUsers.join(',');
        url += `?exclude=${excludedQueryString}`;
    }

    const requestOptions = {
        method: 'GET',
        url: url,
    };

    return request<{ data: Employee[] }>(requestOptions);
};

export const searchReportingLeaveApproverList = async (keyword: string, excludedUsers?: string[]) => {
    let url = `${process.env.REACT_APP_API_ENDPOINT}/employee/search/leave-approver/list/${keyword}`;

    if (excludedUsers && excludedUsers.length > 0) {
        const excludedQueryString = excludedUsers.join(',');
        url += `?exclude=${excludedQueryString}`;
    }

    const requestOptions = {
        method: 'GET',
        url: url,
    };

    return request<{ data: Employee[] }>(requestOptions);
};

export const uploadEmpolyeePhoto = async (file: File, userId: string, onProgress: OnProgress) => {
    // eslint-disable-next-line no-console
    const formData = new FormData();
    formData.append('fileupload', file);
    formData.append('userId', userId);
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/employee/photo/upload`,
        onProgress,
        file: formData,
        noContentType: true,
    };

    return request(requestOptions);
};
