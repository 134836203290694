/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import { Button, Modal } from 'react-bootstrap';
import { HelpTip } from '../../HelpTip/HelpTip';
import { CustomerResourceAllocation, ResourceAllocation } from '../../../types/customer';
import { IRootState, useSelector } from '../../../store';
import { deleteAllocation, updateAllocateResource } from '../../../services/resourceAllocation';
import { useAppContext } from '../../../hooks/useAppContext';
import { getResouceAllocationById } from '../../../services/customer';
import './AllocateResource.scss';

const UpdateAllocateResource = () => {
    const { id: allocationId } = useParams();
    const navigate = useNavigate();
    const globalError = useSelector((state: IRootState) => state.error);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultError = {} as any;
    const [errors, setErrors] = useState(defaultError);
    const pristineForm = {
        employeeId: '',
        allocationDetails: {
            customerId: '',
            employeeId: '',
            startDate: '',
            endDate: '',
            role: '',
            status: '', // Ended, InProgress
            customerApprover: false,
        } as ResourceAllocation,
    };
    const [formData, setFormData] = useState(pristineForm);

    const [error, setError] = useState<string | null>(null);
    const [allocationDetail, setAllocationDetail] = useState<CustomerResourceAllocation>();
    const [loadingData, setLoadingData] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const { triggerSuccess } = useAppContext();
    const [deAllocationTriggered, setDeAllocationTriggered] = useState(false);
    const [showDeAllocateConfirmation, setShowDeAllocateConfirmation] = useState(false);
    const [deleteTriggered, setDeleteTriggered] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const clearData = () => {
        setFormData({ ...pristineForm });
        setSubmitLoading(false);
    };

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
        setFormData({
            ...formData,
            allocationDetails: {
                ...formData.allocationDetails,
                [e.target.name]: e.target.value,
            },
        });
    };

    const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setErrors(defaultError); // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const tempErrors = {} as any;
        if (!formData.allocationDetails.role) {
            tempErrors['role'] = 'Select allocation role';
        }
        if (!formData.allocationDetails.startDate) {
            tempErrors['startDate'] = 'Select start date';
        }
        if (!formData.allocationDetails.endDate) {
            tempErrors['endDate'] = 'Select end date';
        }

        if (Object.keys(tempErrors).length === 0 && allocationDetail) {
            setSubmitLoading(true);
            const data = {
                ...formData.allocationDetails,
                uuid: allocationDetail.uuid,
            };
            try {
                await updateAllocateResource(data);
                triggerSuccess(`Resource allocation updated successfully`);
                navigate(`/customer/details/${allocationDetail.customerId}`);
            } catch (err: unknown) {
                clearData();
            }
            setSubmitLoading(false);
        } else {
            setErrors(tempErrors);
        }
    };

    const fetchAllocationDetail = useCallback(async () => {
        try {
            if (allocationId) {
                setLoadingData(true);
                const { data } = await getResouceAllocationById(allocationId);
                setAllocationDetail(data);
                setFormData({ ...formData, allocationDetails: data });
                setLoadingData(false);
            }
        } catch (err: unknown) {
            setError('Something went wrong');
            setLoadingData(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allocationId]);

    const handleDeAllocationConfirm = async () => {
        //de-allocatin now
        setDeAllocationTriggered(true);
        setDeleteTriggered(false);
        const data = {
            ...formData.allocationDetails,
            uuid: allocationDetail?.uuid as string,
            endDate: new Date().toString(),
            status: 'INACTIVE',
        };
        await updateAllocateResource(data);
        triggerSuccess(`Resource has been deallocated`);
        setDeAllocationTriggered(false);
        setShowDeAllocateConfirmation(false);
        navigate(`/customer/details/${allocationDetail?.customerId}`);
    };

    const handleDeleteConfirm = async () => {
        //deleting now
        setDeleteTriggered(true);
        setDeAllocationTriggered(false);
        const data = {
            uuid: allocationDetail?.uuid as string,
        };
        await deleteAllocation(data);
        triggerSuccess(`Resource allocation is deleted`);
        setDeleteTriggered(false);
        setShowDeAllocateConfirmation(false);
        navigate(`/customer/details/${allocationDetail?.customerId}`);
    };

    useEffect(() => {
        fetchAllocationDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <main className="content resource-allocation">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon icon={icon({ name: 'people-arrows' })} /> Update Resource Allocation
                        </h3>
                    </div>
                    <div className="col-auto ms-auto text-end mt-n1">
                        <button className="btn btn-primary" onClick={() => navigate(-1)}>
                            <FontAwesomeIcon icon={icon({ name: 'angle-left' })} className="far fa-fw fa-bell" /> Back
                        </button>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-info">
                                <p>Update the allocation details.</p>
                                <p>You can change the start date or end date of this allocation. </p>
                                <p>Changing role or any other data would require a new allocation request. </p>
                            </div>

                            <div className="card-body card-body__min-height col-12">
                                <div className="row mt-4">
                                    {(loadingData || submitLoading) && (
                                        <div className="row mt-4">
                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                <div className="spinner-border text-primary me-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {error && (
                                        <div className="col-sm-12">
                                            <div className="alert alert-danger alert-outline alert-dismissible" role="alert">
                                                <div className="alert-icon">
                                                    <FontAwesomeIcon size="lg" icon={icon({ name: 'bell' })} />
                                                </div>
                                                <div className="alert-message">
                                                    <strong>Error</strong> Something went wrong.
                                                    <br /> Unable to get the customer details.
                                                    <br /> If the problem persists, please contact support.
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {!loadingData && !submitLoading && allocationDetail && (
                                        <div className="row">
                                            <div className="row ">
                                                <div className="p-1 px-4">
                                                    <strong>Customer Name:</strong> {allocationDetail.customerName}
                                                </div>{' '}
                                                <div className="p-1 px-4">
                                                    <strong>Employee Name:</strong> {allocationDetail.employeeName}
                                                </div>
                                                <div className="p-1 px-4">
                                                    <strong>Allocation Code:</strong> {allocationDetail.allocationCode}
                                                </div>{' '}
                                            </div>

                                            <div className="row gx-0 col-10 ms-4 mt-4 p-4 bg-light allocate-container">
                                                <h4>Update Allocation Details:</h4>

                                                {allocationDetail.status === 'ACTIVE' ? (
                                                    <div className="row col-10 mt-2  ">
                                                        <label>
                                                            Role <span className="required-field">*</span>
                                                        </label>
                                                        <select
                                                            name="role"
                                                            onChange={handleChange}
                                                            className={`form-control form-control-lg form-select mb-3 ${
                                                                errors.role ? 'is-invalid' : ''
                                                            }`}
                                                            value={formData.allocationDetails.role}
                                                            disabled={true}
                                                        >
                                                            <option value={formData.allocationDetails.role}>
                                                                {formData.allocationDetails.role}
                                                            </option>
                                                        </select>

                                                        {errors.role && (
                                                            <div className="invalid-feedback mb-3">{errors.role}</div>
                                                        )}

                                                        <label>
                                                            Start Date <span className="required-field">*</span>
                                                        </label>
                                                        <DatePicker
                                                            className={`form-control form-control-lg mb-3 ${
                                                                errors.startDate ? 'is-invalid' : ''
                                                            }`}
                                                            name="startDate"
                                                            format="dd/MM/y"
                                                            minDate={moment(new Date()).subtract(60, 'days').toDate()}
                                                            value={formData.allocationDetails.startDate}
                                                            onChange={(date) =>
                                                                date &&
                                                                setFormData({
                                                                    ...formData,
                                                                    allocationDetails: {
                                                                        ...formData.allocationDetails,
                                                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                                        startDate: date as any,
                                                                    },
                                                                })
                                                            }
                                                        />
                                                        {errors.startDate && (
                                                            <div className="invalid-feedback mb-3">{errors.startDate}</div>
                                                        )}
                                                        <label>
                                                            End Date <span className="required-field">*</span>
                                                        </label>
                                                        <DatePicker
                                                            className={`form-control form-control-lg mb-3 ${
                                                                errors.endDate ? 'is-invalid' : ''
                                                            }`}
                                                            name="endDate"
                                                            format="dd/MM/y"
                                                            minDate={moment(new Date(formData.allocationDetails.startDate))
                                                                .add(1, 'days')
                                                                .toDate()}
                                                            value={formData.allocationDetails.endDate}
                                                            onChange={(date) =>
                                                                date &&
                                                                setFormData({
                                                                    ...formData,
                                                                    allocationDetails: {
                                                                        ...formData.allocationDetails,
                                                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                                        endDate: date as any,
                                                                    },
                                                                })
                                                            }
                                                        />
                                                        {errors.endDate && (
                                                            <div className="invalid-feedback mb-3">{errors.endDate}</div>
                                                        )}

                                                        <div className="text-start mt-1">
                                                            Customer as approver{' '}
                                                            <HelpTip
                                                                message={` If you want customer to manage this employee's leave approvals, timesheet approvals, etc. click on below
            checkbox to mark customer as approver.`}
                                                            />
                                                            <input
                                                                type="checkbox"
                                                                name="customerApprover"
                                                                className="ms-4"
                                                                checked={formData.allocationDetails.customerApprover}
                                                                onChange={(date) =>
                                                                    date &&
                                                                    setFormData({
                                                                        ...formData,
                                                                        allocationDetails: {
                                                                            ...formData.allocationDetails,
                                                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                                            customerApprover:
                                                                                !formData.allocationDetails.customerApprover,
                                                                        },
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-12 text-end">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={handleSubmit}
                                                            >
                                                                Submit Allocation{' '}
                                                                {submitLoading && !globalError.isError && (
                                                                    <div
                                                                        className="spinner-border spinner-border-sm   me-2"
                                                                        role="status"
                                                                    >
                                                                        <span className="visually-hidden">Loading...</span>
                                                                    </div>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="col-sm-12">
                                                        <div
                                                            className="alert alert-warning alert-outline alert-dismissible"
                                                            role="alert"
                                                        >
                                                            <div className="alert-icon">
                                                                <FontAwesomeIcon size="lg" icon={icon({ name: 'bell' })} />
                                                            </div>
                                                            <div className="alert-message">
                                                                <strong>Note:</strong> This resource was de-allocated a while
                                                                back. <br />
                                                                In case you need to allocate this resource again you need to
                                                                re-allocate the resource newly.
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-10 m-4 p-4  allocate-container">
                                                <p>If you need to de-allocate this resource, click on below button.</p>
                                                <p>
                                                    The end date for the resource will be marked today, which means from
                                                    tomorrow this resource will be de-allocated from this customer resource
                                                    allocations.
                                                </p>
                                                <div className="text-end">
                                                    <button
                                                        className="btn btn-danger  "
                                                        onClick={() => setShowDeAllocateConfirmation(true)}
                                                    >
                                                        <FontAwesomeIcon
                                                            className="icons"
                                                            size="lg"
                                                            icon={icon({ name: 'xmark' })}
                                                        />
                                                        <strong className="p-2 ">De-Allocate this resource</strong>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="col-10 m-4 p-4  allocate-container">
                                                <p>If you need to complete remove this allocation, click on below button.</p>
                                                <p>
                                                    This action will delete the allocation data for this resource and
                                                    customer.
                                                </p>
                                                <div className="text-end">
                                                    <button
                                                        className="btn btn-danger  "
                                                        onClick={() => setShowDeleteConfirmation(true)}
                                                    >
                                                        <FontAwesomeIcon
                                                            className="icons"
                                                            size="lg"
                                                            icon={icon({ name: 'xmark' })}
                                                        />
                                                        <strong className="p-2 ">Delete Allocation</strong>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showDeAllocateConfirmation} centered={true} onHide={() => setShowDeAllocateConfirmation(false)}>
                {!deAllocationTriggered ? (
                    <>
                        {' '}
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} size="lg" />
                                <span className="ms-2">Remove Allocation</span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                You are about to De-Allocate <strong>{allocationDetail?.employeeName}</strong> who is
                                allocated to customer <strong> {allocationDetail?.customerName}</strong>.
                            </p>
                            <p>This is a irrevesrible action and won&apos;t be reversed.</p>
                            <p>
                                Once this resource is de-allocated, you can see the employee in a de-allocated state under
                                customer resources.
                            </p>
                            <p>
                                The end date for this allocation will be set as today and status will be marked as inactive.
                            </p>
                            <p>Do you wish to continue?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowDeAllocateConfirmation(false)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleDeAllocationConfirm}>
                                De-Allocate Now
                            </Button>
                        </Modal.Footer>
                    </>
                ) : (
                    <>
                        {' '}
                        <Modal.Header>
                            <Modal.Title>
                                <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} size="lg" />
                                <span className="ms-2">Deallocate customer resource</span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h3 className="text-center">Your request is being processed</h3>
                            <p className="text-center">
                                <div className="spinner-border spinner-border-sm me-2" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </p>
                        </Modal.Body>
                    </>
                )}
            </Modal>

            <Modal show={showDeleteConfirmation} centered={true} onHide={() => setShowDeleteConfirmation(false)}>
                {!deleteTriggered ? (
                    <>
                        {' '}
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} size="lg" />
                                <span className="ms-2">Delete Allocation Completely</span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                You are about to delete allocation of <strong>{allocationDetail?.employeeName}</strong> who
                                is allocated to customer <strong> {allocationDetail?.customerName}</strong>.
                            </p>
                            <p>This is a irrevesrible action and won&apos;t be reversed.</p>
                            <p>
                                Once this resource is deleted from this allocation, no one will ever see this allocation
                                again.
                            </p>

                            <p>Do you wish to continue?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleDeleteConfirm}>
                                Delete Allocation
                            </Button>
                        </Modal.Footer>
                    </>
                ) : (
                    <>
                        {' '}
                        <Modal.Header>
                            <Modal.Title>
                                <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} size="lg" />
                                <span className="ms-2">Delete customer resource</span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h3 className="text-center">Your request is being processed</h3>
                            <p className="text-center">
                                <div className="spinner-border spinner-border-sm me-2" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </p>
                        </Modal.Body>
                    </>
                )}
            </Modal>
        </main>
    );
};

export default UpdateAllocateResource;
