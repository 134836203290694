/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import * as Icon from 'react-feather';
// import { saveAs } from 'file-saver';
import InfiniteScroll from 'react-infinite-scroller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { startCase } from 'lodash';
import { Employee, EmployeeFilters } from '../../types/employee';
import { getEmployeeList } from '../../services/employee';
import './ReporteeList.scss';
import { IRootState, useSelector } from '../../store';
import { UserProfile } from '../../types/user';
import { EmployeeOnlineStatus } from '../EmployeeOnlineStatus/EmployeeOnlineStatus';

const ReporteeList = () => {
    const loggedInUser = useSelector((state: IRootState) => state.user) as UserProfile;
    const { designation: stateDesignation, department: stateDepartment } = useSelector((state: IRootState) => state.data);
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [filters, setFilters] = useState<EmployeeFilters>({});
    const [error, setError] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const fetchEmployees = useCallback(async (page: number, searchFilters: EmployeeFilters) => {
        try {
            setLoading(true);
            const enrichSearchFilters = {
                ...searchFilters,
                reportingManagerRole: loggedInUser.role,
                reportingManagerId: loggedInUser.uuid,
            } as EmployeeFilters;
            const { data } = await getEmployeeList(page, enrichSearchFilters);
            if (data.length > 0) {
                setHasMore(true);
                setEmployees((prevData) => [...prevData, ...data]);
            } else {
                setHasMore(false);
            }
            setLoading(false);
        } catch (err: unknown) {
            setError('Something went wrong');
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const filterType = event.target.name; // Get the id of the select element as filterType
        const newFilters: EmployeeFilters = { ...filters };
        if (event.target.value !== '0') {
            newFilters[filterType as keyof EmployeeFilters] = event.target.value;
            setFilters(newFilters);
        } else {
            delete newFilters[filterType as keyof EmployeeFilters];
            setFilters(newFilters);
        }
    };

    const handleSearchClick = () => {
        setEmployees([]);
        fetchEmployees(1, filters);
    };

    const handleClearFilter = () => {
        setFilters({ designation: '0', department: '0' });
        setEmployees([]);
        fetchEmployees(1, {});
    };

    useEffect(() => {
        fetchEmployees(1, filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <main className="content view-employees">
            <div className="container-fluid p-0">
                <div className="row mb-2 mb-xl-3">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon size="lg" icon={icon({ name: 'users' })} /> My Reportees
                        </h3>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-info">
                                <p>This page provides overview of employees who report to you. </p>
                            </div>
                            <div className="card-body">
                                <div
                                    id="datatables-reponsive_wrapper"
                                    className="dataTables_wrapper dt-bootstrap5 no-footer"
                                >
                                    {error && (
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div
                                                    className="alert alert-danger alert-outline alert-dismissible"
                                                    role="alert"
                                                >
                                                    <div className="alert-icon">
                                                        <Icon.Bell className="far fa-fw fa-bell" />
                                                    </div>
                                                    <div className="alert-message">
                                                        <strong>Error</strong> Something went wrong when trying to get the
                                                        Employee data.
                                                        <br /> If the problem persists, please contact support.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {
                                        <>
                                            <div className="search-container">
                                                <div className="mb-3 row">
                                                    <strong>Filter By:</strong>
                                                </div>
                                                <div className="mb-3 row">
                                                    <div className="col-3">
                                                        <select
                                                            name="designation"
                                                            className="form-control form-control-lg form-select"
                                                            onChange={handleFilterChange}
                                                            value={filters.designation}
                                                        >
                                                            <option value="0">Select Designation</option>
                                                            {stateDesignation &&
                                                                stateDesignation.map((designation, i) => (
                                                                    <option key={i} value={`${designation}`}>
                                                                        {designation}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-3">
                                                        <select
                                                            name="department"
                                                            className="form-control form-control-lg form-select"
                                                            onChange={handleFilterChange}
                                                            value={filters.department}
                                                        >
                                                            <option value="0">Select Department</option>
                                                            {stateDepartment &&
                                                                stateDepartment.map((designation, i) => (
                                                                    <option key={i} value={`${designation}`}>
                                                                        {designation}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-1">
                                                        {' '}
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={handleSearchClick}
                                                        >
                                                            Search
                                                        </button>
                                                    </div>
                                                    <div className="col-md-1 text-start pt-1 pl-1">
                                                        <span
                                                            onClick={handleClearFilter}
                                                            className="text-primary cursor-pointer"
                                                        >
                                                            Clear All
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {!loading && !error && employees?.length === 0 && (
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div
                                                    className="alert alert-danger alert-outline alert-dismissible"
                                                    role="alert"
                                                >
                                                    <div className="alert-icon">
                                                        <Icon.Bell className="far fa-fw fa-bell" />
                                                    </div>
                                                    <div className="alert-message">
                                                        <p>
                                                            <strong>
                                                                No data available for reportees. <br /> It is possible that
                                                                there is no data added yet.
                                                                <br />
                                                                OR try refining your search filters.
                                                            </strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {employees?.length !== 0 && (
                                        <>
                                            <div style={{ height: '500px', overflow: 'auto' }}>
                                                <InfiniteScroll
                                                    pageStart={1}
                                                    loadMore={(page: number) => fetchEmployees(page, filters)}
                                                    hasMore={hasMore && !loading}
                                                    loader={
                                                        <div key={0} className="row">
                                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                                <div
                                                                    className="spinner-border text-primary me-2"
                                                                    role="status"
                                                                >
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    useWindow={false}
                                                >
                                                    <table
                                                        id="datatables-reponsive"
                                                        className="table table-striped dataTable no-footer dtr-inline view-employees-table"
                                                        style={{ width: '100%', tableLayout: 'fixed' }}
                                                        aria-describedby="datatables-reponsive_info"
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '20px' }}>{''}</th>
                                                                <th>Name</th>
                                                                <th>Employee Id</th>
                                                                <th>Department</th>
                                                                <th>Designation</th>
                                                                <th>Official Email</th>
                                                                <th>Contact</th>
                                                                <th style={{ width: '80px' }}>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {employees &&
                                                                employees.map((employee, index) => (
                                                                    <tr
                                                                        className={cn({
                                                                            odd: index % 2 === 0,
                                                                            even: index % 2 !== 0,
                                                                        })}
                                                                        key={`${employee.uuid}-${index}`}
                                                                    >
                                                                        <td>
                                                                            <EmployeeOnlineStatus
                                                                                onlineStatus={employee.onlineStatus}
                                                                                showLabel={false}
                                                                            />
                                                                        </td>
                                                                        <td>{startCase(employee.name)}</td>
                                                                        <td>{employee.employeeId ?? '-'}</td>
                                                                        <td>{employee.department ?? '-'}</td>
                                                                        <td>{employee.designation ?? '-'}</td>
                                                                        <td>{employee.officialEmailId ?? '-'}</td>
                                                                        <td>{employee.contactNumber ?? '-'}</td>
                                                                        <td>
                                                                            <NavLink
                                                                                to={`/employee/details/${employee.uuid}`}
                                                                                title="View Details"
                                                                                className="me-2"
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    icon={icon({ name: 'list-check' })}
                                                                                    size="lg"
                                                                                />
                                                                            </NavLink>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </InfiniteScroll>
                                            </div>
                                        </>
                                    )}

                                    {loading && (
                                        <div key={0} className="row">
                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                <div className="spinner-border text-primary me-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ReporteeList;
