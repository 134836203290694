import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IRootState } from '../../store';
import './Login.scss';
import { resetPasswordRequest } from '../../services/user';

const ForgotPassword = () => {
    const globalError = useSelector((state: IRootState) => state.error);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [username, setUsername] = useState<string>('');

    const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(false);
        setUsername(event.currentTarget.value);
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            setSubmitted(false);
            if (username === '') {
                setError(`Enter valid official email address.`);
            } else {
                await resetPasswordRequest(username);
                setError('');
                setSubmitted(true);
            }
            setLoading(false);
        } catch (_e) {}
    };
    const handleKeyPress = (event: React.KeyboardEvent<Element>) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <main className="d-flex w-100">
            <div className="login-container container d-flex flex-column">
                <div className="row vh-100">
                    <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                        <div className="d-table-cell align-middle">
                            <div className="brand text-center mt-4">
                                <h1 className="h2">Vodichron Resource Management System</h1>
                                <p className="lead">Embed Square Solutions Pvt. Ltd.</p>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    {error && <p className="error">{error}</p>}
                                    {globalError.isError && <p className="error">{globalError.message}</p>}
                                    <div className="m-sm-4">
                                        <form>
                                            <div className="mb-3">
                                                <p className="fw-bold">
                                                    Enter your associated official email address below.
                                                </p>
                                                <p>
                                                    If the email exists in our system, you will receive instructions on how
                                                    to reset your password in couple of minutes.
                                                </p>
                                            </div>

                                            {submitted ? (
                                                <>
                                                    <div className="text-center mt-3">
                                                        <NavLink className="btn btn-primary" to="/login">
                                                            <FontAwesomeIcon
                                                                className="me-2"
                                                                icon={icon({ name: 'arrow-left' })}
                                                                size="sm"
                                                            />{' '}
                                                            Back to login
                                                        </NavLink>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="mb-3">
                                                        <label className="form-label">Official Email Address:</label>
                                                        <input
                                                            className="form-control form-control-lg"
                                                            type="email"
                                                            name="email"
                                                            placeholder="Enter your email"
                                                            value={username}
                                                            onChange={handleChangeUsername}
                                                            onKeyDown={handleKeyPress}
                                                        />
                                                    </div>
                                                    <div className="text-center mt-3">
                                                        <button
                                                            type="button"
                                                            className="btn btn-lg btn-primary"
                                                            onClick={handleSubmit}
                                                        >
                                                            Submit Email
                                                            {loading && !globalError.isError && (
                                                                <div
                                                                    className="spinner-border spinner-border-sm me-2 mx-2"
                                                                    role="status"
                                                                >
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                            )}
                                                        </button>
                                                    </div>
                                                    <div className="text-center mt-2">
                                                        <NavLink to="/login">
                                                            <FontAwesomeIcon
                                                                className="icons"
                                                                size="sm"
                                                                icon={icon({ name: 'arrow-left' })}
                                                            />
                                                            {''} Back to login
                                                        </NavLink>
                                                    </div>
                                                </>
                                            )}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ForgotPassword;
