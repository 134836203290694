import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActivityType, UserProfile } from '../../types/user';
import { Activities } from '../../constants/activityMaster';
import { updateUserActivity } from '../../services/user';
import { useAppContext } from '../../hooks/useAppContext';
import { AppDispatch } from '../../store';
import { handleGlobalError } from '../../store/errorHandler';
import { useAuth } from '../../hooks/useAuth';
import { PrivacyModal } from '../Modals/PrivacyModal';
import { PrivacyStatmenContent } from './PrivacyContent';

type PrivacyPopupProp = {
    user: UserProfile;
};

const MAX_WAIT = 10; // 10 seconds

export const PrivacyPopup = (prop: PrivacyPopupProp) => {
    const { user } = prop;
    const { onLogout } = useAuth();
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);
    const { triggerSuccess } = useAppContext();
    const dispatch: AppDispatch = useDispatch();
    const handleClose = () => {
        setShowPrivacyModal(false);
    };

    const handlePrivacyStatementInputSubmit = async (status: boolean) => {
        try {
            const data = {
                activityName: Activities.ACCEPTED_PRIVACY as ActivityType,
                employeeId: user.uuid,
                value: {
                    status,
                },
            };
            await updateUserActivity(data);
            setShowPrivacyModal(false);
            if (status === true) {
                triggerSuccess(`Thank you for accepting the privacy statment.`);
            } else {
                dispatch(
                    handleGlobalError({
                        message:
                            'You have chosen not to accept the privacy statment, this will be reported for further discussion.',
                        isError: true,
                    })
                );
                onLogout();
            }

            setShowPrivacyModal(false);
        } catch (_e) {
            setShowPrivacyModal(false);
        }
    };

    useEffect(() => {
        const privacyTimer = setTimeout(() => {
            if (user.uuid) {
                const privacyActivity = user.activities.find(
                    (activity) => activity.activityName === Activities.ACCEPTED_PRIVACY
                );
                if (privacyActivity) {
                    const valueObject = privacyActivity.value;
                    const { status } = valueObject;
                    if (!status) {
                        setShowPrivacyModal(true);
                    } else {
                        setShowPrivacyModal(false);
                    }
                } else {
                    setShowPrivacyModal(true);
                }
            }
        }, MAX_WAIT * 1000);
        return () => clearTimeout(privacyTimer);
    }, [user]);

    return (
        <PrivacyModal
            handleClose={handleClose}
            showModal={showPrivacyModal}
            handleSubmit={handlePrivacyStatementInputSubmit}
            privacyStatement={<PrivacyStatmenContent />}
        />
    );
};
