/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { PasswordRegex } from '../../../constants/validations';
import { UserRole } from '../../../types/user';
const userFormValidate = (formData: any) => {
    const Roles = Object.values(UserRole);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tempErrors = {} as any;

    if (!formData.employeeId) {
        tempErrors.employeeId = 'Assign employee to give access to.';
    }
    if (!Roles.includes(formData.role)) {
        tempErrors.role = 'Select application user role';
    }

    if (!formData.password) {
        tempErrors.password = 'Password is a required field';
    } else if (!PasswordRegex.test(formData.password)) {
        tempErrors.password =
            'Password must be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character';
    }

    if (formData.password !== formData.confirmPassword) {
        tempErrors.confirmPassword = 'Confirm password not matched';
    }

    return tempErrors;
};

export default userFormValidate;

export const userFormValidateInUpdateFlow = (formData: any) => {
    const roles = Object.values(UserRole);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tempErrors = {} as any;

    if (!roles.includes(formData.role)) {
        tempErrors.role = 'Select application user role';
    }
    if (formData.password && !PasswordRegex.test(formData.password)) {
        tempErrors.password =
            'Password must be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character';
    }
    if (formData.password && formData.password !== formData.confirmPassword) {
        tempErrors.confirmPassword = 'Confirm password not matched';
    }

    return tempErrors;
};
