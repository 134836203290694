/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getCustomerAndResouceAllocationByCustomerId } from '../../services/customer';
import { CustomerWithResourcesAllocation } from '../../types/customer';
import { IRootState, useSelector } from '../../store';
import { UserProfile } from '../../types/user';
import { AllocatedResourceList } from './AllocateResource/AllocatedResourceList';

const CustomerAllocatedResources = () => {
    const loggedInUser = useSelector((state: IRootState) => state.user) as UserProfile;
    const [loadingData, setLoadingData] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [customerData, setCustomerData] = useState<CustomerWithResourcesAllocation>();
    const fetchCustomerDetails = async (id: string) => {
        setLoadingData(true);
        try {
            const { data } = await getCustomerAndResouceAllocationByCustomerId(id as string);
            setCustomerData(data);
        } catch (_e) {
            setError('Something went wrong');
        }
        setLoadingData(false);
    };
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (loggedInUser.uuid) {
            setLoadingData(true);
            fetchCustomerDetails(loggedInUser.uuid);
            setLoadingData(false);
        }
    }, [loggedInUser.uuid]);

    return (
        <main className="content user-registration">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon icon={icon({ name: 'users' })} /> Allocated Resources
                        </h3>
                    </div>

                    <div className="col-12 mt-3">
                        {loadingData && (
                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                <div className="spinner-border text-primary me-2" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        )}

                        {error && (
                            <div className="col-sm-12">
                                <div className="alert alert-danger alert-outline alert-dismissible" role="alert">
                                    <div className="alert-icon">
                                        <FontAwesomeIcon size="lg" icon={icon({ name: 'bell' })} />
                                    </div>
                                    <div className="alert-message">
                                        <strong>Error</strong> Something went wrong.
                                        <br /> Unable to get the customer details.
                                        <br /> If the problem persists, please contact support.
                                    </div>
                                </div>
                            </div>
                        )}

                        {customerData?.resourceAllocations && (
                            <AllocatedResourceList
                                resourceAllocations={customerData?.resourceAllocations}
                                isCustomerView={true}
                                loggedInUserRole={loggedInUser.role}
                            />
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default CustomerAllocatedResources;
