/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useNavigate } from 'react-router-dom';
import { IRootState, useSelector } from '../../store';
import { getCustomerById, updateCustomer } from '../../services/customer';
import { getCountryAndCodes, getTimezones } from '../../services/data';
import { useAppContext } from '../../hooks/useAppContext';
import { customerFormValidate } from './CustomerRegistrationValidation';

type Props = {
    customerId: string;
    isCustomerView: boolean;
};
const CustomerUpdateForm = (props: Props) => {
    const { customerId, isCustomerView } = props;
    const globalError = useSelector((state: IRootState) => state.error);
    const pristineForm = {
        name: '',
        email: '',
        primaryContact: '',
        secondaryContact: '',
        country: '',
        timezone: '',
        password: '',
        confirmPassword: '',
    };
    const { triggerSuccess } = useAppContext();
    const navigate = useNavigate();
    const [countryCodes, setCountryCodes] = useState<{ [x: string]: string }>();
    const [timezones, setTimezones] = useState<{ [x: string]: string }>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultError = {} as any;
    const [errors, setErrors] = useState(defaultError);
    const [formData, setFormData] = useState(pristineForm);
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    useEffect(() => {
        const fetchCustomerDetails = async () => {
            setLoadingData(true);
            const customer = await getCustomerById(customerId as string);
            setFormData({
                ...customer.data,
                password: '',
                confirmPassword: '',
            });
            setLoadingData(false);
        };
        fetchCustomerDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
        setLoading(false);
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setErrors(defaultError);
        const validateErrors = customerFormValidate(formData);
        if (Object.keys(validateErrors).length === 0 && Object.keys(errors).length === 0) {
            setLoading(true);
            const data = {
                ...formData,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any;
            try {
                // TODO: Need to clean up for handling this better
                await updateCustomer(data);

                if (!isCustomerView) {
                    setFormData(pristineForm);
                    triggerSuccess(`Customer data updated successfully`);
                    navigate('/customer/list');
                } else {
                    triggerSuccess(`Your details are updated successfully`);
                }
                setLoading(false);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (_e: any) {
                setLoading(false);
            }
        } else {
            setLoading(false);
            setErrors(validateErrors);
        }
    };

    useEffect(() => {
        const getCountryCodes = async () => {
            const [coutryCodeList, timezoneList] = await Promise.all([getCountryAndCodes(), getTimezones()]);
            if (coutryCodeList) {
                setCountryCodes(coutryCodeList);
            }
            if (timezoneList) {
                setTimezones(timezoneList);
            }
        };
        getCountryCodes();
    }, []);

    return (
        <main className="content customer-registration">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-auto d-none d-sm-block">
                        <h3>
                            <FontAwesomeIcon icon={icon({ name: 'pencil' })} />
                            {isCustomerView ? ' My Account' : ' Update Customer'}
                        </h3>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-info">
                                {isCustomerView ? (
                                    <>
                                        <p>Some fields cannot be updated as they are account critical.</p>
                                        <p>
                                            Once you have updated the fields to change, press the &quot;Save Details&quot;
                                            button to save the information.
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <p>
                                            Please enter all the information for an customer which is mandatory marked with
                                            an asterisk (<span className="required-field">*</span>).
                                        </p>
                                        <p>
                                            Once you have filled out all the fields, press the &quot;Save Details&quot;
                                            button to save the information.
                                        </p>
                                    </>
                                )}
                            </div>

                            {loadingData && (
                                <div key={0} className="row">
                                    <div className="col-sm-12 mt-2 mb-2 text-center">
                                        <div className="spinner-border text-primary me-2" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!loadingData && (
                                <div className="card-body col-8">
                                    {Object.keys(errors).length > 0 && (
                                        <p className="error-message">
                                            <FontAwesomeIcon className="me-2" icon={icon({ name: 'warning' })} /> Looks like
                                            you have some errors on the form, check all the tabs before saving the details.
                                        </p>
                                    )}
                                    <label>
                                        Full Name <span className="required-field">*</span>
                                    </label>
                                    <input
                                        className={`form-control form-control-lg mb-3 ${errors.name ? 'is-invalid' : ''}`}
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                    {errors.name && <div className="invalid-feedback mb-3">{errors.name}</div>}
                                    <label>
                                        Email <span className="required-field">*</span>
                                    </label>
                                    <input
                                        className={`form-control form-control-lg mb-3 ${errors.email ? 'is-invalid' : ''}`}
                                        type="text"
                                        name="email"
                                        value={formData.email}
                                        readOnly={true}
                                        disabled={true}
                                    />
                                    {errors.email && <div className="invalid-feedback mb-3">{errors.email}</div>}
                                    <label>
                                        Primary Contact Number <span className="required-field">*</span>
                                    </label>
                                    <input
                                        className={`form-control form-control-lg mb-3 ${
                                            errors.primaryContact ? 'is-invalid' : ''
                                        }`}
                                        type="text"
                                        name="primaryContact"
                                        value={formData.primaryContact}
                                        onChange={handleChange}
                                        placeholder="+1 0000000000 or +91 0000000000"
                                    />
                                    {errors.primaryContact && (
                                        <div className="invalid-feedback mb-3">{errors.primaryContact}</div>
                                    )}

                                    <label>Secondary Contact Number</label>
                                    <input
                                        className={`form-control form-control-lg mb-3 ${
                                            errors.secondaryContact ? 'is-invalid' : ''
                                        }`}
                                        type="text"
                                        name="secondaryContact"
                                        value={formData.secondaryContact}
                                        onChange={handleChange}
                                        placeholder="+1 0000000000 or +91 0000000000"
                                    />
                                    {errors.secondaryContact && (
                                        <div className="invalid-feedback mb-3">{errors.secondaryContact}</div>
                                    )}

                                    <label>
                                        Country <span className="required-field">*</span>
                                    </label>
                                    <select
                                        name="country"
                                        onChange={handleChange}
                                        className={`form-control form-control-lg form-select mb-3 ${
                                            errors.country ? 'is-invalid' : ''
                                        }`}
                                        value={formData.country}
                                    >
                                        <option value="">Select Country</option>
                                        {countryCodes &&
                                            Object.keys(countryCodes).map((code, i) => (
                                                <option key={i} value={`${countryCodes[`${code}`]}`}>
                                                    {countryCodes[`${code}`]}
                                                </option>
                                            ))}
                                    </select>

                                    {errors.country && <div className="invalid-feedback mb-3">{errors.country}</div>}

                                    <label>Timezone</label>
                                    <select
                                        name="timezone"
                                        onChange={handleChange}
                                        className={`form-control form-control-lg form-select mb-3 ${
                                            errors.timezone ? 'is-invalid' : ''
                                        }`}
                                        value={formData.timezone}
                                    >
                                        <option value="">Select Timezone</option>
                                        {timezones &&
                                            Object.keys(timezones).map((tz, i) => (
                                                <option key={i} value={`${tz}`}>
                                                    {timezones[`${tz}`]}
                                                </option>
                                            ))}
                                    </select>

                                    {errors.timezone && <div className="invalid-feedback mb-3">{errors.timezone}</div>}

                                    {isCustomerView && (
                                        <>
                                            <hr />

                                            <div
                                                className="alert alert-warning alert-outline-coloured alert-dismissible"
                                                role="alert"
                                            >
                                                <div className="alert-icon">
                                                    <FontAwesomeIcon icon={icon({ name: 'bell' })} />
                                                </div>
                                                <div className="alert-message">
                                                    <strong>Note:</strong> If you do not wish to update password keep it
                                                    blank.
                                                </div>
                                            </div>

                                            <label>
                                                Password <span className="required-field">*</span>
                                            </label>
                                            <input
                                                className={`form-control form-control-lg mb-3 ${
                                                    errors.password ? 'is-invalid' : ''
                                                }`}
                                                type="password"
                                                name="password"
                                                value={formData.password}
                                                autoComplete="false"
                                                onChange={handleChange}
                                            />
                                            {errors.password && (
                                                <div className="invalid-feedback mb-3">{errors.password}</div>
                                            )}
                                            <label>
                                                Confirm Password <span className="required-field">*</span>
                                            </label>
                                            <input
                                                className={`form-control form-control-lg mb-3 ${
                                                    errors.confirmPassword ? 'is-invalid' : ''
                                                }`}
                                                type="password"
                                                name="confirmPassword"
                                                value={formData.confirmPassword}
                                                onChange={handleChange}
                                                autoComplete="false"
                                            />
                                            {errors.confirmPassword && (
                                                <div className="invalid-feedback mb-3">{errors.confirmPassword}</div>
                                            )}
                                        </>
                                    )}

                                    <div className="text-end mt-2">
                                        <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                                            Save Details{' '}
                                            {loading && !globalError.isError && (
                                                <div className="spinner-border spinner-border-sm   me-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default CustomerUpdateForm;
