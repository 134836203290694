// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-content .sidebar-item {
  border-bottom: 1px solid #3e4f5a;
}

.sidebar-item .list-inline, .list-unstyled {
  list-style: none;
  padding-left: 0;
  background-color: #434e5a;
}`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/sidebar.scss"],"names":[],"mappings":"AACI;EACI,gCAAA;AAAR;;AAKA;EACI,gBAAA;EACA,eAAA;EACA,yBAAA;AAFJ","sourcesContent":[".sidebar-content {\n    .sidebar-item {\n        border-bottom: 1px solid #3e4f5a;\n    }\n}\n\n\n.sidebar-item .list-inline, .list-unstyled {\n    list-style: none;\n    padding-left: 0;\n    background-color: #434e5a;\n  }\n  \n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
