/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { UserRole } from '../../../types/user';
type BankingInfoProp = {
    formData: any; //UserProfile;
    formErrors: any;
    inputChangeHandler: (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void;
    userRole: UserRole;
    updateFlow?: boolean;
};

const EducationAndExperienceInfo = (props: BankingInfoProp) => {
    const { formData, formErrors, inputChangeHandler } = props;
    return (
        <>
            {' '}
            <label>
                Highest Qualification {props.userRole === UserRole.employee && <span className="required-field">*</span>}
            </label>
            <select
                name="highestEducationalQualification"
                className={`form-control form-control-lg mb-3 ${
                    formErrors.highestEducationalQualification ? 'is-invalid' : ''
                }`}
                value={formData.highestEducationalQualification}
                onChange={inputChangeHandler}
            >
                <option value="0">Select Highest Qualification</option>
                <option value="No formal education">No formal education</option>
                <option value="Primary education">Primary education</option>
                <option value="Secondary education">Secondary education or high school</option>
                <option value="Vocational qualification">Vocational qualification</option>
                <option value="Bachelor degree">Bachelor degree</option>
                <option value="Master degree">Master degree</option>
                <option value="Doctorate or higher">Doctorate or higher</option>
            </select>
            {formErrors.highestEducationalQualification && (
                <div className="invalid-feedback mb-3">{formErrors.highestEducationalQualification}</div>
            )}
            <label>
                Total Work Experience {props.userRole === UserRole.employee && <span className="required-field">*</span>}
            </label>
            <select
                name="totalWorkExperience"
                className={`form-control form-control-lg mb-3 ${formErrors.totalWorkExperience ? 'is-invalid' : ''}`}
                value={formData.totalWorkExperience}
                onChange={inputChangeHandler}
            >
                <option value="0">Select Total Work Experience</option>
                <option value="less than 6 months">less than 6 months</option>
                <option value="more than 6 months">more than 6 months</option>
                <option value="1">1 year</option>
                <option value="2">2 years</option>
                <option value="3">3 years</option>
                <option value="4">4 years</option>
                <option value="5">5 years</option>
                <option value="6">6 years</option>
                <option value="7">7 years</option>
                <option value="8">8 years</option>
                <option value="9">9 years</option>
                <option value="10">10 years</option>
                <option value="11">11 years</option>
                <option value="12">12 years</option>
                <option value="13">13 years</option>
                <option value="14">14 years</option>
                <option value="15">15 years</option>
            </select>
            {formErrors.totalWorkExperience && <div className="invalid-feedback mb-3">{formErrors.totalWorkExperience}</div>}
        </>
    );
};

export default EducationAndExperienceInfo;
