// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar .nav-icon svg {
  cursor: pointer;
}
.navbar .nav-link {
  cursor: pointer;
}
.navbar .timer {
  font-weight: bold;
  padding: 7px;
  width: 100px;
  text-align: center;
  background-color: #5f87b5;
  color: white;
  border-radius: 5px;
}
.navbar .username {
  margin-top: -2px;
}
.navbar .avatar {
  margin-top: -4px;
}
.navbar .dropdown .dropdown-menu.show {
  top: 40px !important;
}
@media (max-width: 575.98px) {
  .navbar .dropdown .dropdown-menu.show {
    top: 60px !important;
    right: 20px !important;
    left: 20px !important;
  }
}
.navbar .login-details {
  position: absolute;
  bottom: -40px;
  right: 0px;
  color: var(--bs-gray-dark);
}
.navbar .bg-header-light {
  background: #5f87b5 !important;
}
.navbar a:active {
  color: inherit;
  background-color: inherit;
}
.navbar .notification-badge {
  position: absolute;
  z-index: 9;
  right: 14px;
  top: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.scss"],"names":[],"mappings":"AAGQ;EACI,eAAA;AAFZ;AAMI;EACQ,eAAA;AAJZ;AAOI;EACI,iBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;AALR;AAOI;EACI,gBAAA;AALR;AAOI;EACI,gBAAA;AALR;AAQI;EACI,oBAAA;AANR;AAOQ;EAFJ;IAGQ,oBAAA;IACA,sBAAA;IACA,qBAAA;EAJV;AACF;AAOI;EACI,kBAAA;EACA,aAAA;EACA,UAAA;EACA,0BAAA;AALR;AAQI;EACI,8BAAA;AANR;AASI;EACI,cAAA;EACA,yBAAA;AAPR;AAUI;EACI,kBAAA;EACA,UAAA;EACA,WAAA;EACA,QAAA;AARR","sourcesContent":["\n.navbar {\n    & .nav-icon {\n        & svg {\n            cursor: pointer;\n        }\n    }\n\n    & .nav-link { \n            cursor: pointer; \n    }\n\n    & .timer {\n        font-weight: bold;\n        padding: 7px;\n        width: 100px;\n        text-align: center;\n        background-color: #5f87b5;\n        color: white;\n        border-radius: 5px; \n    }\n    & .username {\n        margin-top: -2px;\n    }\n    & .avatar {\n        margin-top: -4px;\n    }\n\n    & .dropdown .dropdown-menu.show {\n        top:40px !important;\n        @media(max-width: 575.98px) { \n            top: 60px !important;\n            right: 20px !important;\n            left: 20px !important;\n        }\n    }\n\n    & .login-details {\n        position: absolute;\n        bottom:-40px;\n        right: 0px;\n        color: var(--bs-gray-dark);\n    }\n\n    & .bg-header-light {\n        background:#5f87b5 !important\n    }\n\n    a:active {\n        color: inherit;\n        background-color: inherit;\n    }\n\n    .notification-badge {\n        position: absolute; \n        z-index: 9;\n        right: 14px;\n        top: 2px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
