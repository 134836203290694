import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';
import { EmployeeTimesheet, TaskDetails } from '../../../types/timesheetType';
import '../Submit/EmployeeTimesheetEntryForm.scss';

type TimesheetDetailModalProp = {
    timesheetDetails: EmployeeTimesheet;
    showModal: boolean;
    handleClose: () => void;
};
export const TimesheetDetailModal = (prop: TimesheetDetailModalProp) => {
    const { taskDetails } = prop.timesheetDetails;
    return (
        <Modal centered={true} show={prop.showModal}>
            <Modal.Header>
                <Modal.Title>
                    <h3 className="ms-2">Timesheet - Request #: {prop.timesheetDetails.requestNumber}</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="p-3">
                    <div className="col-md-12 mt-4 mb-4 timesheet-entry-form">
                        {taskDetails.length > 0 && (
                            <>
                                <div className="row ms-md-1 col-md-12 p-1 header-row border">
                                    <div className="col-3  header-col">
                                        <div className=" heading"> Task</div>
                                    </div>
                                    <div className="col-3 header-col">
                                        <div className=" heading">Hours Spent</div>
                                    </div>
                                    <div className="col-4 header-col">
                                        <div className=" heading">Comments</div>
                                    </div>
                                </div>
                                {taskDetails.map((detail: TaskDetails, index: number) => (
                                    <div key={`${detail.task}${index}`} className="row ms-md-1 p-1 col-md-12 data-row">
                                        <div className="col-3  data-col">
                                            {' '}
                                            {detail.task === 'Other' ? <>{detail.task}</> : detail.task}
                                        </div>
                                        <div className="col-3 data-col"> {detail.hours}</div>
                                        <div className="col-4 data-col"> {detail.comments ? detail.comments : '-'}</div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                    <hr />
                    <h4>Approval</h4>
                    <div
                        className={cn('alert', {
                            'alert-danger': prop.timesheetDetails.approvalStatus === 'REJECTED',
                            'alert-success': prop.timesheetDetails.approvalStatus === 'APPROVED',
                            'alert-info': prop.timesheetDetails.approvalStatus === 'REQUESTED',
                        })}
                    >
                        <div className="alert-message">
                            <p className="mt-3 mb-1">
                                <strong className="pe-2">Approval Status:</strong> {prop.timesheetDetails.approvalStatus}
                            </p>
                            {(prop.timesheetDetails.approvalStatus === 'APPROVED' ||
                                prop.timesheetDetails.approvalStatus === 'REJECTED') && (
                                <>
                                    <p className="mt-1 mb-1">
                                        <strong className="pe-2">Approver:</strong> {prop.timesheetDetails.approverDetail}
                                    </p>
                                    <p className="mb-2">
                                        <strong className="pe-2">Action Date:</strong>{' '}
                                        {moment(prop.timesheetDetails.approvalDate).format('DD/MM/YYYY')}
                                    </p>
                                    <p className="mb-1">
                                        <strong className="pe-2">Approver Comments:</strong>
                                    </p>
                                    <p className="mb-2">{prop.timesheetDetails.approverComments ?? '-'}</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => prop.handleClose()}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
