import React, { useState } from 'react';
import { Notyf, NotyfEvent } from 'notyf';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, IRootState } from '../../store';
import { resetGlobalError } from '../../store/errorHandler';
import 'notyf/notyf.min.css'; // for React, Vue and Svelte
import './GlobalError.scss'; // for React, Vue and Svelte
import { setAccessToken } from '../../store/token';

// Create an instance of Notyf
const notyf = new Notyf();
const duration = 5000;
const GlobalErrorHandler = () => {
    const [isDisplayed, setIsDisplayed] = useState(false);
    const dispatch: AppDispatch = useDispatch();
    const { isError, message } = useSelector((state: IRootState) => state.error);

    if (isError) {
        // If token expires, set to null.
        if (message.includes(`is expired`)) {
            dispatch(setAccessToken(null));
        } else if (!isDisplayed) {
            setIsDisplayed(true);
            // Display an error notification
            notyf
                .error({
                    message,
                    dismissible: true,
                    duration,
                    position: {
                        x: 'center',
                        y: 'top',
                    },
                })
                .on(NotyfEvent.Dismiss, () => setIsDisplayed(false));
            setTimeout(() => {
                setIsDisplayed(false);
            }, duration);
        }
        dispatch(resetGlobalError());
    }
    return <></>;
};
export default GlobalErrorHandler;
