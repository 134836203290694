// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/font/GoldmanRegular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import 'react-datepicker/dist/react-datepicker'; */
@font-face {
  font-family: goldman;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main.content {
  margin-top: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.form-label {
  color: #374151;
}

.error-message {
  background: rgb(142, 63, 63);
  color: white;
  padding: 10px 15px;
}

.error {
  color: #8c1919;
}

.card-body__min-height {
  min-height: 600px;
}

.bg-app-header {
  background: #636683;
}

.bg-app-row {
  background: #dadced;
  color: #50516a;
}
.bg-app-row__border-bottom {
  border-bottom: 1px solid #b4b5cb;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA,sDAAA;AAEA;EACE,oBAAA;EACA,4CAAA;AAAF;AAGA;EACE,SAAA;EACA,8JAAA;EAGA,mCAAA;EACA,kCAAA;AAHF;;AAMA;EACE,gBAAA;AAHF;;AAMA;EACE,+EAAA;AAHF;;AAOA;EACE,cAAA;AAJF;;AAOA;EACE,4BAAA;EACA,YAAA;EACA,kBAAA;AAJF;;AAOA;EACE,cAAA;AAJF;;AASI;EACE,iBAAA;AANN;;AAYA;EACE,mBAAA;AATF;;AAYA;EACE,mBAAA;EACA,cAAA;AATF;AAWE;EACE,gCAAA;AATJ","sourcesContent":["/* @import 'react-datepicker/dist/react-datepicker'; */\n\n@font-face {\n  font-family: goldman;\n  src: url(./assets/font/GoldmanRegular.ttf);\n}\n\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\nmain.content {\n  margin-top: 15px;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.form-label {\n  color: #374151;\n}\n\n.error-message {\n  background: rgb(142, 63, 63);\n  color: white;\n  padding: 10px 15px ;\n}\n\n.error {\n  color: #8c1919\n}\n\n.card {\n  &-body {\n    &__min-height {\n      min-height: 600px;\n    }\n  }\n}\n\n\n.bg-app-header {\n  background: #636683;\n}\n\n.bg-app-row {\n  background: #dadced;\n  color: #50516a;\n\n  &__border-bottom {\n    border-bottom: 1px solid #b4b5cb;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
