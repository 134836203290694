/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getMasterData, saveMasterFields } from '../../../services/data';
import { MasterField } from '../../../types/orgData';
import '../ControlCenter.scss';
import { useAppContext } from '../../../hooks/useAppContext';
import { AlphaNumericAndCharactersRegex } from '../../../constants/validations';

const ManageMasterData = () => {
    const { triggerSuccess } = useAppContext();
    const [masterFields, setMasterFields] = useState<MasterField[]>([]);
    const [loadingData, setLoadingData] = useState(false);
    const [selectedField, setSelectedField] = useState<MasterField>();
    const [fieldValue, setFieldValue] = useState('');
    const [error, setError] = useState('');
    const [submitError, setSubmitError] = useState('');
    const [submitLoading, setSubmitLoading] = useState(false);
    const [updatedFields, setUpdatedFields] = useState<string[]>([]);

    const clearFieldValue = () => {
        setError('');
        setFieldValue('');
        setSubmitError('');
    };
    const handleSubmit = async () => {
        try {
            setSubmitLoading(true);
            if (updatedFields.length === 0) {
                setSubmitError('Nothing is updated');
            } else {
                const fieldsToUpdate = masterFields.filter((field) => updatedFields.includes(field.name));
                await saveMasterFields(fieldsToUpdate);
                triggerSuccess(`Master fields values updated successfully.`);
                clearFieldValue();
            }
            setSubmitLoading(false);
        } catch (_e) {
            setSubmitLoading(false);
        }
    };

    const handleClick = (fieldName: string) => {
        if (masterFields.length) {
            const selected = masterFields.find((masterField) => masterField.name === fieldName);
            setSelectedField(selected);
            clearFieldValue();
        }
    };

    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setError('');
        setFieldValue(e.target.value);
    };

    const addFieldValue = () => {
        if (fieldValue === '') {
            setError('Name is a required field');
        } else if (!AlphaNumericAndCharactersRegex.test(fieldValue)) {
            setError('Name should only contain alphanumeric characters, hyphens, underscores, dot and spaces');
        } else if (fieldValue.length > 20) {
            setError('Value should be shorter than 20 characters');
        } else if (
            selectedField &&
            selectedField.value.some((existing) => existing.toLowerCase() === fieldValue.toLowerCase())
        ) {
            setError('Value already exists');
        } else if (selectedField) {
            // Assuming you have a state variable like masterFields and setSelectedField
            const updatedMasterFields = masterFields.map((field) => {
                if (field === selectedField) {
                    return {
                        ...field,
                        value: [...field.value, fieldValue],
                    };
                }
                return field;
            });

            setSelectedField({
                ...selectedField,
                value: [...selectedField.value, fieldValue],
            });
            setUpdatedFields([...updatedFields, selectedField.name]);
            setMasterFields(updatedMasterFields);
            clearFieldValue();
        }
    };

    const removeFieldValue = (valueToRemove: string) => {
        if (selectedField) {
            const updatedSelectedField = {
                ...selectedField,
                value: selectedField.value.filter((value) => value !== valueToRemove),
            };

            const updatedMasterFields = masterFields.map((field) => {
                if (field === selectedField) {
                    return updatedSelectedField;
                }
                return field;
            });
            setUpdatedFields([...updatedFields, selectedField.name]);
            setSelectedField(updatedSelectedField);
            setMasterFields(updatedMasterFields);
        }
    };

    useEffect(() => {
        const getMasterFields = async () => {
            setLoadingData(true);
            const res = await getMasterData();
            if (res) {
                setMasterFields(res.data);
                setSelectedField(res.data[0]);
            }
            setLoadingData(false);
        };
        getMasterFields();
    }, []);

    return (
        <main className="content control-center master-data">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body page-control-center-info">
                                {' '}
                                <p>
                                    <FontAwesomeIcon className="me-3" size="lg" icon={icon({ name: 'shield-alt' })} />{' '}
                                    Application Control Center{' '}
                                    <FontAwesomeIcon className="me-3 ms-3" icon={icon({ name: 'chevron-right' })} />{' '}
                                    Application Data Hub
                                </p>
                            </div>
                            <div className="card-body col-12">
                                <div className="container bg-light p-3 pb-1">
                                    <p>This feature allows you to efficiently manage some common attributes.</p>
                                    <ul>
                                        <li>
                                            <strong>Pre-defined attributes:</strong>
                                            <ul>
                                                <li>
                                                    The fields listed here are predefined, you can update the values assigned
                                                    to each attributes.
                                                </li>
                                                <li>
                                                    In case you need to add more fields which needs to be managed with this
                                                    feature, contact support team.
                                                </li>
                                            </ul>
                                        </li>{' '}
                                    </ul>
                                </div>
                                <div className="form-container bg-light p-4 pt-1">
                                    {loadingData && (
                                        <div className="row mt-4">
                                            <div className="col-sm-12 mt-2 mb-2 text-center">
                                                <div className="spinner-border text-primary me-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {masterFields.length > 0 && (
                                        <div>
                                            <div className="row mt-3  data-row col-12">
                                                <div className="col-md-4 px-0">
                                                    <ul>
                                                        {masterFields.map((field) => (
                                                            <li
                                                                className={cn('cursor-pointer', {
                                                                    active: selectedField?.name === field.name,
                                                                })}
                                                                key={`${field.name}`}
                                                                onClick={() => handleClick(`${field.name}`)}
                                                            >
                                                                {capitalize(field.name)}{' '}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className="col-md-6 field-col p-2">
                                                    {selectedField?.value && (
                                                        <>
                                                            <div className="field-value-container">
                                                                {selectedField.value.map((value) => (
                                                                    <div className="row field-values" key={`${value}`}>
                                                                        <div className="col-11">{value}</div>
                                                                        <div className="col-1">
                                                                            <FontAwesomeIcon
                                                                                className="me-3 cursor-pointer"
                                                                                size="sm"
                                                                                icon={icon({ name: 'trash-can' })}
                                                                                onClick={() => removeFieldValue(value)}
                                                                            />{' '}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className="row field-values">
                                                                <div className="col-11">
                                                                    <input
                                                                        type="text"
                                                                        name="fieldValue"
                                                                        className={`form-control form-control-lg p-2 mb-3 ${
                                                                            error ? 'is-invalid' : ''
                                                                        }`}
                                                                        onChange={handleValueChange}
                                                                        value={fieldValue}
                                                                        maxLength={50}
                                                                    />
                                                                    {error && (
                                                                        <div className="invalid-feedback mb-3 bg-white p-2">
                                                                            {error}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="col-1">
                                                                    <FontAwesomeIcon
                                                                        className={cn('cursor-pointer mt-3', {
                                                                            disabled: fieldValue.length === 0,
                                                                            'text-success': fieldValue.length > 0,
                                                                        })}
                                                                        size="lg"
                                                                        icon={icon({ name: 'circle-plus' })}
                                                                        onClick={addFieldValue}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row  mt-2 data-row col-12">
                                                <div className="col-10  p-0 text-end ">
                                                    {submitError}
                                                    <button
                                                        type="button"
                                                        className="mt-2 btn  btn-primary"
                                                        disabled={submitLoading}
                                                        onClick={handleSubmit}
                                                    >
                                                        Save Changes{' '}
                                                        {submitLoading && (
                                                            <div
                                                                className="spinner-border spinner-border-sm ms-2"
                                                                role="status"
                                                            >
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ManageMasterData;
