// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-registration .tab-content button {
  margin: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/User/Access/ApplicationAccess.scss"],"names":[],"mappings":"AAGQ;EACI,WAAA;AAFZ","sourcesContent":["\n.user-registration {\n    .tab-content {\n        button {\n            margin: 5px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
