import { request } from '../api';
import { HolidayEvent } from '../types/calendar';

export const getLeavesAndHolidays = async () => {
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/calendar/events`,
        data: {
            countryCode: 'IN',
            year: new Date().getFullYear().toString(),
        },
    };
    return request<{ data: HolidayEvent[] }>(requestOptions);
};
